import React from 'react'
import { Row, Col, Table } from 'antd'
import PDFLogo from '../../images/macros_pdf/pdf_banner.png'
import BaseMacrosLogo from '../../images/macros_pdf/base_macros_logo.png'
import ForkKnifeLogo from '../../images/macros_pdf/fork_knife_logo.png'
import TrainingMacrosLogo from '../../images/macros_pdf/training_macros_logo.png'
import AlternateMacrosLogo from '../../images/macros_pdf/alternate_macros_logo.png'
import moment from 'moment'
import { baseMacroColumns, trainingAlternateMacroColumns } from './data'
import { StyledLogoBackground, PDFTitle, DownloadTitle, MacroTableTitle, MacroPDFTableStyled, TrainingInfoStyled } from './Macros.styled'
import { LOCAL_STORAGE } from 'constants.js'

const MacroPDFTable = ({ columns, data, img, tableTitle, tableId }) => {
  return (
    <MacroPDFTableStyled>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <MacroTableTitle id={tableId}>
            <img src={img} alt="" />
            <p>{tableTitle}</p>
          </MacroTableTitle>
        </Col>
        <Col xs={24}>
          <Table columns={columns} dataSource={data} size="small" />
        </Col>
      </Row>
    </MacroPDFTableStyled>
  )
}

const PDFGreeting = ({ name, program }) => {
  const today = moment().format('LL')
  return (
    <Row gutter={[8, 8]} style={{ borderBottom: '1px solid #FF6B00' }}>
      <Col xs={3}>
        <StyledLogoBackground>
          <img src={ForkKnifeLogo} alt="" />
        </StyledLogoBackground>
      </Col>
      <Col xs={15}>
        <PDFTitle>
          <p className="greeting">Hello {name}!</p>
          <p className="middle-text">These are your {program} Macros</p>
          <p className="privacy-message">Macros are for your eyes only please do not share</p>
        </PDFTitle>
      </Col>
      <Col xs={6}>
        <DownloadTitle>
          <p className="downloaded-title">Downloaded on:</p>
          <p className="downloaded-date">{today}</p>
        </DownloadTitle>
      </Col>
    </Row>
  )
}

const TrainingDayInfo = () => {
  return (
    <TrainingInfoStyled>
      <Row gutter={[8, 8]}>
        <Col xs={14}>
          <p style={{ fontWeight: 'bold' }}>On TRAINING DAYS you will add the “Pre-Workout Grams” on top of your base macros:</p>
          <p>The KETOGAINS Pre-Workout MIX (Use your “Pre-Workout Grams”).</p>
          <p>- Coffee / Tea (decaf can work as well)</p>
          <p>- Protein: from a zero or low carb animal based Protein Powder</p>
          <p>- Carbs (only in case you are instructed to): Glucose or Dextrose Powder</p>
          <p>- Fat: MCT Powder, MCT Oil or Coconut oil</p>
        </Col>
        <Col xs={10}>
          <p>Take about 20 minutes before training</p>
          <p>(ALTERNATIVELY, YOU COULD USE SOME FOOD THAT MEETS THE MACROS)</p>
        </Col>
      </Row>
    </TrainingInfoStyled>
  )
}

const parseMacroTable = (macroTable) => {
  if (!macroTable) return null

  return {
    Protein: {
      BaseGrams: macroTable.protein_base_grams,
      PreWorkOutGrams: macroTable.protein_pre_workout_grams,
      TotalGrams: macroTable.protein_total_grams,
      Kcal: macroTable.protein_kcal_gram,
      DailyKcals: macroTable.protein_daily_kcals,
      PercentCalories: macroTable.protein_percent_calories,
    },
    Carbs: {
      BaseGrams: macroTable.carbs_base_grams,
      PreWorkOutGrams: macroTable.carbs_pre_workout_grams,
      TotalGrams: macroTable.carbs_total_grams,
      Kcal: macroTable.carbs_kcal_gram,
      DailyKcals: macroTable.carbs_daily_kcals,
      PercentCalories: macroTable.carbs_percent_calories,
    },
    Fat: {
      BaseGrams: macroTable.fat_base_grams,
      PreWorkOutGrams: macroTable.fat_pre_workout_grams,
      TotalGrams: macroTable.fat_total_grams,
      Kcal: macroTable.fat_kcal_gram,
      DailyKcals: macroTable.fat_daily_kcals,
      PercentCalories: macroTable.fat_percent_calories,
    },
    Total: macroTable.total_daily_kcals,
  }
}

export default ({ macros, display }) => {
  const nickname = localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)
  const trainingDay = parseMacroTable(macros.macro_tables.filter((m) => m.type === 'TrainingDay')[0])
  const baseDay = parseMacroTable(macros.macro_tables.filter((m) => m.type === 'BaseDay')[0])
  const alternate = parseMacroTable(macros.macro_tables.filter((m) => m.type === 'Alternate')[0])

  const baseMacrodata = [
    {
      key: '1',
      name: 'Protein',
      baseMacroGrams: baseDay && baseDay.Protein ? `${baseDay.Protein.BaseGrams} g` : 0,
      totalGrams: baseDay && baseDay.Protein ? `${baseDay.Protein.TotalGrams} g` : 0,
      kcalGram: baseDay && baseDay.Protein ? `${baseDay.Protein.Kcal}` : 0,
      dailyKcals: baseDay && baseDay.Protein ? baseDay.Protein.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '2',
      name: 'Carbs (Net)',
      baseMacroGrams: baseDay && baseDay.Carbs ? `${baseDay.Carbs.BaseGrams} g` : 0,
      totalGrams: baseDay && baseDay.Carbs ? `${baseDay.Carbs.TotalGrams} g` : 0,
      kcalGram: baseDay && baseDay.Carbs ? `${baseDay.Carbs.Kcal}` : 0,
      dailyKcals: baseDay && baseDay.Carbs ? baseDay.Carbs.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '3',
      name: 'Fat',
      baseMacroGrams: baseDay && baseDay.Fat ? `${baseDay.Fat.BaseGrams} g` : 0,
      totalGrams: baseDay && baseDay.Fat ? `${baseDay.Fat.TotalGrams} g` : 0,
      kcalGram: baseDay && baseDay.Fat ? `${baseDay.Fat.Kcal}` : 0,
      dailyKcals: baseDay && baseDay.Fat ? baseDay.Fat.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '4',
      name: '',
      baseMacroGrams: '',
      totalGrams: '',
      kcalGram: 'Total Daily Calories',
      dailyKcals: baseDay && baseDay.Total ? baseDay.Total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
  ]

  const trainingMacrodata = [
    {
      key: '1',
      name: 'Protein',
      baseMacroGrams: trainingDay && trainingDay.Protein ? `${trainingDay.Protein.BaseGrams} g` : 0,
      preWorkoutGrams: trainingDay && trainingDay.Protein ? `${trainingDay.Protein.PreWorkOutGrams} g` : 0,
      totalGrams: trainingDay && trainingDay.Protein ? `${trainingDay.Protein.TotalGrams} g` : 0,
      kcalGram: trainingDay && trainingDay.Protein ? `${trainingDay.Protein.Kcal}` : 0,
      dailyKcals: trainingDay && trainingDay.Protein ? trainingDay.Protein.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '2',
      name: 'Carbs (Net)',
      baseMacroGrams: trainingDay && trainingDay.Carbs ? `${trainingDay.Carbs.BaseGrams} g` : 0,
      preWorkoutGrams: trainingDay && trainingDay.Carbs ? `${trainingDay.Carbs.PreWorkOutGrams} g` : 0,
      totalGrams: trainingDay && trainingDay.Carbs ? `${trainingDay.Carbs.TotalGrams} g` : 0,
      kcalGram: trainingDay && trainingDay.Carbs ? `${trainingDay.Carbs.Kcal}` : 0,
      dailyKcals: trainingDay && trainingDay.Carbs ? trainingDay.Carbs.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '3',
      name: 'Fat',
      baseMacroGrams: trainingDay && trainingDay.Fat ? `${trainingDay.Fat.BaseGrams} g` : 0,
      preWorkoutGrams: trainingDay && trainingDay.Fat ? `${trainingDay.Fat.PreWorkOutGrams} g` : 0,
      totalGrams: trainingDay && trainingDay.Fat ? `${trainingDay.Fat.TotalGrams} g` : 0,
      kcalGram: trainingDay && trainingDay.Fat ? `${trainingDay.Fat.Kcal}` : 0,
      dailyKcals: trainingDay && trainingDay.Fat ? trainingDay.Fat.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '4',
      name: '',
      baseMacroGrams: '',
      preWorkoutGrams: '',
      totalGrams: '',
      kcalGram: 'Total Daily Calories',
      dailyKcals: trainingDay && trainingDay.Total ? trainingDay.Total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
  ]

  const alternateMacrodata = [
    {
      key: '1',
      name: 'Protein',
      baseMacroGrams: alternate && alternate.Protein ? `${alternate.Protein.BaseGrams} g` : 0,
      preWorkoutGrams: alternate && alternate.Protein ? `${alternate.Protein.PreWorkOutGrams} g` : 0,
      totalGrams: alternate && alternate.Protein ? `${alternate.Protein.TotalGrams} g` : 0,
      kcalGram: alternate && alternate.Protein ? `${alternate.Protein.Kcal}` : 0,
      dailyKcals: alternate && alternate.Protein ? alternate.Protein.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '2',
      name: 'Carbs (Net)',
      baseMacroGrams: alternate && alternate.Carbs ? `${alternate.Carbs.BaseGrams} g` : 0,
      preWorkoutGrams: alternate && alternate.Carbs ? `${alternate.Carbs.PreWorkOutGrams} g` : 0,
      totalGrams: alternate && alternate.Carbs ? `${alternate.Carbs.TotalGrams} g` : 0,
      kcalGram: alternate && alternate.Carbs ? `${alternate.Carbs.Kcal}` : 0,
      dailyKcals: alternate && alternate.Carbs ? alternate.Carbs.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '3',
      name: 'Fat',
      baseMacroGrams: alternate && alternate.Fat ? `${alternate.Fat.BaseGrams} g` : 0,
      preWorkoutGrams: alternate && alternate.Fat ? `${alternate.Fat.PreWorkOutGrams} g` : 0,
      totalGrams: alternate && alternate.Fat ? `${alternate.Fat.TotalGrams} g` : 0,
      kcalGram: alternate && alternate.Fat ? `${alternate.Fat.Kcal}` : 0,
      dailyKcals: alternate && alternate.Fat ? alternate.Fat.DailyKcals.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
    {
      key: '4',
      name: '',
      baseMacroGrams: '',
      preWorkoutGrams: '',
      totalGrams: '',
      kcalGram: 'Total Daily Calories',
      dailyKcals: alternate && alternate.Total ? alternate.Total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0,
    },
  ]

  return (
    <div id="divToOfferInfo" style={{ display: display, textAlign: 'center', margin: '0 auto', width: '95%', zoom: '0.65', padding: '20px' }}>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <img style={{ width: '100%' }} src={PDFLogo} alt="" id="MacroLogoPDF" />
        </Col>
      </Row>
      <PDFGreeting name={nickname} program={macros.program.description} />
      <MacroPDFTable tableId={'restDay'} columns={baseMacroColumns} data={baseMacrodata} img={BaseMacrosLogo} tableTitle="Rest Day (Base) Macros" />
      <MacroPDFTable
        tableId={'trainingDay'}
        columns={trainingAlternateMacroColumns}
        data={trainingMacrodata}
        img={TrainingMacrosLogo}
        tableTitle="Training Day Macros"
      />
      <TrainingDayInfo />
      {/* <MacroTable id="restDay" title="Rest Day (Base) Macros" primaryColor="#7A8DC8" macro={baseDay} hidePreWorkOut />
                              <MacroTable id="trainingDay" title="Training Day Macros" primaryColor="#FF8B38" macro={trainingDay} showDescription /> */}
      {!!alternate && (
        <MacroPDFTable
          tableId={'alternate'}
          columns={trainingAlternateMacroColumns}
          data={alternateMacrodata}
          img={AlternateMacrosLogo}
          tableTitle="Alternate Macros (Only to be used if instructed)"
        />
      )}
    </div>
  )
}
