import React, { useState, useEffect } from 'react'
import { ContainerCarouselSets, Preview, ContainerImg } from './CarouselSets.styled'
import { ReactComponent as ArrowLeft } from 'images/exercise/arrow-left-set.svg'
import { ReactComponent as ArrowRight } from 'images/exercise/arrow-right-set.svg'
import defaultExerciseThumbnail from 'assets/icons/defaultExerciseThumbnail.svg'
import { GetThumbnail } from 'services/ExerciseUtilities'
import Message from 'components/Message'

const CarouselSets = ({ exercises, skipExercise, showMessage }) => {
  if (!exercises || exercises.length === 0) {
    return null
  }

  const getInitialValues = () => {
    const screenResolution = window.screen.width
    const totalExercises = exercises.length
    const maxLengthShow = 6

    let initialProgressBar = 0
    let initialCounterLeft = 0
    let initialProgressPointer = 0
    let initialCarrouselProgress = 0
    let initialCounterRight = totalExercises - maxLengthShow

    if (screenResolution > 375) {
      initialProgressBar = -325
      initialProgressPointer = 60
      initialCarrouselProgress = 50
    } else if (screenResolution <= 375 && screenResolution >= 321) {
      initialProgressBar = -310
      initialProgressPointer = 50
      initialCarrouselProgress = 40
    } else {
      if (screenResolution <= 320) {
        initialProgressBar = -283
        initialProgressPointer = 23
        initialCarrouselProgress = 12
      }
    }

    return {
      initialProgressBar,
      initialCounterLeft,
      initialCounterRight,
      initialProgressPointer,
      initialCarrouselProgress,
    }
  }

  const { initialProgressBar, initialCounterLeft, initialCounterRight, initialProgressPointer, initialCarrouselProgress } = getInitialValues()

  // Index
  const [data, setData] = useState({
    index: 0,
  })
  const [count, setCount] = useState(initialCounterRight)
  const [posX, setPosX] = useState(initialCarrouselProgress)
  const [countLeft, setCountLeft] = useState(initialCounterLeft)
  const [pointerX, setPointerX] = useState(initialProgressPointer)
  const [progressBar, setProgressBar] = useState(initialProgressBar)

  const [first, setFirst] = useState(true)
  let x = exercises.findIndex((exercise) => !!exercise.isCurrent)

  useEffect(() => {
    const totalExercises = exercises.length
    const calculatedValue = (totalExercises - 6) * -50 + initialCarrouselProgress

    if (x >= 0 && x <= totalExercises) {
      let sumToP = 0
      setData({ index: x })
      if (!!first) {
        if (x === 1) {
          sumToP = 50
        } else if (x > 1 && x <= 3) {
          sumToP = x * 50
        } else {
          if (x === 4) {
            if (x === totalExercises - 2) {
              sumToP = 200
            } else {
              sumToP = 150
            }
          } else {
            if (x >= 5 && x <= 7) {
              if (x === totalExercises - 1) {
                sumToP = 250
              } else {
                sumToP = 200
                if (x === 7) {
                  sumToP = 300
                }
              }
            }
          }
        }

        if (
          (x === 5 && posX !== calculatedValue) ||
          (x > 3 && x < totalExercises - 2 && posX !== calculatedValue) ||
          (x === 6 && x === totalExercises - 1 && posX !== calculatedValue)
        ) {
          setCountLeft(countLeft + 1)
          setCount(count - 1)
          setPosX(posX - 50)
        }

        if ((x === 6 && x === totalExercises - 2) || (x === 7 && x === totalExercises - 1)) {
          setCountLeft(countLeft + 2)
          setCount(count - 2)
          setPosX(posX - 100)
        }
        setPointerX(pointerX + sumToP)
        setProgressBar(progressBar + sumToP)
      } else {
        if (data.index < totalExercises - 1) {
          if (data.index > 3 && posX !== calculatedValue && data.index < totalExercises - 2) {
            setPosX(posX - 50)
            setCount(count - 1)
            setCountLeft(countLeft + 1)
          }
        }

        if ((data.index >= 0 && data.index < 4) || data.index === totalExercises - 2 || (posX !== calculatedValue && data.index > 0)) {
          setPointerX(pointerX + 50)
          setProgressBar(progressBar + 50)
        }
      }
    }

    skipExercise(x)
    setFirst(false)
  }, [exercises])

  const getExerciseGuide = (exercise) => {
    let url = defaultExerciseThumbnail
    let vimeoVideoURL = exercises[data.index].data.video_url
    const vimeoParameters = 'autoplay=1&loop=1&muted=1&controls=0'
    if (vimeoVideoURL.indexOf('?h=') !== -1) {
      vimeoVideoURL += `&${vimeoParameters}`
    } else {
      vimeoVideoURL += `?${vimeoParameters}`
    }
    let guide

    if (navigator.onLine && exercise.data.video_url) {
      guide = (
        <iframe
          controls
          src={vimeoVideoURL}
          allow="autoplay; fullscreen"
          width="311px"
          height="233px"
          frameBorder="0"
          title={exercise.data.name}
          allowFullScreen={true}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      )
    } else {
      if (exercise.data.gif_url) {
        url = exercise.data.gif_url
      } else if (exercise.data.thumbnails.length > 0) {
        const thumbnailLarge = exercise.data.thumbnails.find(
          (t) => (t.size === 'LARGE' && t.is_recommended) || (t.size === 'LARGE' && !t.is_recommended),
        )
        if (thumbnailLarge) {
          url = thumbnailLarge.url
        }
      }
      guide = <img src={url} alt="" style={{ height: '239px' }} />
    }
    return guide
  }

  const handlePreviousExercise = () => {
    if (data.index > 0) {
      setData({ index: data.index - 1 })
      if (data.index > 1 && data.index < exercises.length - 4 && posX !== initialCarrouselProgress) {
        setPosX(posX + 50)
      }
      if (
        data.index === exercises.length - 1 ||
        (data.index < exercises.length - 1 && data.index > exercises.length - 5) ||
        data.index === 1 ||
        (posX === initialCarrouselProgress && data.index < 5)
      ) {
        setPointerX(pointerX - 50)
        setProgressBar(progressBar - 50)
      }
    }

    if (data.index <= exercises.length - 5 && data.index > 1 && posX !== initialCarrouselProgress) {
      setCountLeft(countLeft - 1)
      setCount(count + 1)
    }

    skipExercise(data.index >= 0 ? data.index - 1 : data.index)
  }

  const handleNextExercise = () => {
    const calculateValue = (exercises.length - 6) * -50 + initialCarrouselProgress
    if (data.index < exercises.length - 1) {
      setData({ index: data.index + 1 })
      if (data.index > 3 && data.index < exercises.length - 2 && posX !== calculateValue) {
        setPosX(posX - 50)
      }
      if ((data.index >= 0 && data.index < 4) || data.index === exercises.length - 2 || (posX === calculateValue && data.index > 0)) {
        setPointerX(pointerX + 50)
        setProgressBar(progressBar + 50)
      }

      if (data.index > 3 && data.index < exercises.length - 2 && posX !== calculateValue) {
        setCountLeft(countLeft + 1)
        setCount(count - 1)
      }
    }

    skipExercise(data.index <= exercises.length - 1 ? data.index + 1 : data.index)
  }

  return (
    <ContainerCarouselSets countLeft={countLeft} count={count} progressBar={progressBar} posX={posX}>
      {!!showMessage && (
        <div className="wrap-message">
          <Message type="success" message="Your workout data was successfully saved!" />
        </div>
      )}
      <div className="container-carousel-sets">
        <div className="progress-bar" />
        <div style={{ transform: `translate3d(${posX}px, 0px, 10px)` }}>
          {exercises.map((set, i) => {
            const data = set.data
            return (
              <div key={set.id} style={exercises.length - count === i || countLeft === i + 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                <ContainerImg i={i} complete={set.isCompleted}>
                  <img
                    id={i}
                    src={GetThumbnail(data)}
                    alt=""
                    style={exercises.length - count === i || countLeft === i + 1 ? { visibility: 'hidden' } : { visibility: 'visible' }}
                  />
                </ContainerImg>
              </div>
            )
          })}
        </div>
      </div>

      <Preview pointer={pointerX} count={count} countLeft={countLeft}>
        <div className="carousel-preview">
          <div className="preview">{getExerciseGuide(exercises[data.index])}</div>

          <ArrowLeft className="previous" data-title="Previous Exercise" onClick={handlePreviousExercise} />
          <ArrowRight className="next" data-title="Next Exercise" onClick={handleNextExercise} />
        </div>
      </Preview>
    </ContainerCarouselSets>
  )
}

export default CarouselSets
