import React from 'react'
import PropTypes from 'prop-types'
import { Radio, Checkbox, Tooltip } from 'antd'
import { GroupCards } from './Selectable.styled'
import { ReactComponent as NoImage } from 'images/picture.svg'
import { ReactComponent as Helper } from 'assets/icons/question-circle.svg'

const SelectableCards = ({ type, defaultValue, catalog, value, onChange }) => {
  let Parent
  let Child

  if (type === 'radio') {
    Parent = Radio.Group
    Child = Radio.Button
  } else if (type === 'check') {
    Parent = Checkbox.Group
    Child = Checkbox
  }

  return (
    <GroupCards>
      <Parent defaultValue={defaultValue} className="group" value={value} onChange={onChange}>
        {catalog.map((item) => {
          return (
            <div key={item.id} className={item.helper ? 'with-helper' : ''}>
              <Child className={item.disabled ? 'disabled' : ''} value={item.id} disabled={item.disabled ? true : false}>
                {item.thumbnail ? <img src={item.thumbnail} alt="" /> : <NoImage />}
                <div>
                  <p>{item.name}</p>
                  <p>{item.description}</p>
                </div>
              </Child>
              {item.helper && (
                <Tooltip title={item.helper}>
                  <Helper />
                </Tooltip>
              )}
            </div>
          )
        })}
      </Parent>
    </GroupCards>
  )
}

SelectableCards.propTypes = {
  type: PropTypes.string.isRequired,
}

export default SelectableCards
