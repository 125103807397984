import React, { Fragment } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Root, Content } from './LayoutNavigation.styled'

const LayoutNavigation = ({ title, hideFooter, isBack, children, completeRoutine, isModal, sub, menu }) => {
  return (
    <Fragment>
      <Root>
        <Content>
          <Header title={title} isBack={isBack} completeRoutine={completeRoutine} isModal={isModal} sub={sub} menu={menu} />
          {children}
        </Content>
        {!hideFooter ? <Footer /> : null}
      </Root>
    </Fragment>
  )
}

export default LayoutNavigation
