import styled from '@emotion/styled'
import { Button } from 'antd'

export const ButtonPrimary = styled(Button)`
  background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
  border-radius: 50px;
  width: 100%;
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
  height: 43px;

  i {
    width: 29px;
    height: 29px;
    position: absolute;
    top: 5px;
    right: 7px;
    font-size: 30px;

    & svg {
      width: 29px;
      height: 29px;
    }
  }

  &.active {
    background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%) !important;
    border-color: white !important;
    color: #ffffff !important;
  }

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
    border-radius: 50px;
    border-color: white;
    width: 100%;
    color: #ffffff;
    font-weight: bold;
    height: 43px;
    & i {
      position: absolute;
      top: 5px;
      right: 7px;
      font-size: 30px;
    }
  }

  @media (max-width: 375px) {
    font-size: 16px;
  }

  @media (max-width: 320px) {
    font-size: 13.5px;
    height: 38px;
  }
`
