import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SelectableCards from 'components/Selectable/Cards'
import SectionError from 'components/SectionError'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [cuisine, setCuisine] = useState({
    id: null,
    styles: [],
  })
  const [cuisineStylesCatalogue, setCuisineStyleCatalog] = useState(null)
  const minOptions = 2

  const cuisineStyleRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const catalogCuisineStyles = response.catalogs.cuisine_style
        const { id, cuisine_style } = response.enrollment.mealPlan
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setCuisineStyleCatalog(catalogCuisineStyles)
          setCuisine({
            id: id,
            styles: cuisine_style,
          })
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [cuisine])

  const saveCallback = async ({ goBack }) => {
    const { id, styles } = cuisine
    const data = {
      id,
      cuisine_style: styles,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (cuisine.styles.length >= minOptions) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(cuisineStyleRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'meal_plan' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={cuisineStyleRef}>
          {submit && cuisine.styles.length < minOptions && (
            <Message
              type="error"
              message={'You need to select at least 2 options in this question'}
              textStyle={{ fontSize: '15px' }}
              isMealPlan={true}
            />
          )}
          <div className="question">
            <p className="title">What cuisine styles do you prefer?</p>
            <p>Please select at least {minOptions} options.</p>
          </div>
          <div className="content">
            <SelectableCards
              type="check"
              value={cuisine.styles}
              catalog={cuisineStylesCatalogue}
              onChange={(e) => setCuisine((prev) => ({ ...prev, styles: e }))}
            />
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
