import styled from '@emotion/styled'
import CronometerBanner from 'images/cronometer-banner.svg'

export const Root = styled.div`
  max-width: 460px;
  margin: 0 auto;
  padding: 30px;

  .banner {
    min-height: 90px;
    background: #ff7c14;
    background-image: url(${CronometerBanner});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: 4px;
  }

  h3 {
    line-height: 18px;
    color: #5d5d5d;
    font-weight: 600;
    text-align: center;
    margin: 20px auto;
    width: 80%;
  }

  .information {
    margin-bottom: 40px;

    p {
      color: #959595;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .create-account {
    text-align: center;
    margin-top: 30px;

    p {
      color: #959595;
      font-size: 12px;
      font-weight: bold;

      a {
        color: #ff6b00;
        border-bottom: 1px solid #ff6b00;
        padding: 3px 5px;
      }
    }
  }

  .ant-btn {
    display: block;
    min-width: 210px;
    max-width: max-content;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 22.5px;
    box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.19);
    font-weight: bold;
    border: 1px solid #ff6b00;
    color: #ff6b00;

    &:hover,
    &:active,
    &:visited {
      border: 1px solid #ff6b00;
      color: #ff6b00;
    }

    &.primary {
      color: #ffffff;
      background: linear-gradient(90deg, #ff4713 0%, #ff7e22 100%);
    }
  }

  .process {
    text-align: center;

    .ant-spin-dot i {
      background: #ff6b00;
    }

    .ant-spin-text {
      color: #959595;
      font-weight: 600;
    }
  }

  .ant-result {
    padding-top: 10px;
  }
`
export const SyncButton = styled.div`
  float: right;
  position: relative;
  right: 5px;
  top: -31px;
  min-width: 70px;

  .ant-btn {
    border-radius: 15.5px;
    font-weight: bold;
    font-size: 12px;
    padding: 3px 15px;
    height: 25px;
    line-height: 12px;
    width: 100%;
    color: #ffffff;

    &.inactive {
      background: linear-gradient(90deg, #707070 0%, #a6a6a6 98.29%);
    }

    &.sync {
      background: linear-gradient(90deg, #26ac00 0%, #37e704 100%);
    }
  }
`
