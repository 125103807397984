import React, { Component, Fragment } from 'react'
import { Form } from 'antd'
import { WEEKLY_MESSAGES_DISPLAY_MAP } from 'constants.js'
import { StyledTextArea } from 'styled'
import { Label } from './WeeksEdit.styled'

const FormItem = Form.Item

class WeeklyMessagesEdit extends Component {
  handleChangeMessage = (event, id) => {
    const { measurementData } = this.props
    localStorage.setItem(`WeeklyMessage.${measurementData.for_date}.${id}`, event.target.innerHTML)
  }

  render() {
    const { getFieldDecorator, measurementData } = this.props
    return (
      <>
        {WEEKLY_MESSAGES_DISPLAY_MAP.map(({ id, name }) => {
          let message = ''
          if (!!localStorage.getItem(`WeeklyMessage.${measurementData.for_date}.${id}`))
            message = localStorage.getItem(`WeeklyMessage.${measurementData.for_date}.${id}`)
          else if (!!measurementData[id]) message = measurementData[id]

          return (
            <Fragment key={id}>
              <Label>{name}</Label>
              <FormItem>
                {getFieldDecorator(id, {
                  initialValue: message,
                })(<StyledTextArea rows="6" maxLength="600" onKeyUp={(e) => this.handleChangeMessage(e, id)} />)}
              </FormItem>
            </Fragment>
          )
        })}
      </>
    )
  }
}

export default WeeklyMessagesEdit
