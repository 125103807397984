import React, { useState, useEffect } from 'react'
import Layout from 'components/Layout'
import BannerSection from 'components/Banner/BannerSection'
import BootcampSelect from 'components/BootcampSelect/'
import Loading from 'components/Loading/Loading'
import { Root, MealPlanContainer } from './Main.styled.js'
import { apiFetch } from 'services/API/apiFetch'
import NetworkDetector from 'services/NetworkDetector'
import SectionError from 'components/SectionError'
import moment from 'moment'

import MealPlanEnrollment from 'assets/icons/meal-plan/meal-plan-enrollment.png'
import MealPlanInProgress from 'assets/icons/meal-plan/meal-plan-in-progress.png'
import { navigate } from '@reach/router'
import { LOCAL_STORAGE } from 'constants.js'
import CardResult from 'components/Cards/Result'

const today = moment().local()

const MealPlanContent = (mealPlanSelected) => {
  let text
  let actionLink
  let targetLink
  let actionText
  let image
  let stages = mealPlanSelected.program.enrollment_stage
  const programType = mealPlanSelected.program.type

  const getLastStageMealPlan = (mealPlan) => {
    let isFinished = checkIfMealPlanFinished(stages)
    let stage = 'food-preferences'
    if (isFinished) {
      stage = 'food-preferences'
    } else if (mealPlan.recipes_favorites && mealPlan.recipes_favorites.length >= 5) {
      stage = 'meal-plan-notes'
    } else if (
      (mealPlan.ingredient_dislikes && mealPlan.ingredient_dislikes.length > 0) ||
      (mealPlan.recipes_favorites && mealPlan.recipes_favorites.length > 0)
    ) {
      stage = 'favorite-meals'
    } else if (
      mealPlan.has_sensitivities === false ||
      (mealPlan.ingredient_category_sensitivities && mealPlan.ingredient_category_sensitivities.length >= 1) ||
      (mealPlan.ingredient_sensitivities && mealPlan.ingredient_sensitivities.length >= 1)
    ) {
      stage = 'food-dislikes'
    } else if (mealPlan.cuisine_style && mealPlan.cuisine_style.length >= 2) {
      stage = 'food-allergies'
    } else if (
      mealPlan.meals_per_day &&
      mealPlan.meals_per_day > 0 &&
      mealPlan.heat_level &&
      mealPlan.heat_level > 0 &&
      mealPlan.meal_plan_type &&
      mealPlan.meal_plan_type.length > 0
    ) {
      stage = 'cuisine-styles'
    }

    return stage
  }

  const checkIfMealPlanFinished = (stages) => {
    let isFinished = false
    const hasMealPlan = stages.find((ce) => ce.id === 'meal_plan')
    if (hasMealPlan) {
      const hasLastStage = hasMealPlan.stages.includes('meal-plan-notes')
      if (hasLastStage) {
        isFinished = true
      }
    }
    return isFinished
  }

  switch (mealPlanSelected.meal_plan.status) {
    case 'EnrollmentInProgress':
      text = 'Please submit your information so we can begin customizing your meal plan!'
      actionLink = `/meal-plan/${getLastStageMealPlan(mealPlanSelected.meal_plan)}`
      actionText = 'Personalize My Meal Plan'
      image = MealPlanEnrollment
      break
    case 'EnrollmentCompleted':
    case 'MealPlanPending':
    case 'MealPlanInProgress':
      text = 'Your Meal Plan is cooking! <br /><b>A button will be enabled to download the meal plan file in PDF form when it is completed!</b>'
      image = MealPlanInProgress
      break
    case 'MealPlanCompleted':
      console.log('mealPlanSelected :>> ', mealPlanSelected)
      text = `<b>Your meal plan is ready.</b> 
        <br/> Please download your meal plan at the following link. 
        Please email stephanie@ketogains.com if you have any questions or problem in download!
        ${programType === 'macros' ? 'You have 14 days for download your meal plan' : ''}
      `
      actionLink = mealPlanSelected.meal_plan.url && `${mealPlanSelected.meal_plan.url}`
      targetLink = '_blank'
      actionText = 'Meal plan download'
      image = MealPlanInProgress
      break
    default:
      text = 'Looking for great recipes? <br />We can help: it’s not too late to purchase a meal plan!'
      actionLink = 'https://www.ketogainsbootcamps.com/meal-plan/'
      targetLink = '_blank'
      actionText = 'I Want a Meal Plan'
      image = MealPlanEnrollment
      break
  }

  return <CardResult {...{ text, actionLink, targetLink, actionText, image }} />
}

const Main = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [bootcamps, setBootcamps] = useState([])
  const [mealPlans, setMealPlans] = useState([])
  const [mealPlanSelected, setMealPlanSelected] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const isConnected = await NetworkDetector()
      const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
      if (isConnected) {
        const mealPlansDB = await apiFetch({
          url: `students/${traineeId}/meal-plans/`,
          method: 'GET',
          isConnected,
        }).catch((err) => err)
        if (!mealPlansDB.hasError) {
          if (mealPlansDB.length > 0) {
            let mealPlans = mealPlansDB.filter((mdb) => {
              if (!!!mdb.meal_plan.status || mdb.meal_plan.status === 'EnrollmentInProgress') return true
              let hasTermsAndConditions = false
              let stages = mdb.program.enrollment_stage
              let finishStage = stages.find((s) => s.id === 'finish')
              if (finishStage) {
                hasTermsAndConditions = finishStage.stages.includes('terms-and-conditions')
              }
              return hasTermsAndConditions
            })
            let dropDownBootcamps = mealPlans.map((mp) => {
              return {
                id: mp.program.program_id,
                label: mp.program.description,
                isCurrent: today.isBetween(moment(mp.program.start_date), moment(mp.program.end_date)),
                type: mp.program.type,
              }
            })
            setMealPlans(mealPlans)
            setBootcamps(dropDownBootcamps)
            let mealPlanToSelect
            const mealPlanFinished = sessionStorage.getItem('MEAL_PLAN_FINISHED')
            if (!!mealPlanFinished) {
              mealPlanToSelect = mealPlans.find((mpdb) => mpdb.program.program_id === mealPlanFinished)
            } else {
              mealPlanToSelect = mealPlans.find((mpdb) => today.isBetween(moment(mpdb.program.start_date), moment(mpdb.program.end_date)))
            }

            if (!mealPlanToSelect) {
              mealPlanToSelect = mealPlans[0]
            }
            setMealPlanSelected(mealPlanToSelect)
            setProgram(mealPlanToSelect.program.program_id)
          } else {
            setError('FORBIDDEN')
            navigate('/')
          }
        } else {
          setError('INTERNAL_SERVER_ERROR')
        }
      } else {
        setError('LOST_NETWORK')
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  const handleSelectBootcamp = (value) => {
    const foundMealPlan = mealPlans.find((mp) => mp.program.program_id === value.key)
    setMealPlanSelected(foundMealPlan)
    setProgram(foundMealPlan.program.program_id)
  }

  const setProgram = (programId) => sessionStorage.setItem('BOOTCAMP_ID_SELECTED', programId)

  return (
    <Layout>
      <Root>
        <BannerSection title="My Meal Plan" picture={localStorage.getItem(LOCAL_STORAGE.USER_PICTURE)} />
        <MealPlanContainer>
          {loading && !error && (
            <Loading>
              <div className="rectangle" />
            </Loading>
          )}
          {!loading && error && <SectionError type={error} />}
          {!loading && !error && (
            <>
              {bootcamps[0].type === 'bootcamp' && (
                <BootcampSelect bootcamps={bootcamps} value={mealPlanSelected.program.program_id} onBootcampSelect={handleSelectBootcamp} />
              )}
              <MealPlanContent {...mealPlanSelected} />
            </>
          )}
        </MealPlanContainer>
      </Root>
    </Layout>
  )
}

export default Main
