import React from 'react'
import styled from '@emotion/styled'
import { Dropdown, Menu, Button, Icon } from 'antd'

const DropdownCustom = styled(Dropdown)`
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ff6b00;
  color: #383838;
  text-align: left;
  height: 50px;
  padding: 4px 10px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    border: 1px solid #ff6b00;
    color: #383838;
  }

  span {
    font-size: 17px;
    font-weight: bold;
  }

  small {
    font-size: 10px;
    font-weight: 500;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }

  .anticon {
    border-radius: 50%;
    padding-top: 7px;

    &.status {
      padding-top: 7px;
      width: 28px;
      height: 28px;
      background-color: #ff6b00;
      color: #ffffff;

      &.success {
        background-color: #5dd578;
      }
    }

    &.drop {
      padding-top: 7px;
      float: right;
      position: relative;
      background-color: #ff6b00;
      box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.3);
      color: #ffffff;
      width: 25px;
      height: 25px;
      top: 2px;
    }
  }

  &.vertical {
    small {
      display: block;
      font-weight: bold;
      margin-left: 0px;
      position: relative;
      top: 0px;
    }

    .anticon {
      float: right;
      position: relative;
      top: -32px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding-top: 7px;
    }
  }
`

const StyledDropdownOptions = styled(Menu)`
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18) !important;

  .ant-dropdown-menu-item {
    color: #5d5d5d;
    border-bottom: 1px solid #e5e5e5;

    &:last-of-type {
      border-bottom: none;
    }

    b {
      color: #383838;
      margin-right: 6px;
    }

    .anticon {
      border-radius: 50%;
      padding-top: 4px;
      background-color: #ff6b00;
      color: #ffffff;
      width: 20px;
      height: 20px;

      &.success {
        background-color: #5dd578;
      }
    }

    &:hover,
    &:active {
      /*background-color: #FF6B00 !important;*/
    }
  }
`

const dropDownOptions = (values, onSelect) => {
  return (
    <StyledDropdownOptions
      onClick={(value) => {
        if (onSelect) onSelect(value)
      }}
    >
      {values.map((value, index) => {
        return (
          <Menu.Item key={value.id}>
            {value.icon && <Icon className={`${value.iconClass ? value.iconClass : ''}`} type={value.icon} />}
            <b>{value.label ? value.label : ''}</b>
            {value.description ? value.description : ''}
          </Menu.Item>
        )
      })}
    </StyledDropdownOptions>
  )
}

export const StyledDropdown = (props) => {
  const { options, iconStatus, label, description, onSelect, ...rest } = props
  return (
    <DropdownCustom trigger={['click']} overlay={dropDownOptions(options, onSelect)} {...rest}>
      <Button>
        {iconStatus}
        {label}
        <small>{description}</small>
        <Icon className="drop" type="down" />
      </Button>
    </DropdownCustom>
  )
}
