import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableCards from 'components/Selectable/Cards'
import { getBodyFatPercentage } from 'services'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [level, setLevel] = useState()
  const [levelCatalog, setLevelCatalog] = useState([])
  const [levelSelected, setLevelSelected] = useState([])
  const [availableLevels, setAvailableLevels] = useState([])
  const levelRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const levels = response.catalogs.Levels.map((l) => {
          return {
            id: l.level,
            name: `Level ${l.level}`,
            thumbnail: l.icon,
          }
        })
        const {
          id,
          level,
          has_training,
          gender,
          bf_pct,
          metric,
          height,
          neck,
          belly_waist,
          narrow_waist,
          hip,
          training_regular,
          training_location,
          training_per_week,
          training_session,
        } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          console.table(response.enrollment.data)
          let unit = 'in'
          const metricSplit = metric.split('/')
          if (metricSplit.length > 1) unit = metricSplit[1]
          let levelAux = level
          if (!levelAux || levelAux <= 0) {
            //If the user is returning, the level will be pre-filled with the info of the last bootcamp.
            let bodyFatPercent = !!bf_pct ? bf_pct : getBodyFatPercentage(gender, unit, height, neck, belly_waist, narrow_waist, hip)
            if (has_training) {
              if (training_location === 2) {
                levelAux = 1
              } else {
                if (training_per_week === 1) {
                  levelAux = 1
                } else {
                  if (training_session === 4) {
                    levelAux = 1
                  } else {
                    if (training_regular === 3) levelAux = 1
                    if (training_regular === 4 || training_regular === 5) {
                      if (gender === 'male') {
                        if (Number(bodyFatPercent) >= 50) levelAux = 1
                        if (Number(bodyFatPercent) >= 35 && Number(bodyFatPercent) < 50) levelAux = 2
                        if (Number(bodyFatPercent) < 35) levelAux = 3
                      } else {
                        if (Number(bodyFatPercent) >= 60) levelAux = 1
                        if (Number(bodyFatPercent) >= 35 && Number(bodyFatPercent) < 60) levelAux = 2
                        if (Number(bodyFatPercent) < 35) levelAux = 3
                      }
                    }
                  }
                }
              }
            } else {
              levelAux = 1
            }
          }
          setLevel(levelAux)
          setLevelCatalog(levels)
          setLevelSelected(levels.filter((l) => l.id === levelAux))
          setAvailableLevels(levels.filter((l) => l.id !== levelAux))
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [level])

  const saveCallback = async ({ goBack }) => {
    const data = { level }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (level) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(levelRef)
        }
      }
    }
  }

  const handleLevelSelect = (level) => {
    setLevel(level)
    setLevelSelected(levelCatalog.filter((l) => l.id === level))
    setAvailableLevels(levelCatalog.filter((l) => l.id !== level))
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'finish' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer>
            <div className="question">
              <p className="title">Here is the Level we recommend for you based on the information you provided.</p>
              <p>We think this Level would be best for you, but you are able to bypass our suggestion and select another Level.</p>
            </div>
            <div className="content">
              <SelectableCards type="radio" value={level} catalog={levelSelected} />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={levelRef}>
            {submit && !level && <Message type="error" message="Please complete all of the mandatory fields" />}
            <div className="question">
              <p className="title">I would like to choose a different Level.</p>
              <p>
                We are always happy to support those who wish to challenge themselves, but please ensure that this change will be a good fit for you
                before proceeding.
              </p>
            </div>
            <div className="content">
              <SelectableCards type="radio" catalog={availableLevels} onChange={(e) => handleLevelSelect(e.target.value)} />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
