import { Link } from '@reach/router'
import styled from '@emotion/styled'

export const Root = styled.div`
  display: inline-block;
  vertical-align: middle;
`
export const Img = styled.img`
  max-width: 144px;
  width: 100%;
`
export const StyledLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;
`
