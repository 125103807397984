import styled from '@emotion/styled'

export const MainContainer = styled.div`
  text-align: center;
  font-size: 28px;
  font-family: Montserrat;
  color: #ff6b00;

  .ant-result-title {
    color: #ff6b00;
  }
`
