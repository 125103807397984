import React from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button } from 'antd'
import { BaseModal } from './Modals.styled'

const InformationModal = (props) => {
  return (
    <BaseModal
      {...props}
      closeIcon={<CloseIcon />}
      cancelButtonProps={{ hidden: true }}
      onCancel={props.onOk}
      footer={[
        <div key="button" style={{ width: '100%', padding: '0px 35% 12px' }}>
          <Button onClick={props.onOk}>Ok</Button>
        </div>,
      ]}
    >
      {props.child ? props.child : null}
    </BaseModal>
  )
}

export default InformationModal
