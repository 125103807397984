import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import Complete from './Complete/Complete'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary'
import { ContainerComplete, Button, ButtonFakeSuccess, GraphContainer } from './Complete/Complete.styled'
import { CstBanner } from '../Main/Banner/Banner.styled'
import Message from 'components/Message'
import { Icon } from 'antd'
import { getLastDB, getCacheWhere } from 'services/db/indexedDB'
import LineGraph from '../../../components/Graphs/LineGraph'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const index = () => {
  const [previousVolume, setPreviousVolume] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [prevVol, setPrevVol] = useState(0)
  const [volume, setVolume] = useState(0)
  const [graphData, setGraphData] = useState([])
  const globalContext = useContext(GlobalContext)

  useState(() => {
    const handleAsync = async () => {
      let resultLast = await getLastDB(null, 'volume_session', 'id').catch((e) => console.error(e))
      let volumeSessionTable = await getCacheWhere('volume_session', {
        routine_id: Number(sessionStorage.getItem('SESSION_ROUTINE_SELECTED')),
      }).catch((e) => console.error(e))

      const { programs } = globalContext
      const currentProgram = programs.find((p) => p.is_current) || {}

      const bootcampDuration = currentProgram.program_id ? currentProgram.duration : 8
      const bootcampStartDate = currentProgram.program_id ? currentProgram.start_date : null

      let graphWeeks = []

      for (let weekItem = 0; weekItem < bootcampDuration; weekItem++) {
        graphWeeks[weekItem] = {
          order: weekItem + 1,
          week: moment(bootcampStartDate)
            .add(weekItem, 'week')
            .format('YYYYMMDD'),
          volume: null,
        }
      }

      if (!!volumeSessionTable) {
        for await (const week of volumeSessionTable) {
          let graphWeek = graphWeeks.find((w) => w.week === week.identifier)
          if (graphWeek) {
            graphWeek.volume = graphWeek.volume + week.volume
          }
        }
      }

      if (graphWeeks.every((w) => !w.volume || w.volume <= 0)) {
        Sentry.addBreadcrumb({
          type: 'debug',
          category: 'exercise',
          message: 'Data',
          data: {
            session: sessionStorage.getItem('SESSION_ROUTINE_SELECTED') || 'Not found',
            graphWeeks,
          },
        })
      }

      setGraphData(graphWeeks)

      const prevVolume = resultLast.prev_volume
      if (prevVolume) {
        setPreviousVolume(true)
        setPrevVol(prevVolume)
      }

      const volume = resultLast.volume
      if (volume) {
        setVolume(volume)
      }
    }
    handleAsync()
  }, [])

  const getLocal = () => {
    let name = localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)
    let firstName = !!name ? name.split(' ') : 'Student'
    return firstName[0]
  }

  const completeWorkout = () => {
    sessionStorage.removeItem('SESSION_ROUTINE_SELECTED')
    setShowSuccess(true)
    setTimeout(() => {
      navigate('/my-exercises/')
    }, 2500)
  }

  return (
    <ContainerComplete>
      <div className="container-complete">
        <CstBanner>
          <p>{`Great job ${getLocal()}!`}</p>
        </CstBanner>

        {showSuccess && <Message type="success" title="Your workout data was successfully saved!" />}

        <Complete volume={volume} previousVolume={previousVolume} prevVol={prevVol} />
        <GraphContainer>
          <p>Routine Weekly Volume Trends</p>
          <LineGraph data={graphData} />
        </GraphContainer>
        <Button>
          {!showSuccess && <BtnPrimary text="Complete my workout" onClick={completeWorkout} />}
          {showSuccess && (
            <ButtonFakeSuccess>
              <Icon type="check" />
            </ButtonFakeSuccess>
          )}
        </Button>
      </div>
    </ContainerComplete>
  )
}

export default index
