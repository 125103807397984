import React from 'react'
import { Radio } from 'antd'
import { ReactComponent as NoImage } from 'images/picture.svg'
import { GroupToggle } from './Selectable.styled'

const SelectableToggle = ({ optionA, optionB, value, onChange }) => {
  if (!optionA || !optionB) return null

  return (
    <GroupToggle>
      <Radio.Group buttonStyle="solid" {...{ value, onChange }}>
        <Radio.Button value={optionA.value}>
          <div className="option">
            {optionA.icon ? <img src={optionA.icon} alt="" /> : <NoImage />}
            <p>{optionA.label}</p>
          </div>
        </Radio.Button>
        <Radio.Button value={optionB.value}>
          <div className="option">
            {optionB.icon ? <img src={optionB.icon} alt="" /> : <NoImage />}
            <p>{optionB.label}</p>
          </div>
        </Radio.Button>
      </Radio.Group>
    </GroupToggle>
  )
}

export default SelectableToggle
