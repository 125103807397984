import React from 'react'
import { CstCard } from './Card.styled'

const Card = ({ style, className, mealPlan, children }) => {
  return (
    <CstCard className={className} style={style} mealplan={mealPlan ? 'true' : 'false'}>
      {children}
    </CstCard>
  )
}

export default Card
