import styled from '@emotion/styled'
import { Modal } from 'antd'

export const TutorialModal = styled(Modal)`
  max-width: 400px;
  top: 20px;
  border-radius: 4px;

  .ant-modal-close-x {
    color: #ffffff;
  }

  .ant-modal-body {
    height: 90vh;
    padding: 0px;
  }

  .tutorial-bar {
    height: 52px;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
  }

  .tutorial-content {
    background: #ffffff;
    padding: 12px;
    text-align: center;
    margin-top: 10px;
    height: calc(42vh - 70px);

    h3 {
      color: #4a4a4a;
      font-size: 18px;
    }

    .multi-section {
      width: 120px;
      height: 3px;
      margin: 0 auto;
      border-radius: 0 2.5px 2.5px 0;
      background-color: #d5d5d5;
      margin-bottom: 9px;

      &::before {
        width: 50%;
        height: 3px;
        display: block;
        content: ' ';
        background-color: #ff9a00;
      }

      &.section2 {
        &::before {
          float: right;
        }
      }
    }

    p {
      width: 80%;
      margin: 0 auto;
      font-size: 11px;
      color: #5d5d5d;
      font-weight: 500;
    }

    .ant-btn-background-ghost,
    .ant-btn-background-ghost:hover,
    .ant-btn-background-ghost:active,
    .ant-btn-background-ghost:visited {
      border: none;
      font-weight: bold;
      ${({ color }) =>
        color &&
        `
      color: ${color};
    `}

      &.back {
        color: #959595;
        bottom: -20px;
        left: 10px;
        position: absolute;
      }

      &.next {
        bottom: -20px;
        right: 10px;
        position: absolute;
      }
    }

    .ant-carousel {
      height: 100%;

      .slick-slider {
        height: 100%;
      }
    }

    .slick-dots {
      li {
        button {
          border-radius: 50%;
          background: #d5d5d5;
          width: 8px;
          height: 8px;
        }

        &.slick-active {
          button {
            ${({ color }) =>
              color &&
              `
      background: ${color};
    `}
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    max-width: 96%;
    top: 0px;

    .ant-modal-body {
      height: 95vh;

      .tutorial-content {
        height: calc(49vh - 70px);

        .ant-btn,
        .ant-btn:hover,
        .ant-btn:visited,
        .ant-btn:active {
          bottom: -5px;
        }

        p {
          width: 100%;
        }
      }
    }
  }
`

export const TutorialImage = styled.div`
  background: linear-gradient(180deg, #ff5a5a 0%, #fc3737 100%);
  min-height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 35px;

  ${({ background }) =>
    background &&
    `
      background-image: url(${background});
    `}

  img {
    height: 48vh;
    width: auto;
  }

  @media (max-width: 767px) {
    img {
      height: 43vh;
      width: auto;
    }
  }
`

export const TutorialStep = styled.div`
  width: 100%;
  height: 44px;
  margin: 0 auto;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  padding-top: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  ${({ pin }) =>
    pin &&
    `
      background-image: url(${pin});
    `}
`

export const TutorialBarItem = styled.div`
  color: #656565;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  padding: 7px 0px;
  cursor: pointer;

  p {
    margin: 0;
  }

  .icon {
    width: 22px;
    height: 22px;
  }

  .anticon {
    border-radius: 50%;
    color: #fff;
    width: 14px;
    height: 14px;
    line-height: normal;
    padding: 3px;
  }

  &.active,
  &:hover {
    .icon > * > * {
      ${({ color }) =>
        color &&
        `
          stroke: ${color};
        `}
    }

    .anticon {
      ${({ color }) =>
        color &&
        `
            background: ${color};
          `}
    }
  }
`

export const TutorialTimelineRoot = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 0px 8px;
  max-width: 460px;
  margin: 0 auto;
  background-color: #fbfbfb;
  box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  p,
  h2 {
    text-align: center;
    color: #000000;
    margin-bottom: 3px;
    line-height: 1.3;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    padding: 0px 70px;
  }

  hr {
    border: none;
    border-top: 2px solid #ececec;
    margin: 16px 0px 10px;
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .content {
    padding: 18px 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.18);
  }

  .ant-steps {
    .ant-steps-item-tail {
      &:after {
        width: 2px !important;
        background-color: #61a0eb;
      }
    }

    .ant-steps-icon {
      img {
        width: 20px;
        height: 20px;
      }
    }

    .ant-steps-item-title {
      h2 {
        font-weight: bold;
        font-size: 15px;
        color: #000000;
        text-align: left;
        margin: 0;
        line-height: 22px;
      }

      h4 {
        font-size: 11px;
        color: #7d9fc8;
        font-weight: bold;
        margin: 0;
        line-height: 16px;
      }
    }

    .ant-steps-item-content {
      margin-right: 31px;
    }
    .ant-steps-item-description {
      color: #000000 !important;
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
    }

    .colored {
      .ant-steps-item-tail {
        &:after {
          background-color: #ff7e22;
        }
      }
      .ant-steps-item-title {
        h4 {
          color: #ff6b00;
        }
      }
    }
  }
`
