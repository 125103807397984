import React from 'react'
import { CarouselWorkout, WeekHighlight } from './CarouselWorkout.styled'
import { CarouselCard } from '../../../../styled/CarouselCard.styled'
import ReportsProgress from '../../Main/ReportsProgress/ReportsProgress'
import { ReactComponent as ArrowLeftCar } from 'images/exercise/control-left-carousel.svg'
import { ReactComponent as ArrowRightCar } from 'images/exercise/control-right-carousel.svg'
import fullWorkout from 'images/exercise/full-workout.png'

import 'assets/scss/index.scss'

function NextArrow(props) {
  const { className, onClick } = props
  return <ArrowRightCar className={className} onClick={onClick} />
}

function PrevArrow(props) {
  const { className, onClick } = props
  return <ArrowLeftCar className={className} onClick={onClick} />
}

export default ({ title, routine, routines, handleSelectRoutine, reports, weekNumber }) => {
  const handleOnChangeRoutine = (order) => {
    for (const [key, routine] of routines.entries()) {
      if (key === order) {
        handleSelectRoutine(routine, order)
        break
      }
    }
  }

  const styleCard = {
    justifyContent: 'space-evenly',
    marginLeft: '10px',
  }

  const order = !routine ? 0 : routines.findIndex((rout) => rout.id === routine.id)

  return (
    <>
      {weekNumber > 0 && (
        <WeekHighlight>
          This is your <b>week {weekNumber}</b> of training!
        </WeekHighlight>
      )}
      <CarouselWorkout
        customTitle={title}
        afterChange={handleOnChangeRoutine}
        arrows={true}
        reports={reports}
        initialSlide={order}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {routines.map((routine) => {
          return (
            <CarouselCard key={routine.id}>
              <ReportsProgress
                key={routine.id}
                image={fullWorkout}
                title={`Day ${routine.order} - ${routine.category}`}
                text={routine.name}
                styleContent={styleCard}
              />
            </CarouselCard>
          )
        })}
      </CarouselWorkout>
    </>
  )
}
