import styled from '@emotion/styled'
import { Input, Checkbox } from 'antd'
import ArrowForward from 'assets/icons/arrow-forward.svg'

export const Root = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 56px;

  @media screen and (max-width: 720px) {
    .mat-label {
      font-size: 16px !important;
    }
  }
  .ListPaymentMethods {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-top: -50px;
    min-width: 360px;
  }

  .containerStripeSections {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-top: -50px;

    .iconCard {
      display: flex;
      flex-direction: row;
      align-items: center;
      .content {
        width: 100%;
      }
      .iconPrimary {
        width: 65px;
        height: 65px;
      }
      &:after {
        content: url(${ArrowForward});
        width: 10px;
        height: 20px;
        margin-right: -9px;
      }
    }
  }
`
export const InputEmail = styled(Input)`
  padding: 0.75rem;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  &:hover {
    background-color: transparent !important;
    border: 1px solid #e6e6e6;
    box-shadow: transparent !important;
  }
  &:focus,
  &:active {
    border-color: hsla(210, 96%, 45%, 50%);
    box-shadow: 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  }
`

export const ContentAddressForm = styled.div`
  height: 550px;
  margin-top: -10px;
  width: 100%;
  margin-bottom: 65px;
`

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`
export const CheckboxDisable = styled(Checkbox)`
  .Checkdisable {
    color: #30313d !important;
  }
  .ant-checkbox-inner {
    background-color: #fff !important;
  }
`
