import React from 'react'
import { Button } from 'styled'
import { Container } from './Result.styled.js'

const index = (props) => {
  const { image, text, secondaryText, actionLink, actionText, targetLink = '', icon } = props

  return (
    <Container>
      <div className="content">
        {icon && icon}
        {image && <img src={image} alt="enrollment" />}
        <hr />
        <p dangerouslySetInnerHTML={{ __html: text }} />
        {secondaryText && <p dangerouslySetInnerHTML={{ __html: secondaryText }} />}
        {actionLink && actionText && (
          <Button href={actionLink} type="primary" target={targetLink} block>
            {actionText}
          </Button>
        )}
      </div>
    </Container>
  )
}

export default index
