import styled from '@emotion/styled'
import { Card } from 'antd'

export const Reports = styled(Card)`
  margin-top: 14px;
  width: 100%;
  max-width: 375px;
  ${({ reports }) => (reports ? 'height: 118px;' : 'height: 77px;')}
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  ${({ reports }) => reports && 'z-index: 2;'}

  .ant-card-body {
    padding: 0px;
    width: 100%;
    max-width: 347px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .container {
    position: relative;
    left: 2px;
    width: 65px;
    height: 65px;
    background: linear-gradient(0deg, ${({ grad }) => grad} 0%, ${({ color }) => color} 99.87%);
    border-radius: 4px;
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    padding: 5px 0;
  }

  .title {
    width: 237px;
    margin: 0px 0px 0px 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #4a4a4a;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p {
    color: red;
  }
  .text {
    width: 227px;
    margin: 0px 0px 0px 10px;
    font-weight: ${({ pseudo }) => (pseudo ? 600 : 500)}px;
    font-family: Montserrat;
    font-style: normal;
    font-size: ${({ pseudo }) => (pseudo ? 13 : 11)}px;
    line-height: ${({ pseudo }) => (pseudo ? 16 : 13)}px;
    color: ${({ colortext }) => (colortext ? colortext : '#000')};
    position: relative;
    padding-left: ${({ exercise }) => (exercise ? '25px' : '')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: visible;
    overflow-x: clip;
    display: block;

    &:before {
      content: url(${({ pseudo }) => pseudo});
      width: 15px;
      height: 20px;
      margin-right: 5px;
      position: absolute;
      bottom: -2px;
      left: 0px;
      top: -2px;
    }
  }

  .button {
    width: 16px;
    height: 77px;
    background: ${({ color }) => color};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    right: 0px;
    background-image: url(${({ lastbutton }) => (lastbutton ? lastbutton : '')});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }

  @media screen and (max-width: 320px) {
    .button {
      right: -5px;
    }

    .title {
      width: 200px;
    }
  }
`
