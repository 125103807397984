import React, { useContext } from 'react'
import { Container } from './indext.styled'
import { ReactComponent as PersonIcon } from 'assets/icons/iconPerson.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/iconWarning.svg'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary'
import { navigate } from '@reach/router'
import GlobalContext from 'context/GlobalContext'

export default ({ reason }) => {
  let message = ''
  let showEnrollmentLink = false
  let showWarningIcon = false
  let showPersonIcon = false
  const globalContext = useContext(GlobalContext)

  switch (reason) {
    case 'NO_WORKOUT':
      const { programs } = globalContext
      const currentProgram = programs.find((p) => p.is_current) || {}
      const upcomingProgram = programs.find((p) => p.is_upcoming) || {}
      const hasCurrentEnrollment = currentProgram.program_id && !currentProgram.is_enrollment_completed
      const hasNextEnrollment = upcomingProgram.program_id && !upcomingProgram.is_enrollment_completed

      if (hasCurrentEnrollment || hasNextEnrollment) {
        showEnrollmentLink = true
        showWarningIcon = true
        message = 'Your training programs are not assigned yet because you have not completed your enrollment.'
      } else {
        showPersonIcon = true
        message = 'Our coaches are reviewing your information and will load your training program soon.'
      }
      break
    case 'MAINTENANCE':
      showPersonIcon = true
      message =
        'We are currently working on loading your training program to our Exercises tracking. <br/> Please check back later today. <br/> In the meantime, you can see your workouts using the dropdown on the upper-left.'
      break
    default:
      break
  }
  return (
    <Container>
      {showPersonIcon && <PersonIcon className="icon" />}
      {showWarningIcon && <WarningIcon className="icon" />}
      <p className="message" dangerouslySetInnerHTML={{ __html: message }} />
      {showEnrollmentLink && <BtnPrimary text="Complete enrollment now" onClick={() => navigate('/enrollment/')} />}
    </Container>
  )
}
