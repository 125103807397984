import styled from '@emotion/styled'

export const CstFooter = styled.footer`
  background: #e4e4e4;
  border-radius: 15px 15px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  padding: 12px 15px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 400px;
`
