import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import moment from 'moment'
import _ from 'lodash'
import { Select, Icon, Result } from 'antd'
import { getDateReview } from 'services'
import { monthFormat, orderTypes, limit, images } from './data'
import Layout from 'components/Layout'
import { Root, Label, Filter, StyledMonthPicker } from './UserPictures.styled'
import { StyledSelect } from 'styled'
import nanoid from 'nanoid'
import Pictures from './Pictures'
import GlobalContext from 'context/GlobalContext'

const Option = Select.Option
const { currentWeekEnd: maxDate } = getDateReview()
const previewTransform = '-/autorotate/yes/'

const checkEndDate = (date, maxDate) =>
  moment(date)
    .endOf('month')
    .isSameOrAfter(maxDate)
    ? maxDate
    : moment(date)
        .endOf('month')
        .format('YYYYMMDD')

const getPictures = gql`
  query($id: uuid!, $filter: [pictures_bool_exp], $order: order_by) {
    trainee(where: { id: { _eq: $id } }) {
      pictures(where: { _and: $filter }, order_by: { for_date: $order }) {
        for_date
        front_url
        side_url
        back_url
        id
      }
      measurement(order_by: { for_date: asc }, limit: 1) {
        for_date
        created
      }
      pictures_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

// Need we adding date ranges without date filter?
class UserPictures extends Component {
  state = {
    filter: {
      startDate: moment(maxDate)
        .startOf('month')
        .format('YYYYMMDD'),
      endDate: checkEndDate(maxDate, maxDate),
    },
    order: 'desc',
    page: 1,
  }

  getFilter = () => {
    const {
      filter: { startDate, endDate },
    } = this.state
    if (moment(startDate).isValid() && moment(endDate).isValid()) {
      return [{ for_date: { _gte: moment(startDate).format('YYYYMMDD') } }, { for_date: { _lte: moment(endDate).format('YYYYMMDD') } }]
    }
    return null
  }

  handlePrevious = () => {
    const { order } = this.state
    const index = order === 'asc' ? limit * 1 : limit * -1

    this.setState((state) => ({
      shownAll: !state.shownAll,
    }))
    this.setState(({ filter }) => ({
      filter: {
        startDate: moment(filter.startDate).subtract(index, 'week'),
        endDate: moment(filter.endDate).subtract(index, 'week'),
      },
    }))
  }

  handleNext = () => {
    const { order } = this.state
    const index = order === 'desc' ? limit * 1 : limit * -1

    this.setState((state) => ({
      shownAll: !state.shownAll,
    }))
    this.setState(({ filter }) => ({
      filter: {
        startDate: moment(filter.startDate).subtract(index, 'week'),
        endDate: moment(filter.endDate).subtract(index, 'week'),
      },
    }))
  }

  handleOrderChange = (order) => {
    this.setState({ order })
  }

  handleFilterChange = (minDate) => (date) => {
    const { order } = this.state
    let startDate, endDate
    if (!date) {
      startDate = order === 'desc' ? moment(maxDate).subtract(limit, 'week') : moment(minDate)
      endDate = order === 'desc' ? moment(maxDate) : moment(minDate).add(limit, 'week')
    } else {
      startDate = moment(date).startOf('month')
      endDate = checkEndDate(date, maxDate)
    }
    this.setState({
      filter: {
        startDate,
        endDate,
      },
    })
  }

  getWeekArray = (pictures, registerDate) => {
    const {
      filter: { startDate, endDate },
      order,
    } = this.state
    const { front, back, side } = images
    const emptyItem = {
      front_url: front,
      back_url: back,
      side_url: side,
      empty: true,
    }
    const result = []
    let firstDay, lastDay

    if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
      firstDay = order === 'desc' ? moment(registerDate) : moment(maxDate).subtract(limit, 'week')
      lastDay = moment(firstDay).add(limit, 'week')
    } else {
      firstDay = moment(startDate).isValid() ? moment(startDate) : moment()
      lastDay = moment(endDate).subtract(1, 'week')
    }

    while (!firstDay.isAfter(lastDay)) {
      var forDate

      if (
        firstDay.isSame(
          moment(startDate)
            .startOf('isoWeek')
            .format('YYYYMMDD'),
        )
      ) {
        forDate = firstDay.format('YYYYMMDD')
        firstDay.add(1, 'day')
      } else {
        forDate = firstDay
          .add(1, 'week')
          .startOf('isoWeek')
          .format('YYYYMMDD')
      }

      // eslint-disable-next-line
      const picture = pictures
        .map((pic) => {
          if (!!pic.front_url) pic.front_url = pic.front_url + previewTransform
          if (!!pic.back_url) pic.back_url = pic.back_url + previewTransform
          if (!!pic.side_url) pic.side_url = pic.side_url + previewTransform
          return pic
        }) // eslint-disable-next-line
        .filter((item) => {
          return item.for_date === forDate
        })[0]
      const data = !!picture
        ? { ...picture, empty: !picture.front_url && !picture.back_url && !picture.side_url }
        : { ...emptyItem, for_date: forDate, id: nanoid() }
      if (order === 'asc') {
        result.push(data)
      } else {
        result.unshift(data)
      }
    }

    return result
  }

  render() {
    const { order } = this.state
    return (
      <GlobalContext.Consumer>
        {({ user }) => {
          return (
            <Query
              query={getPictures}
              fetchPolicy="cache-and-network"
              variables={{
                id: user.trainee_id,
                filter: this.getFilter(),
                order,
              }}
            >
              {({ error, refetch, data }) => {
                if (error) {
                  console.log(error)
                  return null
                }
                if (_.isEmpty(data)) return <Icon type="loading" />
                const pictures = _.get(data, 'trainee[0].pictures') || []
                let minDate = _.get(data, 'trainee[0].measurement[0].for_date')
                minDate = moment(minDate).toISOString()
                const picturesArray = this.getWeekArray(pictures, minDate)
                return (
                  <Layout>
                    <Root>
                      <Filter>
                        <Label>Filter:</Label>
                        <StyledMonthPicker
                          {...{
                            defaultValue: moment(maxDate),
                            disabledDate: (currentDate) =>
                              currentDate.endOf('month') < moment(minDate) || currentDate.startOf('month') > moment(maxDate),
                            format: monthFormat,
                            onChange: this.handleFilterChange(minDate),
                          }}
                        />
                      </Filter>
                      <Filter>
                        <Label>Shown:</Label>
                        <StyledSelect defaultValue={this.state.order} onChange={this.handleOrderChange}>
                          {orderTypes.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.name}
                            </Option>
                          ))}
                        </StyledSelect>
                      </Filter>
                      {picturesArray.length <= 0 && (
                        <Result
                          icon={<Icon type="camera" style={{ color: '#5d5d5d' }} />}
                          title="No pictures available yet"
                          subTitle="Please try to search in the previous month."
                        />
                      )}
                      <Pictures pictures={picturesArray} refetch={refetch} />
                    </Root>
                  </Layout>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default UserPictures
