import { Button } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export default styled(Button)`
  &.ant-btn {
    background: #ff4613;
    border: 0;
    border-radius: 40px;
    color: #fff;
    display: block;
    max-width: 200px;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    margin: 0 auto;
    outline: none;
    padding: 10px 25px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
      background: #c62b00;
      box-shadow: 0 0 4px 2px #d4d4d4;
      cursor: pointer;
    }
    span {
      color: #fff;
      font-size: 12px;
      line-height: 15px;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
  ${({ custom }) => {
    if (custom === 'transparentSimple') {
      return css`
        &.ant-btn {
          background: transparent;
          border: thin solid #000;
          border-radius: 20px;
          span {
            color: #000;
            font-size: 10px;
          }
          &:hover {
            background: transparent;
          }
        }
      `
    }
    if (custom === 'transparentWhite') {
      return css`
        &.ant-btn {
          background: #f36b21;
          border: none;
          outline: none;
          span {
            color: #eee;
          }
          transition: all ease 0.2s;
          &:hover {
            box-shadow: 0 0 3px 1px #dfdfdf;
            span {
              color: #fff;
            }
          }
        }
      `
    }
    if (custom === 'green') {
      return css`
        &.ant-btn {
          background: green;
          &:hover,
          &:focus,
          &:active {
            background: green;
          }
          max-width: none;
        }
        &.fixed-w {
          min-width: 154px;
          font-size: 20px;
          padding: 5px 25px;
        }
      `
    }
    if (custom === 'transparentLink') {
      return css`
        &.ant-btn {
          display: inline-block;
          max-width: none;
          margin-bottom: 20px;
          background: transparent;
          color: #5d5d5d;
          border: thin black solid;
          outline: none;
          text-transform: none;
          letter-spacing: 0;
          span {
            color: #5d5d5d;
          }
          transition: all ease 0.2s;
          &:hover,
          &:active {
            background: #ff6b00; /* #dfdfdf */
            border: thin black solid;
            box-shadow: 0 0 3px 1px #dfdfdf;
            span {
              color: #ffffff; /* #5d5d5d */
            }
            .anticon {
              color: #ffffff;
            }
          }
          &:focus {
            background: transparent;
          }
        }
      `
    }
    if (custom === 'inline') {
      return css`
        &.ant-btn {
          display: inline-block;
          width: 100px;
          margin: 0 10px;
          height: 20px;
          padding: 0;
          text-transform: none;
          letter-spacing: 0px;
        }
      `
    }
    if (custom === 'disabledPagination') {
      return css`
        &.ant-btn {
          background: #efa66f;
          display: inline-block;
          width: 100px;
          margin: 0 10px;
          height: 20px;
          padding: 0;
          text-transform: none;
          letter-spacing: 0px;
          &:active,
          &:focus {
            background: #efa66f;
            box-shadow: none;
          }
        }
      `
    }
  }}
`
