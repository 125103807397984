import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  padding: 60px 0px 10px;

  @media (max-width: 460px) {
    padding: 60px 8px 10px;
  }

  .ant-btn-danger {
    color: #ff6b00;
    background: #ffffff;

    &:hover {
      color: #ffffff;
      background: #ff6b00;
    }
  }
`
export const Wrap = styled.div`
  border-radius: 3px;
  min-height: 100%;
  min-width: 320px;
  width: 100%;
`
export const Content = styled.div`
  background: #f8f9fa;
  height: 100%;
  ${({ custom }) =>
    custom &&
    css`
      padding: 0px 20px 0px;
    `}
`

export const StyledLink = styled(Link)`
  display: block;
  margin: 10px auto;
  font-size: 10px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #ff6b00;
  &:hover,
  &:active {
    color: #ff6b00;
    text-decoration: underline;
  }
`

export const SmallNotificationBanner = styled.div`
  max-width: 460px;
  margin: 0 auto;
  padding: 2px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  font-size: 16px;
  font-weight: bold;
  min-height: 35px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 15px;
    width: 38px;
  }

  .anticon {
    margin-left: 7px;
    vertical-align: text-top;
  }

  p {
    margin: 0;
    font-weight: 500;
  }

  ${({ background }) =>
    background &&
    `
      background-image: url(${background});
      background-position: top center;
    `}
`

export const NotificationBanner = styled.div`
  max-width: 460px;
  margin: 0 auto;
  padding: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: right;
  margin-bottom: 5px;
  border-radius: 4px;
  line-height: 20px;

  hr {
    border-color: rgba(255, 255, 255, 0.26);
    border-style: solid;
  }

  h2{
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    background-size: cover;
  }

  h3{
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
  }

  .actions{
    text-align: right;
    .ant-btn {
      background: #FF4613;
      color: white;
      font-weight: bold;
      border: none;

      &:hover {
        border: none;
      }

      ${({ status }) =>
        !!status && (status === 'delayed' || status === 'overdue' ? `background: white; color: #DE4B4B;` : `background: white; color: #70ca85;`)}
    }
  }

  ${({ background }) =>
    background &&
    `
      background-image: url(${background});
      background-size: auto;
      background-position: center center;
    `}

  ${({ backgroundSize }) =>
    backgroundSize &&
    `
      background-size: ${backgroundSize};
    `}

  ${({ textAlign }) =>
    textAlign &&
    `
      text-align: ${textAlign};
    `}
`

export const BuyBootcampBanner = styled.div`
  max-width: 460px;
  margin: 0 auto;
  background: #ffffff;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 4px;
  line-height: 20px;
  color: #5d5d5d;

  .banner-image {
    border-radius: 4px;
    background-size: auto;
    background-repeat: no-repeat;
    min-height: 320px;
    background-position: top center;
    ${({ background }) =>
      background &&
      `
        background-image: url(${background});
      `}
  }
  .text {
    padding: 0px 10px;
    .instructions {
      padding-right: 22px;
      li {
        margin-bottom: 10px;
      }
    }
  }

  img {
    max-width: 130px;
    border-radius: 50%;
    margin-top: -60px;
    background: #ffffff;
  }

  hr {
    border: none;
    width: 90%;
    border-top: solid 2px #ff6b00;
  }

  h1 {
    font-size: 19px;
    font-weight: bold;
    margin-top: 20px;
    color: #5d5d5d;
  }

  p {
    font-size: 14px;
    color: #5d5d5d;
  }

  .ant-btn {
    min-width: 169px;
    background: linear-gradient(90deg, #ff4713 0%, #ff7e22 100%);
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 18.5px;
    font-weight: bold;
    margin: 10px 0px;

    &:hover {
      border: none;
    }
  }
`

export const PDFReportProgressContainer = styled.div`
  min-width: 320px;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  background-color: #f2f2f2;
  border-top: 1px solid #ffffff;
`

export const CardStatus = styled.div`
  max-width: 460px;
  margin: 0 auto;
  background-color: #fbfbfb;
  border-radius: 5px;
  overflow: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
`

export const ContentCard = styled.div`
  .content-title {
    display: flex;
    justify-items: center;
    background-color: #fff;
    padding: 5px;

    h1 {
      width: 90%;
      height: 23px;
      justify-items: center;
      align-self: center;
      font-size: 20px;
      color: #5d5d5d;
      font-weight: bold;
    }

    .block {
      width: 1%;
      padding: 1px;
      margin-right: 15px;
      background-color: #ff4b2d;
    }
  }

  .content {
    padding: 15px;

    h3 {
      font-size: 17px;
      font-weight: bold !important;
      padding-left: 10px;
    }

    .status-container {
      display: flex;
      justify-content: left;
      align-items: center;

      .text {
        width: 60%;
        margin: auto !important;
        margin-left: 0 !important;
        padding-left: 10px;
        font-size: 15px;
        font-weight: bold !important;
      }

      .content-button {
        width: 25%;
      }
    }
  }
`
