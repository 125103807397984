import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Root = styled.footer`
  position: fixed;
  bottom: 0;
  min-height: 58px;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #ffffff;
  box-shadow: 0 -1px 7px 0 rgba(236, 236, 236, 0.5);
  z-index: 30;
`
export const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 0;
  margin-bottom: -30px;
  list-style: none;
`
export const Li = styled.li`
  width: 20%;
  min-width: 45px;
  text-align: center;
`
export const StyledLink = styled(Link)`
  display: block;
  padding: 5px 10px;
  color: #666666 !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 8px;
  line-height: 1;
  font-weight: thin;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  &[data-footer-link='active'] {
    .beta-tag {
      background: #fff !important;
      color: #e53d2f !important;
    }
  }

  & .beta-tag {
    position: absolute;
    top: 2px;
    left: 58px;

    background: #e53d2f;
    border-radius: 3px;
    color: #fff;
    width: 42px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
  }

  &:focus {
    text-decoration: none;
  }
  svg {
    width: auto;
    height: 25px;
    fill: gray;
  }
  svg.stroke {
    fill: transparent;
    stroke: gray;
  }
  svg.exercises {
    stroke-width: 9px;
    stroke: white;
  }
  svg.photos {
    stroke-width: 5px;
    stroke: white;
  }
  &:hover,
  &:focus {
    color: #f00;
    svg {
      fill: #ff4613;
    }
    svg.stroke {
      fill: transparent;
      stroke: #ff4613;
    }
  }
  &[data-footer-link='active'] {
    color: #ffffff !important;
    svg {
      fill: #ffffff;
    }
    svg.stroke {
      fill: transparent;
      stroke: #ffffff;
    }
  }
  span {
    display: block;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  ${({ disabled }) => {
    if (!!disabled) {
      return css`
        && {
          opacity: 0.3;
          cursor: not-allowed;
        }
      `
    }
  }}

  @media screen and (max-width: 500px) {
    .beta-tag {
      width: 37px;
      height: 18px;
      left: 8px;
    }
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    .beta-tag {
      width: 36px;
      height: 17px;
      left: 6px;
    }
  }

  @media screen and (max-width: 320px) {
    .beta-tag {
      width: 36px;
      height: 17px;
      left: 2px;
    }
  }
`

// Temporary external link
export const CustomStyledLink = styled.a`
  display: block;
  padding: 5px 10px;
  color: #666666 !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 8px;
  line-height: 1;
  font-weight: thin;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  &:focus {
    text-decoration: none;
  }
  svg {
    width: auto;
    height: 25px;
    fill: gray;
  }
  svg.stroke {
    fill: transparent;
    stroke: gray;
  }
  svg.exercises {
    stroke-width: 9px;
    stroke: white;
  }
  svg.photos {
    stroke-width: 5px;
    stroke: white;
  }
  &:hover,
  &:focus {
    color: #f00;
    svg {
      fill: #ff4613;
    }
    svg.stroke {
      fill: transparent;
      stroke: #ff4613;
    }
  }
  span {
    display: block;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  ${({ disabled }) => {
    if (!!disabled) {
      return css`
        && {
          opacity: 0.3;
          cursor: not-allowed;
        }
      `
    }
  }}
`
