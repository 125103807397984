import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import LayoutNavigation from 'components/LayoutNavigation/LayoutNavigation'
import MessageSelectExercsice from './MessageSelectExercise.js/MessageSelectExercise'
import DragAndDrop from 'components/DragAndDrop'
import ContainerNoDnd from './ContainerNoDnd/ContainerNoDnd'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary'
import Loading from 'components/Loading/Loading'
import Modal from '../Modal'
import { ContainerBtnWorkout } from '../../../styled/BtnSelectWokout.styled'
import {
  getCache,
  saveCache,
  getLastDB,
  getCacheAnyOf,
  getCacheWhere,
  getFirstCache,
  deleteCacheById,
  getCacheWhereBetween,
} from 'services/db/indexedDB'
import { deleteRoutineSession } from 'services/ExerciseUtilities'
import CarouselWorkout from './CarouselWorkout/CarouselWorkout'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { LOCAL_STORAGE } from 'constants.js'

const index = () => {
  const [initialState, setInitialState] = useState({
    workout: {},
    routines: [],
    listExercises: [],
    readOnly: false,
    weekNumber: 0,
  })

  const [routineSelected, setRoutine] = useState()
  const [loading, setLoading] = useState(true)
  const [orderSets, setOrderSets] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [listOfMuscles, setListOfMuscles] = useState()
  const [listOfGymEquipments, setListOfGymEquipments] = useState()
  const today = moment().local()

  useEffect(() => {
    const handleAsync = async () => {
      let workoutDB = JSON.parse(localStorage.getItem('WORKOUT'))
      if (!workoutDB) {
        workoutDB = await getFirstCache('workout_plans', { is_selected: 1 }).catch((e) => console.error(e))
        localStorage.setItem('WORKOUT', JSON.stringify(workoutDB))
      }
      if (!workoutDB) {
        navigate('/my-exercises')
        return
      } else {
        let monday = getMonday()
        let readOnly = false
        const {
          routines,
          is_current,
          program: { start_date },
        } = workoutDB

        if (is_current === 0) {
          if (today.isSameOrAfter(moment(start_date).add(-1, 'day'))) {
            readOnly = true
            monday = moment(start_date)
              .startOf('isoWeek')
              .format('YYYYMMDD')
          } else {
            navigate('/my-exercises')
            return
          }
        }
        let listMuscles = []
        let listExercises = []
        let listGymEquipment = []
        let initialRoutine = null
        const listRoutines = routines.filter((routine) => routine.routine_weeks.find((week) => week.identifier === monday))

        listMuscles = await getCache('muscles')
        listGymEquipment = await getCache('gym_equipments')
        initialRoutine = await getInitialRoutine(listRoutines, monday)
        listExercises = initialRoutine.routine_exercises.map((re) => re.exercise_id)
        listExercises = await getCacheAnyOf('exercises', 'id', listExercises)

        setListOfMuscles(listMuscles)
        setListOfGymEquipments(listGymEquipment)
        initialRoutine = await getFormatRoutine({
          listMuscles,
          listExercises,
          listGymEquipment,
          routine: initialRoutine,
          startDay: moment(start_date).format('YYYYMMDD'),
          monday,
        })
        setInitialState({
          workout: workoutDB,
          routines: routines,
          readOnly,
          weekNumber: readOnly ? 0 : moment(monday).diff(moment(workoutDB.program.start_date), 'week') + 1,
          monday,
        })

        setRoutine(initialRoutine)
        setLoading(false)
      }
    }
    handleAsync()
  }, [])

  const getMonday = () => {
    return moment()
      .isoWeekday(1)
      .format('YYYYMMDD')
  }

  const getInitialRoutine = async (routines, monday) => {
    let sessions = []
    let pastRoutines = []
    let routineSelect = null
    const numberDay = moment().isoWeekday()
    const volumeSessions = await getCache('volume_session')
    const routineSession = await getFirstCache('routine_session', { is_completed: 0 })

    if (!!routineSession) {
      routineSelect = routines.find((routine) => routine.id === routineSession.session_id)
      setModalVisible(true)
    } else if (numberDay === 1) {
      routineSelect = routines.find((routine) => routine.order === 1 && routine.category === 'Gym')
    } else {
      if (numberDay > 1) {
        pastRoutines = routines.filter((routine) => routine.order <= numberDay && routine.type !== 'ACTIVE_REST')
        pastRoutines.forEach((pastRoutine) => {
          let hasVolumeSession = false
          if (!!volumeSessions) {
            hasVolumeSession = volumeSessions.find((vs) => vs.routine_id === pastRoutine.id && vs.identifier === monday)
          }
          if (!!hasVolumeSession) {
            sessions.push(pastRoutine)
          } else {
            if (sessions.length > 0) {
              const hastAltRoutine = sessions.find((session) => session.order === pastRoutine.order && session.category !== pastRoutine.category)
              if (!!!hastAltRoutine && !!!routineSelect) {
                routineSelect = pastRoutine
              }
            } else {
              if (!routineSelect) {
                routineSelect = pastRoutine
              }
            }
          }
        })
      }
    }

    if (!routineSelect && sessions.length > 0) {
      const index = sessions.length - 1
      const lastSession = sessions[index]
      const maxDay = Math.max(...routines.map((r) => r.order))
      let numDayAux = numberDay
      if (numberDay > maxDay) numDayAux = maxDay
      routineSelect = routines.find(
        (routine) => routine.order === numDayAux && (lastSession.category === 'Gym' ? routine.category === 'Gym' : routine.category === 'Home'),
      )
    }

    return routineSelect
  }

  const getFormatRoutine = async ({ routine, listMuscles, listExercises, listGymEquipment, startDay, monday }) => {
    const { routine_weeks } = routine
    let lastWeight = null
    let { week_exercises } = routine_weeks.find((week) => week.identifier === monday)

    let sets = await Promise.all(
      week_exercises.map(async (exercise) => {
        const { exercise_id, has_deload, deload_week, deload_percent_min, parent } = exercise
        let foundExercise = listExercises.find((each) => each.id === exercise_id)
        if (!!foundExercise) {
          let { muscles, gym_equipments } = foundExercise
          if (foundExercise.muscles.length > 0 && !foundExercise.muscles[0].hasOwnProperty('name')) {
            foundExercise.muscles = listMuscles.filter((muscle) => !!muscles.includes(muscle.id))
          }
          if (foundExercise.gym_equipments.length > 0 && !foundExercise.gym_equipments[0].hasOwnProperty('name')) {
            foundExercise.gym_equipments = gym_equipments.map((ge) => {
              let objEquipment = {}
              const foundEquipment = listGymEquipment.find((equipment) => equipment.id === ge.gym_equipment_id)
              if (!!foundEquipment) {
                objEquipment = {
                  ...foundEquipment,
                  quantity: ge.quantity,
                }
              }
              return objEquipment
            })
          }
          let filterDay = monday
          if (has_deload) {
            filterDay = moment(startDay)
              .add(deload_week - 1, 'week')
              .format('YYYYMMDD')
          }
          const foundLastWeight = await getCacheWhereBetween(
            'report_last_weight', //table
            { routine_id: routine.id, exercise_id: exercise_id, parent: parent }, //filter
            'week', //key
            startDay, //lower
            filterDay, //upper
          ).catch((e) => console.error(e))
          if (foundLastWeight && foundLastWeight.length > 0) {
            if (has_deload) {
              lastWeight = foundLastWeight[0].weight * (1 - deload_percent_min / 100)
            } else {
              lastWeight = foundLastWeight[0].weight
            }
          }
        }
        exercise['data'] = foundExercise
        exercise['prev_weight'] = lastWeight
        if (!exercise.parent) {
          exercise['alternatives'] = week_exercises.filter((we) => we.parent === exercise_id)
        }

        if (!!!exercise.set_related) {
          exercise = [exercise]
          const exercisesRelated = week_exercises.filter((we) => we.set_related === exercise.id)
          if (exercisesRelated.length > 0) {
            exercise = [...exercise, exercisesRelated]
          }
        }
        if (!exercise[0].parent) {
          return {
            id: uuidv4(),
            type: exercise.length > 1 ? 'SUPER_SET' : 'NORMAL',
            exercises: exercise,
          }
        }
      }),
    )
    sets = sets.filter((set) => !!set)
    delete routine.routine_weeks
    routine.sets = sets
    return routine
  }

  const handleSelectRoutine = async (routine) => {
    Sentry.addBreadcrumb({ category: 'exercise', message: `Select the routine ${routine.name}` })
    let formatRoutine = routine
    if (!routine.sets) {
      const startDay = initialState.workout.program.start_date
      let listExercises = routine.routine_exercises.map((re) => re.exercise_id)
      listExercises = await getCacheAnyOf('exercises', 'id', listExercises)

      formatRoutine = await getFormatRoutine({
        routine,
        startDay,
        listExercises,
        listMuscles: listOfMuscles,
        listGymEquipment: listOfGymEquipments,
        monday: initialState.monday,
      })
    }

    setRoutine(formatRoutine)
  }

  const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  const saveRoutine = async () => {
    let useRoutine = routineSelected
    const uuid = uuidv4()

    const foundSessions = await getCacheWhere('routine_session', { is_completed: 0 }).catch((e) => console.error(e))
    const foundWorkouts = await getCache('current_workout').catch((e) => console.error(e))

    if (foundSessions) {
      for await (const session of foundSessions) {
        if (session.session_id === routineSelected.id) {
          await deleteRoutineSession(session.local_id)
        }
      }
    }

    if (foundWorkouts) {
      for await (const workout of foundWorkouts) {
        if (workout.routine.id === useRoutine.id) {
          await deleteCacheById('current_workout', 'id', workout.id).catch((e) => console.error(e))
        }
      }
    }

    if (orderSets) {
      const reorderedSets = await setReorder(orderSets, useRoutine.sets)
      useRoutine.sets = reorderedSets
    }
    const routine_session = {
      session_id: useRoutine.id,
      local_id: uuid,
      routine_week_id: useRoutine.sets[0].exercises[0].routine_week_id,
      for_date: getMonday(),
      is_completed: 0,
      start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      finish_date: '',
      trainee_id: localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID),
      workout_plan_id: initialState.workout.id,
    }

    const current_workout = {
      workout_id: initialState.workout.id,
      trainee_id: initialState.workout.trainee_id,
      routine: routineSelected,
      is_current: initialState.workout.is_current,
      is_selected: initialState.workout.is_selected,
      routine_exercise_user_notes: initialState.workout.routine_exercise_user_notes,
    }

    Sentry.addBreadcrumb({ category: 'exercise', message: 'Save routine session', data: routine_session })
    const resp = await saveCache('routine_session', routine_session).catch((e) => console.error(e))
    if (resp) {
      const respCurrent = await saveCache('current_workout', current_workout).catch((e) => console.error(e))
      if (respCurrent) {
        sessionStorage.setItem('SESSION_ROUTINE_SELECTED', current_workout.routine.id)
        navigate('/exercises/workout/your-sets')
      }
    } else {
      Sentry.captureMessage('Could not save the routine session')
      console.error("Can't store session")
    }
  }

  const handleSetOrder = (orderSets) => {
    Sentry.addBreadcrumb({ category: 'exercise', message: `Reorder routine ${routineSelected.name}` })
    setOrderSets(orderSets)
  }

  const setReorder = async (order, sets) => {
    return order.map((id, key) => {
      let foundSet = sets.find((set) => set.id === id)
      if (!!foundSet) {
        foundSet.order = key + 1
      }
      return foundSet
    })
  }

  const handleReturnLastSet = async () => {
    const routineSession = await getLastDB(null, 'routine_session', 'id').catch((e) => console.error(e))
    if (routineSession) {
      sessionStorage.setItem('SESSION_ROUTINE_SELECTED', routineSession.session_id)
    }
    Sentry.addBreadcrumb({ category: 'exercise', message: `Return to the last routine`, data: routineSession })
    navigate('/exercises/workout/your-sets')
  }

  const handleRestartRoutine = () => {
    Sentry.addBreadcrumb({ category: 'exercise', message: `Restart the last routine` })
    setModalVisible(false)
    saveRoutine()
  }

  return (
    <LayoutNavigation title="Today Workout" hideFooter={true} isBack={true}>
      {loading && !routineSelected && (
        <Loading>
          <div className="rectangle" />
          <div className="rectangle" />
          <div className="rectangle" />
          <div className="rectangle" />
          <div className="rectangle" />
        </Loading>
      )}
      {!loading && !!routineSelected && (
        <>
          <CarouselWorkout
            title="Select your routine"
            routine={routineSelected}
            routines={initialState.routines}
            handleSelectRoutine={handleSelectRoutine}
            weekNumber={initialState.weekNumber}
          />
          <MessageSelectExercsice text="Select each exercise to see alternative  exercises" />
          {DragAndDrop && <DragAndDrop sets={routineSelected.sets} setOrder={handleSetOrder} />}
          {!DragAndDrop && <ContainerNoDnd sets={routineSelected.sets} setOrder={handleSetOrder} />}
          {!initialState.readOnly && (
            <ContainerBtnWorkout>
              <BtnPrimary text="Select this workout" icon="play-circle" onClick={() => saveRoutine()} />
            </ContainerBtnWorkout>
          )}

          <Modal
            modalVisible={modalVisible}
            message={'You have an unfinished routine'}
            routineName={`Day ${routineSelected.order} ${routineSelected.name}`}
            question={`Would you like to finish it?`}
            extraText={'It will be available until next Sunday'}
            setModalVisible={setModalVisible}
            textFirstButton={'Return to where I left'}
            textSecondButton={'Re-start this routine'}
            handleClickFirstButton={handleReturnLastSet}
            handleClickSecondButton={handleRestartRoutine}
            footerText={'Your previous data will be overwritten'}
          />
        </>
      )}
    </LayoutNavigation>
  )
}

export default index
