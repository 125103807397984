import styled from '@emotion/styled'
import { Card, Tooltip } from 'antd'

export const CstTooltip = styled(Tooltip)`
  margin: 0 auto;
`

export const ContainerKpis = styled(Card)`
  width: 100%;
  max-width: 400px;
  border: none !important;
  .tooltip-inner {
    white-space: pre-wrap;
  }

  p {
    margin-bottom: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #979797;
  }

  .ant-card-body {
    padding: 0px;
  }

  .container-kpis {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    top: -80px;
  }

  .ant-card {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
    border-radius: 4px;
  }
  .container-kpis > .ant-card:nth-of-type(1) {
    height: 57px;
    width: 110px;
  }

  .container-kpis > .ant-card:nth-of-type(2) {
    position: relative;
    top: -18px;
    height: 74px;
    max-width: 141px;
  }

  .container-kpis > .ant-card:nth-of-type(3) {
    height: 57px;
    width: 110px;
  }

  .container-kpis > .ant-card:nth-of-type(2) > .ant-card-body {
    height: 74px !important;
    max-width: 141px !important;
    margin: 0px !important;
  }

  .total-volume {
    margin: 0px;
    position: relative;
    bottom: 2px;
  }

  .container-kpis > .ant-card > .ant-card-body {
    padding: 0px;
    border-radius: 4px;
    &:nth-of-type(1) {
      height: 72px;
      max-width: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;

      color: #4a4a4a;
    }
    &:nth-of-type(2) {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      color: #4a4a4a;
    }

    &:nth-of-type(3) {
      height: 57px;
      max-width: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;

      color: #4a4a4a;
    }
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
  }

  @media screen and (max-width: 320px) {
    .container-kpis {
      & .ant-card:nth-of-type(1),
      .ant-card:nth-of-type(3) {
        width: 80px;

        & .ant-card-body {
          width: 100%;
        }
      }

      & .ant-card:nth-of-type(2) {
        width: 130px;

        & .ant-card-body {
          width: 100%;
        }
      }
    }
  }
`

export const SectionCardContainer = styled.div`
  margin-top: 14px;
  width: 100%;
  max-width: 375px;
  padding: 6px 0px 6px 7px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  border-right: 16px solid ${({ color }) => color};
  cursor: pointer;
  display: grid;
  grid-template-columns: 65px auto;

  .icon {
    background: linear-gradient(0deg, ${({ grad }) => grad} 0%, ${({ color }) => color} 99.87%);
    border-radius: 4px;
    width: 65px;
    height: 65px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  .content {
    padding-left: 11px;

    p {
      margin: 0;
      font-family: Montserrat;

      &.title {
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        font-height: 21px;
        color: #4a4a4a;
      }

      &.text {
        font-weight: ${({ pseudo }) => (pseudo ? 600 : 500)};
        font-size: ${({ pseudo }) => (pseudo ? 13 : 11)}px;
        line-height: ${({ pseudo }) => (pseudo ? 16 : 13)}px;
        color: ${({ colorText }) => (colorText ? colorText : '#000')};
        padding-right: 50px;
      }
    }
  }
`
