import { Avatar, Badge } from 'antd'
import styled from '@emotion/styled'

export const Root = styled.div`
  width: 34px;
  height: 34px;
  position: relative;
  .ant-avatar {
    position: relative;
    z-index: 9999;

    &:hover {
      cursor: pointer;
    }
  }
`
export const StyledAvatar = styled(Avatar)`
  width: 35px;
  height: 35px;
  line-height: 30px;
  font-size: 18px;
`
export const StyledBadge = styled(Badge)`
  .ant-badge-count {
    top: auto;
    right: 3px;
    bottom: -10px;
    padding: 0 3px;
    min-width: 16px;
    height: 16px;
    line-height: 18px;
    font-size: 8px;
    z-index: 10000;
    box-shadow: none;
  }
`
