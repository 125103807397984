import React, { useContext } from 'react'
import GlobalContext from 'context/GlobalContext'
import KetogainsLogo from 'images/tm.svg'
import { Query } from 'react-apollo'
import moment from 'moment'
import BuyBootcampBannerImage from 'images/bootcamps_home.png'
import { Button } from 'antd'
import { getGainspotSSO } from 'services'
import { getBootcampComing } from './querys'
import { BuyBootcampBanner } from './Homepage.styled'

const ComingProgram = (data) => {
  return (
    <>
      <p>{`You can get this bootcamp before anyone! Starts on ${moment(data.data.program[0].start_date).format('MMMM Do YYYY')}`}</p>
      <Button href="/subscription">Buy next camp!</Button>
    </>
  )
}
const CancelledPrograms = ({ user }) => {
  return (
    <>
      <h1>Your access requires action!</h1>
      <div className="text" style={{ padding: '0px 10px' }}>
        <p>
          Hi {user.first_name}, you are seeing this screen because there is an outstanding billing issue or technical issue that we need to resolve.
        </p>
        <Button href={getGainspotSSO('https://www.gainspot.app/payment-failure/')} target="_blank">
          Update credit card
        </Button>
      </div>
    </>
  )
}

export default () => {
  const globalContext = useContext(GlobalContext)
  const activePrograms = globalContext.programs
  const cancelledProgram = globalContext.cancelled_programs.find((p) => p.cancellation_reason === 'PAYMENT_FAILURE') || {}
  const noPrograms = activePrograms.length === 0 && !cancelledProgram.id
  const date = moment()
    .add(1, 'months')
    .format()

  return (
    <BuyBootcampBanner background={BuyBootcampBannerImage}>
      <div className="banner-image" />
      <img src={KetogainsLogo} alt="Ketogains" />
      <hr />
      <Query query={getBootcampComing} fetchPolicy="cache-and-network" variables={{ date }}>
        {({ loading, error, data }) => {
          if (loading) return null
          if (error) return null
          if (data.program.length === 0) return null
          return !noPrograms && date >= data.program[0].access_date && date <= data.program[0].start_date && <ComingProgram data={data} />
        }}
      </Query>
      {cancelledProgram.id && <CancelledPrograms user={globalContext.user} program={cancelledProgram} />}
      <p>
        <small>
          If you feel this was an error, please email us at <a href="mailto:info@ketogains.com">info@ketogains.com </a>
          and we can help resolve it.
        </small>
      </p>
    </BuyBootcampBanner>
  )
}
