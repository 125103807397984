import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableCards from 'components/Selectable/Cards'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [activityLevel, setActivityLevel] = useState()
  const activityLevelRef = useRef(null)
  const [activities, setActivities] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const catalogActivities = response.catalogs.activity_level.map((al) => {
          return {
            id: al.id,
            name: al.label,
            thumbnail: al.icon,
          }
        })
        const { id, activity_level } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setActivities(catalogActivities)
          setActivityLevel(Number(activity_level))
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [activityLevel])

  const saveCallback = async ({ goBack }) => {
    const data = { activity_level: activityLevel }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (activityLevel) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(activityLevelRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'lifestyle' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={activityLevelRef}>
          {submit && !activityLevel && <Message type="error" message="You need to select 1 option in this question" />}
          <div className="question">
            <p className="title">How would a normal day would look like for you?</p>
            <p>Do not consider your workout routine. </p>
          </div>
          <div className="content">
            <SelectableCards type="radio" value={activityLevel} catalog={activities} onChange={(e) => setActivityLevel(e.target.value)} />
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
