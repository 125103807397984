import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableToggle from 'components/Selectable/Toggle'
import { MaterializeInput, MaterializeSelect } from 'components/Materialize'
import SelectableCircles from 'components/Selectable/Circles'
import FoodYes from 'assets/icons/enrollment/food-yes.png'
import FoodNo from 'assets/icons/enrollment/food-no.png'
import { Radio, Input } from 'antd'

const { TextArea } = Input

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [hasDiet, setHasDiet] = useState()
  const [typeDiet, setTypeDiet] = useState()
  const [mealsPerDay, setMealsPerDay] = useState()
  const [trackFood, setTrackFood] = useState()
  const [prot, setProt] = useState()
  const [carbs, setCarbs] = useState()
  const [fat, setFat] = useState()
  const [dietSupliments, setDietSupliments] = useState()
  const [catalogDiets, setCatalogDiets] = useState()
  const hasDietRef = useRef(null)
  const typeDietRef = useRef(null)
  const mealsPerDayRef = useRef(null)
  const trackFoodRef = useRef(null)
  const macronutrientsRef = useRef(null)

  const yesDiet = { value: 'yes', label: 'Yes', icon: FoodYes }
  const noDiet = { value: 'no', label: 'No', icon: FoodNo }
  const yesTrackFood = { value: 'yes', label: 'Yes', icon: FoodYes }
  const noTrackFood = { value: 'no', label: 'No', icon: FoodNo }

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, has_diet, type_of_diet, meals_per_day, track_food, prot, carbs, fat, diet_supliments } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setCatalogDiets(response.catalogs.type_of_diets)
          setTypeDiet(type_of_diet)
          setMealsPerDay(meals_per_day)
          setProt(prot)
          setCarbs(carbs)
          setFat(fat)
          setDietSupliments(diet_supliments)
          if (typeof has_diet === 'boolean') {
            setHasDiet(has_diet ? 'yes' : 'no')
          }
          if (typeof track_food === 'boolean') {
            setTrackFood(track_food ? 'yes' : 'no')
          }
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (hasDiet === 'no') {
      setTypeDiet()
    }
    if (trackFood === 'no') {
      setProt(null)
      setCarbs(null)
      setFat(null)
    }
    validate()
  }, [hasDiet, typeDiet, mealsPerDay, trackFood, prot, carbs, fat])

  const saveCallback = async ({ goBack }) => {
    const data = {
      has_diet: hasDiet === 'yes',
      type_of_diet: typeDiet ? typeDiet : null,
      meals_per_day: mealsPerDay,
      track_food: trackFood === 'yes',
      prot: prot,
      carbs: carbs,
      fat: fat,
      diet_supliments: dietSupliments,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = !!hasDiet && !!mealsPerDay && !!trackFood
    if (hasDiet === 'yes') {
      isValid = isValid && !!typeDiet
    }
    if (trackFood === 'yes') {
      isValid = isValid && Number(prot) > 0 && Number(carbs) > 0 && Number(fat) > 0
    }

    if (isValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          if (trackFood === 'yes') {
            if (prot <= 0 || carbs <= 0 || fat <= 0) scrollToRef(macronutrientsRef)
          }
          !trackFood && scrollToRef(trackFoodRef)
          !mealsPerDay && scrollToRef(mealsPerDayRef)
          if (hasDiet === 'yes') {
            !typeDiet && scrollToRef(typeDietRef)
          }
          !hasDiet && scrollToRef(hasDietRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'lifestyle' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={hasDietRef}>
            {submit && !hasDiet && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Do you follow any specific type of diet?</p>
            </div>
            <div className="content">
              <SelectableToggle optionA={yesDiet} optionB={noDiet} value={hasDiet} onChange={(e) => setHasDiet(e.target.value)} />
            </div>
          </QuestionContainer>
          {hasDiet === 'yes' && (
            <QuestionContainer ref={typeDietRef}>
              {submit && !typeDiet && <Message type="error" message={'This field is required'} />}
              <div className="question">
                <p className="title">What type of diet do you follow?</p>
                <p>This helps us to calculate your Macros better.</p>
              </div>
              <div className="content">
                <MaterializeSelect value={typeDiet} options={catalogDiets} onChange={(value) => setTypeDiet(value)} />
              </div>
            </QuestionContainer>
          )}
          <QuestionContainer ref={mealsPerDayRef}>
            {submit && !mealsPerDay && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">How many meals do you eat per day?</p>
            </div>
            <div className="content">
              <SelectableCircles>
                <Radio.Group className="group" defaultValue={mealsPerDay} onChange={(e) => setMealsPerDay(e.target.value)}>
                  <Radio.Button value={1}>1</Radio.Button>
                  <Radio.Button value={2}>2</Radio.Button>
                  <Radio.Button value={3}>3</Radio.Button>
                  <Radio.Button value={4}>4+</Radio.Button>
                </Radio.Group>
              </SelectableCircles>
            </div>
          </QuestionContainer>
          <QuestionContainer ref={trackFoodRef}>
            {submit && !trackFood && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Do you keep track of your daily Macronutrients?</p>
            </div>
            <div className="content">
              <SelectableToggle optionA={yesTrackFood} optionB={noTrackFood} value={trackFood} onChange={(e) => setTrackFood(e.target.value)} />
            </div>
          </QuestionContainer>
          {trackFood === 'yes' && (
            <QuestionContainer ref={macronutrientsRef}>
              {submit && (!prot || !carbs || !fat) && <Message type="error" message={'This field is required'} />}
              <div className="question">
                <p className="title">Please fill the following fields with your Macronutrients data.</p>
                <p>(in grams)</p>
              </div>
              <div className="content">
                <MaterializeInput
                  type="number"
                  maxLength="3"
                  isValid={prot && Number(prot) > 0}
                  suffix="grams"
                  label="Protein"
                  value={prot}
                  onChange={(e) => setProt(e.target.value)}
                />
                <MaterializeInput
                  type="number"
                  maxLength="3"
                  isValid={carbs && Number(carbs) > 0}
                  suffix="grams"
                  label="Carbohydrate"
                  value={carbs}
                  onChange={(e) => setCarbs(e.target.value)}
                />
                <MaterializeInput
                  type="number"
                  maxLength="3"
                  isValid={fat && Number(fat) > 0}
                  suffix="grams"
                  label="Fat"
                  value={fat}
                  onChange={(e) => setFat(e.target.value)}
                />
              </div>
            </QuestionContainer>
          )}
          <QuestionContainer>
            <div className="question">
              <p className="title">
                Do you take any dietary supplements or multivitamins? <span>(Optional)</span>{' '}
              </p>
              <p>Please provide full details below.</p>
            </div>
            <div className="content">
              <TextArea
                value={dietSupliments}
                onChange={(e) => setDietSupliments(e.target.value)}
                maxLength={500}
                rows={8}
                placeholder="Type here..."
                autoSize={{ minRows: 8, maxRows: 8 }}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
