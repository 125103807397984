import React from 'react'
import { navigate } from '@reach/router'
import { CstPageHeader } from './Header.styled'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-back.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

//FIXME: DEPRECATED: Use the src/components/Layout/PageHeader

const Header = ({ title, isBack, completeRoutine, isModal, sub, menu }) => {
  let navigation = '/my-exercises'
  if (sub) {
    navigation = '/'
  }
  if (menu) {
    navigation = '/'
  }
  return (
    <CstPageHeader
      isBack={isBack}
      className="site-page-header"
      backIcon={isBack ? <ArrowIcon data-title="Back" /> : <CloseIcon data-title="Exit" />}
      onBack={(/*window.history.back()*/) => (completeRoutine ? isModal() : navigate(navigation))}
      title={title}
    />
  )
}

export default Header
