import styled from '@emotion/styled'
import { Button } from 'antd'

export default styled(Button)`
  height: 38px;
  border-radius: 50px;
  border-color: #ff6b00;
  color: #ff6b00;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 20px !important;
  padding: 9px 16px 11px !important;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    border-color: #ff6b00;
    color: #ff6b00;
  }

  &.ant-btn-primary {
    background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
    border-color: transparent;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    &:disabled {
      background: linear-gradient(270deg, rgba(255, 164, 0, 0.5) 2.12%, rgba(255, 107, 0, 0.5) 90.61%);

      &:hover,
      &:focus,
      &:active,
      &:visited {
        background: linear-gradient(270deg, rgba(255, 164, 0, 0.5) 2.12%, rgba(255, 107, 0, 0.5) 90.61%);
        color: #ffffff;
      }
    }
  }
`
