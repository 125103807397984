import { Radio } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export default styled(Radio.Group)`
  .ant-radio-inner {
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
    background-color: #ffffff;
  }
  .ant-radio {
    vertical-align: middle;
  }
  span.ant-radio + * {
    display: inline-block;
    vertical-align: middle;
  }
  .ant-radio-wrapper {
    vertical-align: middle;
    &:hover,
    &:focus {
      .ant-radio .ant-radio-inner {
        border-color: #5d5d5d;
      }
    }
    span {
      white-space: normal;
    }
  }
  .ant-radio-checked {
    &::after {
      animation: none;
      border-color: #ff6b00;
    }
  }
  .ant-radio-checked > .ant-radio-inner {
    border-color: #ff6b00;
    transition: none;
    &::after {
      position: absolute;
      left: 2px;
      top: 2px;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #ff6b00;
      -webkit-transition: none;
      transition: none;
    }
  }

  ${({ custom }) => {
    if (custom === 'inline') {
      return css`
        && {
          flex-direction: row;
        }
      `
    }
    if (custom === 'inlineCenter') {
      return css`
        && {
          flex-direction: row;
          justify-content: center;
        }
      `
    }
    if (custom === 'level') {
      return css`
        && {
          position: relative;
          .ant-radio-wrapper-checked {
            position: absolute;
            top: -80px;
          }
        }
      `
    }
  }}
`
