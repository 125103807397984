import React, { useState, useEffect, useRef, useContext } from 'react'
import moment from 'moment'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import { MaterializeInput, MaterializeSelect, MaterializeDatePicker, MaterializeMultiSelect } from 'components/Materialize'
import PictureUploader from 'components/PictureUploader'

import countries from 'countries'

import EditIcon from 'assets/icons/edit-orange.png'
import AvatarPlaceholder from 'assets/icons/enrollment/AvatarPlaceholder.png'
import BodyFront from 'assets/icons/enrollment/body-front.png'
import BodyBack from 'assets/icons/enrollment/body-back.png'
import BodySide from 'assets/icons/enrollment/body-side.png'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const lastValidDate = () => moment().subtract(18, 'years')

const disabledDate = (dateValue) => {
  const validDate = lastValidDate()
  return validDate.diff(moment(dateValue)) < 0
}

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const personalRef = useRef(null)
  const activityLevelRef = useRef(null)
  const restRef = useRef(null)
  const exerciseRef = useRef(null)
  const foodRef = useRef(null)
  const medicalRef = useRef(null)
  const biometricsRef = useRef(null)
  const measurementsRef = useRef(null)
  const bodyFatRef = useRef(null)
  const goalRef = useRef(null)
  const levelRef = useRef(null)

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [country, setCountry] = useState()
  const [birthdate, setBirthdate] = useState()
  const [uploadedFileURL, setUploadedFileURL] = useState(null)
  const [ethnicHeritage, setEthnicHeritage] = useState()
  const [gender, setGender] = useState()
  const [genderIdentity, setGenderIdentity] = useState()
  const [activityLevel, setActivityLevel] = useState()
  const [sleepHours, setSleepHours] = useState()
  const [sleepRest, setSleepRest] = useState()
  const [hasTraining, setHasTraining] = useState()
  const [trainingRegular, setTrainingRegular] = useState()
  const [trainingLocation, setTrainingLocation] = useState()
  const [trainingPerWeek, setTrainingPerWeek] = useState()
  const [trainingPerSession, setTrainingPerSession] = useState()
  const [injuries, setInjuries] = useState()
  const [hasDiet, setHasDiet] = useState()
  const [typeDiet, setTypeDiet] = useState()
  const [mealsPerDay, setMealsPerDay] = useState()
  const [trackFood, setTrackFood] = useState()
  const [prot, setProt] = useState()
  const [carbs, setCarbs] = useState()
  const [fat, setFat] = useState()
  const [dietSupliments, setDietSupliments] = useState()
  const [frontUrl, setFrontUrl] = useState()
  const [backUrl, setBackUrl] = useState()
  const [sideUrl, setSideUrl] = useState()
  const [hasMedicalConditions, setHasMedicalConditions] = useState()
  const [medicalConditionsSelected, setMedicalConditionsSelected] = useState()
  const [anotherMedicalCondition, setAnotherMedicalCondition] = useState()
  const [prescription, setPrescription] = useState()
  const [bodyFatMethod, setBodyFatMethod] = useState()
  const [bodyFatPercent, setBodyFatPercent] = useState()
  const [unitOfMeasurement, setUnitOfMeasurement] = useState()
  const [height, setHeight] = useState()
  const [weight, setWeight] = useState()
  const [goal, setGoal] = useState()
  const [level, setLevel] = useState()

  const genderCatalog = [
    { id: 'male', label: 'Male' },
    { id: 'female', label: 'Female' },
  ]
  const [ethnicCatalog, setEthnicCatalog] = useState([])
  const [genderIdentificationCatalog, setGenderIdentificationCatalog] = useState([])
  const [trainingRegularCatalog, setTrainingRegularCatalog] = useState([])
  const [trainingLocationCatalog, setTrainingLocationCatalog] = useState([])
  const [trainingPerWeekCatalog, setTrainingPerWeekCatalog] = useState([])
  const [trainingPerSessionCatalog, setTrainingPerSessionCatalog] = useState([])
  const [measurements, setMeasurements] = useState([])
  const [hasTheSameNumbers, setHasTheSameNumbers] = useState(false)
  const [medicalConditionsCatalog, setMedicalConditionsCatalog] = useState([])
  const [bodyFatMethodCatalog, setBodyFatMethodCatalog] = useState([])
  const [catalogDiets, setCatalogDiets] = useState([])
  const [goalCatalog, setGoalCatalog] = useState([])
  const [levelCatalog, setLevelCatalog] = useState([])
  const [activityLevelCatalog, setActivityLevelCatalog] = useState([])

  const [isPersonalEnabled, setIsPersonalEnabled] = useState(false)
  const [isActivityLevelEnabled, setIsActivityLevelEnabled] = useState(false)
  const [isRestEnabled, setIsRestEnabled] = useState(false)
  const [isExerciseEnabled, setIsExerciseEnabled] = useState(false)
  const [isFoodEnabled, setIsFoodEnabled] = useState(false)
  const [isMedicalEnabled, setIsMedicalEnabled] = useState(false)
  const [isBiometricsEnabled, setIsBiometricsEnabled] = useState(false)
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState(false)
  const [isBodyFatEnabled, setIsBodyFatEnabled] = useState(false)
  const [isPicturesEnabled, setIsPicturesEnabled] = useState(false)
  const [isGoalEnabled, setIsGoalEnabled] = useState(false)
  const [isLevelEnabled, setIsLevelEnabled] = useState(false)

  const globalContext = useContext(GlobalContext)
  const currentProgram = globalContext.programs.find((p) => p.program_id === sessionStorage.getItem('BOOTCAMP_ID_SELECTED'))

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const goals = response.catalogs.goal
        const levels = response.catalogs.Levels.map((l) => {
          return {
            id: l.level,
            label: `Level ${l.level}`,
          }
        })
        const enrollment = response.enrollment.data
        if (!enrollment.id) {
          setError('It looks you have not started the enrollment')
        } else {
          const userData = response.catalogs.user
          setEthnicCatalog(response.catalogs.ethnic_heritage)
          setGenderIdentificationCatalog(response.catalogs.gender_identification)
          setActivityLevelCatalog(response.catalogs.activity_level)
          setTrainingRegularCatalog(response.catalogs.regularity_training)
          setTrainingLocationCatalog(response.catalogs.places_train)
          setTrainingPerWeekCatalog(response.catalogs.days_per_week_training)
          setTrainingPerSessionCatalog(response.catalogs.time_per_training_session)
          setCatalogDiets(response.catalogs.type_of_diets)
          let medicalConditions = []
          const medicalConditionsGrouped = response.catalogs.medical_conditions.reduce(
            (result, item) => ({
              ...result,
              [item['category']]: [...(result[item['category']] || []), item],
            }),
            {},
          )
          for (const mcKey of Object.keys(medicalConditionsGrouped)) {
            medicalConditions.push({
              id: mcKey,
              label: mcKey,
              children: medicalConditionsGrouped[mcKey],
            })
          }
          setMedicalConditionsCatalog(medicalConditions)
          setBodyFatMethodCatalog(response.catalogs.body_fat_methods)
          setGoalCatalog(goals)
          setLevelCatalog(levels)

          setFirstName(userData.first_name)
          setLastName(userData.last_name)
          setEmail(userData.email)
          setCountry(userData.country)
          setBirthdate(userData.birthday)
          setUploadedFileURL(userData.picture)
          setEthnicHeritage(userData.ethnic_heritage)
          setGenderIdentity(userData.gender_identity)
          setGender(userData.gender)
          setActivityLevel(enrollment.activity_level)
          setSleepHours(enrollment.sleep_hours)
          setSleepRest(enrollment.sleep_rest)
          if (typeof enrollment.has_training === 'boolean') {
            setHasTraining(enrollment.has_training ? 'yes' : 'no')
          }
          setTrainingRegular(enrollment.training_regular)
          setTrainingLocation(enrollment.training_location)
          setTrainingPerWeek(enrollment.training_per_week)
          setTrainingPerSession(enrollment.training_session)
          setInjuries(enrollment.injuries)
          setTypeDiet(enrollment.type_of_diet)
          setMealsPerDay(enrollment.meals_per_day)
          setProt(enrollment.prot)
          setCarbs(enrollment.carbs)
          setFat(enrollment.fat)
          setDietSupliments(enrollment.diet_supliments)
          if (typeof enrollment.has_diet === 'boolean') {
            setHasDiet(enrollment.has_diet ? 'yes' : 'no')
          }
          if (typeof enrollment.track_food === 'boolean') {
            setTrackFood(enrollment.track_food ? 'yes' : 'no')
          }
          setUnitOfMeasurement(enrollment.metric)
          setHeight(enrollment.height)
          setWeight(enrollment.weight)
          if (typeof enrollment.has_medical_conditions === 'boolean') {
            setHasMedicalConditions(enrollment.has_medical_conditions ? 'yes' : 'no')
          }
          setMedicalConditionsSelected(
            enrollment.medical_conditions.map((mc) => {
              return mc.medical_condition.id
            }),
          )
          setAnotherMedicalCondition(enrollment.another_med_cond)
          setPrescription(enrollment.prescription_med)
          setBodyFatMethod(enrollment.bf_method)
          setBodyFatPercent(enrollment.bf_pct)
          setFrontUrl(enrollment.photo_front_url)
          setBackUrl(enrollment.photo_back_url)
          setSideUrl(enrollment.photo_side_url)
          setGoal(enrollment.current_goal)
          setLevel(enrollment.level)
          const measurements = [
            { id: 'neck', name: 'Neck (narrowest point)', value: enrollment.neck },
            { id: 'shoulder', name: 'Shoulder (circumference)', value: enrollment.shoulder },
            { id: 'chest', name: 'Chest (nipple height)', value: enrollment.chest },
            { id: 'arm', name: 'Arm (dominant side)', value: enrollment.arm },
            { id: 'forearm', name: 'Forearm (dominant side)', value: enrollment.forearm },
            { id: 'narrow_waist', name: 'Waist (narrowest point)', value: enrollment.narrow_waist },
            { id: 'belly_waist', name: 'Waist (at belly button)', value: enrollment.belly_waist },
            { id: 'hip', name: 'Hip (widest point, not glutes)', value: enrollment.hip },
            { id: 'thigh', name: 'Thigh (dominant side)', value: enrollment.thigh },
            { id: 'calf', name: 'Calf (dominant side)', value: enrollment.calf },
          ]
          setMeasurements(measurements)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [
    firstName,
    lastName,
    email,
    country,
    birthdate,
    uploadedFileURL,
    ethnicHeritage,
    gender,
    genderIdentity,
    activityLevel,
    sleepHours,
    sleepRest,
    hasTraining,
    trainingRegular,
    trainingLocation,
    trainingPerWeek,
    trainingPerSession,
    injuries,
    hasDiet,
    typeDiet,
    mealsPerDay,
    trackFood,
    prot,
    carbs,
    fat,
    dietSupliments,
    hasMedicalConditions,
    medicalConditionsSelected,
    anotherMedicalCondition,
    prescription,
    unitOfMeasurement,
    height,
    weight,
    measurements,
    bodyFatMethod,
    bodyFatPercent,
    frontUrl,
    backUrl,
    sideUrl,
    goal,
    level,
  ])

  const saveCallback = async ({ goBack }) => {
    const data = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        country,
        birthday: birthdate,
        picture: uploadedFileURL,
        ethnic_heritage: ethnicHeritage,
        gender,
        gender_identity: genderIdentity,
      },
      has_medical_conditions: hasMedicalConditions === 'yes',
      medical_conditions: medicalConditionsSelected,
      another_med_cond: anotherMedicalCondition,
      prescription_med: prescription,
      activity_level: activityLevel,
      sleep_hours: sleepHours,
      sleep_rest: sleepRest,
      has_training: hasTraining === 'yes',
      training_regular: trainingRegular,
      training_location: trainingLocation,
      training_per_week: trainingPerWeek,
      training_session: trainingPerSession,
      injuries,
      has_diet: hasDiet === 'yes',
      type_of_diet: typeDiet,
      meals_per_day: mealsPerDay,
      track_food: trackFood === 'yes',
      prot,
      carbs,
      fat,
      diet_supliments: dietSupliments,
      photo_front_url: frontUrl,
      photo_back_url: backUrl,
      photo_side_url: sideUrl,
      bf_method: bodyFatMethod,
      bf_pct: bodyFatPercent,
      metric: unitOfMeasurement,
      height,
      weight,
      current_goal: goal,
      level,
    }

    measurements.forEach((element) => {
      data[element.id] = Number(element.value)
    })

    if (hasTraining === 'no') {
      data.training_regular = null
      data.training_location = null
    }

    if (hasMedicalConditions === 'no') {
      data.medical_conditions = []
      data.another_med_cond = null
    }

    if (hasDiet === 'no') {
      data.type_of_diet = null
    }

    if (trackFood === 'no') {
      data.prot = null
      data.carbs = null
      data.fat = null
    }

    if (bodyFatMethod === 'NO') {
      data.bf_pct = null
    }

    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const getValidation = () => {
    const isPersonalValid = !!firstName && !!lastName && !!email && !!country && !!birthdate && !!ethnicHeritage && !!gender && !!genderIdentity
    const isRestValid = sleepHours > 0 && typeof sleepRest === 'boolean'
    let isExerciseValid = !!hasTraining && !!trainingPerWeek && !!trainingPerSession
    if (hasTraining === 'yes') {
      isExerciseValid = !!trainingRegular && !!trainingLocation
    }

    let isFoodValid = !!hasDiet && !!trackFood && !!mealsPerDay
    if (hasDiet === 'yes') {
      isFoodValid = !!typeDiet
    }

    if (trackFood === 'yes') {
      isFoodValid = !!prot && !!carbs && !!fat
    }

    let isMedicalValid = true
    if (hasMedicalConditions === 'yes') {
      isMedicalValid = (!!medicalConditionsSelected && medicalConditionsSelected.length > 0) || !!anotherMedicalCondition
    }

    let isMeasurementsValid =
      measurements.length > 0 &&
      measurements.every((element) => {
        let isValid = checkIsValidMeasurement(element.id, Number(element.value))
        if (!isValid) {
          return false
        }
        return true
      })
    if (isMeasurementsValid) {
      if (!hasTheSameNumbers && measurements.every((m) => m.value === measurements[0].value)) {
        isMeasurementsValid = !measurements.every((m) => m.value === measurements[0].value)
        setHasTheSameNumbers(true)
      }
    }

    let isBiometricsValid = !!unitOfMeasurement
    const minWeight = unitOfMeasurement === 'lbs/in' ? 60 : 27.21
    const minHeight = unitOfMeasurement === 'lbs/in' ? 40 : 101.6
    const maxHeight = unitOfMeasurement === 'lbs/in' ? 90 : 228.6
    const isValidWeight = Number(weight) > Number(minWeight)
    const isValidHeight = Number(minHeight) <= Number(height) && Number(height) <= Number(maxHeight)
    isBiometricsValid = isBiometricsValid && isValidWeight && isValidHeight

    let isBodyFatValid = !!bodyFatMethod
    if (bodyFatMethod !== 'NO') {
      isBodyFatValid = Number(bodyFatPercent) > 0 && Number(bodyFatPercent) <= 100
    }

    return {
      isPersonalValid,
      isRestValid,
      isExerciseValid,
      isBiometricsValid,
      isFoodValid,
      isMeasurementsValid,
      isBodyFatValid,
      isMedicalValid,
    }
  }

  const validate = (shouldFocusError) => {
    let isProgramEnrollmentValid = false
    const { isPersonalValid, isRestValid, isExerciseValid, isBiometricsValid, isFoodValid, isMeasurementsValid, isBodyFatValid } = getValidation()

    if (currentProgram.type === 'meal_plan') {
      isProgramEnrollmentValid = isPersonalValid && isFoodValid && isMedicalValid
    } else if (currentProgram.type === 'macros') {
      isProgramEnrollmentValid =
        isPersonalValid &&
        activityLevel > 0 &&
        isRestValid &&
        isExerciseValid &&
        isFoodValid &&
        isMedicalValid &&
        isBiometricsValid &&
        isMeasurementsValid &&
        isBodyFatValid &&
        !!goal
    } else {
      isProgramEnrollmentValid =
        isPersonalValid &&
        activityLevel > 0 &&
        isRestValid &&
        isExerciseValid &&
        isFoodValid &&
        isMedicalValid &&
        isBiometricsValid &&
        isMeasurementsValid &&
        isBodyFatValid &&
        !!goal &&
        level > 0
    }

    if (isProgramEnrollmentValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          !isPersonalValid && scrollToRef(personalRef)
          currentProgram.type !== 'meal_plan' && isNaN(activityLevel) && scrollToRef(activityLevelRef)
          currentProgram.type !== 'meal_plan' && !isRestValid && scrollToRef(restRef)
          currentProgram.type !== 'meal_plan' && !isExerciseValid && scrollToRef(exerciseRef)
          !isFoodValid && scrollToRef(foodRef)
          !isMedicalValid && scrollToRef(medicalRef)
          currentProgram.type !== 'meal_plan' && !isBiometricsValid && scrollToRef(biometricsRef)
          currentProgram.type !== 'meal_plan' && !isMeasurementsValid && scrollToRef(measurementsRef)
          currentProgram.type !== 'meal_plan' && !isBodyFatValid && scrollToRef(bodyFatRef)
          currentProgram.type !== 'meal_plan' && !!!goal && scrollToRef(goalRef)
          ;['macros', 'meal_plan'].indexOf(currentProgram.type) === -1 && isNaN(level) && scrollToRef(levelRef)
        }
      }
    }
  }

  const checkIsValidMeasurement = (id, value) => {
    const gender = localStorage.getItem(LOCAL_STORAGE.USER_GENDER)
    const num = Number(value)
    let isValid = false
    if (num > 0 && !hasTheSameNumbers) {
      isValid = true
      const field = gender === 'male' ? 'narrow_waist' : 'belly_waist'
      if (id === field || id === 'neck') {
        const waist = measurements.find((m) => m.id === field)
        const neck = measurements.find((m) => (m.id = 'neck'))
        if (Number(neck.value) > 0 && Number(waist.value) > 0) {
          isValid = isValid && Number(waist.value) >= Number(neck.value)
        }
      }
    }

    return isValid
  }

  const handleSetMeasurement = (id, value) => {
    const data = measurements.map((m) => {
      if (m.id === id) {
        m.value = value
      }
      return m
    })
    setMeasurements(data)
    setHasTheSameNumbers(false)
  }

  const {
    isPersonalValid,
    isRestValid,
    isExerciseValid,
    isBiometricsValid,
    isFoodValid,
    isBodyFatValid,
    isMedicalValid,
    isMeasurementsValid,
  } = getValidation()

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'finish' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={personalRef}>
            {submit && !isPersonalValid && <Message type="error" message="Please complete all of the mandatory fields." />}
            <div className="question with-icon">
              <p className="title">Personal Data</p>
              <img src={EditIcon} alt="Edit" onClick={() => setIsPersonalEnabled(!isPersonalEnabled)} />
            </div>
            <div className="content">
              <MaterializeInput
                name="first_name"
                label="First Name"
                value={firstName}
                isValid={!!firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeInput
                name="last_name"
                label="Last Name"
                value={lastName}
                isValid={!!lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeInput name="email" label="Account email" isValid={!!email} value={email} disabled />
              <MaterializeSelect
                label="Country of residence"
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                autoClearSearchValue={false}
                value={country}
                options={countries}
                onChange={(value) => setCountry(value)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeDatePicker
                disabledDate={disabledDate}
                style={{ width: 400 }}
                label="Birthdate (MM/DD/YYYY)"
                value={!!birthdate ? moment(birthdate) : null}
                format="MM/DD/YYYY"
                onChange={(value) => setBirthdate(value)}
                disabled={!isPersonalEnabled}
              />
              <PictureUploader
                picture={uploadedFileURL}
                placeholder={AvatarPlaceholder}
                urlModifiers="-/quality/lighter/"
                onSelect={(pictureUrl) => setUploadedFileURL(pictureUrl)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeSelect
                label="Ethnic Heritage"
                value={ethnicHeritage}
                options={ethnicCatalog}
                onChange={(value) => setEthnicHeritage(value)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeSelect
                label="Gender Assigned at Birth"
                value={gender}
                options={genderCatalog}
                onChange={(value) => setGender(value)}
                disabled={!isPersonalEnabled}
              />
              <MaterializeSelect
                label="Gender Identity"
                value={genderIdentity}
                options={genderIdentificationCatalog}
                onChange={(value) => setGenderIdentity(value)}
                disabled={!isPersonalEnabled}
              />
            </div>
          </QuestionContainer>
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={activityLevelRef}>
              {submit && isNaN(activityLevel) && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Activity Level</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsActivityLevelEnabled(!isActivityLevelEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="How would a normal day would look like for you?"
                  value={activityLevel}
                  options={activityLevelCatalog}
                  onChange={(value) => setActivityLevel(value)}
                  disabled={!isActivityLevelEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={restRef}>
              {submit && !isRestValid && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Rest</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsRestEnabled(!isRestEnabled)} />
              </div>
              <div className="content">
                <MaterializeInput
                  type="number"
                  maxLength={2}
                  suffix="hours"
                  isValid={sleepHours && Number(sleepHours) > 0}
                  label="Hours of sleep"
                  value={sleepHours}
                  onChange={(e) => setSleepHours(e.target.value)}
                  disabled={!isRestEnabled}
                />
                <MaterializeSelect
                  label="Do you usually wake rested?"
                  value={!!sleepRest ? 'yes' : 'no'}
                  options={[
                    { id: 'yes', label: 'Yes' },
                    { id: 'no', label: 'No' },
                  ]}
                  onChange={(value) => setSleepRest(value === 'yes')}
                  disabled={!isRestEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={exerciseRef}>
              {submit && !isExerciseValid && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Exercise</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsExerciseEnabled(!isExerciseEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="Do you strength train regularly?"
                  value={hasTraining}
                  options={[
                    { id: 'yes', label: 'Yes' },
                    { id: 'no', label: 'No' },
                  ]}
                  onChange={(value) => setHasTraining(value)}
                  disabled={!isExerciseEnabled}
                />
                <MaterializeSelect
                  label="How long have you been doing strength training?"
                  value={trainingRegular}
                  options={trainingRegularCatalog}
                  onChange={(value) => setTrainingRegular(value)}
                  disabled={!isExerciseEnabled || hasTraining === 'no'}
                />
                <MaterializeSelect
                  label="Where do you normally train?"
                  value={trainingLocation}
                  options={trainingLocationCatalog}
                  onChange={(value) => setTrainingLocation(value)}
                  disabled={!isExerciseEnabled || hasTraining === 'no'}
                />
                <MaterializeSelect
                  label="How many days per week can you commit to training?"
                  value={trainingPerWeek}
                  options={trainingPerWeekCatalog}
                  onChange={(value) => setTrainingPerWeek(value)}
                  disabled={!isExerciseEnabled}
                />
                <MaterializeSelect
                  label="How much time can you commit per training session?"
                  value={trainingPerSession}
                  options={trainingPerSessionCatalog}
                  onChange={(value) => setTrainingPerSession(value)}
                  disabled={!isExerciseEnabled}
                />
                <MaterializeInput
                  name="injuries"
                  label="Do you have any pre-existing injury or problem?"
                  value={injuries}
                  isValid={injuries}
                  onChange={(e) => setInjuries(e.target.value)}
                  disabled={!isExerciseEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          <QuestionContainer ref={foodRef}>
            {submit && !isFoodValid && <Message type="error" message="Please complete all of the mandatory fields." />}
            <div className="question with-icon">
              <p className="title">Food</p>
              <img src={EditIcon} alt="Edit" onClick={() => setIsFoodEnabled(!isFoodEnabled)} />
            </div>
            <div className="content">
              <MaterializeSelect
                label="Do you follow any specific type of diet?"
                value={hasDiet}
                options={[
                  { id: 'yes', label: 'Yes' },
                  { id: 'no', label: 'No' },
                ]}
                onChange={(value) => setHasDiet(value)}
                disabled={!isFoodEnabled}
              />
              <MaterializeSelect
                label="What type of diet do you follow?"
                value={typeDiet}
                options={catalogDiets}
                onChange={(value) => setTypeDiet(value)}
                disabled={!isFoodEnabled || hasDiet === 'no'}
              />
              <MaterializeSelect
                label="How many meals do you eat per day?"
                value={mealsPerDay}
                options={[
                  { id: 1, label: '1' },
                  { id: 2, label: '2' },
                  { id: 3, label: '3' },
                ]}
                onChange={(value) => setMealsPerDay(value)}
                disabled={!isFoodEnabled}
              />
              <MaterializeSelect
                label="Do you keep track of your daily Macronutrients?"
                value={trackFood}
                options={[
                  { id: 'yes', label: 'Yes' },
                  { id: 'no', label: 'No' },
                ]}
                onChange={(value) => setTrackFood(value)}
                disabled={!isFoodEnabled}
              />
              <MaterializeInput
                label="Protein"
                type="number"
                maxLength="3"
                isValid={prot && Number(prot) > 0}
                suffix="grams"
                value={prot}
                onChange={(e) => setProt(e.target.value)}
                disabled={!isFoodEnabled || trackFood === 'no'}
              />
              <MaterializeInput
                label="Carbohydrate"
                type="number"
                maxLength="3"
                isValid={carbs && Number(carbs) > 0}
                suffix="grams"
                value={carbs}
                onChange={(e) => setCarbs(e.target.value)}
                disabled={!isFoodEnabled || trackFood === 'no'}
              />
              <MaterializeInput
                label="Fat"
                type="number"
                maxLength="3"
                isValid={fat && Number(fat) > 0}
                suffix="grams"
                value={fat}
                onChange={(e) => setFat(e.target.value)}
                disabled={!isFoodEnabled || trackFood === 'no'}
              />
              <MaterializeInput
                label="Do you take any dietary supplements or vitamins?"
                type="text"
                value={dietSupliments}
                onChange={(e) => setDietSupliments(e.target.value)}
                disabled={!isFoodEnabled}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={medicalRef}>
            {submit && !isMedicalValid && <Message type="error" message="Please complete all of the mandatory fields." />}
            <div className="question with-icon">
              <p className="title">Medical Conditions</p>
              <img src={EditIcon} alt="Edit" onClick={() => setIsMedicalEnabled(!isMedicalEnabled)} />
            </div>
            <div className="content">
              <MaterializeSelect
                label="Do you have any medical conditions to report?"
                value={hasMedicalConditions}
                options={[
                  { id: 'yes', label: 'Yes' },
                  { id: 'no', label: 'No' },
                ]}
                onChange={(value) => setHasMedicalConditions(value)}
                disabled={!isMedicalEnabled}
              />
              <MaterializeMultiSelect
                label="Your medical conditions"
                value={medicalConditionsSelected}
                options={medicalConditionsCatalog}
                onChange={(value) => setMedicalConditionsSelected(value)}
                disabled={!isMedicalEnabled || hasMedicalConditions === 'no'}
              />
              <MaterializeInput
                label="Medical Conditions or Diagnosis not listed"
                type="text"
                value={anotherMedicalCondition}
                onChange={(e) => setAnotherMedicalCondition(e.target.value)}
                disabled={!isMedicalEnabled || hasMedicalConditions === 'no'}
              />
              <MaterializeInput
                label="Do you take any prescription medications?"
                type="text"
                value={prescription}
                onChange={(e) => setPrescription(e.target.value)}
                disabled={!isMedicalEnabled}
              />
            </div>
          </QuestionContainer>
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={biometricsRef}>
              {submit && !isBiometricsValid && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Biometrics</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsBiometricsEnabled(!isBiometricsEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="Preferred Units of Measure"
                  value={unitOfMeasurement}
                  options={[
                    { id: 'lbs/in', label: 'Pounds and Inches' },
                    { id: 'kg/cm', label: 'Kilograms and Centimeters' },
                  ]}
                  onChange={(value) => setUnitOfMeasurement(value)}
                  disabled
                />
                <MaterializeInput
                  type="number"
                  maxLength={2}
                  suffix={unitOfMeasurement === 'kg/cm' ? 'centimeters' : 'inches'}
                  isValid={height && Number(height) > 0}
                  label="Height"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  disabled={!isBiometricsEnabled}
                />
                <MaterializeInput
                  type="number"
                  maxLength={2}
                  suffix={unitOfMeasurement === 'kg/cm' ? 'kilograms' : 'pounds'}
                  isValid={weight && Number(weight) > 0}
                  label="Weight"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  disabled={!isBiometricsEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={measurementsRef}>
              {submit && !isMeasurementsValid && <Message type="error" message="Invalid input, please confirm your entry" />}
              <div className="question with-icon">
                <p className="title">Body Measurements</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsMeasurementEnabled(!isMeasurementEnabled)} />
              </div>
              <div className="content">
                {measurements.map((measurement) => {
                  return (
                    <MaterializeInput
                      key={measurement.id}
                      type="number"
                      suffix={unitOfMeasurement === 'kg/cm' ? 'centimeters' : 'inches'}
                      label={measurement.name}
                      value={measurement.value}
                      isValid={Number(measurement.value) && checkIsValidMeasurement(measurement.id, measurement.value)}
                      onChange={(e) => handleSetMeasurement(measurement.id, e.target.value)}
                      disabled={!isMeasurementEnabled}
                    />
                  )
                })}
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={bodyFatRef}>
              {submit && !isBodyFatValid && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Body Fat</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsBodyFatEnabled(!isBodyFatEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="Do You Know Your Body Fat Percentage?"
                  value={bodyFatMethod}
                  options={bodyFatMethodCatalog}
                  onChange={(value) => setBodyFatMethod(value)}
                  disabled={!isBodyFatEnabled}
                />
                <MaterializeInput
                  type="number"
                  maxLength={2}
                  suffix="%"
                  isValid={bodyFatPercent && Number(bodyFatPercent) > 0}
                  label="Your Body Fat Percentage"
                  value={bodyFatPercent}
                  onChange={(e) => setBodyFatPercent(e.target.value)}
                  disabled={!isBodyFatEnabled || bodyFatMethod === 'NO'}
                />
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer>
              <div className="question with-icon">
                <p className="title">Starting Pictures</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsPicturesEnabled(!isPicturesEnabled)} />
              </div>
              <div className="content">
                <PictureUploader
                  title="Front Picture"
                  picture={frontUrl}
                  placeholder={BodyFront}
                  urlModifiers="-/quality/lighter/"
                  onSelect={(pictureUrl) => setFrontUrl(pictureUrl)}
                  disabled={!isPicturesEnabled}
                />
                <PictureUploader
                  title="Back Picture"
                  picture={backUrl}
                  placeholder={BodyBack}
                  urlModifiers="-/quality/lighter/"
                  onSelect={(pictureUrl) => setBackUrl(pictureUrl)}
                  disabled={!isPicturesEnabled}
                />
                <PictureUploader
                  title="Side Picture"
                  picture={sideUrl}
                  placeholder={BodySide}
                  urlModifiers="-/quality/lighter/"
                  onSelect={(pictureUrl) => setSideUrl(pictureUrl)}
                  disabled={!isPicturesEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          {currentProgram.type !== 'meal_plan' && (
            <QuestionContainer ref={goalRef}>
              {submit && !goal && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Bootcamp Goal</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsGoalEnabled(!isGoalEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="Your Bootcamp Goal"
                  value={goal}
                  options={goalCatalog}
                  onChange={(value) => setGoal(value)}
                  disabled={!isGoalEnabled}
                />
              </div>
            </QuestionContainer>
          )}
          {['macros', 'meal_plan'].indexOf(currentProgram.type) === -1 && (
            <QuestionContainer ref={levelRef}>
              {submit && !level && <Message type="error" message="Please complete all of the mandatory fields." />}
              <div className="question with-icon">
                <p className="title">Bootcamp Level</p>
                <img src={EditIcon} alt="Edit" onClick={() => setIsLevelEnabled(!isLevelEnabled)} />
              </div>
              <div className="content">
                <MaterializeSelect
                  label="Your Bootcamp Level"
                  value={level}
                  options={levelCatalog}
                  onChange={(value) => setLevel(value)}
                  disabled={!isLevelEnabled}
                />
              </div>
            </QuestionContainer>
          )}
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
