import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SelectableCards from 'components/Selectable/Cards'
import SectionError from 'components/SectionError'
import { SelectMultiple } from 'styled'
import ok from 'assets/images/ok.png'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState()
  const [canGoNext, setCanGoNext] = useState(false)
  const [disableSelect, setDisableSelect] = useState(false)

  const [catalog, setCatalogs] = useState({
    ingredients: [],
    ingredientCategories: [],
  })
  const [ingredientsSelected, setIngredientsSelects] = useState({
    id: null,
    ingredient_category_sensitivities: [],
    ingredient_sensitivities: [],
    ingredients_sensitivities_catalog: [],
  })

  const allergenicRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.error) {
        const { ingredients, ingredientCategories } = response.catalogs
        const noAllergiesOption = {
          id: 0,
          name: 'None, I can eat all foods',
          thumbnail: ok,
        }
        const { id, ingredient_sensitivities, ingredient_category_sensitivities, has_sensitivities } = response.enrollment.mealPlan
        const hasSensitivities = typeof has_sensitivities === 'boolean' ? !has_sensitivities : has_sensitivities
        let mutatedCatalogIngredients = handleMutation(ingredientCategories, hasSensitivities)
        mutatedCatalogIngredients = [...mutatedCatalogIngredients, noAllergiesOption]
        mutatedCatalogIngredients.sort((a, b) => a.id - b.id)
        let mutatedIngredients = handleMutation(ingredients, hasSensitivities)
        setDisableSelect(hasSensitivities)
        setCatalogs({
          ingredients: mutatedIngredients,
          ingredientCategories: mutatedCatalogIngredients,
        })

        let data = []
        if (has_sensitivities && ingredient_sensitivities.length > 0) {
          data = await mutatedIngredients.filter((i) => ingredient_sensitivities.find((e) => e === i.id))
        }

        setIngredientsSelects({
          id,
          has_sensitivities,
          ingredient_category_sensitivities: typeof has_sensitivities === 'boolean' && !has_sensitivities ? [0] : ingredient_category_sensitivities,
          ingredient_sensitivities,
          ingredients_sensitivities_catalog: data,
        })
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [ingredientsSelected])

  const saveCallback = async ({ goBack }) => {
    const { id, has_sensitivities, ingredient_category_sensitivities, ingredient_sensitivities } = ingredientsSelected
    const data = {
      id,
      has_sensitivities,
      ingredient_sensitivities,
      ingredient_category_sensitivities: ingredient_category_sensitivities[0] === 0 ? [] : ingredient_category_sensitivities,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    const { ingredient_category_sensitivities, ingredient_sensitivities } = ingredientsSelected
    let canGoNext = ingredient_category_sensitivities.length >= 1 || ingredient_sensitivities.length >= 1 ? true : false
    setCanGoNext(canGoNext)
    if (!canGoNext && shouldFocusError && !loading) {
      scrollToRef(allergenicRef)
    }
  }

  const handleSelectItem = (item) => {
    let array = ingredientsSelected.ingredient_sensitivities
    array = [...array, item]
    let data = catalog.ingredients.filter((i) => i.id === item)
    setIngredientsSelects((prev) => ({
      ...prev,
      ingredient_sensitivities: array,
      ingredients_sensitivities_catalog: [...prev.ingredients_sensitivities_catalog, ...data],
    }))
  }

  const handleSelectCard = async (array) => {
    const none = array.find((n) => n === 0)
    const isDisabled = none === 0 ? true : false

    setCatalogs({
      ingredients: handleMutation(catalog.ingredients, isDisabled),
      ingredientCategories: handleMutation(catalog.ingredientCategories, isDisabled),
    })

    setIngredientsSelects((prev) => ({
      ...prev,
      has_sensitivities: !isDisabled,
      ingredient_category_sensitivities: isDisabled ? [0] : array,
      ingredient_sensitivities: isDisabled ? [] : prev.ingredient_sensitivities,
    }))

    setDisableSelect(isDisabled)
  }

  const handleMutation = (array, isDisabled) => {
    if (typeof isDisabled === 'boolean') {
      return array.map((item) => {
        if (item.id !== 0) {
          item.disabled = isDisabled
        }
        return item
      })
    }
    return array
  }

  const filterIngredients = catalog.ingredients.filter(
    (o) =>
      !ingredientsSelected.ingredient_sensitivities.includes(o.id) &&
      !ingredientsSelected.ingredients_sensitivities_catalog.find((ci) => ci.id === o.id),
  )
  return (
    <EnrollmentLayout {...{ ...props, loading, canGoNext, saveCallback, section: 'meal_plan' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={allergenicRef}>
          {submit &&
            (ingredientsSelected.ingredient_category_sensitivities.length <= 0 && ingredientsSelected.ingredient_sensitivities.length <= 0) && (
              <Message
                type="error"
                message={'You need to select at least 1 option in this question'}
                textStyle={{ fontSize: '15px' }}
                isMealPlan={true}
              />
            )}
          <div className="question">
            <p className="title">Do you have any food allergies, restrictions or sensitivities?</p>
            <p>Please select at least 1 option.</p>
          </div>
          <div className="content">
            <SelectableCards
              type="check"
              catalog={catalog.ingredientCategories}
              value={ingredientsSelected.ingredient_category_sensitivities}
              onChange={handleSelectCard}
            />
            <SelectableCards
              type="check"
              catalog={ingredientsSelected.ingredients_sensitivities_catalog}
              value={ingredientsSelected.ingredient_sensitivities}
              onChange={(e) => setIngredientsSelects((prev) => ({ ...prev, ingredient_sensitivities: e }))}
            />
            <div className="sub-component">
              <p>Add other foods to exclude for allergies</p>
              <SelectMultiple onSelect={handleSelectItem} disabled={disableSelect} options={filterIngredients} placeholder="eg.avocado" />
            </div>
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
