import React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { Select, Icon } from 'antd'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'

const SelectMultiple = styled(Select)`
  .ant-select-selection--multiple {
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    font-size: 16px;
    color: #5d5d5d;
    box-shadow: none;
    .ant-select-selection__rendered {
      height: 100%;
    }
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    display: inherit !important;
  }
  [aria-expanded='true'] {
    border: 1.5px solid #ff6800 !important;
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #fff;
    }
  }
`
export default ({ onChange, onSelect, disabled, options, placeholder, defaultValue }) => {
  return (
    <>
      <Global
        styles={css`
          .styled-select-multiple {
            .ant-select-dropdown-menu {
              max-height: 120px !important;
            }
          }
        `}
      />
      <SelectMultiple
        mode="multiple"
        size="large"
        placeholder={placeholder}
        style={{ width: '100%' }}
        menuItemSelectedIcon={<Plus />}
        onChange={onChange}
        onSelect={onSelect}
        disabled={disabled}
        dropdownClassName="styled-select-multiple"
        className="select"
        autoClearSearchValue={true}
        maxTagCount={0}
        defaultValue={defaultValue}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Icon type="warning" style={{ fontSize: '44px', color: '#C4C4C4' }} />
            <p style={{ fontSize: '13px', color: '#959595' }}>Your search did not match any results</p>
          </div>
        }
      >
        {options.map((option) => {
          return (
            <Select.Option className="optionMultiple" key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          )
        })}
      </SelectMultiple>
    </>
  )
}
