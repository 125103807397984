import React from 'react'
import { WrapDropDown } from './BoxBootcamp.styled.js'
import Card from 'components/Cards/Card/Card'

const BoxBootcamps = ({ children }) => {
  return (
    <WrapDropDown>
      <Card>
        <div className="bgr-title">
          <span>Select your Bootcamp</span>
        </div>
        <div className="ctn-children">{children}</div>
      </Card>
    </WrapDropDown>
  )
}

export default BoxBootcamps
