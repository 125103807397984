import styled from '@emotion/styled'

export const Container = styled.div`
  .content {
    background: #ffffff;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 15px 5px;
    text-align: center;
    margin-bottom: 5px;

    hr {
      max-width: 290px;
      margin: 12px auto;
      border: none;
      border-top: 1px solid #c4c4c4;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      max-width: 290px;
      color: #959595;
      margin: 0 auto;
      margin-bottom: 14px;

      b {
        color: #5d5d5d;
      }
    }
  }
`
