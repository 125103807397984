import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Drawer, Menu } from 'antd'

export const Root = styled.div``

export const WrapTrademark = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, #ff6702 0%, #ff4512 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6702', endColorstr='#ff4512', GradientType=1 ); /* IE6-9 */
  height: 140px;
  margin-bottom: 30px;
  flex-wrap: wrap;
`

export const Version = styled.div`
  color: #ffdd99;
  font-size: 9px;
  text-align: right;
  width: 100%;
  padding: 0 10px;
`

export const Trademark = styled.div`
  background: #fff;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-size: cover;
  border-radius: 80px;
  margin: 30px auto 0;
  box-shadow: inset 0 0 3px 1px #dfdfdf;
  height: 80px;
  width: 80px;
`
export const StyledA = styled.a`
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 14px;
  color: #818181;
  display: block;
  text-align: left;
  transition: 0.3s;
  &:hover {
    color: #000;
  }
  &.close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 20px;
  }
  svg {
    width: auto;
    height: 18px;
    fill: #ffffff;
  }
`
export const StyledSpan = styled.span`
  cursor: pointer;
`
export const StyledDrawer = styled(Drawer)`
  &.custom-nav {
    z-index: 10000;

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background-color: #ffd196;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffd196;
    }
    .ant-menu-vertical .ant-menu-item:after,
    .ant-menu-vertical-left .ant-menu-item:after,
    .ant-menu-vertical-right .ant-menu-item:after,
    .ant-menu-inline .ant-menu-item:after {
      border-right: 3px solid #ff6b00;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }

  .learning {
    span {
      max-width: calc(100% - 30px);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .download {
      background: linear-gradient(90deg, #ff4713 7.81%, #ff7e22 96.3%);
      box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
      border-radius: 50%;
      padding: 5px;
      width: 25px;
      height: 25px;
      float: right;
      top: 7px;
      position: relative;
      line-height: 1;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }
`
export const StyledMenu = styled(Menu)`
  .ant-menu-item,
  .ant-menu-submenu-title {
    color: #777;

    .anticon {
      margin-right: 0px;
    }
  }
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    color: #000;
    .ant-menu-submenu-arrow {
      &:before,
      &:after {
        background: #000 !important;
      }
    }
  }
  .ant-menu-submenu-arrow {
    &:before,
    &:after {
      background: #777;
    }
  }

  .ant-menu-submenu ul {
    .ant-menu-item {
      font-size: 12px;
    }
  }

  .ant-menu-item > a:hover {
    color: #000;
  }
  ${({ width }) =>
    width &&
    css`
      width: ${width} px;
    `}
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: auto;
    height: 14px;
    fill: #ff6b00;

    &.logout {
      height: 12px;
      transform: rotate(180deg);
    }
  }
  .ant-menu-item-disabled {
    svg {
      opacity: 0.25;
    }
  }
`
export const StyledLink = styled.a`
  text-decoration: none;
`
