import React from 'react'
import LayoutNavigation from 'components/LayoutNavigation/LayoutNavigation'
import { Root } from 'components/Homepage/Homepage.styled'
import Banner from '../Banner/Banner'
import Card from 'components/Cards/Card/Card'
import { ReactComponent as IconBarra } from 'assets/icons/IconBarra.svg'
import { ReactComponent as UnderC } from 'assets/icons/underC.svg'
import { LOCAL_STORAGE } from 'constants.js'

const UnderConstruction = ({ title }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: '70%',
  }

  return (
    <LayoutNavigation title="Your Progress" hideFooter={true} isBack={true}>
      <Root style={{ padding: '0px 0px' }}>
        <Banner
          name={localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)}
          picture={localStorage.getItem(LOCAL_STORAGE.USER_PICTURE)}
          style={{ marginBottom: '10px' }}
        />
        <Card className="wo" style={{ height: '55vh', display: 'flex', justifyContent: 'center' }}>
          <div style={style}>
            <IconBarra />
            <UnderC />
          </div>
        </Card>
      </Root>
    </LayoutNavigation>
  )
}

export default UnderConstruction
