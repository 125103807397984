import styled from '@emotion/styled'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  width: 100%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
`

export const Wrap = styled.div`
  width: 100%;
  max-width: 365px;
  margin: 15px auto 15px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d4d4d4;
`

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #ff6b00;
  margin-bottom: 50px;
  width: 100%;
`

export const H1 = styled.h1`
  color: #fff;
  line-height: 1.2;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  padding: 14px 32px;
  text-transform: uppercase;
`
export const H2 = styled.h2`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  padding: 0;
  text-transform: uppercase;
`
export const ThankYou = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 45px;
  max-width: 200px;
`

export const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  margin-bottom: -30px;
  list-style: none;
  width: 90%;
`

export const Li = styled.li`
  && {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: #fff;
    border: solid 1px #ff4613;
    height: 85px;
    width: 85px;
  }
`

export const Value = styled.div`
  color: #ff6b00;
  font-size: 23px;
  font-weight: 600;
`

export const Name = styled.div`
  color: #4a4a4a;
  font-size: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
`
export const Content = styled.div`
  background: #f8f9fa;
  padding: 0px;
  text-align: center;
`
export const ButtonView = styled.div`
  margin-bottom: 20px;
`
