import * as Sentry from '@sentry/browser'
import { setCookie, getCookie, removeCookie } from 'tiny-cookie'
import { message } from 'antd'
import apolloClient from 'apolloClient'
import { COOKIE } from 'constants.js'
import { getUserData } from 'queries'
import { loadInitialStorage } from 'services'
import moment from 'moment'
import { deleteDB, getCacheWhere } from 'services/db/indexedDB.js'
import { navigate } from '@reach/router'
import { LOCAL_STORAGE } from './constants'

const authServer = process.env.REACT_APP_AUTH_SERVER
const today = moment()
  .local()
  .format('YYYY-MM-DD')

function IsPersonalComplete(queryData) {
  return !!queryData.id && !!queryData.gender && queryData.gender.length > 0 && !!queryData.birthday && queryData.birthday.length > 0
}

export const loginCredentials = ({ username, password, redirect }) => {
  return new Promise((resolve, reject) => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('username', username)
    urlencoded.append('password', password)
    urlencoded.append('issueJWT', 'true')
    urlencoded.append('includeRoles', 'true')
    urlencoded.append('refreshableJWT', 'true')

    fetch(`${authServer}/wp-json/aam/v2/authenticate`, {
      method: 'POST',
      body: urlencoded,
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        if (wpData.user && wpData.jwt) {
          setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: '24h' })
          setCookie(COOKIE.USER_TOKEN, `Bearer ${wpData.jwt.token}`, {
            expires: moment.unix(wpData.jwt.token_expires).toDate(),
          })

          const user = wpData.user.data

          if (user.contact_id <= 0) {
            console.log('Auth server validation')
            reject('User inactive')
          } else {
            apolloClient
              .query({
                query: getUserData,
                variables: { id: `${user.contact_id}`, email: user.user_email, today },
              })
              .then(({ data }) => {
                if (data.user.length <= 0) {
                  console.log('App server validation')
                  reject('User inactive')
                  return
                }
                const authData = {
                  id: user.ID,
                }

                const { has_enrollment, totalBootcampsAssigned } = loadInitialStorage(data.user[0], authData)

                // Set Sentry context
                Sentry.configureScope((scope) => {
                  scope.setUser({
                    email: user.user_email,
                    id: user.ID,
                    username: user.display_name,
                    nickname: user.display_name,
                    cid: user.contact_id,
                  })
                })

                // Redirect to wizard or home
                if (!redirect && (!IsPersonalComplete(data.user[0]) || (has_enrollment && totalBootcampsAssigned <= 1))) {
                  navigate('/enrollment')
                } else if (redirect) {
                  navigate(redirect)
                } else {
                  navigate('/')
                }
              })
          }
        } else {
          let message = wpData.message || wpData.reason || 'Something failed please try again'
          if (wpData.code) {
            message = 'Wrong email or password.'
          }
          reject(message)
        }
      })
      .catch((error) => reject(`${error}`))
  })
}

export const logout = async (withRedirect = true) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  const oldToken = getCookie(COOKIE.USER_TOKEN)
  if (oldToken) {
    await fetch(`${authServer}/wp-json/aam/v2/jwt/revoke`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ jwt: oldToken.replace('Bearer ', '') }),
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        console.log('wpData :>> ', wpData)
      })
      .catch((error) => {
        console.log('error :>> ', error)
      })
  }

  localStorage.clear()
  deleteDB()
  removeCookie(COOKIE.API_TOKEN)
  removeCookie(COOKIE.USER_TOKEN)
  removeCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)
  if (withRedirect) window.location = '/login'
}

export const renewToken = async (email, reload = true) => {
  apolloClient.writeData({
    data: {
      App: {
        loading: true,
        __typename: 'Application',
      },
    },
  })

  return new Promise(async (resolve, reject) => {
    let result = {}
    const oldToken = getCookie(COOKIE.USER_TOKEN)
    if (!getCookie(COOKIE.USER_TOKEN_EXPIRE_TIME) && !oldToken) {
      result = { hasError: true, message: 'We could not refresh your credentials' }
    } else {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      await fetch(`${authServer}/wp-json/aam/v2/jwt/refresh`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ jwt: oldToken.replace('Bearer ', '') }),
      })
        .then((resp) => resp.json())
        .then((wpData) => {
          if (wpData.token && wpData.token_expires) {
            const email = localStorage.getItem(LOCAL_STORAGE.USER_EMAIL)
            const id = localStorage.getItem(LOCAL_STORAGE.EX_USER_ID)

            setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: '2h' })
            setCookie(COOKIE.USER_TOKEN, `Bearer ${wpData.token}`, {
              expires: moment.unix(wpData.token_expires).toDate(),
            })

            apolloClient
              .query({
                query: getUserData,
                variables: { id, email, today },
              })
              .then(({ data }) => {
                const authData = { id: id }
                loadInitialStorage(data.user[0], authData)
              })

            result = { hasError: false, token: wpData.token }
          } else {
            result = { hasError: true, message: 'We could not refresh your credentials' }
          }
        })
        .catch((error) => {
          console.log('error :>> ', error)
          result = { hasError: true, message: 'We could not refresh your credentials' }
        })
    }

    if (result.hasError) {
      message.error(`Could not get a new token (${result.message}).`)
      const foundSessions = await getCacheWhere('routine_session', { is_completed: 1 }).catch((e) => console.error(e))
      const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
      if (foundSessions && traineeId) {
        Sentry.addBreadcrumb({
          type: 'debug',
          category: 'auth/exercises',
          message: 'Data',
          data: {
            trainee_id: traineeId,
            num_routine_sessions: foundSessions.length,
            last_session: foundSessions[foundSessions.length - 1].for_date,
            error: result.message,
          },
        })
        Sentry.captureMessage('User was logged out with sessions pending syncing')
      }
      logout()
      reject(result.message)
      return
    } else {
      apolloClient.writeData({
        data: {
          App: {
            loading: false,
            __typename: 'Application',
          },
        },
      })
      resolve(`Bearer ${result.token}`)
    }
  })
}

export const impersonate = async (jwt, isAdmin) => {
  return new Promise((resolve, reject) => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('jwt', jwt)

    fetch(`${authServer}/wp-json/aam/v2/jwt/validate`, {
      method: 'POST',
      body: urlencoded,
    })
      .then((response) => response.json())
      .then(async (wpData) => {
        if (wpData.userId) {
          if (getCookie(COOKIE.USER_TOKEN) && wpData.email !== localStorage.getItem(LOCAL_STORAGE.USER_EMAIL)) {
            await logout(false)
          }

          let expiration = '24h'

          if (isAdmin) {
            expiration = '1h'
            localStorage.setItem(LOCAL_STORAGE.IMPERSONATE_USER, true)
          }

          setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: expiration })
          setCookie(COOKIE.USER_TOKEN, `Bearer ${jwt}`, { expires: moment.unix(wpData.exp).toDate() })

          apolloClient
            .query({
              query: getUserData,
              variables: { id: `${wpData.contact_id}`, email: wpData.email, today },
            })
            .then(({ data }) => {
              if (data.user.length <= 0) {
                reject('User inactive')
                return
              }

              const authData = { id: wpData.userId }

              const { has_enrollment, totalBootcampsAssigned } = loadInitialStorage(data.user[0], authData)

              // Set Sentry context
              Sentry.configureScope((scope) => {
                scope.setUser({
                  email: wpData.email,
                  id: wpData.userId,
                  username: wpData.email,
                  nickname: wpData.email,
                  cid: wpData.contact_id,
                })
              })

              // Redirect to wizard or home
              if (!IsPersonalComplete(data.user[0]) || (has_enrollment && totalBootcampsAssigned <= 1)) {
                resolve('/enrollment')
              } else {
                resolve('/')
              }
            })
        } else {
          reject(wpData.reason ? wpData.reason : 'We could not validate your credentials, please try again.')
        }
      })
      .catch((error) => {
        console.log('error :>> ', error)
        reject('We could not validate your credentials')
      })
  })
}

export const RegisterUser = async ({ firstName, lastName, username, password }) => {
  const dataUser = {
    firstName,
    lastName,
    email: username,
    password,
  }

  const result = await fetch(`${process.env.REACT_APP_API_URL}user`, {
    method: 'POST',
    body: JSON.stringify(dataUser),
  })
    .then((resp) => resp.json())
    .then((data) => data)
    .catch((error) => ({ hasError: true, message: error.message }))

  return result
}
