import React, { Component } from 'react'
import Layout from 'components/Layout'
import MainForm from './MainForm'
import { Root } from './Personal.styled'
import GlobalContext from 'context/GlobalContext'

class Personal extends Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {({ user }) => {
          return (
            <Layout>
              <Root>
                <MainForm
                  {...{
                    contact_id: user.contact_id,
                    units: user.units,
                    authEmail: user.email,
                    is_returning: user.is_returning,
                  }}
                />
              </Root>
            </Layout>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Personal
