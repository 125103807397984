import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { resetUl } from 'services/style'

export const Root = styled.nav`
  background-color: #5d5d5d;
  padding: 25px 40px;
  ul {
    ${resetUl()}
    text-align: right;
  }
  &:after {
    content: '';
    width: 40px;
    height: 42px;
    background-color: #5d5d5d;
    position: absolute;
    top: -42px;
    right: -3px;
  }
  /* tmp solution */
  &:before {
    content: '';
    width: 3px;
    height: 100%;
    background-color: #5d5d5d;
    position: absolute;
    top: 0;
    right: -3px;
  }
`
export const Li = styled.li`
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #fff;
    font-weight: 500;
    position: relative;

    &:hover {
      color: #fff;
      &:after {
        position: absolute;
        top: 7px;
        right: -25px;
        font-size: 32px;
        line-height: 0;
        content: '•';
      }
    }
  }
  .btn-review {
    color: #fff;
    font-weight: 500;
    position: relative;
    padding: 0px;
    &:hover {
      color: #fff;
      &:after {
        position: absolute;
        top: 10px;
        right: -25px;
        font-size: 32px;
        line-height: 0;
        content: '•';
      }
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;

      a {
        &:hover {
          cursor: not-allowed;
          &:after {
            display: none;
          }
        }
      }
    `}
`
