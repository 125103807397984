import React, { useContext, useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { Root } from '../Layout/Layout.styled'
import { MainEnrollmentContainer, Card } from '../Main/Main.styled'
import { Button } from 'styled'
import BannerSection from 'components/Banner/BannerSection'
import HandEnrollment from 'assets/icons/enrollment/hand-enrollment.png'
import 'assets/scss/index.scss'
import GlobalContext from 'context/GlobalContext'
const index = () => {
  const [programId, setProgramId] = useState()
  const [loading, setLoading] = useState(true)
  const [isBootcamp, setIsBootcamp] = useState(false)
  const [programType, setProgramType] = useState('')
  const globalContext = useContext(GlobalContext)
  useEffect(() => {
    const programSelected = sessionStorage.getItem('BOOTCAMP_ID_SELECTED')
    const program = globalContext.programs.find((p) => p.program_id === programSelected)
    if (program) {
      setProgramId(programSelected)
      setProgramType(program.typeLabel)
      if (program.type === 'bootcamp') {
        setIsBootcamp(true)
      }
      setLoading(false)
      //sessionStorage.removeItem('BOOTCAMP_ID_SELECTED')
    }
  }, [])

  return (
    <Layout>
      {!loading && (
        <Root>
          <BannerSection title={`My ${programType} ${isBootcamp ? 'Enrollment' : 'Submission'}`} picture={HandEnrollment} />
          <Card style={{ padding: '12px 0px' }}>
            <MainEnrollmentContainer>
              <div className="content" style={{ padding: '45px 5px 35px' }}>
                <div id="confetti" />
                <hr />
                {isBootcamp && <p style={{ marginBottom: '45px' }}>You are officially enrolled in the new Bootcamp!</p>}
                {!isBootcamp && (
                  <p style={{ marginBottom: '45px' }}>You completed your submission, we will start to read and analyze your information.</p>
                )}
                <Button href={isBootcamp ? `/bootcamp-timeline/${programId}` : `/`} type="primary" block>
                  {isBootcamp ? 'View Bootcamp Timeline' : 'Go to Dashboard'}
                </Button>
              </div>
            </MainEnrollmentContainer>
          </Card>
        </Root>
      )}
    </Layout>
  )
}

export default index
