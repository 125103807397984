import gql from 'graphql-tag'

export const getFbUrl = gql`
  query getFbUrl($id: uuid!, $level: Int!, $sublevel: String) {
    program_url(where: { program_id: { _eq: $id }, level: { _eq: $level }, sub_level: { _eq: $sublevel }, type: { _eq: "facebook" } }) {
      url
      id
    }
  }
`

export const appendMessage = gql`
  mutation appendMessage($data: message_insert_input!) {
    insert_message(objects: [$data]) {
      returning {
        timestamp
        body
      }
    }
  }
`

export const getMessages = gql`
  query($id: uuid!) {
    message(where: { thread: { trainee_id: { _eq: $id } } }, order_by: { timestamp: asc }) {
      timestamp
      body
      sender_id
      read
      id
    }
  }
`
