import styled from '@emotion/styled'

export const Root = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 56px;
`

export const MealPlanContainer = styled.div`
  margin: 0px 10px;
`
