import styled from '@emotion/styled'
import { Checkbox } from 'antd'

export default styled(Checkbox.Group)`
  width: 100%;

  .ant-checkbox-group-item {
    display: block;
    background: #ffffff;
    border: 2px solid #717271;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0;
    margin-bottom: 10px;
    padding: 10px 15px 10px 10px;
    display: grid;
    grid-template-columns: 20px auto;

    .ant-checkbox {
      visibility: hidden;
      align-self: center;

      .ant-checkbox-inner {
        width: 23px;
        height: 23px;
      }
    }

    &.ant-checkbox-wrapper-checked {
      background: linear-gradient(90deg, #ff6b00 0%, #ffa401 100%);
      background-clip: padding-box;
      color: #ffffff;
      border-color: transparent;

      .ant-checkbox-checked {
        visibility: visible;

        &:after {
          border-color: transparent;
        }

        .ant-checkbox-inner {
          background: #ffffff;
          border-color: #ffffff;
          border-radius: 50%;

          &:after {
            border: 2px solid #ff6b00;
            border-top: 0;
            border-left: 0;
            width: 8.57142856px;
            height: 13.71428571px;
          }
        }
      }
    }
  }

  &.fake-disabled {
    .ant-checkbox-group-item:not(.ant-checkbox-wrapper-checked) {
      cursor: not-allowed;
      .ant-checkbox {
        cursor: not-allowed;
      }
    }
  }
`
