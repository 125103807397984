import styled from '@emotion/styled'
import { Carousel } from 'antd'

export const CarouselSwipe = styled(Carousel)`
  width: 375px;
  .slick-dots.slick-dots-bottom {
    display: none !important;
  }
  .ant-card {
    max-width: 400px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    position: relative;
    left: -12px !important;
  }

  .text {
    position: relative;
    left: -11px !important;
  }

  .text:before {
    bottom: -8px !important;
  }

  .container {
    left: -10px !important;
  }

  .button {
    width: 19px !important;
    right: 0px;
  }

  @media screen and (max-width: 320px) {
    width: 315px;
    .title {
      position: relative;
      left: -7px !important;
    }

    .text {
      position: relative;
      left: -7px !important;
    }

    .container {
      left: 1px !important;
    }

    .button {
      /* left: 9px; */
    }
  }
`
