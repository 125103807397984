import React, { Component } from 'react'
import eyeOpened from 'images/eyeOpened.png'
import eyeClosed from 'images/eyeClosed.png'

class Peephole extends Component {
  cancelShowPassword = () => {
    const { showPassword, tooglePassword } = this.props
    if (!!showPassword) {
      tooglePassword()
    }
  }

  render() {
    const { showPassword, tooglePassword } = this.props
    return (
      <img
        alt="password"
        {...{
          src: showPassword ? eyeClosed : eyeOpened,
          onMouseDown: tooglePassword,
          onMouseUp: tooglePassword,
          onMouseLeave: this.cancelShowPassword,
        }}
      />
    )
  }
}

export default Peephole
