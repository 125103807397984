import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from '@reach/router'
import { DatePicker, Modal } from 'antd'

const { MonthPicker } = DatePicker

export const Root = styled.div`
  max-width: 400px;
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 100px 48px 20px;
`
export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
`
export const Image = styled.img`
  display: block;
  width: 98px;
  height: 98px;
  object-fit: contain;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
`
export const Month = styled.p`
  color: #5d5d5d;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  ${({ custom }) => {
    if (custom === 'modal') {
      return css`
        && {
          color: #ffffff;
        }
      `
    }
  }}
`
export const Week = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  span {
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    margin-left: 8px;
  }
  ${({ custom }) => {
    if (custom === 'modal') {
      return css`
        && {
          color: #ffffff;
          svg {
            fill: #ffffff;
            path {
              fill: #ffffff;
            }
          }
        }
      `
    }
  }}
`
export const Label = styled.span`
  font-size: 11px;
  font-weight: 600;
  margin-right: 10px;
  min-width: 40px;
`
export const Filter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
`
export const StyledMonthPicker = styled(MonthPicker)`
  font-size: 12px;
  width: 100%;
  .ant-input {
    font-size: 10px;
  }
`
export const UploadButton = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  padding: 12px 32px;
  border: 1px solid #979797;
  border-radius: 50px;
  background-color: #eaeaea;
  color: #5d5d5d;
`

export const ModalPicture = styled(Modal)`
  height: calc(100% - 60px);
  padding: 0;

  @media (min-width: 1210px) {
    width: calc(100% - 60px) !important;
    max-width: 1050px;
    min-height: 500px;
    max-height: 630px;
  }

  @media (min-width: 760px) {
    width: calc(100% - 60px) !important;
    max-width: 900px;
    min-height: 500px;
    max-height: 630px;
  }

  @media (max-width: 759px) {
    flex-grow: 1;
    height: 100%;
    margin: 0;
    width: 100% !important;
    max-width: 100% !important;

    .ant-modal-content {
      .ant-modal-header {
        text-align: center;
      }
    }
  }

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .ant-modal-header {
      border-bottom: none;

      .ant-modal-title {
        color: #353535;
        font-size: 22px;
        font-weight: 700;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 10px 20px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .ant-modal-footer {
      border-top: none;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px 15px;
      margin-top: 10px;

      .btn-delete {
        order: 0;
        all: initial;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: inline-block;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
        padding: 10px;
        width: auto;
        min-width: 100px;
        height: auto;
        min-height: 0;
        border-radius: 6px;
        border: 1px solid #157cfc;
        background: transparent;
        color: #157cfc;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        font-family: inherit;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        line-height: 20px;
        box-shadow: none;
        text-shadow: none;
        transition: background 0.3s, color 0.3s, border 0.3s;
      }

      .uploadcare-tab-effects--additions {
        order: 1;
      }

      .uploadcare--widget {
        display: inline-block;
        order: 2;

        .uploadcare--widget__button_type_open {
          padding: 10px 20px;
        }
      }

      @media (max-width: 759px) {
        display: block;
        .btn-delete {
          float: left;
        }
      }
    }
  }
`
