import iconBodyFront from 'images/icon-body-front.png'
import iconBodyBack from 'images/icon-body-back.png'
import iconBodySide from 'images/icon-body-side.png'

export const previewTransform = '-/autorotate/yes/-/preview/96x96/'
export const previewModalTransform = '-/autorotate/yes/-/preview/400x400/'

export const monthFormat = 'MMMM YYYY'

export const limit = 5

export const orderTypes = [{ name: 'Newest first', value: 'desc' }, { name: 'Oldest first', value: 'asc' }]

export const images = {
  front: iconBodyFront,
  back: iconBodyBack,
  side: iconBodySide,
}

export const missedPictures = [
  { url: iconBodyFront, id: 1 },
  {
    url: iconBodyBack,
    id: 2,
  },
  { url: iconBodySide, id: 3 },
]
