import styled from '@emotion/styled'

export const PictureUploaderContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 12px;

  p {
    font-weight: 600;
    font-size: 15px;
    color: #4a4a4a;
  }

  .picture {
    width: 100%;
    max-width: 240px;
    height: 240px;
    background: #e3e3e3;
    margin: 0 auto;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: inherit;
      object-fit: contain;
    }

    .uploadcare--widget {
      display: none;
    }

    &:after {
      content: ' ';
      background-color: #ff4613;
      height: 45px;
      width: 45px;
      border: 0;
      outline: none;
      border-radius: 50%;
      font-size: 0px !important;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKrSURBVHgB7ZnhddowEMePvHwvG1SdoB6BTtB2gniDdAPoBG0ngExQmAA6AdlA7gSkE1zvnqVg9GzLJ8lEJPzeO7CFJOuPT7qzDHDlysuAiIoNLhkSMMcj3+ASoYFP8ZQDZMxNz29Tz3lW3MAr4dUIubUHPCfg1H2UW9ldvSaTSdXTfhDNPqJxVicpc9NHgeEsMXaJjxyAZWr60hiOtv2EwHPkCfJAkW1jxPA/ucAwDmgCJdYZQAq2kJK2gXnq/8bzoMl+4NA7N1QIFc/Itnh+9tgQcwsBdIk6MwUZu/WCTy49IBb2oE+Iu5pVkB/v7EGnEIq4LOR7o+gXf/CcgAyZ+CrYCWWE8TkvjzMYBrd5INuRPZoyZWxGdgdx7Ghcn3prYP08ohrnSrA6abISPJg+Q2MY4pCYg8fld4+y1ONkWRwC1mmSRjkiIRL2EIi53kF2uaOQoDjSQUX2tWWAfHfuoV4q+ZjnzYZ8e9Wsx+k81eX246coHsqWPu57/mWNLak7yrKE5K6lW9rPB7Q7uGKwTnnEQlJF9o0zGAUmdfDArrZsFpCL7SAg+KYSsnbOJXtgfAcKp+wPCEklxE1nPoIMV0gFQlIJid28UxDJWNmv9PH5ESJJJcTNd6Q+7gqRumYyIa6Pr2C4n69a9rYKSIUwjnA8mDrtC/SnHPuWdiUGxJFUQphFRx+6o/5PbEkuUZY8jpJrcTqyJjd59nfjMh+wfhizuRaXre3zjSOCdy0VpATDsl+NgVuf1O4O5SRPUSwK6t3CmaCNvRMrGAOM3zlc+u4Oxu+JjTJHXEo2uhjPmR3ZX6gDpSJ7T/YFEr4FG1OIpYCUcaGDN/Hq7QnyeeXQxT974Nug20DerAfVwnpvS2OeLJpj9e40GkElfX2GPN61V2QP5pH4ypWx+Q9DSFd+0kuDxQAAAABJRU5ErkJggg==);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      position: absolute;
      display: block;
      bottom: 4px;
      right: 4px;
      float: right;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
