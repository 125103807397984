import gql from 'graphql-tag'

export const updateTraineeProgramCoachRate = gql`
  mutation updateRate($traineeId: uuid, $programId: uuid, $data: Boolean) {
    update_trainee_program_coach(where: { trainee_id: { _eq: $traineeId }, program_id: { _eq: $programId } }, _set: { review_sent: $data }) {
      returning {
        trainee_id
        review_sent
      }
    }
  }
`
