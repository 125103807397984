import styled from '@emotion/styled'
import { Modal } from 'antd'

export const CstModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;

    .wrap-content {
      padding: 38px 28px 20px;
      text-align: center;

      & p {
        font-weight: bold;
        span,
        small {
          display: block;
        }
        span {
          color: #ff6b00 !important;
          font-weight: bold;
        }
        small {
          font-weight: normal;
        }
      }

      & p:first-of-type {
        font-weight: 500;
        padding: 0px 20px;
      }

      & p:last-child {
        margin-bottom: 0px;
      }
      .txt-warning {
        color: #ff6b00 !important;
        white-space: nowrap;
      }

      .btn {
        font-size: 15px;
        margin-top: 12px;
      }

      .btn-transparent {
        background: transparent !important;
        border: 4px solid #ff6b00;
        color: #ff6b00 !important;
      }

      .text-footer {
        color: #ff6b00;
        font-weight: 500;
      }
    }
  }
`
