import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  width: 100%;
  overflow: hidden;
  justify-content: center;

  .css-h8drfd {
    width: 100%;
    max-width: 400px;
    position: relative;
    background: white;
  }

  @media screen and (max-width: 320px) {
    .ant-page-header-back {
      left: -33px;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  ${({ disabledFooter }) => {
    if (disabledFooter) {
      return css`
        && {
          padding-bottom: 0;
        }
      `
    }
  }}
`

export const Footer = styled.div`
  height: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
`
