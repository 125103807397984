import styled from '@emotion/styled'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #471308;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  padding: 20px;
`
