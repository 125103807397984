import React, { useContext, useState } from 'react'
import { Button } from 'antd'
import { FirstEnrollmentContainerModal } from './Main.styled'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

export default ({ visible, preFillEnrollment }) => {
  const [visibleModal, setVisibleModal] = useState(visible)
  const globalContext = useContext(GlobalContext)
  const programSelected = sessionStorage.getItem('BOOTCAMP_ID_SELECTED')
  const program = globalContext.programs.find((p) => p.program_id === programSelected)

  const handlePreFillEnrollment = (type) => {
    preFillEnrollment(type)
    setVisibleModal(false)
  }

  const getFirstName = () => {
    let name = localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)
    name = name.split(' ')
    return name[0]
  }

  return (
    <FirstEnrollmentContainerModal
      title={<div className="banner" />}
      footer={[
        <Button type="primary" onClick={() => handlePreFillEnrollment('new')} block>
          Begin my {program.type === 'bootcamp' ? 'Enrollment' : 'Submission'}
        </Button>,
      ]}
      closable={false}
      visible={visibleModal}
    >
      <h3>Hi {getFirstName()}, we are happy to have you here!</h3>
      <p>
        The first step to get you set up in the Ketogains {program.typeLabel} is to{' '}
        <span>fill your {program.type === 'bootcamp' ? 'Enrollment' : 'Submission'} data</span>. Here you will provide us with some information about
        you, your lifestyle{['bootcamp', 'macros'].indexOf(program.type) !== -1 ? ', body composition,' : ''} and preferences. Our coaches carefully
        read and analyze all this to assign your{' '}
        {program.type === 'bootcamp' ? 'Macros, Training Program and Bootcamp Level' : program.type === 'meal_plan' ? 'Meal Plan' : 'Macros'}.
      </p>
      {program.type !== 'meal_plan' && (
        <>
          <p>
            Most of this information you already know it, but there are some bits in the <span>Body Composition section</span> that will require some
            material to provide the data. Don't worry, you probably have it all in your home already.
          </p>
          <p>
            <b>What you will need to fill your Body Composition data is:</b>
          </p>
          <p>
            <b>- A bathroom scale</b>
          </p>
          <p>
            <b>- A measuring tape</b>
          </p>
          <p>
            <b>- A digital camera(your phone camera works just fine)</b>
          </p>
          <p>And that's all!</p>
        </>
      )}
      <p>
        <span>We hope you enjoy this {program.typeLabel} and work hard to achieve your goals!</span>
      </p>
    </FirstEnrollmentContainerModal>
  )
}
