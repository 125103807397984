import React from 'react'
import Card from '../Cards/Card/Card'
import { CardMessage } from './Message.styled'
import { ReactComponent as IconWarning } from 'assets/icons/IconInfo.svg'
import { ReactComponent as IconInfo } from 'assets/icons/IconInfoBlue.svg'
import { ReactComponent as IconSuccess } from 'assets/icons/IconSuccessCheck.svg'
import { ReactComponent as IconRedWarning } from 'assets/icons/IconWarningRed.svg'
const Message = ({ type, color, title, message, cardStyle, textStyle, isMealPlan = false }) => {
  if (!!!type) type = 'info'
  let IconToShow

  switch (type) {
    case 'warning':
      color = '#FFA401'
      IconToShow = IconWarning
      break
    case 'error':
      color = '#E53D2F'
      IconToShow = IconRedWarning
      break
    case 'success':
      color = '#6FCF97'
      IconToShow = IconSuccess
      break
    default:
      color = '#2EA3F2'
      IconToShow = IconInfo
      break
  }

  return (
    <Card style={cardStyle} mealPlan={isMealPlan}>
      <CardMessage color={color}>
        {!!IconToShow && <IconToShow className="icon" />}
        <div className="wrap-text">
          <b>{title ? title : ''}</b>
          {!!message && <p style={textStyle} dangerouslySetInnerHTML={{ __html: message }} />}
        </div>
      </CardMessage>
    </Card>
  )
}

export default Message
