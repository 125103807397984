import React, { Component } from 'react'
import { Carousel, Button } from 'antd'
import { TutorialModal, TutorialImage, TutorialBarItem, TutorialStep } from './Tutorial.styled'
import { TutorialContent } from './Tutorial.data'
import { ReactComponent as StartIcon } from 'images/Tutorial/tutorial-start-icon.svg'
import { ReactComponent as DashboardIcon } from 'images/Tutorial/tutorial-dashboard-icon.svg'
import { ReactComponent as ExercisesIcon } from 'images/Tutorial/tutorial-excercises-icon.svg'
import { ReactComponent as LearningIcon } from 'images/Tutorial/tutorial-learning-icon.svg'
import { ReactComponent as MacrosIcon } from 'images/Tutorial/tutorial-macros-icon.svg'
import { ReactComponent as PhotosIcon } from 'images/Tutorial/tutorial-photos-icon.svg'
import showdown from 'showdown'

const TutorialIcon = (props) => {
  const { itemActive } = props
  let iconToShow
  switch (itemActive) {
    case 1:
    default:
      iconToShow = <StartIcon className="icon" />
      break
    case 2:
      iconToShow = <DashboardIcon className="icon" />
      break
    case 3:
      iconToShow = <MacrosIcon className="icon" />
      break
    case 4:
      iconToShow = <LearningIcon className="icon" />
      break
    case 5:
      iconToShow = <ExercisesIcon className="icon" />
      break
    case 6:
      iconToShow = <PhotosIcon className="icon" />
      break
  }
  return iconToShow
}

const converter = new showdown.Converter()
const createMarkup = (body) => {
  return { __html: body }
}

class Tutorial extends Component {
  state = {
    visible: null,
    itemActive: 1,
    sectionActive: 1,
    stepActive: 1,
    onCloseEvent: null,
  }

  componentDidMount() {
    const { onCloseTutorial } = this.props
    if (!!onCloseTutorial) {
      this.setState({
        onCloseEvent: onCloseTutorial,
      })
    }
  }

  hideModal = () => {
    this.setState({ visible: false })
  }

  changeItem = (itemId) => {
    if (!!this.slider) this.slider.goTo(0)
    this.setState({
      itemActive: itemId,
      sectionActive: 1,
      stepActive: 1,
    })
  }

  onCarouselChange = (current) => {
    this.setState({
      stepActive: current + 1,
    })
  }

  handleNextBack = (isBack) => {
    const itemActive = TutorialContent.filter((item) => item.Id === this.state.itemActive)[0]
    const sectionActive = itemActive.Sections.filter((section) => section.Id === this.state.sectionActive)[0]
    const stepActive = sectionActive.Steps.filter((step) => step.Id === this.state.stepActive)[0]

    if (isBack) {
      if (!!this.slider) this.slider.prev()
      this.setState({ stepActive: stepActive.Id - 1 })
    } else {
      if (itemActive.Id === TutorialContent.length) {
        this.state.onCloseEvent()
        return
      }
      if (stepActive.Id === sectionActive.Steps.length) {
        if (sectionActive.Id === itemActive.Sections.length) {
          if (!!this.slider) this.slider.goTo(0)
          this.setState({
            itemActive: itemActive.Id + 1,
            sectionActive: 1,
            stepActive: 1,
          })
        } else {
          if (!!this.slider) this.slider.goTo(0)
          this.setState(
            {
              sectionActive: sectionActive.Id + 1,
              stepActive: 1,
            },
            () => {
              setTimeout(() => {
                if (!!this.slider) this.slider.goTo(0)
              }, 300)
            },
          )
        }
      } else {
        if (!!this.slider) this.slider.next()
        this.setState({ stepActive: stepActive.Id + 1 })
      }
    }
  }

  render() {
    const { showed } = this.props
    const itemActive = TutorialContent.filter((item) => item.Id === this.state.itemActive)[0]
    const sectionActive = itemActive.Sections.filter((section) => section.Id === this.state.sectionActive)[0]
    const stepActive = sectionActive.Steps.filter((step) => step.Id === this.state.stepActive)[0]
    const isLastItem = itemActive.Id === TutorialContent.length

    let isModalVisible = showed
    if (this.state.visible !== null) isModalVisible = this.state.visible

    return (
      <TutorialModal onCancel={this.state.onCloseEvent} visible={isModalVisible} footer={null} color={itemActive.Color}>
        <TutorialImage background={sectionActive.Background}>
          <img src={stepActive.Image} alt="" />
        </TutorialImage>
        <div className="tutorial-bar">
          {TutorialContent.map((item) => {
            return (
              <TutorialBarItem
                className={item.Id === this.state.itemActive ? 'active' : ''}
                onClick={() => this.changeItem(item.Id)}
                key={item.Id}
                color={item.Color}
              >
                <p>
                  <TutorialIcon itemActive={item.Id} />
                </p>
                <p>{item.Title}</p>
              </TutorialBarItem>
            )
          })}
        </div>
        <div className="tutorial-content">
          {sectionActive.Steps.length > 1 && (
            <Carousel ref={(slider) => (this.slider = slider)} afterChange={this.onCarouselChange}>
              {sectionActive.Steps.map((step) => {
                return (
                  <div key={step.Id}>
                    <TutorialStep pin={sectionActive.Pin}>{step.Id}</TutorialStep>
                    <h3>{step.Title}</h3>
                    {itemActive.Sections.length > 1 && <div className={`multi-section section${sectionActive.Id}`} />}
                    <p dangerouslySetInnerHTML={createMarkup(converter.makeHtml(step.Content))} />
                  </div>
                )
              })}
            </Carousel>
          )}
          {sectionActive.Steps.length === 1 && (
            <div>
              <TutorialStep pin={sectionActive.Pin}>{sectionActive.Steps[0].Id}</TutorialStep>
              <h3>{sectionActive.Steps[0].Title}</h3>
              <p dangerouslySetInnerHTML={createMarkup(converter.makeHtml(sectionActive.Steps[0].Content))} />
            </div>
          )}
          {this.state.stepActive !== 1 && (
            <Button onClick={() => this.handleNextBack(true)} type="link" className="back" ghost>
              Back
            </Button>
          )}
          <Button onClick={() => this.handleNextBack(false)} type="link" className="next" ghost>
            {isLastItem ? 'Finish Tour' : 'Next'}
          </Button>
        </div>
      </TutorialModal>
    )
  }
}

export default Tutorial
