export const resetButton = () =>
  `
    all: unset;
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    &:hover {
       cursor: pointer;
    }
    `
export const resetUl = () => `
    list-style: none;
    padding-left: 0;
    margin: 0;
`
