import React, { useContext, useEffect, useState } from 'react'
import BannerSection from 'components/Banner/BannerSection'
import Loading from 'components/Loading/Loading'
import SectionError from 'components/SectionError'
import NetworkDetector from 'services/NetworkDetector'
import { Icon, Progress } from 'antd'
import { apiFetch } from 'services/API/apiFetch'
import { workFlow } from '../uses/WizardData'
import Layout from 'components/Layout'
import { Root } from '../Layout/Layout.styled'
import { MainEnrollmentContainer, Card } from './Main.styled'
import FirstEnrollmentModal from './FirstEnrollmentModal'
import PreFillEnrollmentModal from './PreFillEnrollmentModal'
import { navigate } from '@reach/router'
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward-orange.svg'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const Stages = ({ stageEnrollment, hasMealPlan, user, programType }) => {
  let stages = workFlow.filter((section) => {
    if (programType === 'meal_plan') {
      return ['personal', 'lifestyle', 'meal_plan', 'finish'].indexOf(section.id) !== -1
    }
    return true
  })
  let countSections = 0

  const getStageStatus = (stage) => {
    let isDisabled = false
    let finished = false
    let progressPercent = 0
    let progressColor = ''
    let started = false
    let isVisible = true

    if (programType === 'meal_plan') {
      if (stage.id === 'lifestyle') {
        stage.steps = stage.steps.filter((s) => ['food', 'medical'].indexOf(s.path) !== -1)
      }

      if (stage.id === 'finish') {
        stage.steps = stage.steps.filter((s) => ['summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
      }
    }

    if (programType === 'macros') {
      if (stage.id === 'finish') {
        stage.steps = stage.steps.filter((s) => ['goal', 'summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
      }
    }

    if (stage.id === 'meal_plan') {
      if (programType !== 'meal_plan' && !hasMealPlan) {
        isVisible = false
      }
    }

    if (isVisible) {
      if (stage.id === 'finish') {
        const numStages = programType === 'meal_plan' || hasMealPlan ? stages.length - 1 : stages.length - 2
        if (countSections < numStages) {
          isDisabled = true
        }
      }

      if (stage.id !== 'personal') {
        if (!user.birthdate && !user.gender) {
          isDisabled = true
        }
      }

      const foundStage = stageEnrollment.find((se) => se.id === stage.id)
      if (foundStage) {
        progressPercent = calculatePercentage(stage.steps.length, foundStage.stages.length)
        if (progressPercent > 0) {
          started = true
          progressColor = '#ffa401'
          if (progressPercent === 100) {
            finished = true
            progressColor = '#6fcf97'
            countSections++
          }
        }
      }
    }

    return {
      progressPercent,
      progressColor,
      isDisabled,
      isVisible,
      started,
      finished,
    }
  }

  const calculatePercentage = (steps, stage) => {
    return (stage * 100) / steps
  }

  const handleStageClick = (stage) => {
    let path
    let steps
    let sectionStages

    const foundSection = stageEnrollment.find((se) => se.id === stage)
    const foundWizardStage = stages.find((s) => s.id === stage)
    steps = foundWizardStage.steps

    if (programType === 'meal_plan') {
      if (stage === 'lifestyle') {
        steps = steps.filter((s) => ['food', 'medical'].indexOf(s.path) !== -1)
      }
      if (stage === 'finish') {
        steps = steps.filter((s) => ['summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
      }
    }

    if (programType === 'macros') {
      if (stage === 'finish') {
        steps = steps.filter((s) => ['goal', 'summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
      }
    }

    if (foundSection) {
      sectionStages = foundSection.stages
      if (sectionStages.length === steps.length || sectionStages.length === 0) {
        path = steps[0].path
      } else {
        let currentStage = sectionStages[sectionStages.length - 1]
        let step = steps.find((s) => s.path === currentStage)
        step = step.order + 1
        currentStage = steps.find((s) => s.order === step)
        path = currentStage.path
      }
    } else {
      path = steps[0].path
    }
    navigate(`/enrollment/${path}`)
  }

  return (
    <>
      {stages.map((stage) => {
        const { progressPercent, progressColor, isDisabled, isVisible, started, finished } = getStageStatus(stage)
        return (
          isVisible && (
            <Card
              key={stage.id}
              className={`stage ${isDisabled ? 'disabled' : ''}`}
              onClick={() => (!isDisabled ? handleStageClick(stage.id) : null)}
            >
              <div>
                <img src={stage.icon} alt={stage.title} />
              </div>
              <div>
                <h1>{stage.title}</h1>
                <div className="content">
                  <p dangerouslySetInnerHTML={{ __html: stage.description }} />
                  <ArrowForward />
                </div>
                <div className="content">
                  <Progress percent={progressPercent} showInfo={false} strokeColor={progressColor} />
                  {(started || finished) && <Icon type={finished ? 'check' : 'exclamation'} className={finished ? 'success' : ''} />}
                </div>
              </div>
            </Card>
          )
        )
      })}
    </>
  )
}

export default (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [program, setProgram] = useState({ typeLabel: '' })
  const [stageEnrollment, setStageEnrollment] = useState([])
  const [hasMealPlan, setHasMealPlan] = useState()
  const [lastWeekly, setLastWeekly] = useState()
  const [lastEnrollment, setLastEnrollment] = useState()
  const [showFirstEnrollmentModal, setShowFirstEnrollmentModal] = useState(false)
  const [showPreFillEnrollmentModal, setShowPreFillEnrollmentModal] = useState(false)
  const globalContext = useContext(GlobalContext)

  useEffect(() => {
    const fetchData = async () => {
      const isConnected = await NetworkDetector()
      const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
      if (isConnected) {
        const programDB = await apiFetch({
          url: `students/${traineeId}/enrollment`,
          method: 'GET',
          isConnected,
        }).catch((err) => err)

        if (programDB.hasError) {
          if (programDB.status === 403) {
            navigate('/')
            return
          }
          setError('INTERNAL_SERVER_ERROR')
        } else {
          const { program, enrollment } = programDB
          const enrollmentId = enrollment.data.id
          const traineeProgramCoach = enrollment.trainee_program_coach
          const stage = programDB.enrollment.trainee_program_coach.enrollment_stage

          if (traineeProgramCoach.is_enrollment_finish) {
            navigate('/')
          } else {
            let hasMealPlan = Number(traineeProgramCoach.extras.meal_plan)
            hasMealPlan = hasMealPlan > 0 ? true : false
            sessionStorage.setItem('BOOTCAMP_ID_SELECTED', program.program_id)
            switch (program.type) {
              case 'macros':
                program.typeLabel = 'Macros Review'
                break
              case 'meal_plan':
                program.typeLabel = 'Meal Plan'
                break
              case 'bootcamp':
              default:
                program.typeLabel = 'Bootcamp'
                break
            }
            setProgram(program)
            setStageEnrollment(stage)
            setHasMealPlan(hasMealPlan)
            setLastWeekly(enrollment.last_weekly)
            setLastEnrollment(enrollment.last_enrollment)

            if (!enrollmentId) {
              if (enrollment.is_returning) {
                setShowPreFillEnrollmentModal(true)
              } else {
                setShowFirstEnrollmentModal(true)
              }
            }
          }
        }
      } else {
        setError('LOST_NETWORK')
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  const handlePreFillEnrollment = async (resource, date, program_id) => {
    const isConnected = await NetworkDetector()
    if (isConnected) {
      const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
      const programId = sessionStorage.getItem('BOOTCAMP_ID_SELECTED')
      const body = {
        program_id,
        resource,
        date,
      }
      const programDB = await apiFetch({
        url: `students/${traineeId}/pre_fill_enrollment/${programId}`,
        method: 'POST',
        body: JSON.stringify(body),
        isConnected,
      }).catch((err) => err)

      if (programDB.hasError) {
        setError('INTERNAL_SERVER_ERROR')
      }
    }
  }

  return (
    <Layout>
      <Root>
        <BannerSection
          title={`My ${program.typeLabel} ${program.type === 'bootcamp' ? 'Enrollment' : 'Submission'}`}
          picture={localStorage.getItem(LOCAL_STORAGE.USER_PICTURE)}
        />
        <MainEnrollmentContainer>
          {loading && !error && (
            <Loading>
              <div className="rectangle" />
              <div className="rectangle" />
              <div className="rectangle" />
              <div className="rectangle" />
            </Loading>
          )}
          {!loading && error && <SectionError type={error} />}
          {!loading && !error && (
            <>
              <Card>
                <h1>Welcome to the {program.typeLabel}!</h1>
                <p>
                  Please complete each of the {program.type === 'bootcamp' ? 'enrollment' : 'submission'} sections below (you can start them in any
                  order).
                </p>
              </Card>
              <Stages {...{ stageEnrollment, programType: program.type, hasMealPlan, user: globalContext.user }} />
              <PreFillEnrollmentModal
                visible={showPreFillEnrollmentModal}
                weekly={lastWeekly}
                enrollment={lastEnrollment}
                preFillEnrollment={handlePreFillEnrollment}
              />
              <FirstEnrollmentModal visible={showFirstEnrollmentModal} preFillEnrollment={handlePreFillEnrollment} />
            </>
          )}
        </MainEnrollmentContainer>
      </Root>
    </Layout>
  )
}
