import React, { useEffect, useState } from 'react'
import { Icon, Result } from 'antd'
import { MainContainer } from './Impersonate.styled.js'
import { impersonate } from 'auth'

export default (props) => {
  const [status, setStatus] = useState('')
  const [title, setTitle] = useState('Please wait...')
  const [subTitle, setSubTitle] = useState('')

  useEffect(() => {
    const queryString = props.location.search
    const urlParams = new URLSearchParams(queryString)
    const jwt = urlParams.get('jwt')

    if (!jwt) {
      window.location = '/'
    } else {
      impersonate(jwt, urlParams.get('admin') === 'true')
        .then((redirectURL) => {
          setStatus('success')
          setTitle(`Welcome back!`)
          setSubTitle('Please wait, we will redirect you to your dashboard')
          window.location = redirectURL
        })
        .catch((error) => {
          setTitle('Login failed')
          setSubTitle(`Error from server: ${error} <br />Please try again`)
          setStatus('error')
        })
    }
  }, [])

  return (
    <MainContainer>
      <Result
        status={status}
        icon={status === '' ? <Icon type="loading" /> : null}
        title={title}
        subTitle={<div dangerouslySetInnerHTML={{ __html: subTitle }} />}
      />
    </MainContainer>
  )
}
