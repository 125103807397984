import React, { useState, useEffect, useRef } from 'react'
//Import style from component
import { CarouselSwipeNoDnd } from './CarouselNoDnd.styled'
import { MenuPoints } from '../../../../styled/MenuPointsComponent.styled'
import ReportProgress from '../../Main/ReportsProgress/ReportsProgress'
import { Container, KettlebellNoDnd } from '../../../../styled/KettlebellExerciseNoDnd.styled'
import kettlebell from '../../../../images/kettlebell.svg'
import bodyweight from '../../../../images/exercise/bodyweight.svg'
import dumbbell from '../../../../images/exercise/dumbbell.svg'
import arrows from '../../../../images/arrows.svg'
import { GetThumbnail } from 'services/ExerciseUtilities'

const ContainerNoDnd = ({ sets, setOrder }) => {
  const myRefs = useRef([])

  const [data, setData] = useState(sets)
  let param = sets

  useEffect(() => {
    const setSets = async () => {
      setData(sets)
    }
    setSets()
  }, [sets])

  const newOrder = Array.from(data) || Array.from(param)

  const handleChangeCard = (e, set, ref) => {
    if (e === 0) {
      set.exercises = set.exercises.map((exercise) => {
        delete exercise.alt_selected
        return exercise
      })
    } else if (e > 0) {
      let altId = null
      for (const exe of set.exercises) {
        for (const [key, alt] of exe.alternatives.entries()) {
          const order = e - 1
          if (key === order) {
            altId = alt.exercise_id
          }
        }
        set.exercises[0]['alt_selected'] = altId
      }
    }
    ref['position'] = e
  }

  const handleSetOrder = async (set) => {
    let arrayIds = []

    for await (const value of set) {
      arrayIds.push(value.id)
    }
    arrayIds.join(',')
    setOrder(arrayIds)
  }
  return (
    <>
      <Container>
        {newOrder.map((exe, i) => {
          const e = exe.exercises
          return (
            <MenuPoints key={i}>
              <ul id={`menu-point-${i}`}>
                <div />
                <li
                  onClick={() => {
                    let res = newOrder.splice(i, 1)
                    newOrder.splice(i - 1, 0, res[0])

                    setData(newOrder)
                    document.getElementById(`menu-point-${i}`).classList.toggle('is-active')

                    handleSetOrder(newOrder)
                  }}
                  className="up"
                >
                  Move up
                </li>
                <li
                  onClick={() => {
                    let res = newOrder.splice(i, 1)
                    newOrder.splice(i + 1, 0, res[0])

                    setData(newOrder)
                    document.getElementById(`menu-point-${i}`).classList.toggle('is-active')

                    handleSetOrder(newOrder)
                  }}
                  className="down"
                >
                  Move Down
                </li>
              </ul>
              <CarouselSwipeNoDnd
                key={exe.id}
                afterChange={(event) => handleChangeCard(event, exe, myRefs.current[exe.id])}
                initialSlide={!myRefs.current[exe.id] || !myRefs.current[exe.id].position ? 0 : myRefs.current[exe.id].position}
                ref={(myRef) => (myRefs.current[exe.id] = !myRefs.current[exe.id] ? myRef : myRefs.current[exe.id])}
              >
                <div style={{ width: '100%' }}>
                  {e.map((x, y) => {
                    return (
                      <KettlebellNoDnd key={x.id}>
                        <button
                          onClick={() => {
                            document.getElementById(`menu-point-${i}`).classList.toggle('is-active')
                          }}
                        />
                        <ReportProgress
                          key={x.id}
                          image={GetThumbnail(x.data)}
                          title={x.data.name}
                          text={
                            x.data.gym_equipments[0].quantity === 0
                              ? 'Bodyweight'
                              : x.data.gym_equipments[0].quantity === 1
                              ? 'Kettlebell'
                              : x.data.gym_equipments[0].quantity === 2
                              ? '2 dumbbell'
                              : null
                          }
                          color="#ffab2b"
                          pseudo={
                            x.data.gym_equipments[0].quantity === 0
                              ? bodyweight
                              : x.data.gym_equipments[0].quantity === 1
                              ? kettlebell
                              : x.data.gym_equipments[0].quantity === 2
                              ? dumbbell
                              : null
                          }
                          lastbutton={arrows}
                          showArrows={x.alternatives.length > 0 ? true : false}
                          clickButton={(e) => {
                            myRefs.current[exe.id].next()
                          }}
                        />
                        {}
                      </KettlebellNoDnd>
                    )
                  })}
                </div>
                {e[0].alternatives.length > 0 ? (
                  <div style={{ width: '100%' }}>
                    {e.map((alt, j) => {
                      const altExercise = alt.alternatives[0].data
                      return (
                        <KettlebellNoDnd key={alt.id}>
                          <button
                            onClick={() => {
                              document.getElementById(`menu-point-${i}`).classList.toggle('is-active')
                            }}
                          />
                          <ReportProgress
                            key={alt.id}
                            image={GetThumbnail(altExercise)}
                            title={altExercise.name}
                            text={
                              altExercise.gym_equipments[0].quantity === 0
                                ? 'Bodyweight'
                                : altExercise.gym_equipments[0].quantity === 1
                                ? 'Kettlebell'
                                : altExercise.gym_equipments[0].quantity === 2
                                ? '2 dumbbell'
                                : null
                            }
                            color="#ffab2b"
                            pseudo={
                              altExercise.gym_equipments[0].quantity === 0
                                ? bodyweight
                                : altExercise.gym_equipments[0].quantity === 1
                                ? kettlebell
                                : altExercise.gym_equipments[0].quantity === 2
                                ? dumbbell
                                : null
                            }
                            lastbutton={arrows}
                            showArrows={true}
                            clickButton={(e) => {
                              myRefs.current[exe.id].next()
                            }}
                          />
                        </KettlebellNoDnd>
                      )
                    })}
                  </div>
                ) : null}
              </CarouselSwipeNoDnd>
            </MenuPoints>
          )
        })}
      </Container>
    </>
  )
}

export default ContainerNoDnd
