import React from 'react'
import { PageHeaderStyled } from './Header.styled'
import { Button } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

const PageHeader = (props) => {
  const handleBack = () => {
    if (typeof props.onBack === 'function') {
      props.onBack()
    } else {
      window.history.back()
    }
  }

  const handleClose = () => {
    if (typeof props.onClose === 'function') {
      props.onClose()
    } else {
      window.history.back()
    }
  }

  return (
    <PageHeaderStyled
      backIcon={props.showBack ? <ArrowIcon data-title="Back" /> : <></>}
      onBack={handleBack}
      title={props.title ? props.title : 'Ketogains'}
      extra={
        props.showClose ? (
          <Button onClick={handleClose} type="link">
            <CloseIcon />
          </Button>
        ) : (
          <></>
        )
      }
    />
  )
}

export default PageHeader
