import React, { useState, useEffect, useRef } from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd'
import { Reports } from '../../views/Exercises/Main/ReportsProgress/ReportsProgress.styled'
import Loading from 'components/Loading/Loading'
import { CarouselSwipe } from './CarouselSwipe.styled'
import bodyweight from '../../images/exercise/bodyweight.svg'
import dumbbell from '../../images/exercise/dumbbell.svg'
import arrows from '../../images/arrows.svg'
import { GetThumbnail } from 'services/ExerciseUtilities'
import * as Sentry from '@sentry/browser'

const DragAndDropItem = ({ exercise, showArrows, grad, clickButton, styleContent }) => {
  let thumbnail = GetThumbnail(exercise)

  let gymEquipment = 'Bodyweight'
  let pseudo = bodyweight
  if (exercise.gym_equipments && exercise.gym_equipments.length > 0) {
    gymEquipment = exercise.gym_equipments.map((e) => `${e.name}${e.quantity > 1 ? ` (${e.quantity})` : ''}`).join(' / ')
    if (!exercise.gym_equipments.find((e) => e.name === 'Bodyweight')) {
      pseudo = dumbbell
    }
  }

  return (
    <Reports color="#ffab2b" grad={grad} pseudo={pseudo} lastbutton={arrows} exercise>
      <div className="container">
        <img src={thumbnail} alt={exercise.name} />
      </div>
      <div className="content" style={styleContent}>
        <p className="title" style={{ lineHeight: '20px' }}>
          {exercise.name}
        </p>
        <p className="text" style={{ marginBottom: '0px' }}>
          {gymEquipment}
        </p>
      </div>
      {showArrows && <div className="button" onClick={clickButton} data-ignore-sentry />}
    </Reports>
  )
}

const DragAndDrop = ({ sets, setOrder }) => {
  //Refs to swipe
  const myRefs0 = useRef([])

  const [state, setState] = useState({
    loading: true,
    data: [],
  })

  useEffect(() => {
    const setExercises = async () => {
      const initialData = {
        tasks: {},
        columns: {},
        columnOrder: ['column-0'],
      }
      let column = 'column'
      let arrTasks = []
      for await (const [index, set] of sets.entries()) {
        const key = set.id //task + '-' + index
        initialData.tasks[key] = {
          id: key,
          content: (
            <>
              {set.exercises
                .filter((exe) => exe.data)
                .map((exe, i) => {
                  const exercise = exe.data
                  return (
                    <CarouselSwipe
                      key={key}
                      afterChange={(e) => handleChangeCard(e, set, myRefs0.current[set.id])}
                      ref={(myRef) => (myRefs0.current[set.id] = myRef)}
                    >
                      <DragAndDropItem
                        id={i}
                        key={exercise.id}
                        exercise={exercise}
                        showArrows={exe.alternatives && exe.alternatives.length > 0}
                        clickButton={(e) => {
                          myRefs0.current[set.id].next()
                        }}
                      />
                      {exe.alternatives
                        .filter((alt) => alt.data)
                        .map((alt, i) => {
                          const altExercise = alt.data
                          return (
                            <DragAndDropItem
                              key={altExercise.id}
                              exercise={altExercise}
                              showArrows={true}
                              clickButton={(e) => {
                                myRefs0.current[set.id].next()
                              }}
                            />
                          )
                        })}
                    </CarouselSwipe>
                  )
                })}
            </>
          ),
        }
        let keyColumn
        if (index === 0) {
          keyColumn = column + '-' + index
          initialData.columns[keyColumn] = {
            id: keyColumn,
            title: 'To do',
          }
        }
        arrTasks.push(key)
      }

      initialData.columns['column-0']['taskIds'] = arrTasks
      setState({
        data: initialData,
        loading: false,
      })
    }
    setExercises()
  }, [sets])

  const getExerciseSelectedName = (exercise) => {
    let exerciseName = ''
    if (exercise.alt_selected) {
      const exerciseAlternativeSelected = exercise.alternatives.find((ea) => ea.exercise_id === exercise.alt_selected)
      if (exerciseAlternativeSelected) {
        exerciseName = exerciseAlternativeSelected.data.name
      } else {
        exerciseName = 'exercise not found '
      }
    } else {
      exerciseName += exercise.data.name
    }
    return exerciseName
  }

  const handleChangeCard = (e, set, ref) => {
    let sentryLog = 'Exercise changed from ' + getExerciseSelectedName(set.exercises[0])

    if (e === 0) {
      set.exercises = set.exercises.map((exercise) => {
        delete exercise.alt_selected
        return exercise
      })
    } else if (e > 0) {
      let altId = null
      for (const exe of set.exercises) {
        for (const [key, alt] of exe.alternatives.entries()) {
          const order = e - 1
          if (key === order) {
            altId = alt.exercise_id
          }
        }
        set.exercises[0]['alt_selected'] = altId
      }
    }
    sentryLog += ' to ' + getExerciseSelectedName(set.exercises[0])
    Sentry.addBreadcrumb({ category: 'exercise', message: sentryLog })
  }

  const handleSetOrder = (newTaskIds) => {
    setOrder(newTaskIds)
  }

  if (state.loading) {
    return (
      <Loading>
        <div className="rectangle" />
      </Loading>
    )
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (sets && sets.length) {
      const setSelected = sets.find((s) => s.id === draggableId)
      if (setSelected) {
        let sentryLog = 'Reorder set with exercises ' + setSelected.exercises.map((e) => getExerciseSelectedName(e)).join('and ')
        Sentry.addBreadcrumb({ category: 'exercise', message: sentryLog })
      }
    }

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const column = state.data.columns[source.droppableId]
    const newTaskIds = Array.from(column.taskIds)
    newTaskIds.splice(source.index, 1)
    newTaskIds.splice(destination.index, 0, draggableId)
    const newColumn = {
      ...column,
      taskIds: newTaskIds,
    }

    const newState = {
      ...data,
      columns: {
        ...data.columns,
        [newColumn.id]: newColumn,
      },
    }
    setNewOrder(newState.tasks)
    setState({ data: newState })
    handleSetOrder(newTaskIds)
  }

  const setNewOrder = (data) => {
    const newArray = []
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key]
        newArray.push(element)
      }
    }
  }

  const data = state.data
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {data.columnOrder.map((columnId) => {
        const column = data.columns[columnId]
        const tasks = column.taskIds.map((taskId) => data.tasks[taskId])
        return <Column key={column.id} column={column} tasks={tasks} />
      })}
    </DragDropContext>
  )
}

//TODO: This is not a generic component, should be at view/exercises/workout
export default DragAndDrop
