import React from 'react'
import { Collapse, Icon } from 'antd'
import styled from '@emotion/styled'

const StyledCollapse = styled(Collapse)`
  max-width: 400px;
  margin: 0 auto;
  &.ant-collapse {
    border: none;

    .ant-collapse-item {
      border: none;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: #fbfbfb;

      .ant-collapse-header {
        height: auto;
        color: #323232;
        font-size: 15px;
        font-weight: 500;
        padding: 12px 14px;
        background-color: #fbfbfb;
        box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
        border: none;
        border-left: 5px solid #ff6b00;
        border-radius: 4px;

        .ant-collapse-arrow {
          color: #ff6b00;
          width: 20px;
          height: 20px;
          font-size: 20px;
          line-height: 23px;
          right: 16px;
          left: inherit;
        }

        .indicator-seen {
          display: inline-block;
          height: 22px;
          width: 22px;
          font-size: 12px;
          margin-right: 8px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          color: #ffffff;
          text-align: center;
          background-color: #ff6b00;
          box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);

          &.error {
            background-color: #f5222d;
          }

          &.unseen {
            background-color: #d5d5d5 !important;
            top: 5px;
            position: relative;
          }

          .anticon {
            vertical-align: 0 !important;
          }
        }

        .status {
          display: inline-block;
          height: 22px;
          width: 22px;
          font-size: 12px;
          margin-right: 8px;
          border-radius: 50%;
          color: #ffffff;
          text-align: center;
          background-color: #ff6b00;
          box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);

          &.success {
            background-color: #5dd578;
          }
        }
      }

      &.red {
        .ant-collapse-header {
          border-left-color: #e53d2f;
        }
      }

      &.green {
        .ant-collapse-header {
          border-left-color: #6fcf97;
        }
      }

      &.orange {
        .ant-collapse-header {
          border-left-color: #ffa401;
        }
      }

      &.blue {
        .ant-collapse-header {
          border-left-color: #2ea3f2;
        }
      }

      &.purple {
        .ant-collapse-header {
          border-left-color: #b32ef2;
        }
      }

      &.error {
        .ant-collapse-header {
          border-left: 5px solid #f5222d !important;
        }
      }

      &.unseen {
        .ant-collapse-header {
          border-left: 5px solid #d5d5d5 !important;
        }
      }

      &.ant-collapse-item-active {
        border: none;
        .ant-collapse-header {
          background-color: #fbfbfb;
          box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
        }
        .ant-collapse-content {
          border-top: none;
        }
        .ant-collapse-arrow {
          line-height: 22px;
        }
      }
    }

    .ant-collapse-content {
      background: #ffffff;
      box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.15);
      border-radius: 0px 0px 4px 4px;

      .ant-collapse-content-box {
        background: #ffffff;
      }
    }
  }
`

StyledCollapse.expandIcon = ({ isActive }) => {
  return <Icon type={isActive ? 'up' : 'down'} />
}

export default StyledCollapse
