import gql from 'graphql-tag'

export const getProgram = gql`
  query getProgram($traineeId: uuid!) {
    trainee_program_coach(where: { trainee_id: { _eq: $traineeId } }, order_by: { program: { access_date: desc } }) {
      is_active
      cancellation_date
      cancellation_reason
      enrollment_stage
      program {
        description
        start_date
        program_id
      }
    }
  }
`
export const getBootcamp = gql`
  query getBootcamp {
    program(where: { access_date: { _lte: "now()" }, _and: { start_date: { _gte: "now()" } } }) {
      program_id
      product_tag
      description
    }
  }
`
export const getBootcampComing = gql`
  query getBootcampComing($date: date!) {
    program(where: { start_date: { _gte: $date } }, order_by: { access_date: asc }) {
      program_id
      product_tag
      description
      start_date
      access_date
    }
  }
`
