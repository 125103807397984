import React from 'react'
import { ContainerSectionError } from './SectionError.styled'
import { ReactComponent as IconWiFi } from 'assets/icons/WiFi.svg'
import { Icon } from 'antd'

const getLostNetworkMessage = () => {
  return (
    <>
      <p>Oops!</p>
      <p>You need to access this section with internet connection.</p>
      <p>Please connect to a network and try again.</p>
    </>
  )
}

const getDefaultMessage = (details) => {
  return (
    <>
      <p>Oops!</p>
      <p>There was an error processing the request</p>
      <p>
        <small>{details ? details : 'GENERIC_ERROR'}</small>
      </p>
    </>
  )
}

const SectionError = ({ message, icon, style, type }) => {
  switch (type) {
    case 'LOST_NETWORK':
      icon = <IconWiFi />
      message = getLostNetworkMessage()
      break
    default:
      if (!icon) {
        icon = <Icon type="warning" />
      }
      if (!message) {
        message = getDefaultMessage(type)
      }
      break
  }

  return (
    <ContainerSectionError style={style}>
      {icon && <>{icon}</>}
      {message && <>{message}</>}
    </ContainerSectionError>
  )
}

export default SectionError
