import styled from '@emotion/styled'
import { Card } from 'antd'
import image from '../images/exercise/kettlebell-swing.png'
import kettlebell from 'images/kettlebell.svg'

export const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`

export const KettlebellNoDnd = styled(Card)`
  margin-bottom: 5px;
  width: 100%;
  max-width: 353px;
  height: 77px;
  background: #ffffff;
  border-radius: 4px;
  right: -23px;

  .ant-card {
    margin: 0px !important;
  }

  .ant-card-body {
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .kettlebell {
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;
    width: 4.063rem;
    height: 4.063rem;
    border-radius: 4px;
  }

  .container-kettlebell {
    width: 65px;
    height: 65px;
    border-radius: 4px;
    position: relative;
    left: -11px;
  }

  .title-kettlebell {
    width: 172px;
    height: 24px;
    margin-bottom: 7px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    font-height: 21px;
    color: #4a4a4a;
    position: relative;
    left: -17px;
  }

  .text-kettlebell {
    width: 90px;
    height: 18px;
    display: flex;
    justify-content: space-around;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
    position: relative;
    left: -17px;
    &:before {
      content: url(${kettlebell});
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 4px;
      width: 15px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .button {
    right: -2px !important;
  }

  @media screen and (max-width: 320px) {
    max-width: 290px;

    .container-kettlebell {
      left: 3px;
    }

    .title-kettlebell {
      font-size: 16px;
      left: 9px;
    }

    .text-kettlebell {
      font-size: 12px;
      left: 9px;
    }

    .button-kettlebell {
      width: 19px;
      position: relative;
      right: -6px;
    }
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    max-width: 330px;

    .container-kettlebell {
      left: -6px;
    }

    .title-kettlebell {
      font-size: 16px;
      left: -7px;
    }

    .text-kettlebell {
      font-size: 12px;
      left: -7px;
    }

    .button-kettlebell {
      width: 19px;
      position: relative;
      right: -29px;
    }
  }
`
