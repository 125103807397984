import React, { useState, useEffect, useRef, useContext } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import { MaterializeInput } from 'components/Materialize'
import PoundsIcon from 'assets/icons/enrollment/weightLb.png'
import KilogramsIcon from 'assets/icons/enrollment/weightKg.png'
import SelectableCards from 'components/Selectable/Cards'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)

  const [metricUnits, setMetricUnits] = useState()
  const [height, setHeight] = useState()
  const [currentWeight, setCurrentWeight] = useState()
  const [isReturning, setIsReturning] = useState()
  const metricUnitsRef = useRef(null)
  const biometricsRef = useRef(null)

  const globalContext = useContext(GlobalContext)

  const metricsCatalog = [
    { id: 'lbs/in', name: 'Pounds and Inches', thumbnail: PoundsIcon },
    { id: 'kg/cm', name: 'Kilograms and Centimeters', thumbnail: KilogramsIcon },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      const { user } = globalContext
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, metric, height, weight } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setMetricUnits(metric)
          setHeight(height)
          setCurrentWeight(weight)
          setIsReturning(metric ? user.is_returning : false)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [metricUnits, currentWeight, height])

  const saveCallback = async ({ goBack }) => {
    const data = { metric: metricUnits, height, weight: currentWeight }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (inputValidator('Height', height) && inputValidator('Weight', currentWeight) && metricUnits) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          !inputValidator('Weight', currentWeight) && scrollToRef(biometricsRef)
          !inputValidator('Height', height) && scrollToRef(biometricsRef)
        }
      }
    }
  }

  const inputValidator = (type, value) => {
    const minWeight = metricUnits === 'lbs/in' ? 60 : 27.21
    const minHeight = metricUnits === 'lbs/in' ? 40 : 101.6
    const maxHeight = metricUnits === 'lbs/in' ? 90 : 228.6
    let valid
    switch (type) {
      case 'Weight':
        valid = Number(value) >= Number(minWeight) ? true : false
        break
      case 'Height':
        valid = Number(minHeight) <= Number(value) && Number(value) <= Number(maxHeight)
        break
      default:
        valid = false
        break
    }
    return valid
  }

  const handleSelectMetric = (metric) => {
    setMetricUnits(metric)
    localStorage.setItem(LOCAL_STORAGE.USER_UNITS, metric)
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'body_composition' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          {!isReturning && (
            <QuestionContainer ref={metricUnitsRef}>
              {submit && !metricUnits && <Message type="error" message={'Please complete all the mandatory fields'} />}
              <div className="question">
                <p className="title">How would you like to add your body measurements?</p>
              </div>
              <div className="content">
                <SelectableCards type="radio" value={metricUnits} catalog={metricsCatalog} onChange={(e) => handleSelectMetric(e.target.value)} />
              </div>
            </QuestionContainer>
          )}
          <QuestionContainer ref={biometricsRef}>
            {submit && !inputValidator('Weight', currentWeight) && !inputValidator('Height', height) && (
              <Message type="error" message={'Invalid input, please confirm your entry!'} />
            )}
            <div className="question">
              <p className="title">Please fill the following fields with your Biometric data?</p>
            </div>
            <div className="content">
              <MaterializeInput
                className="short"
                type="number"
                suffix={metricUnits === 'lbs/in' ? 'inches' : 'centimeters'}
                value={height}
                maxLength="5"
                label={`Height (${metricUnits === 'lbs/in' ? 'in' : 'cm'})`}
                isValid={height && inputValidator('Height', height)}
                onChange={(e) => setHeight(e.target.value)}
              />
              <MaterializeInput
                className="short"
                type="number"
                maxLength="5"
                value={currentWeight}
                suffix={metricUnits === 'lbs/in' ? 'pounds' : 'kilograms'}
                label={`Current Weight (${metricUnits === 'lbs/in' ? 'lbs' : 'kg'})`}
                isValid={currentWeight && inputValidator('Weight', currentWeight)}
                onChange={(e) => setCurrentWeight(e.target.value)}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
