import React from 'react'
import ketogainsTm from 'images/ketogains-tm.svg'
import {
  Root,
  Img,
  // StyledLink
} from './Logo.styled'

class Logo extends React.Component {
  render() {
    return (
      <Root>
        {/* <StyledLink to="/"> */}
        <Img src={ketogainsTm} alt="" />
        {/* </StyledLink> */}
      </Root>
    )
  }
}

export default Logo
