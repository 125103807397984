import React, { useState, useEffect } from 'react'
import CardInfoExercise from '../CardInfoExercise/CardInfoExercise'
import { ContSets, ButtonFakeSuccess } from './ContainerSets.styled'
import Card from 'components/Cards/Card/Card'
import { ReactComponent as InfoIcon } from 'assets/icons/IconInfo.svg'
import { ReactComponent as LessIcon } from 'assets/icons/iconLess.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/IconMore.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/iconPerson.svg'
import { ReactComponent as Helper } from 'assets/icons/question-circle.svg'
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg'
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward-orange.svg'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary'

import { Button, Tooltip, Icon } from 'antd'
import { getTechniqueTooltip, getTechniqueLabel, getGoalExerciseSession } from 'services/ExerciseUtilities'

const ContainerSets = ({
  localId,
  exercise,
  onFinishSet,
  onUpdateSet,
  onFinishExercise,
  techniques,
  handleWeekSets,
  overwriteWeekSet,
  showSuccessButton,
}) => {
  const [currentExercise, setCurrentExercise] = useState()
  const [currentSet, setCurrentSet] = useState({})
  const [numCurrentSet, setNumCurrentSet] = useState(0)
  const [loading, setLoading] = useState(true)
  const [colorStats, setColorStats] = useState(false)
  const [reps, setReps] = useState()
  const [weightSet, setWeightSet] = useState(0)
  const [exerciseNote, setExerciseNote] = useState('')
  const [modalExercisePosition, setModalExercisePosition] = useState(-740)
  const [preReps, setPreReps] = useState()
  const [preWeight, setPreWeight] = useState()
  const [btnLoading, setBtnLoading] = useState(false)
  const [isOverwrite, setIsOverwrite] = useState(false)

  useEffect(() => {
    const handleAsync = async () => {
      setLoading(true)
      let set
      let numSet = 0
      const { sets_data, week_sets } = exercise

      if (sets_data && sets_data.length > 0) {
        const lengthSets = sets_data.length
        const lengthConfig = week_sets.length
        numSet = lengthSets === lengthConfig ? lengthSets - 1 : lengthSets
      }
      set = sets_data[numSet] || week_sets[numSet]
      setExerciseNote(exercise.user_notes)
      handleSetConfiguration({ set, numSet })
      setCurrentExercise(exercise)
      setLoading(false)
      setBtnLoading(false)
    }
    handleAsync()
  }, [exercise])

  useEffect(() => {
    if (!loading && isOverwrite) {
      if (!!currentSet && !currentSet.is_done) {
        let renewState = false
        let overwriteCurrentSet = { ...currentSet }
        if (!!reps) {
          if (
            (!!currentSet.min_repetitions_overwrite && reps !== currentSet.min_repetitions_overwrite) ||
            (!currentSet.min_repetitions_overwrite && reps !== currentSet.min_repetitions)
          ) {
            renewState = true
            overwriteCurrentSet.min_repetitions_overwrite = reps
          }
        }
        if (weightSet !== '' && weightSet >= 0) {
          if (
            (!!currentSet.min_weight_overwrite && weightSet !== currentSet.min_weight_overwrite) ||
            (!currentSet.min_weight_overwrite && weightSet !== 0 && weightSet !== currentExercise.prev_weight)
          ) {
            renewState = true
            overwriteCurrentSet.min_weight_overwrite = weightSet
          }
        }

        if (!!renewState) {
          currentExercise.week_sets[numCurrentSet] = overwriteCurrentSet
          setCurrentSet(overwriteCurrentSet)
          overwriteWeekSet(overwriteCurrentSet)
        }
      }
    }
  }, [reps, weightSet])

  const handleShowModalExercise = (e) => {
    setModalExercisePosition(modalExercisePosition === -35 ? modalExercisePosition - 740 : modalExercisePosition + 740)
  }

  const handleAddSet = () => {
    if (currentExercise.sets_data.length < currentExercise.week_sets.length) {
      let totalOriginalSets = currentExercise.week_sets.length
      const setToCopy = currentExercise.week_sets[totalOriginalSets - 1]
      const newSet = JSON.parse(JSON.stringify(setToCopy))
      newSet.id = setToCopy.id + 1
      newSet.is_custom = 1
      newSet.order = totalOriginalSets + 1
      currentExercise.week_sets.push(newSet)
      handleWeekSets(currentExercise.week_sets)
      setCurrentExercise((prev) => ({
        ...prev,
        currentExercise,
      }))
    }
  }

  const handleRemoveSet = () => {
    if (currentExercise.sets_data.length < currentExercise.week_sets.length) {
      let originalSets = currentExercise.week_sets.filter((s) => (typeof s.is_custom === 'boolean' ? false : !s.is_custom))
      let lengthSets = originalSets.length
      let totalSets = currentExercise.week_sets.length
      if (totalSets === lengthSets || totalSets === currentSet.order) {
        return
      }
      currentExercise.week_sets.pop()
      handleWeekSets(currentExercise.week_sets)
      setCurrentExercise((prev) => ({
        ...prev,
        currentExercise,
      }))
    }
  }

  const handleAddWarmupSet = () => {
    if (currentExercise.sets_data.length < currentExercise.week_sets.length) {
      let lastSetCompleted = 0
      let firstWeekSet = currentExercise.week_sets[0]
      if (currentExercise.sets_data.length > 0) lastSetCompleted = currentExercise.sets_data[currentExercise.sets_data.length - 1].order

      currentExercise.week_sets = currentExercise.week_sets.map((s) => {
        if (s.order > lastSetCompleted) {
          s.order = s.order + 1
        }
        return s
      })

      let warmupSet = {
        id: firstWeekSet.id - 1,
        min_repetitions: 0,
        max_repetitions: 0,
        min_weight: 0,
        max_weight: 0,
        is_amrap: 0,
        is_optional: 0,
        is_custom: 1,
        is_warmup: 1,
        is_done: false,
        order: lastSetCompleted + 1,
      }

      currentExercise.week_sets.push(warmupSet)
      currentExercise.week_sets = currentExercise.week_sets.sort((a, b) => a.order - b.order)
      handleWeekSets(currentExercise.week_sets)

      setCurrentExercise((prev) => ({
        ...prev,
        currentExercise,
      }))
      setCurrentSet(warmupSet)
      setReps(0)
      setWeightSet(0)
    }
  }

  const handleUpdateSet = () => {
    const { sets_data } = currentExercise
    let updatedSet = {
      ...currentSet,
      reps,
      weight: Number(weightSet),
    }
    currentExercise.sets_data = sets_data.map((set) => {
      if (set.id === updatedSet.id) {
        set = updatedSet
      }
      return set
    })
    setCurrentSet(updatedSet)
    onUpdateSet(updatedSet)
  }

  const handleFinishSet = async () => {
    setBtnLoading(true)
    setColorStats(true)
    setTimeout(() => {
      setColorStats(false)
    }, 1100)

    const { sets_data, week_sets } = currentExercise
    const nextNum = numCurrentSet + 1
    const set = week_sets[nextNum]
    let currentSetAux = {
      ...currentSet,
      routine_session_exercise_id: localId,
      exercise_id: currentExercise.exercise_id,
      reps,
      weight: Number(weightSet),
      is_custom: !!currentSet.is_custom,
      is_done: true,
    }

    if (!currentSetAux.is_custom) setWeightSet(currentSetAux.weight ? currentSetAux.weight : 0)

    currentExercise.sets_data = [...sets_data, currentSetAux]
    handleSetConfiguration({ set, numSet: nextNum })
    await onFinishSet(currentExercise, currentSetAux, exerciseNote)
    setBtnLoading(false)
  }

  const handleFinishExercise = async () => {
    setBtnLoading(true)
    const { sets_data } = currentExercise
    let currentSetAux = {
      ...currentSet,
      routine_session_exercise_id: localId,
      exercise_id: currentExercise.exercise_id,
      reps,
      weight: Number(weightSet),
      is_custom: !!currentSet.is_custom,
      is_done: true,
    }
    currentExercise.user_notes = exerciseNote || null
    currentExercise.sets_data = [...sets_data, currentSetAux]
    await onFinishExercise(currentExercise, currentSetAux, exerciseNote)
  }

  const handleSetReps = (value) => {
    if (value) {
      setIsOverwrite(true)
      setReps(value)
    } else {
      if (reps === '') {
        setReps(preReps)
        setIsOverwrite(false)
      } else {
        setIsOverwrite(false)
        setPreReps(reps)
        setReps('')
      }
    }
  }

  const handleSetWeight = (value) => {
    if (value) {
      setIsOverwrite(true)
      setWeightSet(value)
    } else {
      if (weightSet === '') {
        setWeightSet(preWeight)
        setIsOverwrite(false)
      } else {
        setIsOverwrite(false)
        setPreWeight(weightSet)
        setWeightSet('')
      }
    }
  }

  const handleSetConfiguration = ({ set, numSet }) => {
    let weight
    let repetitions = 0
    let thisExercise = exercise
    if (!!set.is_done) {
      const { reps: setReps, weight: setWeight } = set
      repetitions = setReps
      weight = setWeight
    } else {
      const { min_repetitions, min_weight, min_repetitions_overwrite, min_weight_overwrite } = set
      repetitions = min_repetitions_overwrite || min_repetitions || repetitions
      if (min_weight_overwrite >= 0) {
        weight = min_weight_overwrite
      } else if (numSet > 0 && weightSet > 0) {
        weight = weightSet
      } else if (min_weight > 0) {
        weight = min_weight
      } else if (thisExercise.prev_weight) {
        weight = thisExercise.prev_weight
      } else {
        weight = 0
      }
      if (numSet === 0) {
        if (localStorage.getItem('PREFERRED_UNITS') === 'KG') {
          weight = Math.round(weight / 2.2046)
        }
      }
    }
    setNumCurrentSet(numSet)
    setReps(repetitions)
    setWeightSet(weight)
    setCurrentSet(set)
  }

  const handleClickArrow = (action) => {
    const { sets_data, week_sets } = currentExercise
    const num = action === 'NEXT' ? numCurrentSet + 1 : numCurrentSet - 1
    const numSetsConfig = week_sets.length
    let set = null

    if (num >= numSetsConfig || num < 0) {
      return
    } else {
      set = sets_data[num] || week_sets[num]
      handleSetConfiguration({ set, numSet: num })
    }
  }

  if (loading) return null

  let totalSetsLabel = 1
  if (currentExercise.sets_data && currentExercise.sets_data.length > currentExercise.week_sets.length) {
    totalSetsLabel = currentExercise.sets_data.length
  } else {
    totalSetsLabel = currentExercise.week_sets.length
  }

  const preferredUnits = localStorage.getItem('PREFERRED_UNITS')
  const techniqueTooltip = getTechniqueTooltip(currentExercise.technique, techniques, currentExercise.technique_notes)
  let deload
  if (currentExercise.technique === 'DELOAD') {
    deload = {
      deload_week: currentExercise.deload_week,
      deload_percent_min: currentExercise.deload_percent_min,
      deload_percent_max: currentExercise.deload_percent_max,
    }
  }

  return (
    <Card style={{ marginBottom: '50px' }}>
      <ContSets warmUp={currentSet.is_warmup} changeColor={colorStats}>
        <div className="details-info">
          <div>{currentExercise.data.name}</div>
          <button onClick={handleShowModalExercise} title="Exercise Detail Information">
            <InfoIcon data-title="Exercise Detail Information" />
          </button>
        </div>
        <div className="">
          <div style={{ borderBottom: '1px solid #ccc', paddingTop: '8px' }}>Goal</div>
          <div className="container-cards">
            <Card>
              <p style={{ fontWeight: 'bold' }}>{getGoalExerciseSession(currentExercise.week_sets, currentExercise.technique)}</p>
              <p>Sets x {currentExercise.reps_unit}</p>
            </Card>
            <Card>
              <div
                style={{ fontWeight: 'bold', wordBreak: 'break-word' }}
                dangerouslySetInnerHTML={getTechniqueLabel(currentExercise.technique, techniques, deload)}
              />
              <p>Technique</p>
              {techniqueTooltip && (
                <Tooltip title={techniqueTooltip}>
                  <Helper className="technique-helper" />
                </Tooltip>
              )}
            </Card>
          </div>
        </div>
        <div className="container-sets">
          <div className="container-controls">
            <p>
              <span>{totalSetsLabel}</span>sets
            </p>
            <Button shape="circle" ghost title="Delete Set">
              <LessIcon onClick={handleRemoveSet} data-title="Delete Set" />
            </Button>
            <Button shape="circle" title="Add Set">
              <MoreIcon onClick={handleAddSet} data-title="Add Set" />
            </Button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PersonIcon onClick={() => (currentSet.is_warmup === 0 ? handleAddWarmupSet() : null)} data-title="Mark Set as Warmup" />
            <small>Warmup</small>
          </div>
        </div>
        <div className="container-stats">
          <div className={`wrap-arrow ${numCurrentSet <= 0 ? 'disabled' : ''}`} onClick={() => handleClickArrow('BACK')}>
            <ArrowBack className="arrow-left" />
            <p>Prior Set</p>
          </div>
          <Card>
            <p>{currentSet.order}</p>
            <p className="label-info-sets">Set</p>
          </Card>
          <Card>
            <input
              name="total_reps"
              type="number"
              value={reps}
              onFocus={() => handleSetReps(null)}
              onBlur={(e) => handleSetReps(e.target.value)}
              onChange={(e) => handleSetReps(e.target.value)}
            />
            <p className="label-info-sets">{currentExercise.reps_unit}</p>
          </Card>
          <Card>
            <input
              name="total_weight"
              type="number"
              value={weightSet}
              onFocus={() => handleSetWeight(null)}
              onBlur={(e) => handleSetWeight(Number(e.target.value))}
              onChange={(e) => handleSetWeight(Number(e.target.value))}
            />
            <p className="label-info-sets">
              {preferredUnits === 'LB' ? 'Lbs' : 'Kgs'}
              {!!!currentExercise.technique && <>*</>}
            </p>
          </Card>
          <div className={`wrap-arrow ${!currentExercise.week_sets[numCurrentSet + 1] ? 'disabled' : ''}`} onClick={() => handleClickArrow('NEXT')}>
            <ArrowForward className="arrow-right" />
            <p>Next Set</p>
          </div>
        </div>
        {!!!currentExercise.technique && <div className="container-stats">*We use your previous weight as a reference</div>}
        <div className="container-user-notes">
          <p>Add a note for this exercise</p>
          <textarea name="user_notes" value={exerciseNote || ''} maxLength={250} onChange={(e) => setExerciseNote(e.target.value)} />
        </div>
        <div className="container-btn">
          {!!showSuccessButton && (
            <ButtonFakeSuccess>
              <Icon type="check" />
            </ButtonFakeSuccess>
          )}
          {!showSuccessButton && !!currentSet.is_done && (currentSet.reps === reps || !reps) && (currentSet.weight === weightSet || !weightSet) && (
            <BtnPrimary text="Finished" />
          )}

          {!showSuccessButton &&
            !!currentSet.is_done &&
            ((!!reps && currentSet.reps !== reps) || (!!weightSet && currentSet.weight !== weightSet)) && (
              <BtnPrimary text="Save changes" onClick={handleUpdateSet} />
            )}

          {!showSuccessButton && !currentSet.is_done && currentExercise.sets_data.length <= currentExercise.week_sets.length && (
            <BtnPrimary
              text={currentSet.order === totalSetsLabel ? 'Finish Exercise' : 'Finish Set'}
              onClick={() => (currentSet.order === totalSetsLabel ? handleFinishExercise() : handleFinishSet())}
              loading={btnLoading}
            />
          )}
        </div>
      </ContSets>

      <CardInfoExercise
        data={modalExercisePosition}
        setData={setModalExercisePosition}
        title={currentExercise.data.name}
        description={currentExercise.data.description}
        preparation={currentExercise.data.preparation}
        execution={currentExercise.data.execution}
        equipments={currentExercise.data.gym_equipments}
        muscles={currentExercise.data.muscles}
      />
    </Card>
  )
}

export default ContainerSets
