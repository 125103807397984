import styled from '@emotion/styled'

export const GroupCircles = styled.div`
  .group {
    flex-direction: row;
    justify-content: space-evenly;
    label {
      align-items: center;
      border: none;
      border: 2px solid #5d5d5d;
      border-radius: 50%;
      color: #5d5d5d;
      display: flex;
      justify-content: center;
      height: 45px;
      width: 45px;
      font-size: 22px;
      font-weight: bold;
      &::before {
        display: none;
      }
      span img {
        filter: grayscale(100%);
      }
    }
    label.ant-radio-button-wrapper-checked {
      border: ${({ image }) => (image ? `3px solid #FF6B00` : `none`)};
      color: #fff;
      background: ${({ image }) => (image ? `#FFF` : `linear-gradient(90deg, #FF6B00 0%, #FFA401 100%)`)};
      box-shadow: none;
      span img {
        filter: grayscale(0%);
      }
    }
    label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      outline: none !important;
    }
  }
`

export const GroupCards = styled.div`
  .group {
    width: 100%;

    .with-helper {
      display: grid;
      grid-template-columns: auto 22px;
      grid-gap: 3px;

      svg {
        align-self: center;
      }
    }

    .ant-checkbox-wrapper {
      display: block;
      width: 100%;
      margin-left: 0px;
      border: 1px solid #d9d9d9;
      .ant-checkbox {
        display: none;
      }
    }
  }

  .group {
    .ant-checkbox-checked,
    .ant-radio-button-wrapper-checked,
    .ant-checkbox-wrapper-checked {
      background: linear-gradient(90deg, #ff6b00 0%, #ffa401 100%);
      border: none;
      span {
        div {
          & p {
            color: #fff !important;
          }
        }
      }
    }
    label {
      border-radius: 4px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
      padding: 10px 10px;
      line-height: 30px;
      height: auto;
      margin: 6px 0px;
      display: flex !important;
      flex-direction: column !important;
      span {
        padding: 0px;
        display: flex;
        align-items: center;
        div {
          & p {
            font-size: 14px;
          }
          & p:first-of-type {
            color: #5d5d5d;
            font-weight: bold;
            margin-bottom: -5px;
            text-transform: capitalize;
          }
          & p:last-child {
            color: #4a4a4a;
            margin-bottom: 5px;
          }
        }
        svg,
        img {
          border: 1px solid #d9d9d9;
          width: 79px;
          height: 79px;
          margin-right: 15px;
          padding: 5px;
          background: #fff;
        }
      }
    }
  }

  .disabled {
    opacity: 0.5;
  }
`

export const GroupToggle = styled.div`
  .ant-radio-group {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-gap: 30px;

    .ant-radio-button-wrapper {
      height: auto;
      border-radius: 4px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
      padding: 10px;

      .option {
        display: grid;
        grid-template-columns: 60px auto;

        svg,
        img {
          border: 1px solid #d9d9d9;
          width: 60px;
          height: 60px;
          padding: 5px;
          background: #fff;
        }

        p {
          align-self: center;
          margin-bottom: 0;
          padding-left: 10px;
          color: #5d5d5d;
          font-weight: 500;
          font-size: 14px;
        }
      }

      &.ant-radio-button-wrapper-checked {
        background: linear-gradient(90deg, #ff6b00 0%, #ffa401 100%);
        border: none;

        .option {
          p {
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }

    @media screen and (max-width: 374.9px) {
      .ant-radio-button-wrapper {
        padding: 8px;

        .option {
          grid-template-columns: 50px auto;

          svg,
          img {
            width: 50px;
            height: 50px;
          }

          p {
            padding-left: 5px;
          }
        }
      }
    }
  }
`
