import React, { useState, useEffect } from 'react'
import EnrollmentLayout from '../Layout'
import { QuestionContainer } from '../Layout/Layout.styled'
import { Input } from 'antd'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import SectionError from 'components/SectionError'

const { TextArea } = Input

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [userNotes, setUserNotes] = useState('')
  const [error, setError] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const mealPlan = response.enrollment.mealPlan
        if (mealPlan && mealPlan.user_notes) {
          setUserNotes(mealPlan.user_notes)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  const saveCallback = async ({ goBack }) => {
    const data = {
      user_notes: userNotes,
      finish_meal_plan: goBack ? false : true,
    }
    return await HandleResponse({ ...props, data, goBack })
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, canGoNext: true, saveCallback, section: 'meal_plan' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer>
            <div className="question">
              <p className="title">
                Any specific info about your gastrointestinal health (eg. GERD, IBS, SIBO) or your dietary preferences not covered here?{' '}
                <span>(Optional)</span>
              </p>
              <p>Up to 750 characters</p>
            </div>
            <div className="content">
              <TextArea
                value={userNotes}
                onChange={(e) => setUserNotes(e.target.value)}
                maxLength={750}
                rows={8}
                autoSize={{ minRows: 8, maxRows: 8 }}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
