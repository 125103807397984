import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import _ from 'lodash'
import moment from 'moment'
import { validatingQueryDataObject, validatingWizardData } from 'services/validation'
import Uploader from 'components/Uploader'
import SubmitButton from 'components/SubmitButton'
import { previewTransform, uploaderOptions } from './data'
import {
  Root,
  Title,
  Description,
  Instruction,
  Skip,
  UploaderWrap,
  UploadedPic,
  UploaderTitle,
  UploaderDescription,
} from './SidePhotosUploader.styled'
import GlobalContext from 'context/GlobalContext'

const getPictures = gql`
  query($id: uuid!, $weekTag: String!) {
    pictures(where: { trainee_id: { _eq: $id }, for_date: { _eq: $weekTag } }, order_by: { for_date: desc }) {
      id
      for_date
      front_url
      side_url
      back_url
    }
  }
`

const upsertWeeklyPictures = gql`
  mutation upsert_pictures($data: pictures_insert_input!) {
    insert_pictures(objects: [$data], on_conflict: { constraint: pictures_trainee_for_date, update_columns: [front_url, side_url, back_url] }) {
      affected_rows
      returning {
        id
      }
    }
  }
`

const initialState = {
  date: null,
  frontPicture: null,
  backPicture: null,
  sidePicture: null,
  isFrontPictureUploaded: false,
  isBackPictureUploaded: false,
  isSidePictureUploaded: false,
}

const getUploadedPropertName = (pictureName) => `is${_.upperFirst(pictureName)}Uploaded`

class SidePhotosUploader extends Component {
  state = initialState

  handleSubmit = (mutation, traineeId, forDate, result) => (event) => {
    event.preventDefault()
    const { nextUrl, edit, handleChange } = this.props
    const { frontPicture, backPicture, sidePicture } = this.state
    // For upsert we need data about all pictures
    const data = {}
    if (frontPicture || (result && result.front_url)) data.front_url = frontPicture ? frontPicture : result.front_url
    if (sidePicture || (result && result.side_url)) data.side_url = sidePicture ? sidePicture : result.side_url
    if (backPicture || (result && result.back_url)) data.back_url = backPicture ? backPicture : result.back_url
    data.trainee_id = traineeId
    data.for_date = forDate

    return new Promise((resolve, reject) => {
      if (validatingWizardData(traineeId)) {
        const queryVariables = { data }
        validatingQueryDataObject({ traineeId, forDate })
        if (!!handleChange) {
          mutation({
            refetchQueries: [{ query: getPictures, variables: { id: traineeId, weekTag: forDate } }],
            variables: queryVariables,
          }).then((response) => {
            this.setState(initialState)
            if (!!handleChange) {
              handleChange()
              const requestPayload = _.get(response, 'data.update_pictures.returning[0].id')
              resolve(requestPayload)
            }
          })
        } else {
          mutation({
            variables: queryVariables,
          }).then(() => {
            navigate(nextUrl && edit ? `/` : nextUrl ? `/${nextUrl}/` : '/')
          })
        }
      }
    })
  }

  handleSkip = (event) => {
    event.preventDefault()
    const { nextUrl, edit } = this.props
    navigate(nextUrl && edit ? `/` : nextUrl ? `/${nextUrl}/` : '/')
  }

  handleUploadedFile = (pictureName, uploadedFile) => {
    this.setState({
      [pictureName]: uploadedFile.cdnUrl,
      [getUploadedPropertName(pictureName)]: true,
      date: uploadedFile.sourceInfo.file.lastModifiedDate,
    })
  }

  render() {
    const { pre, title, description, instruction, edit, forDate, children, handleChange } = this.props
    let buttonText
    if (!!handleChange) {
      buttonText = 'Save changes'
    } else {
      buttonText = edit ? 'Save' : 'Save and next'
    }
    return (
      <GlobalContext.Consumer>
        {({ user }) => {
          return (
            <Query query={getPictures} fetchPolicy="cache-and-network" variables={{ id: user.trainee_id, weekTag: forDate }}>
              {({ error, data }) => {
                if (error) {
                  console.log(error)
                  return null
                }
                const result = _.get(data, 'pictures[0]')

                return (
                  <Mutation mutation={upsertWeeklyPictures}>
                    {(mutation) => (
                      <Root custom={edit || handleChange ? 'disablePadding' : ''}>
                        {children}
                        {pre ? <Instruction>{pre}</Instruction> : ''}
                        <Title>{title}</Title>
                        {description ? <Description>{description}</Description> : ''}
                        {instruction ? <Instruction>{instruction}</Instruction> : ''}
                        {uploaderOptions.map(({ pictureTitle, pictureDescription, pictureName, defaultPicture, name }) => (
                          <Fragment key={pictureName}>
                            <UploaderTitle>{pictureTitle}</UploaderTitle>
                            <UploaderDescription>{pictureDescription}</UploaderDescription>
                            <UploaderWrap custom={this.state[getUploadedPropertName(pictureName)] === true ? 'uploaded' : ''}>
                              {/* TODO: Refactoring - something strange with src  */}
                              <UploadedPic
                                src={
                                  this.state[pictureName] && this.state[getUploadedPropertName(pictureName)]
                                    ? this.state[pictureName] + previewTransform
                                    : (result && result[name] && result[name] + previewTransform) || defaultPicture
                                }
                                alt={this.state[pictureName]}
                                isFileUploaded={this.state[pictureName] && this.state[getUploadedPropertName(pictureName)]}
                              />
                              <Uploader
                                onUploadComplete={(uploadedFile) => {
                                  this.handleUploadedFile(pictureName, uploadedFile)
                                }}
                              />
                            </UploaderWrap>
                          </Fragment>
                        ))}
                        {/* {!!edit || ( */}
                        <Skip type="button" onClick={this.handleSkip}>
                          I’d rather do this later.
                        </Skip>
                        {/* )} */}
                        <form onSubmit={this.handleSubmit(mutation, user.trainee_id, forDate, result)}>
                          <SubmitButton
                            {...{
                              handleSubmit: this.handleSubmit(mutation, user.trainee_id, forDate, result),
                              buttonContent: buttonText,
                            }}
                          />
                        </form>
                      </Root>
                    )}
                  </Mutation>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

// Set default props
SidePhotosUploader.defaultProps = {
  edit: false,
  pre: null,
  title: null,
  description: null,
  instruction: null,
  nextUrl: null,
  forDate: moment()
    .local()
    .startOf('isoWeek')
    .format('YYYYMMDD'),
}

SidePhotosUploader.propTypes = {
  edit: PropTypes.bool,
  pre: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  instruction: PropTypes.string,
  nextUrl: PropTypes.string,
  forDate: PropTypes.string,
}

export default SidePhotosUploader
