import styled from '@emotion/styled'

export const Root = styled.div`
  && {
    font-family: 'Montserrat', sans-serif;
    font-size: 120%;
    line-height: 1.5;
    p {
      margin-bottom: 1em;
    }
    h1 {
      font-weight: 500;
      margin-bottom: 0.5em;
    }
    strong {
      font-weight: bolder;
    }
  }
`

export const Wrap = styled.div`
  && {
    background-color: #fff;
    color: #333;
    margin: 0 20px 20px 20px;
    padding: 20px;
  }
`

export const Centered = styled.span`
  display: block;
  margin: 1em auto;
  text-align: center;
`

export const H1 = styled.h1`
  && {
    background-color: rgba(10, 10, 10, 0.9);
    color: #fff;
    font-size: 140%;
    margin: -20px;
    padding: 15px;
    text-align: center;
  }
`

export const A = styled.a`
  && {
    color: #333;
    font-weight: bold;
    text-decoration: none;
    transition: all ease 0.2s;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
`
