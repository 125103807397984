import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { getWeekRange } from 'services'
import ChangeablePicture from './ChangeablePicture'
import { Inner, Month, Week, UploadButton } from './UserPictures.styled'
import { ReactComponent as CalendarIcon } from 'images/calendar.svg'

class Pictures extends Component {
  render() {
    const { pictures, refetch } = this.props
    const monthTitles = []
    return (
      <Fragment>
        {pictures.map(({ id, front_url: front, back_url: back, side_url: side, for_date: forDate, empty }) => {
          let isShowMonth = true
          if (monthTitles.includes(moment(forDate).format('MMMM'))) {
            isShowMonth = false
          } else {
            monthTitles.push(moment(forDate).format('MMMM'))
          }
          return (
            <div key={id}>
              {isShowMonth && <Month>{moment(forDate).format('MMMM YYYY')}</Month>}
              <Week>
                <CalendarIcon />
                <span>{getWeekRange(forDate)}</span>
              </Week>
              <Inner>
                <ChangeablePicture
                  {...{
                    id,
                    src: front,
                    alt: 'Front Picture',
                    forDate,
                    refetch,
                    type: 'front',
                    disableModal: empty,
                  }}
                />
                <ChangeablePicture
                  {...{
                    id,
                    src: back,
                    alt: 'Back Picture',
                    forDate,
                    refetch,
                    type: 'back',
                    disableModal: empty,
                  }}
                />
                <ChangeablePicture
                  {...{
                    id,
                    src: side,
                    alt: 'Side Picture',
                    forDate,
                    refetch,
                    type: 'side',
                    disableModal: empty,
                  }}
                />
                {empty && <UploadButton to={`/weekly-pictures/${forDate}`}>Upload this week's photos</UploadButton>}
              </Inner>
            </div>
          )
        })}
      </Fragment>
    )
  }
}

export default Pictures
