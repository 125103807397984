import styled from '@emotion/styled'
import { card } from 'antd'

export const CardContainer = styled(card)`
  position: absolute;
  /* left: 19px; */
  bottom: ${({ data }) => data}px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  height: 500px;
  .ant-card-body {
    padding: 15px 11px;
    width: 100%;
  }

  .equipment-main {
    /* height: 56px; */
    display: flex;
    padding: 11px 10px 0px;
    margin-bottom: 12px;
    justify-content: space-between;
    border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;

    & div:nth-of-type(1) {
      position: relative;
      left: -5px;
    }

    & div:nth-of-type(2) {
      position: relative;
      /* left: -30px; */
    }
  }

  .info-exercise {
    margin-left: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const FirstSection = styled('div')`
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  & div {
    max-width: 237px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    color: #4a4a4a;
  }
  & svg {
    position: relative;
    right: 31px;
    top: 4px;
  }
`

export const TitleScroll = styled('p')`
  width: 100%;
  max-width: 320px;
  height: 18px;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #4a4a4a;
`

export const ScrollContainer = styled.div`
  width: 100%;
  max-width: 345px;
  height: 300px;
  margin: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 151.6%;
  color: #4a4a4a;
  overflow-y: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10%;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff6b00;
    border-radius: 3px;
  }
`
export const ToolsObjective = styled('div')`
  & svg:nth-of-type(1) {
    top: 1px;
    margin-right: 10px;
    position: relative;
    vertical-align: top;
  }

  & p:nth-of-type(2) {
    margin: 0px 0px 1px 0px;
    height: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #4a4a4a;
  }

  & p:nth-of-type(3) {
    margin: 0px;
    height: 18px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
  }
`
