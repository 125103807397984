import React, { useContext, useState } from 'react'
import GlobalContext from 'context/GlobalContext'
import { navigate } from '@reach/router'
import PageHeader from 'components/Layout/PageHeader'
import Stepper from 'components/Stepper'
import { Button } from 'styled'
import { Main, FooterContainer } from './Layout.styled'
import Loading from 'components/Loading/Loading'
import { message } from 'antd'
import { backStage, nextStage, getStageInformation } from '../uses/Wizard'

const Layout = (props) => {
  const { loading, path, children, canGoNext, saveCallback, section } = props
  const [loadingNext, setLoadingNext] = useState(false)
  const [loadingBack, setLoadingBack] = useState(false)
  // const section = 'meal_plan'
  const module = path.split('/')[1]
  const stage = path.split('/')[2]
  const globalContext = useContext(GlobalContext)
  const currentProgram = globalContext.programs.find((p) => p.program_id === sessionStorage.getItem('BOOTCAMP_ID_SELECTED'))

  let stageInformation = getStageInformation(section, stage, module, currentProgram.type)

  const bannerData = stageInformation.section.steps.map((step) => {
    return {
      id: step.order,
      title: step.shortTitle,
      icon: step.icon,
    }
  })

  let buttonNextText = stageInformation.order === bannerData.length ? 'Submit' : 'Next'

  const handleActionButton = async ({ goBack }) => {
    goBack ? setLoadingBack(true) : setLoadingNext(true)
    if (typeof saveCallback === 'function') {
      const response = await saveCallback({ goBack })
      if (!response || response.hasError) {
        if (response) message.error(response.error, 10)
      } else {
        goBack ? backStage(section, stage, module) : nextStage(section, stage, module)
      }
    }
    goBack ? setLoadingBack(false) : setLoadingNext(false)
  }

  const handleClose = () => {
    navigate(`/${module}`)
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <PageHeader showClose={true} onClose={handleClose} title={stageInformation.section.title} />
      <Main>
        <Stepper {...{ steps: bannerData, selected: stageInformation.order }} />
        <section>
          <header>
            <h2>{stageInformation.title}</h2>
          </header>
          {loading && (
            <Loading>
              <div className="rectangle" />
            </Loading>
          )}
          {!loading && <>{children}</>}
        </section>
      </Main>
      {!loading && (
        <FooterContainer>
          <div>
            <Button loading={loadingBack} onClick={() => handleActionButton({ goBack: true })}>
              Back
            </Button>
            <Button
              loading={loadingNext}
              type="primary"
              onClick={() => handleActionButton({ goBack: false })}
              className={!canGoNext ? 'opacity' : ''}
            >
              {buttonNextText}
            </Button>
          </div>
        </FooterContainer>
      )}
    </div>
  )
}

export default Layout
