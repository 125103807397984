import styled from '@emotion/styled'
import { Card } from 'antd'

export const CardWeeklyEstimated = styled(Card)`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  width: 100%;
  max-width: 375px;
  height: 155px;
  margin-top: 15px;

  .ant-card-body {
    padding: 0px;
  }

  .container-weekly {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  & svg {
    width: 87px;
    height: 60px;
    margin-right: 28px;
  }

  .card-weekly-title {
    width: 320px;
    height: 22px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #4a4a4a;
    margin-top: 11px;
    margin-left: 12px;
    margin-bottom: 27px;
  }

  .weekly-ads {
    width: 150px;
    height: 23px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    color: #ff6b00;
    align-self: flex-end;
    margin-bottom: 12px;
  }
`
