import styled from '@emotion/styled'
import menu from '../images/exercise/menu-points.png'
import imageUp from '../images/exercise/menu-no-dnd-up.svg'
import imageDown from '../images/exercise/menu-no-dnd-down.svg'

export const MenuPoints = styled.div`
  width: 100%;
  max-width: 380px;
  position: relative;
  /* display: flex:
	justify-content: flex-end; */

  & button {
    width: 10px;
    height: 45px;
    border: 0px;
    position: absolute;
    top: 14px;
    left: -20px;
    background: #fff;
    background-image: url(${menu});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    z-index: 999999;
  }

  & ul {
    width: 147px;
    height: 88px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    position: absolute;
    top: 91px;
    z-index: 999999;
    background: black;
    visibility: hidden;

    & div {
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 18px solid black;
      position: absolute;
      left: 0px;
      top: -17px;
    }

    & li {
      width: 70%;
      height: 20px;
      margin: 0px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
    }
  }

  .up {
    &:before {
      content: url(${imageUp});
      position: absolute;
      left: 10px;
      width: 21.44px;
    }
  }

  .down {
    &:before {
      content: url(${imageDown});
      position: absolute;
      left: 10px;
      width: 21.44px;
    }
  }

  .is-active {
    visibility: visible;
    z-index: 9999999991;
  }

  @media screen and (max-width: 320px) {
    /* max-width: 287px; */
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    max-width: 360px;

    & button {
      /* left: 4px; */
    }
  }
`
