import React from 'react'
import BtnPrimary from '../CustomButtons/BtnPrimary/BtnPrimary'
import { CstFooter } from './Footer.styled'

const Footer = () => {
  return (
    <CstFooter>
      <BtnPrimary text="Begin Today's Training" icon="play-circle" />
    </CstFooter>
  )
}

export default Footer
