import React, { Component } from 'react'
import get from 'lodash/get'
import apolloClient from 'apolloClient'
import { Root, Wrap, TimelineWrap, SnoozeConfirm, GlobalStyle } from './Timeline.styled'
import { getTimelineStats, updateSnooze } from './data'

import { Button, Icon } from 'antd'
import { navigate } from '@reach/router'

import DailyIcon from 'images/icon-daily.svg'
import CalendarIcon from 'images/icon-calendar.svg'
import CompleteIcon from 'images/icon-complete.svg'
import { ReactComponent as IconBell } from 'images/icon-bell.svg'
import { ReactComponent as IconBellOff } from 'images/icon-bell-off.svg'
import { Query } from 'react-apollo'
import moment from 'moment'

const today = moment().local()

class TimelineWarning extends Component {
  constructor(props) {
    super(props)
    this.exposedFunctions = {}
  }

  state = {
    snoozeVisible: false,
    isSnoozed: null,
  }

  handleSnooze = (visible) => {
    this.setState({ snoozeVisible: visible })
  }

  getSnoozeTitle = (_snooze) => {
    return (
      <div className={_snooze ? 'snoozed' : ''}>
        {!_snooze && <IconBellOff className="snooze-icon" />}
        {_snooze && <IconBell className="snooze-icon" />}
        <h3>Turn {_snooze ? 'on' : 'off'} the reminder?</h3>
      </div>
    )
  }

  snooze = (_snooze, days) => {
    const { traineeId } = this.props
    let snoozeTimeline = _snooze ? moment(today).add(days, 'day') : null
    apolloClient
      .mutate({
        mutation: updateSnooze,
        variables: {
          traineeId,
          snoozeTimeline,
        },
      })
      .then((data) => {
        this.setState({
          snoozeVisible: false,
          isSnoozed: _snooze,
        })
      })
  }

  getSnoozeActions = (isSnoozed) => {
    return (
      <div className={isSnoozed ? 'snoozed' : ''}>
        {isSnoozed && <Button onClick={() => this.snooze(false, 0)}>Turn on</Button>}
        {!isSnoozed && (
          <>
            <Button onClick={() => this.snooze(true, 1)}>Turn off for today</Button>
            <Button onClick={() => this.snooze(true, 7)}>Turn off for this week</Button>
          </>
        )}
        <Button className="btn-cancel" onClick={() => this.handleSnooze(false)}>
          Cancel
        </Button>
      </div>
    )
  }

  render() {
    const { traineeId, closingProgram } = this.props
    let { start_date, weekNumber } = this.props
    if (!!!start_date) {
      if (!!!closingProgram && !!!closingProgram.program_id) return null
      start_date = closingProgram.start_date
      weekNumber = closingProgram.duration || 8
    }

    return (
      <Root>
        <GlobalStyle />
        <Wrap>
          <Query query={getTimelineStats} variables={{ traineeId, startDate: moment(start_date).format('YYYYMMDD') }} fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading || error) return null

              this.exposedFunctions = { refetch }

              const trainee = get(data, 'trainee[0]') || {}
              const weeklies = get(trainee, 'measurement_aggregate.aggregate.count') || 0
              const dailies = get(trainee, 'macro_aggregate.aggregate.count') || 0

              const totalDailies = today.diff(moment(start_date).local(), 'day') + 1
              let dailiesMissing = totalDailies - dailies

              const weekliesMissing = weekNumber - weeklies
              const isWeeklyDailyComplete = weekliesMissing === 0 && dailiesMissing === 0

              let isSnoozed = this.state.isSnoozed

              if (!!trainee.snooze_timeline && isSnoozed === null) {
                var snoozeTimeline = moment(trainee.snooze_timeline).local()
                isSnoozed = today.isBefore(snoozeTimeline)
              }

              if (dailiesMissing < 0) {
                dailiesMissing = 0
              }
              return (
                <TimelineWrap>
                  <div className="title">
                    <p>
                      Edit Previous Entry
                      <SnoozeConfirm
                        visible={this.state.snoozeVisible}
                        onVisibleChange={(visible) => this.handleSnooze(visible)}
                        content={this.getSnoozeActions(isSnoozed)}
                        title={this.getSnoozeTitle(isSnoozed)}
                        trigger="click"
                        className={isSnoozed ? 'snoozed' : isWeeklyDailyComplete ? 'complete' : ''}
                        placement="topRight"
                      >
                        {isSnoozed && <IconBellOff />}
                        {!isSnoozed && <IconBell />}
                      </SnoozeConfirm>
                    </p>
                  </div>
                  <div className="content">
                    {!isSnoozed && isWeeklyDailyComplete && (
                      <div className="weekly-daily-complete">
                        <img src={CompleteIcon} alt="" />
                        <div>
                          <h3>Keep Up The Excellent Work!</h3>
                          <p>All your data is up-to-date</p>
                        </div>
                      </div>
                    )}
                    {!isSnoozed && !isWeeklyDailyComplete && (
                      <div className="missing-data">
                        <div className="red-title">You are missing the following info </div>
                        <div className="total-missing-data">
                          <div>
                            <img src={DailyIcon} alt="" />
                            <span className="total">{dailiesMissing}</span> <span>Daily</span>
                          </div>
                          <div>
                            <img src={CalendarIcon} alt="" />
                            <span className="total">{weekliesMissing}</span> <span>Weekly</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {weekNumber >= 1 && (
                      <>
                        <Button className="edit-previous-data" onClick={() => navigate('/daily-entry/edit')}>
                          <strong>{isWeeklyDailyComplete ? 'Edit' : 'Update'} Daily</strong> <Icon type="right" />
                        </Button>
                        <Button className="edit-previous-data" onClick={() => navigate('/weeks-edit')}>
                          <strong>{isWeeklyDailyComplete ? 'Edit' : 'Update'} Weekly</strong> <Icon type="right" />
                        </Button>
                      </>
                    )}
                  </div>
                </TimelineWrap>
              )
            }}
          </Query>
        </Wrap>
      </Root>
    )
  }
}

export default TimelineWarning
