import React, { Component, Fragment } from 'react'
import { Form } from 'antd'
import _ from 'lodash'
import Uploader from 'components/Uploader'
import { UploaderWrap, UploadedPic, UploaderTitle, UploaderDescription } from 'components/SidePhotosUploader/SidePhotosUploader.styled'
import { previewTransform, uploaderOptions } from 'components/SidePhotosUploader/data'

const getUploadedPropertName = (pictureName) => `is${_.upperFirst(pictureName)}Uploaded`

class WeeklyPhotosEdit extends Component {
  state = {
    frontPicture: null,
    backPicture: null,
    sidePicture: null,
  }

  handleUploadedFile = (pictureName, uploadedFile) => {
    const { handlePictureSelected } = this.props
    this.setState(
      {
        [pictureName]: uploadedFile.cdnUrl,
        [getUploadedPropertName(pictureName)]: true,
        date: uploadedFile.sourceInfo.file.lastModifiedDate,
      },
      () => {
        if (handlePictureSelected) handlePictureSelected(pictureName, uploadedFile.cdnUrl)
      },
    )
  }

  render() {
    const { pictures } = this.props
    return (
      <>
        {uploaderOptions.map(({ pictureTitle, pictureDescription, pictureName, defaultPicture, name }) => (
          <Fragment key={pictureName}>
            <UploaderTitle>{pictureTitle}</UploaderTitle>
            <UploaderDescription>
              If self-taken photos or 'selfies' are your only option, please take the photo as close as possible to the posture depicted below
            </UploaderDescription>
            <UploaderWrap custom={this.state[getUploadedPropertName(pictureName)] === true ? 'uploaded' : ''}>
              {/* TODO: Refactoring - something strange with src  */}
              <UploadedPic
                src={
                  this.state[pictureName] && this.state[getUploadedPropertName(pictureName)]
                    ? this.state[pictureName] + previewTransform
                    : (pictures && pictures[name] && pictures[name] + previewTransform) || defaultPicture
                }
                alt={this.state[pictureName]}
                isFileUploaded={this.state[pictureName] && this.state[getUploadedPropertName(pictureName)]}
              />
              <Uploader
                onUploadComplete={(uploadedFile) => {
                  this.handleUploadedFile(pictureName, uploadedFile)
                }}
              />
            </UploaderWrap>
          </Fragment>
        ))}
      </>
    )
  }
}

export default Form.create()(WeeklyPhotosEdit)
