import React, { useState } from 'react'
import './index.scss'
import { Select } from 'antd'
const { Option, OptGroup } = Select

const MaterializeMultiSelect = ({ value, label, className, options, onChange, showSearch, filterOption, autoClearSearchValue, disabled }) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className={`mat-multi-select ${className ? className : ''} ${disabled ? 'disabled' : ''} wrap`}>
      <div className={`${isFocused || value ? 'is-active is-completed' : ''} mat-div`}>
        <label htmlFor={label} className="mat-label">
          {label || 'Select an option'}
        </label>
        <Select
          mode="multiple"
          value={value}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={(e) => {
            setIsFocused(false)
          }}
          disabled={disabled}
          onChange={onChange}
          showSearch={showSearch}
          filterOption={filterOption}
          autoClearSearchValue={autoClearSearchValue}
        >
          {options.map((group) => {
            return (
              <OptGroup key={group.id} label={group.label}>
                {group.children.map((option) => {
                  return (
                    <Option className="optionMultiple" key={option.id} value={option.id}>
                      {option.label}
                    </Option>
                  )
                })}
              </OptGroup>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default MaterializeMultiSelect
