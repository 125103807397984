import NetworkDetector from 'services/NetworkDetector'
import { apiFetch } from 'services/API/apiFetch'
import { navigate } from '@reach/router'
import { LOCAL_STORAGE } from 'constants.js'

const generalData = async () => {
  const isConnected = await NetworkDetector()
  const trainee_id = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
  const program_id = sessionStorage.getItem('BOOTCAMP_ID_SELECTED')

  return { isConnected, trainee_id, program_id }
}

export const HandleRequest = async ({ path }) => {
  const { isConnected, trainee_id, program_id } = await generalData()
  const module = path.split('/')[1]
  const stage = path.split('/')[2]
  const error = {
    hasError: true,
    message: '',
  }

  if (!program_id) {
    navigate(`/${module}`)
  }

  if (isConnected) {
    const response = await apiFetch({
      url: `students/${trainee_id}/enrollment-stage/${program_id}?module=${module}&stage=${stage}`,
      method: 'GET',
      isConnected,
    }).catch((e) => e)
    if (!response.hasError) {
      return response
    } else {
      if (response.status !== 403) {
        error.message = 'INTERNAL_SERVER_ERROR'
        return error
      }
      navigate(`/${module}`)
    }
  } else {
    error.message = 'LOST_NETWORK'
    return error
  }
}

export const HandleResponse = async ({ path, data, goBack }) => {
  const { isConnected, trainee_id, program_id } = await generalData()
  const module = path.split('/')[1]
  const stage = path.split('/')[2]
  data.stageFinished = !goBack
  if (isConnected) {
    const response = await apiFetch({
      url: `students/${trainee_id}/enrollment-stage/${program_id}?module=${module}&stage=${stage}`,
      method: 'POST',
      body: JSON.stringify(data),
      isConnected,
    }).catch((e) => e)
    return response
  } else {
    return { hasError: true, error: 'LOST_NETWORK' }
  }
}
