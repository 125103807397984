import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Tabs, Collapse } from 'antd'
import BaseMacrosBg from '../../images/macros_pdf/base_macros_bg.png'
import TrainingMacrosBg from '../../images/macros_pdf/training_macros_bg.png'
import AlternateMacrosBg from '../../images/macros_pdf/alternate_macros_bg.png'

export const Root = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 56px;
`

export const MacroBanner = styled.div`
  width: 100;
  background: linear-gradient(90deg, #ff4713 0%, #ff7e22 100%);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  height: 95px;
  padding: 16px 12px 16px;
  margin-bottom: 7px;

  h1 {
    text-align: center;
    font-size: 21px;
    color: #ffffff;
  }

  hr {
    border: none;
    border-top: 1px solid #ffffff;
  }

  p {
    width: 70%;
    margin: 0 auto;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  img {
    position: relative;
    top: -14px;
    cursor: pointer;
    float: right;
  }
`

export const MacroTabs = styled(Tabs)`
  clear: both;

  .ant-tabs-bar {
    border: none;
  }

  .ant-tabs-nav {
    height: 40px;
    border-radius: 19.5px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(176, 176, 176, 0.19);
    padding: 3px;
    width: 100%;

    .ant-tabs-tab {
      background: transparent !important;
      color: #959595;
      border: none !important;
      font-weight: bold;
      text-align: center;
      height: 34px;
      font-size: 12px;
      line-height: normal !important;
      padding: 3px 0px !important;
      width: 50%;

      &.ant-tabs-tab-active {
        span {
          display: block;
          padding: 8px 20px;
          border-radius: 17px;
          color: #ffffff !important;
          background: #ff6b00 !important;

          &.blue {
            background: #5c7ce2 !important;
          }

          &.green {
            background: #3ad297 !important;
          }
        }
      }
    }
  }

  &.has-alternate {
    .ant-tabs-nav {
      .ant-tabs-tab {
        width: 33%;
      }
    }
  }
`

export const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 9px;
  padding: 8px;

  .title {
    border: none;
    font-size: 15px;
    font-weight: bold;
    padding: 0px 8px 5px 8px;
    border-bottom: 1px solid #ff6b00;

    &.blue {
      border-bottom: 1px solid #5c7ce2;
    }

    &.green {
      border-bottom: 1px solid #3ad297;
    }

    p.total {
      float: right;

      small {
        color: #959595;
        font-size: 8px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    padding: 8px;

    table {
      width: 100%;

      thead {
        th {
          color: #959595;
          font-size: 9px;
          font-weight: 600;
          text-align: left;
          padding-right: 7px;
        }
      }

      tbody {
        td {
          font-weight: bold;
          font-size: 16px;
          color: #383838;
          text-align: left;

          small {
            font-size: 9px;
          }

          &.total {
            color: #ff6b00;

            &.blue {
              color: #5c7ce2;
            }

            &.green {
              color: #3ad297;
            }
          }
        }
      }
    }
  }

  &.BaseDay {
    .content {
      table {
        tbody {
          td {
            font-size: 26px;
          }
        }
      }
    }
  }

  .footer {
    border-top: 1px solid #d5d5d5;

    .empty-dailies {
      text-align: center;
      padding: 10px;

      p {
        margin: 0;
        font-size: 12px;
        .anticon {
          margin-bottom: 4px;
          font-size: 35px !important;
        }
      }
    }

    table {
      width: 100%;
      background-color: #f7f7f7;

      &:before {
        border-bottom: 3px solid red;
        position: relative;
        content: ' ';
      }

      thead {
        background: #e3e3e3;

        th {
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          color: #908d8d;
          padding: 5px 0px;

          .anticon {
            font-size: 13px;
            position: relative;
            float: left;
            right: -5px;
            margin-top: 1px;
          }
        }
      }

      tbody {
        td {
          padding: 7px;
          font-size: 10px;
          color: #5d5d5d;
          text-align: center;

          &.daily-date {
            font-size: 8px;
            color: #959595;
          }

          &.daily-total {
            font-weight: 600;
            color: #ff6b00;
          }

          .ant-tag {
            font-size: 9px;
          }

          .ant-progress-line {
            max-width: 70px;

            .ant-progress-inner {
              background-color: #d5d5d5;
            }
          }
        }
      }
    }
  }

  &.total-card {
    border-left: 8px solid #ff6b00;
    max-height: 80px;
    clear: both;
    overflow: auto;

    div.image-container {
      float: left;

      img {
        width: 60px;
        height: 60px;
      }
    }

    div.container {
      border-left: 1px solid #d5d5d5;
      float: left;
      padding: 0px 10px;

      p {
        margin: 0;
        font-weight: 600;

        &.total {
          font-size: 28px;
          color: #383838;
        }

        &.orange {
          color: #ff6b00;
        }

        &.blue {
          color: #5c7ce2;
        }

        &.green {
          color: #3ad297;
        }
      }
    }

    &.blue {
      border-left: 8px solid #5c7ce2;
    }

    &.green {
      border-left: 8px solid #3ad297;
    }

    .anticon {
      color: #ff6b00;
      float: right;
      position: relative;
      bottom: -50px;
    }
  }
`

export const MacroCollapse = styled(Collapse)`
  border: none;
  .ant-collapse-item {
    border: none;
    padding: 0;
    background: #ffffff;

    .ant-collapse-header {
      padding: 6px 40px 1px 4px;

      .adherence-title {
        .anticon {
          background: #26ac00;
          color: #ffffff;
          width: 14px;
          height: 14px;
          padding: 1px;
          margin-right: 10px;
          border-radius: 50%;
          font-size: 10px;
          top: -2px;
          position: relative;
        }

        &.red {
          .anticon {
            background: #e53d2e;
          }
        }

        &.orange {
          .anticon {
            background: #fdac23;
          }
        }

        span {
          color: #383838;
          font-size: 15px;
          font-weight: 600;
        }

        small {
          color: #959595;
          font-size: 11px;
          margin-left: 8px;
        }
      }

      .ant-collapse-arrow {
        right: 16px;
        left: initial;
        line-height: normal;

        .anticon {
          border-radius: 50%;
          padding: 2px;
          width: 14px;
          height: 14px;
          font-size: 10px;
          top: -2px;
          position: relative;
        }

        .orange {
          background-color: #ff6b00;
          color: #ffffff;
        }

        .blue {
          background-color: #5c7ce2;
          color: #ffffff;
        }

        .green {
          background-color: #3ad297;
          color: #ffffff;
        }
      }
    }
  }

  .ant-collapse-content {
    border-top: none;
    border-radius: 0;

    .ant-collapse-content-box {
      padding: 0;
    }
  }
`

export const MacroEmptyContainer = styled.div`
  text-align: center;
  color: #666666 !important;
  padding-top: 30px;

  .anticon {
    font-size: 80px;
  }
`

export const MacroTablePDF = styled.table`
  width: 100%;
  font-size: 14px;
  color: #5c5c5c;
  font-family: sans-serif;

  thead {
    tr {
      th {
        text-align: center;
        color: #959595;
        font-size: 10px;
        padding: 11px 0px;
      }

      &.main-header {
        ${({ primaryColor }) =>
          primaryColor &&
          css`
            background: ${primaryColor};
          `}

        th {
          text-align: left;
          color: #ffffff;
          font-size: 14px;
          padding: 7px 12px 10px 12px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        background: #ffffff;
        text-align: center;
        color: #5c5c5c;

        &.title {
          color: #3b3c39;
          font-size: 10px;
          font-weight: bold;
          text-align: left;
        }
      }

      &.colored {
        td {
          background: #f5f5f5;
        }
      }
    }
  }

  tfoot {
    background: F5F5F5;

    tr {
      td {
        padding: 10px;
        text-align: center;
      }
    }

    .label {
      text-align: right;
      color: #3b3c39;
      font-size: 10px;
      font-weight: bold;
    }

    .description {
      td {
        padding: 0px;
        background: #ffffff;
        text-align: left;
        font-size: 9px;
        color: #5d5d5d;
      }

      &.first {
        td {
          padding: 10px 0px 0px 0px;
        }
      }
    }
  }
`

export const StyledLogoBackground = styled.div`
  background: linear-gradient(181.59deg, #ff5e31 6.02%, #ff7e22 95.99%);
  padding: 7px;
  border-radius: 5px;
`

export const PDFTitle = styled.div`
  text-align: left;
  p {
    margin-bottom: 0;
    font-weight: bold;
  }
  .greeting {
    font-size: 15px;
  }
  .middle-text {
    font-size: 8px;
  }
  .privacy-message {
    color: #ff6b00;
    font-size: 8px;
  }
`

export const DownloadTitle = styled.div`
  text-align: right;
  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 9px;
  }
  .downloaded-date {
    color: #ff6b00;
  }
`

export const MacroPDFTableStyled = styled.div`
  .ant-pagination {
    display: none;
  }
  .ant-table {
    border: none;
    font-size: 10px;
    .ant-table-thead {
      th {
        text-align: center;
        font-size: 9px;
      }
    }
    .ant-table-row {
      text-align: center;
      td:first-of-type {
        font-weight: bold;
        font-size: 9px;
      }
    }
    tr:nth-of-type(2),
    tr:nth-of-type(4) {
      background: #ececec;
    }
    tr:last-child {
      td {
        font-weight: bold;
      }
    }
  }
`

export const MacroTableTitle = styled.div`
  width: 100%;
  padding: 2px 15px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;

  p {
    margin-bottom: 0;
    color: white;
    font-size: 12px;
    padding: 3px;
    font-weight: 600;
  }

  &#restDay {
    background-image: url(${BaseMacrosBg});
  }
  &#trainingDay {
    background-image: url(${TrainingMacrosBg});
  }
  &#alternate {
    background-image: url(${AlternateMacrosBg});
  }
`

export const TrainingInfoStyled = styled.div`
  margin: 5px 0;
  border: 1px solid #ff6b00;
  padding: 3px 10px;
  border-radius: 5px;
  .ant-row {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-col:first-of-type {
      color: #3b3c39;
    }
    .ant-col:last-child {
      p {
        color: #5d5d5d;
      }
    }
  }
  font-size: 7px;
  text-align: left;
`
