import React, { useState, useEffect, useRef, useReducer } from 'react'
import { ContainerTextEditor, GiftSelector, GifButton, StyledButton } from './TextEditor.styled'
import { Popover, Icon, Tooltip, message } from 'antd'
import { Widget } from '@uploadcare/react-widget/dist/cjs'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'quill-emoji'
import Picker from 'react-giphy-component'
import sendIcon from 'assets/icons/send_icon.png'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'
import { LOCAL_STORAGE, UPLOADS } from '../../../constants'

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  },
  true,
)

const TextEditor = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [editorHtml, setEditorHtml] = useState('')
  const [attachments, setAttachments] = useState([])
  const [gifVisible, setGifVisible] = useState(false)
  const [forceRenderUploadCare, setForceRenderUploadCare] = useReducer((key) => !key)
  const uploadCareWidgetApi = useRef()
  const quillRef = useRef(null)
  const translations = {
    errors: {
      fileMaximumSize: 'Something',
    },
    dialog: {
      tabs: {
        preview: {
          error: {
            fileMaximumSize: {
              title: 'Wrong size.',
              text: 'Sorry, the image should not be greater than 3Mb',
              back: 'Back',
            },
          },
        },
      },
    },
  }

  useEffect(() => {
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }

    const message = localStorage.getItem(LOCAL_STORAGE.CURRENT_MESSAGE)
    const attachments = localStorage.getItem(LOCAL_STORAGE.CURRENT_ATTACHMENT)

    setEditorHtml(!!message ? message : '')
    setAttachments(!!attachments ? JSON.parse(attachments) : [])

    setLoading(false)
  }, [])

  const handleSendMessage = () => {
    const { thread_id, id, isWeeklyFeedback, traineeCID, currentProgramId, send, refetch, appendMessage } = props
    if ((!!!editorHtml || editorHtml.replace(/<(.|\n)*?>/g, '').trim().length === 0) && attachments.length === 0) {
      setError(true)
      const t = setTimeout(() => {
        setError(false)
        stopTimeOut()
      }, 1700)

      const stopTimeOut = () => {
        clearTimeout(t)
      }
    } else {
      let message = editorHtml
      message += '<div class="attachments">'
      attachments.forEach((attach) => {
        const sizeReadable = fileSizeReadable(attach.size)
        if (attach.isImage) {
          message += '<div class="attachment-item image">'
          message += `<img width="250" src="${attach.cdnUrl}" />`
          message += `<p><a class="uploadcare-link" href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
          message += `<p>${sizeReadable}</p>`
          message += '</div>'
        } else {
          message += '<div class="attachment-item file">'
          message += '<div class="preview">'
          message += `<img src="/icons/icon-file.png" alt="" />`
          message += '</div>'
          message += '<div class="detail">'
          message += `<p><a class="uploadcare-link" href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
          message += `<p>${sizeReadable}</p>`
          message += '</div>'
          message += '</div>'
        }
      })
      message += '</div>'
      send(thread_id, id, isWeeklyFeedback, traineeCID, currentProgramId, message, refetch, appendMessage, () => {
        localStorage.removeItem(LOCAL_STORAGE.CURRENT_ATTACHMENT)
        localStorage.removeItem(LOCAL_STORAGE.CURRENT_MESSAGE)
        setEditorHtml('')
        setAttachments([])
        setForceRenderUploadCare()
      })
    }
  }

  const handleChange = (html) => {
    setEditorHtml(html)
    localStorage.setItem(LOCAL_STORAGE.CURRENT_MESSAGE, html)
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }
  }

  const handleFileSelected = (file) => {
    if (file) {
      file.done((fileInfo) => {
        const attachments = [fileInfo]
        localStorage.setItem(LOCAL_STORAGE.CURRENT_ATTACHMENT, JSON.stringify(attachments))
        setAttachments(attachments)
      })
    }
  }

  const gifContainer = (onSelectGif) => (
    <GiftSelector>
      <Picker
        {...{
          apiKey: process.env.REACT_APP_GIPHY_KEY,
          modal: true,
          placeholder: 'Search GIPHY',
          imagePlaceholderColor: '#FF6B00',
          loader: <Icon type="loading" />,
          onSelected: onSelectGif,
          children: <Icon className="search-icon" type="search" />,
        }}
      />
    </GiftSelector>
  )

  const handleGifSelect = (gif) => {
    const { send, thread_id, id, refetch, appendMessage, handleGifSelection } = props
    let gifVersion = gif.downsized
    if (!!!gifVersion.url) {
      gifVersion = gif.downsized_large
    }
    let width = gifVersion.width
    if (width > 250) width = 250

    const message = `<img width="${width}" src="${gifVersion.url}" />`
    if (typeof send === 'function') {
      handleGifSelection(message, thread_id, id, refetch, appendMessage, () => {
        setGifVisible(false)
      })
    }
  }

  const fileSizeReadable = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  const deleteAttachment = (uuid) => {
    const newAttachments = attachments.filter((a) => a.uuid !== uuid)
    localStorage.setItem(LOCAL_STORAGE.CURRENT_ATTACHMENT, JSON.stringify(newAttachments))
    setAttachments(newAttachments)
    setForceRenderUploadCare()
  }

  const maxSize = () => {
    return function(fileInfo) {
      if (!fileInfo.size) {
        return
      }
      if (fileInfo.size > 3200000) {
        message.warning('Max size: 3mb')
        throw new Error('fileMaximumSize')
      }
    }
  }

  return (
    <ContainerTextEditor>
      {error && <small>Please enter a message</small>}
      {!loading && (
        <>
          <div className="wrap-editor">
            <ReactQuill
              placeholder="Type to send message"
              onChange={handleChange}
              modules={TextEditor.modules}
              formats={TextEditor.formats}
              defaultValue={editorHtml}
              ref={quillRef}
              value={editorHtml}
              dangerouslySetInnerHTML={{ __html: editorHtml }}
            />
            {attachments && attachments.length > 0 && (
              <div className="attachments">
                {attachments.map((item) => {
                  const sizeReadable = fileSizeReadable(item.size)
                  return (
                    <div className="attachment-item" key={item.uuid}>
                      <div className={`preview ${item.isImage ? 'image' : 'icon'}`}>
                        {item.isImage && <img src={item.cdnUrl} alt="" />}
                        {!item.isImage && <Icon type="file" theme="filled" />}
                      </div>
                      <div className="detail">
                        <p className="name">{item.name}</p>
                        <p className="size">{sizeReadable}</p>
                      </div>
                      <div className="actions">
                        <Icon onClick={() => deleteAttachment(item.uuid)} type="delete" theme="filled" />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <div id="quill-toolbar" className="buttons-container">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" />
            <select className="ql-color">
              <option value="#E53D2F" />
              <option value="#6FCF97" />
              <option value="#2EA3F2" />
              <option value="#FF6B00" />
            </select>
            <button className="ql-video" />
            <Widget
              key={forceRenderUploadCare}
              ref={uploadCareWidgetApi}
              publicKey={UPLOADS.UPLOADCARE_PUBLIC_KEY}
              imageShrink={UPLOADS.UPLOADCARE_SHRINK}
              id="file"
              name="file"
              tabs="file url gdrive dropbox"
              previewStep="true"
              onFileSelect={handleFileSelected}
              validators={[maxSize()]}
              localeTranslations={translations}
            />
            <button className="ql-emoji" />
            <Popover
              content={gifContainer(handleGifSelect)}
              overlayClassName="chat-container"
              visible={gifVisible}
              trigger="click"
              onVisibleChange={(visible) => setGifVisible(visible)}
            >
              <GifButton>GIF</GifButton>
            </Popover>
            <Tooltip placement="rightTop" title="Send message">
              <StyledButton onClick={handleSendMessage}>
                <img src={sendIcon} alt="send_icon" />
              </StyledButton>
            </Tooltip>
          </div>
        </>
      )}
    </ContainerTextEditor>
  )
}

TextEditor.modules = {
  toolbar: {
    container: '#quill-toolbar',
  },
  'emoji-toolbar': true,
}

TextEditor.formats = ['header', 'bold', 'italic', 'underline', 'list', 'link', 'align', 'emoji', 'color', 'video']

export default TextEditor
