import React, { Component, Fragment } from 'react'
import { Query, Mutation } from 'react-apollo'

import moment from 'moment'
import _ from 'lodash'
import { Form, Select, Icon } from 'antd'
import SubmitButton from 'components/SubmitButton'

import { dayTypes, measurementsDisplayMap, getDailyEntries, upsertMacroEntry } from './data'
import { getBodyFatPercentage } from 'services'
import { StyledInput, StyledSelect, StyledTextArea } from 'styled'
import { Root, Wrap, TimelineWrap, StyledForm } from './Timeline.styled'
import CronometerButtonSync from 'components/Cronometer/CronometerButtonSync'

import TitleIcon from 'images/icon-macro-training.svg'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as WarningIcon } from 'images/icon-warning.svg'

const { Item: FormItem } = Form
const { Option } = Select

class WeekDays extends Component {
  getDayIcon = (weekDay, dailies) => {
    if (!weekDay.IsFuture) {
      const hasData = !!dailies.find((day) => day.for_date === weekDay.ForDate)
      if (hasData) {
        return <CheckIcon />
      }
      return <WarningIcon />
    }
    return <span />
  }

  render() {
    const { dailies, days, onSelect } = this.props

    return (
      <div className="week-days-container">
        <div className="week-days-top">
          {days.map((weekDay) => {
            return (
              <Fragment key={weekDay.ForDate}>
                <div onClick={() => onSelect(weekDay)} className={`${weekDay.className}`}>
                  <p>{weekDay.DayName.toUpperCase()}</p>
                  <p>
                    <b>{weekDay.DayNumber}</b>
                  </p>
                </div>
              </Fragment>
            )
          })}
        </div>
        <div className="week-days-bottom">
          {days.map((weekDay) => {
            return (
              <Fragment key={weekDay.ForDate}>
                <div onClick={() => onSelect(weekDay)} className={`${weekDay.className}`}>
                  <p className="icon">{this.getDayIcon(weekDay, dailies)}</p>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    )
  }
}

class Timeline extends Component {
  state = {
    showSaveSuccess: false,
    activeDay: null,
    showNotes: false,
  }

  getWeekDays = (forDate) => {
    let weekDays = []

    const startOfWeek = moment(moment(forDate).startOf('isoWeek'))
    const startOfWeekAux = moment(moment(forDate).startOf('isoWeek'))
    const endOfWeek = moment(moment(forDate).endOf('isoWeek'))

    while (!startOfWeek.isAfter(endOfWeek, 'day')) {
      const date = moment(startOfWeek)
      let day = {
        ForDate: date.format('YYYYMMDD'),
        DayNumber: date.format('DD'),
        DayName: date.format('dd'),
        IsFuture: date.isAfter(moment().local()),
        IsStartDate: date.isSame(startOfWeekAux, 'day'),
        IsEndDate: date.isSame(endOfWeek, 'day'),
        className: 'day',
      }

      day.className += day.IsFuture ? ' is-future' : ''
      if (!!this.state.activeDay) day.className += day.ForDate === this.state.activeDay ? ' active' : ''
      else day.className += day.ForDate === forDate ? ' active' : ''

      weekDays.push(day)
      startOfWeek.add(1, 'day')
    }

    return weekDays
  }

  getWarning = (input, inputValue, compareValue, macroTable, lastBfPct) => {
    let result = {
      validateStatus: null,
      help: null,
    }

    if (!!!inputValue) return result

    switch (input) {
      case 'weight':
        if (!!compareValue) {
          let showWarning = Math.abs(((inputValue - compareValue) / compareValue) * 100) > 30
          if (showWarning) {
            result.validateStatus = 'warning'
            result.help = (
              <span className="warning-cont">
                <span className="icon-container">
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
                <span>This is very different from last value! Are you using a different unit of measurement? Did you mistype your entry?</span>
              </span>
            )
          }
        }
        break
      case 'prot':
        if (!!macroTable) {
          let proteinCompare = macroTable.protein_total_grams
          if (!(proteinCompare - 10 <= inputValue && inputValue <= proteinCompare + 10)) {
            result.validateStatus = 'warning'
            if (inputValue > proteinCompare) {
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your protein intake is high by more than 10g.</span>
                </span>
              )
            } else {
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your protein intake is low by more than 10g.</span>
                </span>
              )
            }
          }
        }
        break
      case 'carbs':
        if (!!macroTable) {
          let carbsCompare = macroTable.carbs_total_grams
          if (!(carbsCompare - 5 <= inputValue && inputValue <= carbsCompare + 5)) {
            result.validateStatus = 'warning'
            if (inputValue > carbsCompare) {
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your carbohydrate intake is high by more than 5g.</span>
                </span>
              )
            } else {
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your carbohydrate intake is low by more than 5g.</span>
                </span>
              )
            }
          }
        }
        break
      case 'fat':
        if (!!macroTable) {
          let fatCompare = macroTable.fat_total_grams
          if (!(fatCompare - 5 <= inputValue && inputValue <= fatCompare + 5)) {
            result.validateStatus = 'warning'
            if (inputValue > fatCompare) {
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your fat intake is high by more than 5g.</span>
                </span>
              )
            } else
              result.help = (
                <span className="warning-cont">
                  <span className="icon-container">
                    <Icon type="exclamation-circle" theme="filled" />
                  </span>
                  <span>Your fat intake is low by more than 5g.</span>
                </span>
              )
          }
        }
        break
      case 'bf_pct':
        {
          let maxValue = this.calcPercentSkulptScan(lastBfPct)
          if (inputValue < 0) {
            result.validateStatus = 'error'
            result.help = (
              <span className="warning-cont">
                <span className="icon-container">
                  <Icon type="close-circle" theme="filled" />
                </span>
                <span>Your body fat percentage can not be less than 0%.</span>
              </span>
            )
          } else if (inputValue > 100) {
            result.validateStatus = 'error'
            result.help = (
              <span className="warning-cont">
                <span className="icon-container">
                  <Icon type="close-circle" theme="filled" />
                </span>
                <span>Your body fat percentage can not be greater than 100%.</span>
              </span>
            )
          } else if (lastBfPct !== null && inputValue > maxValue) {
            result.validateStatus = 'warning'
            result.help = (
              <span className="warning-cont">
                <span className="icon-container">
                  <Icon type="exclamation-circle" theme="filled" />
                </span>
                <span>Your body fat percentage can not be 25% higher than the last record.</span>
              </span>
            )
          }
        }
        break
      default:
        break
    }
    return result
  }

  validateSkulptScan = (lastBfPct, id) => (rule, value, callback) => {
    if (id === 'bf_pct') {
      let maxValue = this.calcPercentSkulptScan(lastBfPct)
      if (value > 100 || value < 0) {
        return callback('Error message')
      } else if (lastBfPct !== null && value > maxValue) {
        return callback('Error message')
      }
    }

    callback()
  }

  calcPercentSkulptScan = (lastBfPct) => {
    //Max 25%
    let value
    if (lastBfPct !== null) {
      const valuePercent = Math.round(lastBfPct * 0.25)
      value = lastBfPct + valuePercent
    }
    return value
  }

  handleSelectDay = (weekDay) => {
    const {
      form: { resetFields, validateFields },
    } = this.props

    if (!weekDay.IsFuture && this.state.activeDay !== weekDay.ForDate) {
      this.setState(
        {
          activeDay: weekDay.ForDate,
          showSaveSuccess: false,
        },
        () => {
          validateFields()
          resetFields()
        },
      )
    }
  }

  handleFormItemChange = () => {
    this.setState({ showSaveSuccess: false })
  }

  toggleShowNotes = () => {
    this.setState({
      showNotes: !this.state.showNotes,
    })
  }

  handleSubmit = (upsert, defaultBfPct, metric, refetch, onChange) => (event) => {
    event.preventDefault()

    const {
      forDate,
      traineeId,
      form: { validateFieldsAndScroll, resetFields },
    } = this.props
    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, values) => {
        if (error) {
          console.error(error)
          reject()
          return
        }
        let bf_method = 'Yes, by using Skulpt'

        if (!values.bf_pct) {
          values.bf_pct = defaultBfPct
          bf_method = 'No'
        }
        values = _.mapValues(values, (v) => (v === null || v === undefined || v === '' || v === 0 ? null : v))

        upsert({
          variables: {
            data: {
              ...values,
              bf_method,
              metric,
              trainee_id: traineeId,
              for_date: moment(this.state.activeDay || forDate).format('YYYYMMDD'),
            },
          },
        })
          .then(() => {
            refetch()
            resetFields()
            this.setState({ showSaveSuccess: true }, () => {
              if (onChange) onChange()
            })
          })
          .catch(() => {
            resetFields()
          })
      })
    })
  }

  render() {
    const {
      traineeId,
      programId,
      forDate,
      form: { getFieldDecorator, getFieldValue },
      onChange,
    } = this.props
    const { showSaveSuccess, activeDay } = this.state

    let forDateDefault = (!!forDate ? moment(forDate) : moment().local()).format('YYYYMMDD')
    const weekDays = this.getWeekDays(forDateDefault)
    const startDate = weekDays.find((day) => day.IsStartDate).ForDate
    const endDate = weekDays.find((day) => day.IsEndDate).ForDate
    //const programId = 'a5c78f01-29ac-45bb-b197-f94c0f018ff8'
    return (
      <Root>
        <Wrap>
          <Query query={getDailyEntries} variables={{ traineeId, programId, startDate, endDate }} fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading || error) return null
              const trainee = _.get(data, 'trainee[0]') || {}
              const user = _.get(trainee, 'user') || {}
              const traineeDetails = _.get(trainee, 'trainee_details[0]') || {}
              const lastEntryWithWeight = _.get(trainee, 'lastEntryWithWeight[0]') || {}
              const macroTables = _.get(trainee, 'macros[0].macro_tables') || []
              const measurements = _.get(trainee, 'measurement[0]') || {}

              const dailies = _.get(trainee, 'dailyEntries') || []
              const daySelected = dailies.find((day) => day.for_date === (activeDay || forDateDefault)) || {}

              let lastWeight = dailies
                .sort((a, b) => {
                  return b.for_date - a.for_date
                })
                .find((day) => day.weight > 0 && moment(day.for_date).isBefore(daySelected.for_date))
              if (!!lastWeight) lastWeight = lastWeight.weight
              if (!!!lastWeight && !!lastEntryWithWeight) {
                lastWeight = lastEntryWithWeight.weight
              }
              const dayTypeSelected = getFieldValue('day_type') || daySelected.day_type || ''
              let macroToValidate
              if (dayTypeSelected !== '')
                macroToValidate = macroTables.find((table) => table.type === (dayTypeSelected === 'base' ? 'BaseDay' : 'TrainingDay'))

              const units = traineeDetails.metric || 'lbs/in'
              const weightUnit = units && units.includes('kg') ? 'kg' : 'lbs'
              const lengthUnit = units && units.includes('cm') ? 'cm' : 'in'
              const metric = weightUnit.includes('kg')
              const bodyFatPercentage = getBodyFatPercentage(
                user.gender,
                lengthUnit,
                traineeDetails.height,
                measurements.neck,
                measurements.belly_waist,
                measurements.narrow_waist,
                measurements.hip,
              )

              const formItemLayout = {
                labelCol: { span: 11 },
                wrapperCol: { span: 13 },
              }

              let lastBfPct = dailies
                .sort((a, b) => {
                  return b.for_date - a.for_date
                })
                .find((day) => day.bf_pct > 0 && moment(day.for_date).isBefore(daySelected.for_date))
              if (!!lastBfPct) lastBfPct = lastBfPct.bf_pct
              if (!!!lastBfPct && !!lastEntryWithWeight) {
                lastBfPct = lastEntryWithWeight.bf_pct
              }
              return (
                <TimelineWrap>
                  <div className="title">
                    <p>
                      <img src={TitleIcon} alt="" />
                      My Daily Data
                    </p>
                    <CronometerButtonSync configuration={trainee.configuration} />
                  </div>
                  <div className="content">
                    <p className="subtitle">Select a day</p>
                    <WeekDays days={weekDays} forDate={forDateDefault} dailies={dailies} onSelect={this.handleSelectDay} />
                    <Mutation mutation={upsertMacroEntry} key={activeDay}>
                      {(mutation) => {
                        return (
                          <StyledForm layout="horizontal" hideRequiredMark={true}>
                            <p>General:</p>
                            <FormItem label="Type of day" {...formItemLayout}>
                              {getFieldDecorator('day_type', {
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please choose',
                                  },
                                ],
                                onChange: this.handleFormItemChange,
                                initialValue: daySelected.day_type ? daySelected.day_type : '',
                              })(
                                <StyledSelect>
                                  {dayTypes.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </StyledSelect>,
                              )}
                            </FormItem>
                            {measurementsDisplayMap.map(({ id, name, displayUnit, required, message }, ind) => {
                              let initialValue
                              let actualValue = getFieldValue(id) === undefined ? daySelected[id] : getFieldValue(id)
                              let warning = this.getWarning(id, actualValue, lastWeight, macroToValidate, lastBfPct)

                              if (id === 'bf_pct') {
                                initialValue = daySelected.bf_method && daySelected.bf_method.includes('Skulpt') ? daySelected[id] : null
                              } else {
                                initialValue = daySelected ? daySelected[id] : null
                              }

                              return (
                                <Fragment key={id}>
                                  {ind === 2 && <p>Daily Macros:</p>}
                                  <FormItem validateStatus={warning.validateStatus} help={warning.help}>
                                    {getFieldDecorator(id, {
                                      onChange: this.handleFormItemChange,
                                      initialValue,
                                    })(
                                      <StyledInput
                                        className="input-with-fake-label"
                                        name={name}
                                        type="number"
                                        step=".01"
                                        min="0"
                                        max="1000"
                                        custom="veryShort"
                                        addonBefore={<div className="fake-label">{name}</div>}
                                        addonAfter={displayUnit || weightUnit}
                                        autoComplete="off"
                                      />,
                                    )}
                                  </FormItem>
                                </Fragment>
                              )
                            })}
                            <div className="notes">
                              <p className="link-toggle" onClick={() => this.toggleShowNotes()}>
                                Add a Note <Icon type={this.state.showNotes ? 'up' : 'down'} />
                              </p>
                              {this.state.showNotes && (
                                <FormItem label="Include a note about your daily">
                                  {getFieldDecorator('notes', {
                                    onChange: this.handleFormItemChange,
                                    initialValue: daySelected.notes ? daySelected.notes : '',
                                    rules: [
                                      {
                                        max: 250,
                                        message: 'The note can be at most 250 characters',
                                      },
                                    ],
                                  })(<StyledTextArea autosize={{ maxRows: 5 }} />)}
                                  <p className={`counter`}>{getFieldValue('notes').length}/250</p>
                                </FormItem>
                              )}
                            </div>

                            {showSaveSuccess && <p className="success-message">Keep up the good work!</p>}
                            <SubmitButton
                              {...{
                                handleSubmit: this.handleSubmit(mutation, bodyFatPercentage, metric, refetch, onChange),
                                custom: showSaveSuccess ? 'green' : '',
                                className: 'fixed-w',
                                buttonContent: showSaveSuccess ? <Icon height="1.5em" type="check" /> : 'Save changes',
                              }}
                            />
                          </StyledForm>
                        )
                      }}
                    </Mutation>
                  </div>
                </TimelineWrap>
              )
            }}
          </Query>
        </Wrap>
      </Root>
    )
  }
}

export default Form.create()(Timeline)
