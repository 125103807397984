import { Form } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import iconPicture from 'images/picture.svg'

export const EnrollmentForm = styled(Form)`
  && {
    width: 100%;
    font-size: 10px;
    @media only screen and (max-width: 320px) {
      max-width: 100%;
    }
    &.daily {
      background-color: #ffffff;
      padding: 20px 10px 1px;
    }
    .ant-form-item {
      font-family: 'Montserrat', sans-serif;
    }
    .ant-form-item-label {
      line-height: inherit;
      padding-bottom: 0;
    }
    label {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      color: #4a4a4a;
    }
    .notation {
      font-size: 9px;
      color: #a6a6a6;
    }
    .description {
      color: #a6a6a6;
      line-height: 11px;
    }
    .ant-input {
      font-family: 'Montserrat', sans-serif;
      font-size: 9px;
    }
    .uploadPic {
      position: relative;
      .ant-btn {
        margin: 0 auto 9px;
        padding: 10px;
        text-align: center;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 210px;
      height: 210px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      background-color: #fff;
      border: 1px solid #cfcfcf;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-image: url(${iconPicture});
      background-size: 150px;
      background-repeat: no-repeat;
      background-position: top center;

      ${({ background }) =>
        background &&
        `
          background-image: url(${background});
          background-size: auto;
          background-position: center center;
        `}
    }
    .ant-upload-list-picture-card {
      float: none;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      float: none;
      width: 210px;
      height: 210px;
      margin: auto;
    }
    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      all: unset;
    }
    .ant-form-extra {
      padding-top: 8px;
      font-size: 9px;
    }
    .ant-form-explain {
      padding-top: 8px;
      font-size: 9px;
      font-weight: 600;
      text-align: left;
      color: #5d5d5d !important;
      .warning-cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-container {
          width: 20%;
          font-size: 18px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #faad14;
        }
      }
    }
  }
  ${({ custom }) => {
    if (custom === 'short') {
      return css`
        && {
          padding-left: 40px;
          padding-right: 40px;
        }
      `
    }
  }}
`

export const EnrollmentContent = styled.div`
  max-width: 400px;
  margin: 0 auto;
  border-radius: 4px;
  background: #f8f9fa;
  padding: 0px;
  margin-bottom: 10px;
  ${({ edit }) =>
    edit &&
    css`
      padding-top: 70px;
    `}
`

export const EnrollmentWrap = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  padding: 0px 20px 0px 20px;
`

export const EnrollmentTitle = styled.h2`
  width: 100%;
  font-size: 15px;
  color: #323232;
  padding: 0px;
  border-radius: 4px;
  padding: 12px 20px;
  background-color: #fbfbfb;
  box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
  border-left: 5px solid #ff6b00;
`
