import React, { useRef, useState, useEffect } from 'react'
import { CardUnits } from './PreferredUnits.styled'
import { Switch } from 'antd'

const PreferredUnits = ({ title, text, weightUnit, handlePreferredUnits }) => {
  const lbRef = useRef()
  const kgRef = useRef()
  const [unitMeasurements, setUnitMeasurements] = useState(weightUnit)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    localStorage.setItem('PREFERRED_UNITS', weightUnit)
    setUnitMeasurements(weightUnit)
    setLoading(false)
  }, [weightUnit])

  const handleToggle = () => {
    let newUnitMeasurement = unitMeasurements === 'LB' ? 'KG' : 'LB'
    lbRef.current.classList.toggle('change-units')
    kgRef.current.classList.toggle('change-units')
    setUnitMeasurements(newUnitMeasurement)
    localStorage.setItem('PREFERRED_UNITS', newUnitMeasurement)
    handlePreferredUnits(newUnitMeasurement)
  }
  if (loading) return null
  return (
    <CardUnits>
      <div className="container-pref-units">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <div className="opt-measurements">
        <span ref={lbRef} className={unitMeasurements === 'KG' ? 'change-units' : ''}>
          Lb
        </span>
        <span ref={kgRef} className={unitMeasurements === 'LB' ? 'change-units' : ''}>
          Kg
        </span>
        <Switch checked={localStorage.getItem('PREFERRED_UNITS') === 'KG' ? true : false} onClick={handleToggle} />
      </div>
    </CardUnits>
  )
}

export default PreferredUnits
