import React, { Component } from 'react'
import { Redirect } from '@reach/router'
import { Query, Mutation } from 'react-apollo'
import moment from 'moment'
import _ from 'lodash'
import { getWeekArray, getDateReview } from 'services'
import { getWeeklys, upsertWeekly } from './queries'
import { setContactInfusionSoftTag } from 'queries'
import { Form, Collapse, Icon, message } from 'antd'
import Layout from 'components/Layout'
import WeeklyMeasurementsEdit from './WeeklyMeasurementsEdit'
import WeeklyIssuesEdit from './WeeklyIssuesEdit'
import WeeklyMessagesEdit from './WeeklyMessagesEdit'
import WeeklyPhotosEdit from './WeeklyPhotosEdit'
import { StyledSectionBanner, StyledCollapse, StyledDropdown } from 'styled'
import { Wrap, Content, LastWeekly } from './WeeksEdit.styled'

import { ReactComponent as CalendarIcon } from '../../images/icon-calendar.svg'
import SubmitButton from '../SubmitButton'
import { MEASUREMENTS_DISPLAY_MAP } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const Panel = Collapse.Panel
const { currentWeekStart } = getDateReview()

const getCollapseTitle = (title, isComplete) => {
  return (
    <div>
      <span className={`status ${isComplete ? 'success' : ''}`}>
        {isComplete && <Icon type="check" />}
        {!isComplete && <Icon type="exclamation" />}
      </span>
      <span>{title}</span>
    </div>
  )
}

class WeeksEdit extends Component {
  state = {
    activeWeekly: null,
    frontPicture: null,
    backPicture: null,
    sidePicture: null,
  }

  isWeeklyComplete = (week, picturesInWeek) => {
    let measurementsComplete =
      !!week.neck &&
      week.neck > 0 &&
      !!week.chest &&
      week.chest > 0 &&
      !!week.shoulder &&
      week.shoulder > 0 &&
      !!week.arm &&
      week.arm > 0 &&
      !!week.forearm &&
      week.forearm > 0 &&
      !!week.belly_waist &&
      week.belly_waist > 0 &&
      !!week.narrow_waist &&
      week.narrow_waist > 0 &&
      !!week.hip &&
      week.hip > 0 &&
      !!week.thigh &&
      week.thigh > 0 &&
      !!week.calf &&
      week.calf > 0

    let adherenceAndIssuesComplete =
      (!!week.macro_adherence && week.macro_adherence > 0) ||
      (!!week.training_adherence && week.training_adherence > 0) ||
      (!!week.sleep_issues && week.sleep_issues > 0) ||
      (!!week.hunger_issues && week.hunger_issues > 0) ||
      (!!week.fatigue && week.fatigue > 0) ||
      (!!week.lethargy && week.lethargy > 0) ||
      (!!week.stress && week.stress > 0)

    let weekReviewComplete =
      (!!week.desc_well && !!week.desc_well.trim()) ||
      (!!week.desc_bad && !!week.desc_bad.trim()) ||
      (!!week.desc_improve && !!week.desc_improve.trim())

    let photosComplete = picturesInWeek.front_url && picturesInWeek.side_url && picturesInWeek.back_url
    let complete = measurementsComplete && adherenceAndIssuesComplete && weekReviewComplete

    return {
      measurementsComplete,
      adherenceAndIssuesComplete,
      weekReviewComplete,
      photosComplete,
      complete,
      icon: complete ? 'check' : 'exclamation',
      iconClass: complete ? 'success' : '',
    }
  }

  formatWeeklys = (weeks, measurements, pictures) => {
    return weeks.map((week, index) => {
      const weekSelected = measurements.find((m) => m.for_date === week) || { for_date: week }
      const picturesInWeek = pictures.find((p) => p.for_date === weekSelected.for_date) || {}
      const weeklyValidation = this.isWeeklyComplete(weekSelected, picturesInWeek)
      const startDateWeek = moment(weekSelected.for_date).format('MMM DD')
      const endDateWeek = moment(weekSelected.for_date)
        .endOf('isoWeek')
        .format('MMM DD')
      const weekMerged = _.merge(weekSelected, weeklyValidation)

      return {
        ...weekMerged,
        pictures: picturesInWeek,
        id: weekMerged.for_date,
        label: `Weekly ${index + 1}`,
        number: index + 1,
        description: `${startDateWeek} - ${endDateWeek}`,
      }
    })
  }

  handleWeeklyChange = (value) => {
    const {
      form: { resetFields },
    } = this.props

    this.setState(
      {
        activeWeekly: value.key,
      },
      () => {
        resetFields()
      },
    )
  }

  handlePictureSelected = (pictureName, url) => {
    this.setState({
      [pictureName]: url,
    })
  }

  handleSubmit = (mutation, refetch, traineeId, weekly, programId, contactId) => (event) => {
    event.preventDefault()

    const {
      form: { validateFieldsAndScroll },
    } = this.props

    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, values) => {
        if (error) {
          console.error(error)
          reject('Validation')
          return
        }

        const weeklyValidation = this.isWeeklyComplete(values, {})
        MEASUREMENTS_DISPLAY_MAP.forEach((field) => {
          if (values[field.id] === null || values[field.id] === undefined || values[field.id] === '' || values[field.id] === 0) {
            values[field.id] = null
          }
        })

        if (weeklyValidation.measurementsComplete || weeklyValidation.adherenceAndIssuesComplete || weeklyValidation.weekReviewComplete) {
          mutation({
            mutation: upsertWeekly,
            variables: {
              dataMeasurements: {
                ...values,
                trainee_id: traineeId,
                for_date: weekly.for_date,
              },
              dataPictures: {
                front_url: this.state.frontPicture || weekly.pictures.front_url,
                side_url: this.state.sidePicture || weekly.pictures.side_url,
                back_url: this.state.backPicture || weekly.pictures.back_url,
                trainee_id: traineeId,
                for_date: weekly.for_date,
              },
            },
          })
            .then((data) => {
              localStorage.removeItem(`WeeklyMessage.${weekly.for_date}.desc_well`)
              localStorage.removeItem(`WeeklyMessage.${weekly.for_date}.desc_bad`)
              localStorage.removeItem(`WeeklyMessage.${weekly.for_date}.desc_improve`)
              localStorage.removeItem(`WeeklyMessage.${weekly.for_date}.desc_other`)
              message.success('Weekly saved!')

              if (weeklyValidation.weekReviewComplete) {
                const infusionSoftTag = `Weekly${weekly.number}`
                setContactInfusionSoftTag(contactId, programId, [infusionSoftTag])
              }
              refetch()
              resolve()
            })
            .catch((error) => {
              reject('Mutation not executed')
            })
        } else {
          message.warning('Please complete at least one section.')
          reject('Validation')
        }
      })
    })
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      last,
    } = this.props

    const bannerDescription = `Your weekly information is vital for tracking your progress, please take the time to ${
      last ? 'complete it' : 'update them'
    }.`

    const buttonText = last ? 'Save Weekly' : 'Save Changes'

    return (
      <Layout>
        <Wrap>
          <StyledSectionBanner icon={<CalendarIcon />} title="My Weekly" description={bannerDescription} />
          <GlobalContext.Consumer>
            {({ user, programs }) => {
              const currentProgram = programs.find((p) => p.is_current) || {}
              const closingProgram = programs.find((p) => p.is_closing) || {}

              if (!currentProgram.program_id && !closingProgram.program_id) return <Redirect to="/" noThrow />

              let startDate
              if (currentProgram.start_date)
                startDate = moment(currentProgram.start_date)
                  .local()
                  .format('YYYYMMDD')
              else if (!!!startDate && closingProgram.start_date)
                startDate = moment(closingProgram.start_date)
                  .local()
                  .format('YYYYMMDD')

              let currentDuration
              if (currentProgram.duration) currentDuration = currentProgram.duration
              else if (!currentProgram.duration && closingProgram.duration) currentDuration = closingProgram.duration

              let programId = currentProgram.program_id
              if (!currentProgram.program_id && closingProgram.program_id) programId = closingProgram.program_id

              const unitsLength = user.units.split('/')[1]
              const weeks = getWeekArray(moment(startDate), moment(currentWeekStart), currentDuration)
              const activeWeekly = this.state.activeWeekly || weeks[weeks.length - 1]
              const endDate = moment(startDate)
                .add(currentDuration, 'week')
                .format('YYYYMMDD')
              console.log('weeks.length :>> ', weeks.length)
              if (weeks.length <= 0) return <Redirect to="/" noThrow />

              return (
                <Query query={getWeeklys} variables={{ traineeId: user.trainee_id, startDate, endDate }}>
                  {({ loading, error, data, refetch }) => {
                    if (loading || error) return null

                    const measurementsDB = _.get(data, 'measurement') || []
                    const picturesDB = _.get(data, 'pictures') || []
                    const measurements = this.formatWeeklys(weeks, measurementsDB, picturesDB)
                    const measurementData = measurements.find((m) => m.for_date === activeWeekly) || {}

                    const prevMeasurementData =
                      JSON.parse(JSON.stringify(measurements))
                        .sort((a, b) => b.for_date - a.for_date)
                        .find((m) => moment(m.for_date).isBefore(activeWeekly)) || {}

                    return (
                      <>
                        {!!last && (
                          <LastWeekly>
                            <h3>
                              {measurementData.label} <small>{measurementData.description}</small>
                            </h3>
                          </LastWeekly>
                        )}
                        <Content>
                          {!!!last && (
                            <>
                              <p>Choose a Weekly</p>
                              <StyledDropdown
                                label={measurementData.label}
                                description={measurementData.description}
                                onSelect={this.handleWeeklyChange}
                                iconStatus={
                                  <Icon
                                    className={`status ${measurementData.complete ? 'success' : ''}`}
                                    type={measurementData.complete ? 'check' : 'exclamation'}
                                  />
                                }
                                options={measurements}
                              />
                              <hr />
                              <p>Update Your Weekly</p>
                            </>
                          )}
                          <Mutation mutation={upsertWeekly}>
                            {(mutation) => {
                              return (
                                <Form layout="horizontal">
                                  <StyledCollapse {...{ accordion: true, expandIcon: StyledCollapse.expandIcon }}>
                                    <Panel forceRender={true} header={getCollapseTitle('Measurements', measurementData.measurementsComplete)} key="1">
                                      <WeeklyMeasurementsEdit
                                        {...{ measurementData, unitsLength, prevMeasurementData, getFieldDecorator, getFieldValue }}
                                      />
                                    </Panel>
                                    <Panel
                                      forceRender={true}
                                      header={getCollapseTitle('Adherence and issues', measurementData.adherenceAndIssuesComplete)}
                                      key="2"
                                    >
                                      <WeeklyIssuesEdit {...{ measurementData, getFieldDecorator, gender: user.gender }} />
                                    </Panel>
                                    <Panel
                                      forceRender={true}
                                      header={getCollapseTitle('Your week in review', measurementData.weekReviewComplete)}
                                      key="3"
                                    >
                                      <WeeklyMessagesEdit {...{ measurementData, getFieldDecorator }} />
                                    </Panel>
                                    <Panel forceRender={true} header={getCollapseTitle('Photos', measurementData.photosComplete)} key="4">
                                      <WeeklyPhotosEdit
                                        {...{ pictures: measurementData.pictures, handlePictureSelected: this.handlePictureSelected }}
                                      />
                                    </Panel>
                                  </StyledCollapse>
                                  <Form.Item>
                                    <SubmitButton
                                      {...{
                                        buttonContent: buttonText,
                                        handleSubmit: this.handleSubmit(
                                          mutation,
                                          refetch,
                                          user.trainee_id,
                                          measurementData,
                                          programId,
                                          user.contact_id,
                                        ),
                                      }}
                                    />
                                  </Form.Item>
                                </Form>
                              )
                            }}
                          </Mutation>
                        </Content>
                      </>
                    )
                  }}
                </Query>
              )
            }}
          </GlobalContext.Consumer>
        </Wrap>
      </Layout>
    )
  }
}

export default Form.create()(WeeksEdit)
