import styled from '@emotion/styled'

export const Root = styled.div`
  height: 60vh;
  margin: 10px auto 0;
  padding-right: 20px;
  overflow-y: auto;
`

export const MessageList = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
  font-size: 11px;
  color: #5d5d5d;
`

export const Message = styled.li`
  width: max-content;
  max-width: 80%;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 10px;
  text-align: left;
  background-color: #f3f3f3;
  border-radius: 7px;
  word-wrap: break-word;

  &.trainee {
    margin-left: auto;
  }

  &.coach {
    margin-left: 0;
    margin-right: auto;
  }

  &.unread {
    border: thin solid #ff6b00;
  }

  img {
    max-width: 100%;
  }
`

export const Answer = styled.div`
  padding: 5px 0 0;

  p {
    margin-bottom: 1em;
  }

  .attachments {
    .attachment-item {
      &.file {
        display: flex;
        flex-direction: row;
        align-items: center;

        .preview {
          min-width: 36px;
          max-width: 36px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #979797;
          border-radius: 4px;
          flex-grow: 1;
          padding: 8px;
          text-align: center;

          img {
            width: 13px;
            height: 13px;
          }
        }

        .detail {
          margin-left: 10px;
          flex-grow: 2;

          p {
            margin-bottom: 0px;
            overflow: hidden;
            max-width: 140px;
            white-space: nowrap;
            text-overflow: ellipsis;

            a,
            a:hover,
            a:visited {
              font-weight: 600;
              color: #5d5d5d;
            }
          }
        }
      }

      &.image {
        img {
          border: 1px solid #979797;
          border-radius: 4px;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 0px;
          overflow: hidden;
          max-width: 140px;
          white-space: nowrap;
          text-overflow: ellipsis;

          a,
          a:hover,
          a:visited {
            font-weight: 600;
            color: #5d5d5d;
          }
        }
      }
    }
  }

  iframe {
    width: 100%;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
`

export const Date = styled.span`
  display: block;
  padding: 3px;
  text-align: right;
  color: #959595;
  font-size: 8px;
  font-weight: 300;
  text-align: left;
`

export const ReadBlock = styled.div`
  display: block;
  height: 1px;
  width: 100%;
`
