import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { PageHeader } from 'antd'

export const CstPageHeader = styled(PageHeader)`
  width: 100%;
  .ant-page-header-heading {
    display: flex;
    ${({ isBack }) => {
      if (!isBack) {
        return css`
          flex-direction: row-reverse;
          .anticon.anticon-close {
            color: #93a6d7;
          }
          .ant-page-header-heading-title {
            padding-right: 0px;
            padding-left: 12px;
          }
        `
      }
    }}
    .ant-page-header-back {
      margin-right: 0px;
    }
    align-items: center;
    .anticon.anticon-left {
      color: #ff6b00;
      border-radius: 1px;
    }
    .ant-page-header-heading-title {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 19px;
      color: #4a4a4a;
    }
  }
`
