import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary.js'
import { CheckboxList, StyledCollapse } from 'styled'
import { Collapse, Input } from 'antd'
const { Panel } = Collapse
const { TextArea } = Input
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [generalCatalog, setGeneralCatalog] = useState()
  const [gastrointestinalCatalog, setGastrointestinalCatalog] = useState()
  const [eatingDisordersCatalog, setEatingDisordersCatalog] = useState()
  const [reproductiveHealthCatalog, setReproductiveHealthCatalog] = useState()

  const [general, setGeneral] = useState([])
  const [gastrointestinal, setGastrointestinal] = useState([])
  const [eatingDisorders, setEatingDisorders] = useState([])
  const [reproductiveHealth, setReproductiveHealth] = useState([])
  const [hasMedicalConditions, setHasMedicalConditions] = useState([])
  const [anotherMedicalCondition, setAnotherMedicalCondition] = useState(null)
  const [prescription, setPrescription] = useState()
  const [medicalConditionsSelected, setMedicalConditionsSelected] = useState()
  const [keys, setKeys] = useState()

  const questionRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { general, gastrointestinal, eating_disorders, reproductive_health } = response.catalogs.medical_conditions
        const { id, has_medical_conditions, another_med_cond, prescription_med } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setGeneralCatalog(mutateCatalog(general))
          setGastrointestinalCatalog(mutateCatalog(gastrointestinal))
          setEatingDisordersCatalog(mutateCatalog(eating_disorders))
          setReproductiveHealthCatalog(mutateCatalog(reproductive_health))

          const medicalConditions = response.enrollment.data.medical_conditions
          setGeneral(medicalConditions.general ? medicalConditions.general : [])
          setGastrointestinal(medicalConditions.gastrointestinal ? medicalConditions.gastrointestinal : [])
          setEatingDisorders(medicalConditions.eating_disorders ? medicalConditions.eating_disorders : [])
          setReproductiveHealth(medicalConditions.reproductive_health ? medicalConditions.reproductive_health : [])
          setHasMedicalConditions(has_medical_conditions)
          setAnotherMedicalCondition(another_med_cond)
          setPrescription(prescription_med)
          activeKeys(medicalConditions, another_med_cond)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const medicalConditions = general
      .concat(gastrointestinal)
      .concat(eatingDisorders)
      .concat(reproductiveHealth)
    setMedicalConditionsSelected(medicalConditions)

    if (medicalConditions.length > 0 || !!anotherMedicalCondition) {
      setHasMedicalConditions(true)
    }
    validate()
  }, [general, gastrointestinal, eatingDisorders, reproductiveHealth, anotherMedicalCondition])

  useEffect(() => {
    if (hasMedicalConditions === false) {
      setGeneral([])
      setGastrointestinal([])
      setEatingDisorders([])
      setReproductiveHealth([])
      setAnotherMedicalCondition()
      setKeys([])
    }
    validate()
  }, [hasMedicalConditions])

  const activeKeys = (medicalConditions, another_med_cond) => {
    let keys = []
    for (const key in medicalConditions) {
      if (medicalConditions.hasOwnProperty(key) && medicalConditions[key]) {
        keys.push(key)
      }
    }
    if (another_med_cond) {
      keys.push('another_med_cond')
    }
    setKeys(keys)
  }

  const mutateCatalog = (catalog) => {
    return catalog.map((c) => {
      return {
        label: c.label,
        value: c.id,
      }
    })
  }

  const saveCallback = async ({ goBack }) => {
    const data = {
      medical_conditions: medicalConditionsSelected,
      has_medical_conditions: hasMedicalConditions,
      another_med_cond: anotherMedicalCondition ? anotherMedicalCondition : null,
      prescription_med: prescription,
    }

    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = typeof hasMedicalConditions === 'boolean'
    if (hasMedicalConditions) {
      isValid =
        isValid &&
        (general.length > 0 ||
          gastrointestinal.length > 0 ||
          eatingDisorders.length > 0 ||
          reproductiveHealth.length > 0 ||
          !!anotherMedicalCondition)
    } else {
      isValid = isValid && hasMedicalConditions === false
    }

    if (isValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(questionRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'lifestyle' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={questionRef}>
            {submit &&
              (medicalConditionsSelected.length <= 0 &&
                !anotherMedicalCondition &&
                (hasMedicalConditions === true || hasMedicalConditions === null)) && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">
                Please read carefully the following medical conditions and select the ones that apply to you or select "I don't have any medical
                conditions."
              </p>
            </div>
            <div className="content">
              <StyledCollapse expandIcon={StyledCollapse.expandIcon} defaultActiveKey={keys}>
                <div style={{ margin: '5px 0px 20px', background: '#fff' }}>
                  <BtnPrimary
                    style={{ background: 'transparent', color: '#717271', border: '2px solid #717271' }}
                    text="I don't have any medical conditions"
                    className={hasMedicalConditions === false ? 'active' : ''}
                    onClick={() => setHasMedicalConditions((prev) => (typeof prev === 'boolean' ? !prev : false))}
                  />
                </div>
                <Panel header="General Medical Conditions" key="general">
                  <CheckboxList
                    value={general}
                    options={generalCatalog}
                    className={hasMedicalConditions === false ? 'fake-disabled' : ''}
                    onChange={(checkedValue) => setGeneral(checkedValue)}
                  />
                </Panel>
                <Panel header="Gastrointestinal Conditions" key="gastrointestinal">
                  <CheckboxList
                    value={gastrointestinal}
                    options={gastrointestinalCatalog}
                    className={hasMedicalConditions === false ? 'fake-disabled' : ''}
                    onChange={(checkedValue) => setGastrointestinal(checkedValue)}
                  />
                </Panel>
                <Panel header="Eating Disorders" key="eating_disorders">
                  <CheckboxList
                    value={eatingDisorders}
                    options={eatingDisordersCatalog}
                    className={hasMedicalConditions === false ? 'fake-disabled' : ''}
                    onChange={(checkedValue) => setEatingDisorders(checkedValue)}
                  />
                </Panel>
                <Panel header="Reproductive Health Conditions" key="reproductive_health">
                  <CheckboxList
                    value={reproductiveHealth}
                    options={reproductiveHealthCatalog}
                    className={hasMedicalConditions === false ? 'fake-disabled' : ''}
                    onChange={(checkedValue) => setReproductiveHealth(checkedValue)}
                  />
                </Panel>
                <Panel header="I have a medical condition or diagnosis not addressed above" key="another_med_cond">
                  <TextArea
                    value={anotherMedicalCondition}
                    onChange={(e) => setAnotherMedicalCondition(e.target.value)}
                    maxLength={500}
                    rows={8}
                    placeholder="Please explain"
                    autoSize={{ minRows: 8, maxRows: 8 }}
                    className={hasMedicalConditions === false ? 'fake-disabled' : ''}
                  />
                </Panel>
              </StyledCollapse>
            </div>
          </QuestionContainer>
          <QuestionContainer>
            <div className="question">
              <p className="title">
                Do you take any prescription medications? <span>(Optional)</span>
              </p>
              <p>Please include names, purpose, etc.</p>
            </div>
            <div className="content">
              <TextArea
                value={prescription}
                onChange={(e) => setPrescription(e.target.value)}
                maxLength={500}
                rows={8}
                placeholder="Type here..."
                autoSize={{ minRows: 8, maxRows: 8 }}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
