import React from 'react'
import { FirstModal, SpanText } from './InitialInfoModal.styled'
import { Button } from 'antd'

const InitialInfoModal = ({ firstModalVisible, setFirstModalVisible }) => {
  let validationModal = firstModalVisible

  const handleOk = () => {
    let response = localStorage.setItem('FIRST_TIME_EXERCISES', false)
    setFirstModalVisible(response)
  }

  return (
    <FirstModal
      centered
      visible={validationModal}
      footer={null}
      onCancel={() => {
        let response = localStorage.setItem('FIRST_TIME_EXERCISES', false)
        setFirstModalVisible(response)
      }}
    >
      <div className="first-modal-info" style={{ textAlign: 'center', paddingTop: '51px' }}>
        <p style={{ marginBottom: '30px' }}>
          The <SpanText>Exercises section is in Beta phase</SpanText>, we encourage you to try it!
        </p>
        <p>
          You can <SpanText>download</SpanText> the PDF of your{' '}
          <SpanText> Gym Training Program or the Excel version of your Bodyweight Training in the left-side dropdown menu</SpanText>.
        </p>
        <div style={{ width: '100%', padding: '20px 16%' }}>
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>
        </div>
      </div>
    </FirstModal>
  )
}

export default InitialInfoModal
