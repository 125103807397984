import styled from '@emotion/styled'
import ChatGroupBanner from '../../images/chat-group-banner.svg'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  padding-top: 56px;
`
export const Wrap = styled.div`
  background: #fff;
  border-radius: 3px;
  min-width: 320px;
  width: 100%;
  background: #f8f9fa;
`
export const Content = styled.div`
  height: 100%;
  min-height: 100%;
  background: #ffffff;
  max-width: 460px;
  margin: 0 auto;
  padding: 10px 4px 10px;
  .content-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
`
export const SubTitle = styled.h3`
  margin: 0 0 5px 0;
  color: #ff6b00;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  color: #ff6b00;
`
export const GroupBanner = styled.div`
  max-width: 460px;
  margin: 0 auto;
  background-image: url(${ChatGroupBanner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;

  p {
    margin: 0;
    line-height: 1.3;
    font-size: 12px;
  }

  img {
    width: 40px;
    height: 30px;
    margin-right: 10px;
  }

  .ant-btn-link {
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      color: #ffffff;
    }
  }

  .anticon {
    width: 50px;
    cursor: pointer;
  }
`

export const GroupPopoverContent = styled.div`
  background: #ffffff;
  padding: 5px;
  max-width: 210px;
  border-radius: 4px;

  p {
    color: #5d5d5d;
    text-align: center;
    font-size: 12px;
    margin: 0;
  }

  .ant-btn {
    background: linear-gradient(90deg, #ff4713 7.81%, #ff7e22 96.3%);
    box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
    border-radius: 17px;
    display: block;
    color: #ffffff;
    font-size: 12px;
    width: 100%;
    line-height: 1;
    height: 24px;
    margin-bottom: 2px;
    border: none !important;

    span {
      margin-top: 6px;
    }

    &:hover {
      border: none !important;
    }

    &:disabled {
      color: #ffffff;
      opacity: 0.7;
    }
  }
`
