import { message } from 'antd'
import { navigate } from '@reach/router'

export const validatingWizardData = (...arg) => {
  const valid = arg.every((value) => !!value)
  if (!valid) {
    message.error("You don't have some data")
    navigate('/enrollment/')
  }
  return valid
}

export const validatingQueryData = (...arg) => {
  const valid = arg.every((value) => !!value)
  if (!valid) {
    message.error('Sorry, some problems for  connecting with data base')
  }
  return valid
}

export const validatingQueryDataObject = (variables = {}) => {
  const checkableKeys = Object.keys(variables)
  if (checkableKeys.length === 0) {
    message.error(`Sorry, some problems for  connecting with data base.`)
    return false
  }
  const emptyKeys = checkableKeys.filter((key) => !variables[key])
  if (emptyKeys.length === 0) {
    return true
  } else {
    const messageText = emptyKeys.reduce((acc, key) => {
      return acc + `${key} not found. `
    }, 'Sorry, some problems for  connecting with data base. ')
    message.error(messageText, 3)
    return false
  }
}

export const validateField = (inputValue, previousValue) => {
  if (!previousValue) return false
  return Math.abs(((inputValue - previousValue) / previousValue) * 100) > 30
}
