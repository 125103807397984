import React from 'react'
import { CstBanner, CstP } from './Banner.styled'
import { Avatar } from 'antd'

const Banner = ({ name, picture, style, reports, ...rest }) => {
  const handleSpliceName = (name) => {
    let studentName = 'Student'
    if (!!name && name !== 'null') {
      const spaceIndex = name.indexOf(' ')
      studentName = spaceIndex === -1 ? name : name.substr(0, spaceIndex)
    }
    return studentName
  }

  return (
    <CstBanner {...rest} style={style} reports={reports}>
      <Avatar className="sizeAvatar" src={picture} />
      <CstP className="title">
        {reports ? <span className="title">Reports and Progress</span> : <span className="title"> {handleSpliceName(name) + "'s"} training</span>}
      </CstP>
    </CstBanner>
  )
}

export default Banner
