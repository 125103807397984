import React, { Component, Fragment } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import get from 'lodash/get'
import showdown from 'showdown'
import 'intersection-observer' // polyfill for iOS
import Waypoint from 'react-intersection-visible'
import apolloClient from 'apolloClient'
import { Root, MessageList, Message, Answer, Date, ReadBlock } from './Messages.styled'

const converter = new showdown.Converter()

const readMessage = gql`
  mutation($messageId: uuid!) {
    update_message(where: { id: { _eq: $messageId } }, _set: { read: true }) {
      returning {
        id
      }
    }
  }
`

const createMarkup = (body) => {
  return { __html: body }
}

class Messages extends Component {
  initMessage = []

  rootElement = React.createRef()

  componentDidUpdate() {
    if (!!get(this.rootElement, 'current')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.messages.length === this.props.messages.length) {
      return false
    }
    return true
  }

  componentDidMount() {
    const { startBootcamp } = this.props
    this.initMessage = [
      {
        id: 0,
        sender_id: 0,
        timestamp: !!startBootcamp ? moment(startBootcamp) : moment().startOf('year'),
        body: 'Welcome to bootcamp!',
        read: true,
      },
    ]
  }

  readMessages = (messageId) => () => {
    apolloClient.mutate({
      mutation: readMessage,
      variables: {
        messageId,
      },
    })
  }

  getClasses = (senderId, read) => {
    if (senderId === this.props.userID) {
      return 'trainee'
    }
    if (!read && !!senderId) {
      return 'coach unread'
    }
    return 'coach'
  }

  scrollToUnread = (element) => {
    if (!!get(this.rootElement, 'current') && !!get(element, 'offsetTop')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  render() {
    const { messages, userID, loading } = this.props
    return (
      <Root ref={this.rootElement}>
        <MessageList>
          {!loading &&
            messages.map((message) => (
              <Fragment key={message.id}>
                {userID !== message.sender_id && !message.read && (
                  <Waypoint onShow={this.readMessages(message.id)}>
                    <ReadBlock ref={this.scrollToUnread} />
                  </Waypoint>
                )}
                <Message className={this.getClasses(message.sender_id, message.read)}>
                  <Answer dangerouslySetInnerHTML={createMarkup(converter.makeHtml(message.body))} />
                  <Date>{moment(message.timestamp).format('D MMMM HH:mm')}</Date>
                </Message>
              </Fragment>
            ))}
        </MessageList>
      </Root>
    )
  }
}

export default Messages
