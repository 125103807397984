import React from 'react'
import { logout } from 'auth'
import backgroundImage from 'images/sign-in-bg.png'
import { Root } from './Logout.styled'

const Logout = () => {
  logout()
  return <Root bgUrl={backgroundImage} />
}

export default Logout
