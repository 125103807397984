import styled from '@emotion/styled'

export const Message = styled.div`
  width: 100%;
  height: 30px;
  margin-top: 8px;
  background: #ff6b00;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;

  & div {
    height: 100%;
    display: flex;
    align-items: center;
    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 1px 0px;
    }

    & svg {
      height: 17px !important;
      margin: 0px 2px;
    }
  }
`
