import React from 'react'
import { Icon, Collapse, Progress, Tag, Tooltip } from 'antd'
import { Card, MacroCollapse } from './Macros.styled'
import moment from 'moment'
import { Link } from '@reach/router'

import MacroIconRest from '../../images/icon-macro-rest.svg'
import MacroIconTraining from '../../images/icon-macro-training.svg'
import MacroIconAlternate from '../../images/icon-macro-alternate.svg'

const { Panel } = Collapse

const collapseIcon = (isActive, type) => {
  const color = type === 'BaseDay' ? 'blue' : type === 'TrainingDay' ? 'orange' : 'green'
  return (
    <span>
      <Icon className={color} type={isActive ? 'up' : 'down'} />
    </span>
  )
}

const adherenceIcon = (percent) => {
  let iconColor
  if (percent <= 50 || percent > 100) iconColor = 'red'
  else if (percent <= 79) iconColor = 'orange'

  return (
    <div className={`adherence-title ${iconColor}`}>
      <Icon type="exclamation" />
      <span>{percent}%</span>
      <small>Adherence</small>
    </div>
  )
}

function MacroDailies(props) {
  const { dailies, type, totalGrams } = props

  if (!!!dailies || dailies.length <= 0) {
    return (
      <div className="empty-dailies">
        <p>
          <Icon type="info-circle" theme="twoTone" twoToneColor="#FF6B00" />
        </p>
        <p>We can not find your dailies</p>
        <p>
          You can add <Link to="/">here</Link>
        </p>
      </div>
    )
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            <Tooltip
              placement="bottomLeft"
              title={
                <div style={{ fontSize: '9px' }}>
                  <p>This is your steady attachment to your macros intakes. We calculated your total adherence using your last 7 entered dailys.</p>
                </div>
              }
            >
              <Icon type="info-circle" />
            </Tooltip>
            Day
          </th>
          <th>Date</th>
          <th>Adherence</th>
          <th>Intake</th>
        </tr>
      </thead>
      <tbody>
        {dailies.map((daily) => {
          let value
          switch (type) {
            case 'Protein':
            default:
              value = daily.prot
              break
            case 'Carbs (Net)':
              value = daily.carbs
              break
            case 'Fat':
              value = daily.fat
              break
          }
          const percent = (value * 100) / totalGrams
          let strokeColor
          if (percent >= 80 && percent <= 100) strokeColor = '#54D446'
          else if (percent >= 50 && percent <= 79) strokeColor = '#FDAC23'
          else strokeColor = '#E53D2E'
          return (
            <tr key={daily.for_date}>
              <td>
                {moment(daily.for_date)
                  .local()
                  .format('dddd')}
              </td>
              <td className="daily-date">
                {moment(daily.for_date)
                  .local()
                  .format('DD / MMM / YYYY')}
              </td>
              <td>
                {percent > 100 && <Tag color="#E53D2E">OVERFLOW</Tag>}
                {percent <= 100 && <Progress strokeColor={strokeColor} showInfo={false} percent={percent} size="small" />}
              </td>
              <td className="daily-total">{value} g</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function MacroCard(props) {
  const { type, data, parentType, dailies, showDailies } = props
  const color = parentType === 'TrainingDay' ? 'orange' : parentType === 'BaseDay' ? 'blue' : 'green'

  const sumBy = (arr, func) => arr.reduce((acc, item) => acc + func(item), 0)

  let adherencePercent
  if (parentType !== 'Alternate') {
    var totalGrams = sumBy(dailies, function(daily) {
      let value
      switch (type) {
        case 'Protein':
        default:
          value = daily.prot
          break
        case 'Carbs (Net)':
          value = daily.carbs
          break
        case 'Fat':
          value = daily.fat
          break
      }
      return value
    })

    var dailiesToCount = dailies.filter((daily) => {
      let value
      switch (type) {
        case 'Protein':
        default:
          value = daily.prot
          break
        case 'Carbs (Net)':
          value = daily.carbs
          break
        case 'Fat':
          value = daily.fat
          break
      }
      return value > 0
    })

    adherencePercent = (((totalGrams / (dailiesToCount.length || 1)) * 100) / data.TotalGrams).toFixed(0)
  }

  return (
    <Card className={parentType}>
      <div className={`title ${color}`}>
        {type}
        <p className="total">
          <small>Total Kcal&nbsp;&nbsp;&nbsp;</small>
          {data.PercentCalories}%
        </p>
      </div>
      <div className="content">
        <table>
          <thead>
            <tr>
              <th>Base Macro Grams</th>
              {parentType !== 'BaseDay' && <th>Pre-Workout Grams</th>}
              {parentType !== 'BaseDay' && <th>Total Grams</th>}
              <th>Kcal/Grams</th>
              <th>Daily Kcals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {data.BaseGrams}
                <small>g</small>
              </td>
              {parentType !== 'BaseDay' && (
                <td>
                  {data.PreWorkOutGrams}
                  <small>g</small>
                </td>
              )}
              {parentType !== 'BaseDay' && (
                <td>
                  {data.TotalGrams}
                  <small>g</small>
                </td>
              )}
              <td>{data.Kcal}</td>
              <td className={`total ${color}`}>{data.DailyKcals}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {parentType !== 'Alternate' && showDailies && (
        <div className="footer">
          <MacroCollapse expandIcon={(collapse) => collapseIcon(collapse.isActive, parentType)}>
            <Panel header={adherenceIcon(adherencePercent)} key="1">
              <MacroDailies dailies={dailies} type={type} totalGrams={data.TotalGrams} />
            </Panel>
          </MacroCollapse>
        </div>
      )}
    </Card>
  )
}

function MacroTotal(props) {
  const { type, total } = props
  const color = type === 'TrainingDay' ? 'orange' : type === 'BaseDay' ? 'blue' : 'green'
  const colorTitle = type === 'TrainingDay' ? 'orange' : type === 'BaseDay' ? 'blue' : 'green'
  const icon = type === 'TrainingDay' ? MacroIconTraining : type === 'BaseDay' ? MacroIconRest : MacroIconAlternate
  return (
    <Card className={`total-card ${color}`}>
      <div className="image-container">
        <img src={icon} alt={type} />
      </div>
      <div className="container">
        <p className={colorTitle}>Total Daily Calories</p>
        <p className="total">{total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
      </div>
      {type === 'TrainingDay' && (
        <Tooltip
          title={
            <div style={{ fontSize: '9px' }}>
              <p>On TRAINING DAYS you will add the “Pre-Workout Grams” on top of your base macros:</p>
              <p>
                <b>The KETOGAINS Pre-Workout MIX</b>
              </p>
              (Use your “Pre-Workout Grams”).
              <ul style={{ paddingLeft: '10px' }}>
                <li>
                  <b>Coffee / Tea</b> (decaf can work as well)
                </li>
                <li>
                  <b>Protein</b>: from a zero or low carb animal based Protein Powder
                </li>
                <li>
                  <b>Carbs</b> (only in case you are instructed to): Glucose or Dextrose Powder
                </li>
                <li>
                  <b>Fat</b>: MCT Powder, MCT Oil or Coconut oil
                </li>
              </ul>
              <p>Take about 20 minutes before training. (ALTERNATIVELY, YOU COULD HAVE SOME FOOD THAT MEETS THE MACROS)</p>
            </div>
          }
        >
          <Icon type="info-circle" />
        </Tooltip>
      )}
    </Card>
  )
}

export default ({ data, dailies, showDailies }) => {
  const macroTable = {
    Protein: {
      BaseGrams: data.protein_base_grams,
      PreWorkOutGrams: data.protein_pre_workout_grams,
      TotalGrams: data.protein_total_grams,
      Kcal: data.protein_kcal_gram,
      DailyKcals: data.protein_daily_kcals,
      PercentCalories: data.protein_percent_calories,
    },
    Carbs: {
      BaseGrams: data.carbs_base_grams,
      PreWorkOutGrams: data.carbs_pre_workout_grams,
      TotalGrams: data.carbs_total_grams,
      Kcal: data.carbs_kcal_gram,
      DailyKcals: data.carbs_daily_kcals,
      PercentCalories: data.carbs_percent_calories,
    },
    Fat: {
      BaseGrams: data.fat_base_grams,
      PreWorkOutGrams: data.fat_pre_workout_grams,
      TotalGrams: data.fat_total_grams,
      Kcal: data.fat_kcal_gram,
      DailyKcals: data.fat_daily_kcals,
      PercentCalories: data.fat_percent_calories,
    },
    Total: data.total_daily_kcals,
  }

  return (
    <div>
      <MacroCard
        isCurrentProgram={false}
        type="Protein"
        data={macroTable.Protein}
        dailies={dailies}
        showDailies={showDailies}
        parentType={data.type}
      />
      <MacroCard
        isCurrentProgram={false}
        type="Carbs (Net)"
        data={macroTable.Carbs}
        dailies={dailies}
        showDailies={showDailies}
        parentType={data.type}
      />
      <MacroCard isCurrentProgram={false} type="Fat" data={macroTable.Fat} dailies={dailies} showDailies={showDailies} parentType={data.type} />
      <MacroTotal type="TrainingDay" total={macroTable.Total} />
    </div>
  )
}
