import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableToggle from 'components/Selectable/Toggle'
import { MaterializeInput } from 'components/Materialize'
import RestYes from 'assets/icons/enrollment/rest-yes.png'
import RestNo from 'assets/icons/enrollment/rest-no.png'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [sleepHours, setSleepHours] = useState()
  const [sleepRest, setSleepRest] = useState()
  const sleepHoursRef = useRef(null)
  const sleepRestRef = useRef(null)

  const yesRest = { value: 'yes', label: 'Yes', icon: RestYes }
  const noRest = { value: 'no', label: 'No', icon: RestNo }

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, sleep_hours, sleep_rest } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setSleepHours(sleep_hours ? sleep_hours : undefined)
          if (typeof sleep_rest === 'boolean') {
            setSleepRest(sleep_rest ? 'yes' : 'no')
          }
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [sleepHours, sleepRest])

  const saveCallback = async ({ goBack }) => {
    const data = { sleep_hours: Number(sleepHours), sleep_rest: sleepRest === 'yes' }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (Number(sleepHours) > 0 && Number(sleepHours) <= 24 && sleepRest) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          if (sleepHours <= 0) scrollToRef(sleepHoursRef)
          if (!sleepRest) scrollToRef(sleepRestRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'lifestyle' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={sleepHoursRef}>
            {submit && !sleepHours && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">How many hours of sleep do you get (on average) each night?</p>
            </div>
            <div className="content" style={{ paddingTop: '12px', paddingBottom: '55px' }}>
              <MaterializeInput
                type="number"
                maxLength={2}
                suffix="hours"
                isValid={sleepHours && (Number(sleepHours) > 0 && Number(sleepHours) <= 24)}
                label="Hours of sleep"
                value={sleepHours}
                className="short"
                onChange={(e) => setSleepHours(e.target.value)}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={sleepRestRef}>
            {submit && !sleepRest && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Do you usually wake rested?</p>
            </div>
            <div className="content">
              <SelectableToggle optionA={yesRest} optionB={noRest} value={sleepRest} onChange={(e) => setSleepRest(e.target.value)} />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
