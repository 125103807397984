import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { resetButton } from 'services/style'

export const Root = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 70px 48px 20px;
  ${({ custom }) => {
    if (custom === 'disablePadding') {
      return css`
        && {
          padding-top: 20px;
        }
      `
    }
  }}
`

export const Title = styled.h2`
  && {
    margin-bottom: 0;
    padding: 0 10px 10px;
    color: #4a4a4a;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
  }
`

export const Description = styled.h2`
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
`
export const Instruction = styled.p`
  color: #5d5d5d;
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  text-align: center;
`
export const Skip = styled.button`
  ${resetButton()}
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  display: block;
  margin: 40px auto;
  text-decoration: underline;
`
export const UploaderWrap = styled.div`
  width: 210px;
  height: 210px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  margin-bottom: 18px;
  position: relative;
  ${({ custom }) => {
    if (custom === 'uploaded') {
      return css`
        && {
          .uploadcare--widget__button_type_open {
            display: none;
          }
        }
      `
    }
  }}

  .uploadcare--widget {
    text-align: center;
  }

  .uploadcare--widget__button {
    background: #ff4613;
    border: 0;
    border-radius: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    height: auto;
    margin: 0 auto;
    outline: none;
    padding: 10px 25px;
    text-transform: uppercase;
    background: transparent;
    border: thin solid #000;
    border-radius: 20px;
    margin: 0 auto 9px;
    padding: 10px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  .uploadcare--widget__button_type_open,
  .uploadcare--widget__button_type_cancel {
    position: relative;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    color: #000;
    background-color: #fff;
  }

  .uploadcare--widget__text {
    display: none;
  }

  .uploadcare--progress_type_canvas {
    color: #ff4613;
    margin-top: 8px;
  }
`

export const UploadedPic = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border-radius: 2px;

  ${({ isFileUploaded }) =>
    isFileUploaded &&
    css`
      width: 210px;
      height: 210px;
      object-fit: contain;
    `}
`
export const UploaderTitle = styled.h2`
  color: #ff6b00;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  padding-top: 35px;
`
export const UploaderDescription = styled.p`
  color: #5d5d5d;
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  text-align: center;
`
