import React from 'react'
import { ContainerKpis, CstTooltip } from './Cards.styled'

const KpisCards = ({ workouts, total_volume, goal }) => {
  const handleRangeQuantity = (num, digits) => {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    let i
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
  }

  const handleValueUnits = () => {
    const value = localStorage.getItem('PREFERRED_UNITS') === 'KG' ? total_volume / 2.2046 : total_volume
    return value
  }

  return (
    <ContainerKpis>
      <div className="container-kpis">
        <ContainerKpis>
          <div>{workouts > 0 ? workouts : '-'}</div>
          <div>
            <p>Workouts</p>
          </div>
        </ContainerKpis>
        <CstTooltip
          id="kpi-tooltip"
          placement="bottom"
          title={`Total Volume \n${
            total_volume > 0
              ? handleValueUnits(total_volume)
                  .toFixed(1)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              : '-'
          } ${localStorage.getItem('PREFERRED_UNITS') === 'KG' ? 'Kg' : 'Lb'}`}
        >
          <ContainerKpis>
            <div>{total_volume > 0 ? `${handleRangeQuantity(Math.round(handleValueUnits(total_volume)), 1)}` : '-'}</div>
            <div>
              <p className="total-volume">Total Volume {localStorage.getItem('PREFERRED_UNITS') === 'KG' ? 'Kg' : 'Lb'}</p>
            </div>
          </ContainerKpis>
        </CstTooltip>
        <ContainerKpis>
          <div>{goal > 0 ? handleRangeQuantity(goal, 1) : '-'}</div>
          <div>
            <p>Goals</p>
          </div>
        </ContainerKpis>
      </div>
    </ContainerKpis>
  )
}

export default KpisCards
