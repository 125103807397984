import styled from '@emotion/styled'

export const WrapDropDown = styled.div`
  width: 100%;
  max-width: 380px;
  margin-bottom: 10px;
  .bgr-title {
    width: 100%;
    background: linear-gradient(90deg, #ff6b00 7.81%, #ffa400 96.3%);
    box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
    border-radius: 5px 5px 0px 0px;
    padding: 5px 10px;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .ctn-children {
    padding: 10px 5px 0px;
  }
`
