import styled from '@emotion/styled'
import { Modal, Checkbox } from 'antd'

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox {
    display: none;
  }
  .ant-form-item-control .ant-checkbox-wrapper {
    margin-right: 15px;
  }
  .ant-checkbox-checked + span > svg#liked path {
    fill: #67d27f;
  }
  .ant-checkbox-checked + span > svg#disliked path {
    fill: #ff4b2d;
  }
`
export const CustomModal = styled(Modal)`
  .ant-modal-wrap {
    overflow: auto !important;
  }
  .ant-modal-content {
    padding: 10px;
    .ant-modal-header {
      background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
      border-radius: 4px 4px 0px 0px;
      .ant-modal-title {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      }
    }
    .ant-modal-body {
      .label-txt {
        .ant-form-item-label {
          padding-bottom: 1px;
        }
        .span-txt {
          font-size: 12px;
        }
      }

      padding: 15px 15px 0px;
      .ant-form-item {
        margin-bottom: 12px;
        textarea {
          ::-webkit-input-placeholder {
            /* Edge */
            font-size: 13px;
            color: #979797;
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 13px;
            color: #979797;
          }

          ::placeholder {
            font-size: 13px;
            color: #979797;
          }
        }
      }
      .ant-form-item-label {
        display: flex;
        label {
          // display: flex;
          // flex-direction: row-reverse;
          font-weight: 600;
          font-size: 15px;
          color: #4a4a4a;
        }
      }
      .ant-form-item-required::before {
        content: '';
      }
      .ant-form-item-label > label::after {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
      .label-txt .ant-form-item-label > label::after {
        content: '';
      }
      .ant-form-item-control {
        line-height: 20px;
        .ant-form-explain {
          display: none;
        }
      }
      .ant-form-item-control-wrapper {
        //padding: 0 14px;
      }
      .ant-form-item-children,
      .ant-rate {
        width: 100%;
        text-align: center;
        .ant-rate-star:not(:last-child) {
          margin-right: 23px;
        }
      }

      .ant-rate-star-zero {
        .ant-rate-star-second {
          svg {
            filter: drop-shadow(1px 1px 0px #979797) drop-shadow(0px 1px 0px #979797) drop-shadow(0px -1px 0px #979797)
              drop-shadow(0px 0px 0px #979797);
            path {
              fill: none;
              stroke: #979797;
              stroke-width: 5;
              stroke-linecap: round;
              stroke-linejoin: round;
            }
          }
        }
      }

      .ant-rate-star-second {
        font-size: 32px;
      }
    }
    .ant-modal-footer div {
      display: flex;
      justify-content: space-between;
      padding: 0px 15px;
      .ant-btn {
        border: 1px solid #ff6b00;
        box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
        border-radius: 22.5px;
        font-weight: bold;
        font-size: 16px;
        color: #ff6b00;
        text-align: center;
        width: 45%;
      }
      .ant-btn-primary {
        background: linear-gradient(90deg, #ff4713 7.81%, #ff7e22 96.3%);
        box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
        color: #ffffff;
      }
    }
  }
`
