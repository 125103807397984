import React, { Component } from 'react'
import { Steps } from 'antd'
import { TutorialTimelineRoot } from './Tutorial.styled'
import TimelineBanner from '../../images/Tutorial/tutorial-timeline-banner.svg'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'
import get from 'lodash/get'
import moment from 'moment'

const getTimelineAndLinks = gql`
  query getTimelineAndLinks($traineeId: uuid!, $programId: uuid!) {
    trainee_program_coach(where: { _and: [{ trainee_id: { _eq: $traineeId } }, { program_id: { _eq: $programId } }] }) {
      program {
        timeline
        program_urls(where: { type: { _in: ["learning"] } }) {
          type
          level
          sub_level
          url
        }
      }
      enrollment {
        level
        sub_level
      }
    }
  }
`

class TutorialTimeline extends Component {
  getStepTitle = (step) => {
    return (
      <>
        <h4>{moment(step.Date).format('MMMM D')}</h4>
        <h2>{step.Title}</h2>
      </>
    )
  }

  getStepIcon = (step) => {
    if (!!step.Icon) return <img style={step.IconStyle ? step.IconStyle : null} src={step.Icon} alt="" />
    return
  }

  render() {
    const { traineeId, programId } = this.props
    return (
      <TutorialTimelineRoot>
        <div className="banner">
          <img src={TimelineBanner} alt="" />
        </div>
        <div className="content">
          <h2>Welcome to the Bootcamp!</h2>
          <p>
            Whats next? <br /> This timeline will guide you through your next steps as you prepare for the upcoming bootcamp.
          </p>
          <hr />
          <Query query={getTimelineAndLinks} variables={{ traineeId, programId }} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading || error) return null
              let learningLinkDB = ''

              const programData = get(data, 'trainee_program_coach[0].program') || []
              const timeline = programData.timeline
              const programUrls = programData.program_urls

              if (!!programUrls && programUrls.length > 0) {
                learningLinkDB = programUrls.find((p) => p.type === 'learning') ? programUrls.find((p) => p.type === 'learning').url : ''
              }

              return (
                <Steps direction="vertical">
                  {timeline.map((step, i) => {
                    if (step.Title === 'Bootcamp Learning Course') {
                      let learningLink = 'here'
                      if (!!learningLinkDB) {
                        learningLink = `<a href="${learningLinkDB}" target="_blank">here</a>`
                      }
                      step.Description = step.Description.replace('{learningLink}', learningLink)
                    }
                    return (
                      <Steps.Step
                        key={i}
                        className={step.IsColored ? 'colored' : ''}
                        title={this.getStepTitle(step)}
                        description={<div dangerouslySetInnerHTML={{ __html: step.Description }} />}
                        icon={this.getStepIcon(step)}
                      />
                    )
                  })}
                </Steps>
              )
            }}
          </Query>
        </div>
      </TutorialTimelineRoot>
    )
  }
}

export default TutorialTimeline
