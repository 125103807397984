// If 'production', use production URL 'https://portal.ketogains.com'
// In 'development' use 'https://develop--ketogains.netlify.com'
// Please set .env files to modify.
// For local testing it is required to set .env.development.local with 'http://localhost:3000'
// DO NOT COMMIT .local file to repository

export const AppVersion = `v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BRANCH || ''}`
export const DB = process.env.REACT_APP_DB

export const INFUSIONSOFT_API = {
  URI: `${process.env.REACT_APP_API_URL}infusion-soft/contact-tag`,
}

export const COOKIE = {
  USER_TOKEN: 'USER_TOKEN',
  USER_TOKEN_EXPIRE_TIME: 'USER_TOKEN_EXPIRE_TIME',
  API_TOKEN: 'API_TOKEN',
}

export const COOKIESTAMPED = {
  LAST_OPEN: 'LAST_SHOW',
  MODAL_SHOWN_TODAY: 'MODAL_SHOWN_TODAY',
  COUNT_MODAL: 'COUNT_MODAL',
}

export const STAMPED = {
  PRODUCT_ID: '687774725553',
  PRODUCT_URL: 'https://www.ketogainsbootcamps.com/bootcamp',
  PRODUCT_IMG_URL: 'https://www.ketogains.com/wp-content/uploads/2020/04/Bootcamp-Logo-20201.png',
}

export const LOCAL_STORAGE = {
  USER_ID: 'USER_ID',
  EX_USER_ID: 'EX_USER_ID',
  TRAINEE_ID: 'TRAINEE_ID',
  USER_NICKNAME: 'USER_NICKNAME',
  USER_PICTURE: 'USER_PICTURE',
  USER_UNITS: 'USER_UNITS',
  USER_GENDER: 'USER_GENDER',
  USER_EMAIL: 'USER_EMAIL',
  USER_COUNTRY: 'USER_COUNTRY',
  CONTACT_ID: 'CONTACT_ID',
  CURRENT_MESSAGE: 'CURRENT_MESSAGE',
  CURRENT_ATTACHMENT: 'CURRENT_ATTACHMENT',
  IMPERSONATE_USER: 'IMPERSONATE_USER',
}

export const UPLOADS = {
  UPLOADCARE_PUBLIC_KEY: 'b30ba35631dd3b1d4a32',
  UPLOADCARE_TABS: 'file camera',
  UPLOADCARE_SHRINK: '1024x1024 80%',
  UPLOADCARE_IMAGES_ONLY: true,
  UPLOADCARE_PREVIEW: true,
  UPLOADCARE_CROP: 'free',
  UPLOADCARE_LOCALE: 'en',
  UPLOADCARE_LOCALE_TRANSLATIONS: {
    buttons: {
      cancel: 'Remove',
      remove: 'Remove',
      choose: {
        files: {
          one: 'Upload your CV',
          other: 'Upload your documents',
        },
        images: {
          one: 'Upload your photo',
          other: 'Upload your photos',
        },
      },
    },
  },
}

export const MEASUREMENTS_DISPLAY_MAP = [
  {
    id: 'neck',
    name: 'Neck',
    description: '(Narrowest point)',
  },
  {
    id: 'chest',
    name: 'Chest',
    description: '(Around nipple height)',
  },
  {
    id: 'shoulder',
    name: 'Shoulder',
    description: '(Circumference)',
  },
  {
    id: 'arm',
    name: 'Arm',
    description: '(Dominant arm, unflexed)',
  },
  {
    id: 'forearm',
    name: 'Forearm',
    description: '(Dominant arm, unflexed)',
  },
  {
    id: 'belly_waist',
    name: 'Waist',
    description: '(At belly button/navel)',
  },
  {
    id: 'narrow_waist',
    name: 'Waist',
    description: '(At narrowest point)',
  },
  {
    id: 'hip',
    name: 'Hip',
    description: '(At widest point)',
  },
  {
    id: 'thigh',
    name: 'Thigh',
    description: '(Dominant leg, unflexed)',
  },
  {
    id: 'calf',
    name: 'Calf',
    description: '(Dominant leg, unflexed)',
  },
]

export const MEASUREMENTS_LIST = ['neck', 'chest', 'shoulder', 'arm', 'forearm', 'belly_waist', 'narrow_waist', 'hip', 'thigh', 'calf']

export const WEEKLY_MESSAGES_DISPLAY_MAP = [
  {
    id: 'desc_well',
    name: 'What went well this week?',
  },
  {
    id: 'desc_bad',
    name: 'What didn`t go well this week?',
  },
  {
    id: 'desc_improve',
    name: 'What is your gameplan for improvement?',
  },
  {
    id: 'desc_other',
    name: 'What else should we know about your week?',
  },
]
