import React from 'react'
import { CardWeeklyEstimated } from './WeeklyEstimated.styled'
import { ReactComponent as Chart } from '../../../../images/exercise/exercise-chart.svg'

const WeeklyEstimated = ({ title }) => {
  return (
    <CardWeeklyEstimated>
      <div className="container-weekly">
        <p className="card-weekly-title">{title}</p>
        <Chart />
        <p className="weekly-ads">Coming soon!</p>
      </div>
    </CardWeeklyEstimated>
  )
}

export default WeeklyEstimated
