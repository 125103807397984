import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import moment from 'moment'
import get from 'lodash/get'
import { getDateReview, getWeekNumber, getSuffix, getGainspotSSO } from 'services'
import { Icon } from 'antd'
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg'
import Layout from 'components/Layout'
import { Timeline, TimelineWarning } from 'components/Timeline'
import TutorialTimeline from 'components/Tutorial/TutorialTimeline'
import Countdown from 'components/Countdown'
import { Root, Wrap, NotificationBanner, SmallNotificationBanner, PDFReportProgressContainer, CardStatus } from './Homepage.styled'
import PDFReportProgress from './PDFProgressReport'

import green from 'images/Green card.png'
import red from 'images/Red card.png'

import TutorialBannerBackground from 'images/Tutorial/tutorial-banner-background.svg'
import TutorialBannerPin from 'images/Tutorial/tutorial-banner-pin.svg'
import WeeklyBannerBackground from 'images/filosofia_D.jpg'
import { Button } from 'antd'
import { navigate } from '@reach/router'
import Tutorial from '../Tutorial'
import { StyledButton } from 'styled'
import GlobalContext from 'context/GlobalContext'
import ProgramPending from 'components/Homepage/ProgramPending'
import BannerSection from 'components/Banner/BannerSection'
import CardResult from 'components/Cards/Result'
import EmptyProgram from './EmptyProgram'

const today = moment().local()
const { isStartOfFilledWeek, currentWeekStart } = getDateReview()

const getMacroData = gql`
  query getMacroData($id: uuid!, $currentWeekStart: String!) {
    measurement(where: { trainee: { id: { _eq: $id } }, for_date: { _eq: $currentWeekStart } }) {
      id
    }
  }
`

export const getEnrollmentBanners = gql`
  query getEnrollmentBanner($programId: uuid!) {
    program_enrollment_banner(where: { program_id: { _eq: $programId } }) {
      phase_key
      title
      subtitle
      button
      days_to_show
    }
  }
`

const Banner = (props) => {
  const { background, backgroundSize, textAlign, status, title, subtitle, button, onClick, href } = props
  return (
    <NotificationBanner {...{ background, backgroundSize, status, textAlign }}>
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
      <hr />
      <div className="actions">
        <Button type="link" shape="round" size={'small'} onClick={onClick} href={href} target="_blank">
          {button}
          <Icon type="right" />
        </Button>
      </div>
    </NotificationBanner>
  )
}

class EnrollmentBanner extends React.Component {
  render() {
    const { programId, startDate } = this.props
    return (
      <Query query={getEnrollmentBanners} variables={{ programId }}>
        {({ loading, error, data }) => {
          if (loading || error) return null

          var banners = get(data, 'program_enrollment_banner', []) || []
          const daysToStartBootcamp = today.diff(moment(startDate), 'days')

          var bannerToShow = banners
            .sort((a, b) => {
              return b.days_to_show - a.days_to_show
            })
            .filter((banner) => {
              if (banner.days_to_show < 0) {
                return daysToStartBootcamp >= banner.days_to_show && daysToStartBootcamp < 0
              } else {
                return 0 <= daysToStartBootcamp && daysToStartBootcamp < banner.days_to_show
              }
            })[0]

          if (!!!bannerToShow) {
            bannerToShow = banners.sort((a, b) => {
              return a.days_to_show - b.days_to_show
            })[0]
          }

          var status = bannerToShow.phase_key
          var card = red

          if (bannerToShow.phase_key === 'on_time') {
            card = green
          }

          return (
            <Banner
              background={card}
              status={status}
              title={bannerToShow.title}
              subtitle={bannerToShow.subtitle}
              button={bannerToShow.button}
              onClick={() => {
                navigate(`/enrollment/`)
              }}
            />
          )
        }}
      </Query>
    )
  }
}

class TutorialBanner extends React.Component {
  state = {
    openTutorial: false,
  }

  ShowTutorial = () => {
    this.setState({
      openTutorial: true,
    })
  }

  handleCloseTutorial = () => {
    this.setState({
      openTutorial: false,
    })
  }

  render() {
    return (
      <div>
        <SmallNotificationBanner background={TutorialBannerBackground} onClick={this.ShowTutorial}>
          <img src={TutorialBannerPin} alt="" />
          <div>
            Take the Portal Tour
            <ArrowForward className="anticon" />
          </div>
        </SmallNotificationBanner>
        <Tutorial showed={this.state.openTutorial} onCloseTutorial={this.handleCloseTutorial} />
      </div>
    )
  }
}
class Homepage extends Component {
  timelineWarningRef = null

  handleTimelineChange = () => {
    if (this.timelineWarningRef) {
      this.timelineWarningRef.exposedFunctions.refetch()
    }
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ user, programs, cancelled_programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const upcomingProgram = programs.find((p) => p.is_upcoming) || {}
          const closingProgram = programs.find((p) => p.is_closing) || {}
          const cancelledProgram = cancelled_programs.find((p) => p.cancellation_reason === 'PAYMENT_FAILURE') || {}

          let has_enrollment = false
          let enrollmentProgram = {}

          if (currentProgram.program_id && !currentProgram.is_enrollment_completed) {
            enrollmentProgram = currentProgram
            has_enrollment = true
          } else if (upcomingProgram.program_id && !upcomingProgram.is_enrollment_completed) {
            enrollmentProgram = upcomingProgram
            has_enrollment = true
          }

          const weekNumber = getWeekNumber(currentProgram.start_date, currentProgram.duration, currentWeekStart)
          const weekNumberSuffix = getSuffix(weekNumber)

          if (!user.trainee_id) return <Layout disabledFooter={false} />

          const showClosingBanner =
            !!!has_enrollment &&
            !currentProgram.program_id &&
            !upcomingProgram.program_id &&
            closingProgram.program_id &&
            today.isSameOrAfter(moment(closingProgram.close_date).add(-3, 'day'))

          const showEmpty = !currentProgram.program_id && !upcomingProgram.program_id && !!!has_enrollment && !!!closingProgram.program_id
          const showOnlyEnrollment = !!has_enrollment && enrollmentProgram.program_id === currentProgram.program_id

          let programIdAux = currentProgram.program_id
          let startDateAux = currentProgram.start_date
          if (!!!programIdAux && !currentProgram.start_date && closingProgram.program_id) {
            programIdAux = closingProgram.program_id || null
            startDateAux = closingProgram.start_date || null
          }

          if (startDateAux !== null) startDateAux = moment(startDateAux).format('YYYYMMDD')

          return (
            <Layout>
              <Root>
                <Wrap>
                  {!has_enrollment && upcomingProgram.program_id && <TutorialBanner />}
                  {has_enrollment && (currentProgram.type === 'bootcamp' || upcomingProgram.type === 'bootcamp') && (
                    <EnrollmentBanner {...{ programId: enrollmentProgram.program_id, startDate: enrollmentProgram.start_date }} />
                  )}
                  {!showEmpty && cancelledProgram.id && (
                    <Banner
                      background={red}
                      status="overdue"
                      title="Your access requires action!"
                      subtitle={`There is an outstanding billing issue or technical issue that we need to resolve in your ${cancelledProgram.description}`}
                      button=" Update credit card"
                      href={getGainspotSSO('https://www.gainspot.app/payment-failure/')}
                    />
                  )}
                  {showClosingBanner && (
                    <Banner
                      background={red}
                      status="overdue"
                      title="Your Ketogains Bootcamp is already over!"
                      subtitle="Last chance to review your data."
                      button="Join us again!"
                      href="https://www.ketogains.com/bootcamp-2/"
                    />
                  )}
                  {showEmpty && <EmptyProgram />}
                  {((currentProgram.program_id && !showOnlyEnrollment && currentProgram.type === 'bootcamp') || closingProgram.program_id) && (
                    <Query query={getMacroData} fetchPolicy="cache-and-network" variables={{ id: user.trainee_id, currentWeekStart }}>
                      {({ loading, error, data }) => {
                        if (error) {
                          console.log(error)
                          return <Layout disabledFooter={false} />
                        }
                        if (loading) return null

                        const currentWeekMeasurementId = get(data, 'measurement[0].id') || ''
                        const showWeeklyBanner = currentProgram.program_id && !currentWeekMeasurementId && isStartOfFilledWeek && !showOnlyEnrollment

                        return (
                          <>
                            {showWeeklyBanner && (
                              <Banner
                                background={WeeklyBannerBackground}
                                backgroundSize="cover"
                                textAlign="left"
                                title={`It’s the end of your ${weekNumber}${weekNumberSuffix} week,`}
                                subtitle="Make sure to update your Weekly"
                                button="My Weekly Report"
                                onClick={() => navigate('/week/last')}
                              />
                            )}
                            {currentProgram.program_id && !showOnlyEnrollment && (
                              <Timeline
                                {...{ traineeId: user.trainee_id, programId: currentProgram.program_id, onChange: this.handleTimelineChange }}
                              />
                            )}
                            <TimelineWarning
                              ref={(input) => (this.timelineWarningRef = input)}
                              {...{ traineeId: user.trainee_id, weekNumber, start_date: currentProgram.start_date, closingProgram }}
                            />
                          </>
                        )
                      }}
                    </Query>
                  )}

                  {['macros', 'meal_plan'].indexOf(currentProgram.type) !== -1 && (
                    <>
                      {!currentProgram.is_enrollment_completed ? (
                        <CardStatus>
                          <BannerSection title={`My ${currentProgram.typeLabel}`} picture={user.picture} />
                          <CardResult
                            {...{
                              text: `<b>Please submit your information so we can begin reviewing your ${
                                currentProgram.type === 'macros' ? 'macros' : 'meal_plan'
                              }!</b>`,
                              icon: <Icon type="hourglass" style={{ fontSize: '80px' }} />,
                              actionLink: '/enrollment',
                              actionText: 'Go to enrollment',
                            }}
                          />
                        </CardStatus>
                      ) : (
                        <CardStatus>
                          <BannerSection title={`My ${currentProgram.typeLabel}`} picture={user.picture} />
                          <ProgramPending {...{ programs: currentProgram, traineeId: user.trainee_id }} />
                        </CardStatus>
                      )}
                    </>
                  )}

                  {!has_enrollment && upcomingProgram.program_id && (
                    <Countdown {...{ programId: upcomingProgram.program_id, expanded: false, viewButtonTimeline: !!currentProgram.program_id }} />
                  )}
                  {!has_enrollment && upcomingProgram.program_id && !currentProgram.program_id && (
                    <TutorialTimeline traineeId={user.trainee_id} programId={upcomingProgram.program_id} />
                  )}
                  {((currentProgram.program_id && currentProgram.type === 'bootcamp') || closingProgram.program_id) && !showOnlyEnrollment && (
                    <PDFReportProgressContainer first>
                      <div>
                        <StyledButton onClick={PDFReportProgress(startDateAux, user.trainee_id, programIdAux)} custom="transparentLink">
                          Download your progress report to PDF <Icon type="down-circle" />
                        </StyledButton>
                      </div>
                    </PDFReportProgressContainer>
                  )}
                </Wrap>
              </Root>
            </Layout>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Homepage
