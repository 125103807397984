import React, { Component } from 'react'
import { Popover, Button } from 'antd'
import { navigate } from '@reach/router'
import { SyncButton } from './Cronometer.styled'
import { ReactComponent as CronometerLogo } from 'images/cronometer-logo-small.svg'
import { ReactComponent as KetogainsLogo } from 'images/ketogains-logo-small.svg'
import { ReactComponent as CancelIcon } from 'images/icon-cancel.svg'
import { ReactComponent as ArrowIcon } from 'images/icon-arrow-right.svg'

class CronometerButtonSync extends Component {
  state = {
    popoverVisible: false,
  }

  handlePopoverVisible = (visible) => {
    this.setState({ popoverVisible: visible })
  }

  popoverTitle = (isActive) => {
    return (
      <div>
        <CronometerLogo />
        {isActive && <CancelIcon className="cronometor-action" />}
        {!isActive && <ArrowIcon className="cronometor-action" />}
        <KetogainsLogo />
        {isActive && (
          <p>
            Do you want to <b>disconect</b> your <b>Daily Macros</b> from your <b>Cronometer Account</b>?
          </p>
        )}
        {!isActive && (
          <p>
            Do you want <b>Ketogains</b> to input automatically your <b>Daily Macros</b> from your <b>Cronometer Account?</b>
          </p>
        )}
      </div>
    )
  }

  popoverContent = (isActive) => {
    const cronometerAuthorizationLink = `https://cronometer.com/oauth/authorize?response_type=code&client_id=KmSiXqPVlUvZMQ&redirect_uri=${
      process.env.REACT_APP_DOMAIN
    }/cronometer/result`
    return (
      <div>
        {!isActive && (
          <>
            <Button onClick={() => navigate('/cronometer')}>I want to know more</Button>
            <Button onClick={() => navigate(cronometerAuthorizationLink)}>Yes, sync my account</Button>
          </>
        )}
        {isActive && <Button onClick={() => navigate('cronometer/result?action=unsync')}>Unsync my account</Button>}
        <Button className="btn-cancel" onClick={() => this.handlePopoverVisible(false)}>
          Cancel
        </Button>
      </div>
    )
  }

  render() {
    const { configuration } = this.props
    let isActive = !!configuration && !!configuration.cronometerToken
    return (
      <SyncButton>
        <Popover
          visible={this.state.popoverVisible}
          onVisibleChange={(visible) => this.handlePopoverVisible(visible)}
          placement="bottomRight"
          title={this.popoverTitle(isActive)}
          content={this.popoverContent(isActive)}
          trigger="click"
        >
          <Button className={`${isActive ? 'sync' : 'inactive'}`}>Sync</Button>
        </Popover>
      </SyncButton>
    )
  }
}

export default CronometerButtonSync
