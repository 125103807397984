import React, { useState, useEffect } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import SectionError from 'components/SectionError'
import BodyFront from 'assets/icons/enrollment/body-front.png'
import BodyBack from 'assets/icons/enrollment/body-back.png'
import BodySide from 'assets/icons/enrollment/body-side.png'
import PictureUploader from 'components/PictureUploader'

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [frontUrl, setFrontUrl] = useState()
  const [backUrl, setBackUrl] = useState()
  const [sideUrl, setSideUrl] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, photo_front_url, photo_back_url, photo_side_url } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setFrontUrl(photo_front_url)
          setBackUrl(photo_back_url)
          setSideUrl(photo_side_url)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  const saveCallback = async ({ goBack }) => {
    const data = {
      photo_front_url: frontUrl,
      photo_back_url: backUrl,
      photo_side_url: sideUrl,
    }
    let response = false
    response = await HandleResponse({ ...props, data, goBack })
    return response
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext: true, section: 'body_composition' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer>
          <div className="question">
            <p className="title">Please upload your starting pictures as shown on each section</p>
          </div>
          <div className="content">
            <PictureUploader
              title="Front Picture"
              picture={frontUrl}
              placeholder={BodyFront}
              urlModifiers="-/quality/lighter/"
              onSelect={(pictureUrl) => setFrontUrl(pictureUrl)}
            />
            <PictureUploader
              title="Back Picture"
              picture={backUrl}
              placeholder={BodyBack}
              urlModifiers="-/quality/lighter/"
              onSelect={(pictureUrl) => setBackUrl(pictureUrl)}
            />
            <PictureUploader
              title="Side Picture"
              picture={sideUrl}
              placeholder={BodySide}
              urlModifiers="-/quality/lighter/"
              onSelect={(pictureUrl) => setSideUrl(pictureUrl)}
            />
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
