import React, { Component, Fragment } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import moment from 'moment'
import _ from 'lodash'
import CountdownNow from 'react-countdown-now'
import backgroundImage from 'images/sign-in-bg.png'
import Bootcamp from './Bootcamp'
import { Root, Wrap, Body, Ul, Li, Value, Name, H1, H2, ButtonView } from './Countdown.styled'
import { StyledButton } from 'styled'

const mockedTitle = 'We are going live in:'
const today = moment().format('YYYY-MM-DD')

const getBootcamp = gql`
  query($programId: uuid!) {
    program(where: { program_id: { _eq: $programId } }, limit: 1, order_by: { start_date: asc }) {
      start_date
      description
    }
  }
`

class Countdown extends Component {
  showCountdown = (startDate) => {
    if (!!!startDate) return false
    return moment(today).isBefore(startDate)
  }

  render() {
    const { title, expanded, programId, viewButtonTimeline } = this.props
    let button = (
      <ButtonView>
        <StyledButton href={`/bootcamp-timeline/${programId}`}>View Timeline</StyledButton>
      </ButtonView>
    )
    return (
      <Query query={getBootcamp} variables={{ programId }}>
        {({ error, data }) => {
          if (error) {
            console.error(error)
            return null
          }
          if (_.isEmpty(data)) return null
          const { description, start_date } = _.get(data, 'program[0]') || {}
          if (!this.showCountdown(start_date)) return null
          return (
            <Root bgUrl={expanded && backgroundImage}>
              <Wrap>
                <Body>
                  <H1>{title || `${description} starts in:` || mockedTitle}</H1>
                  <CountdownNow
                    now={moment}
                    date={moment(start_date)
                      .local()
                      .toISOString()}
                    renderer={({ days, hours, minutes, completed }) => (
                      <Fragment>
                        {completed || (
                          <Ul>
                            <Li>
                              <Value>{days}</Value>
                              <Name>days</Name>
                            </Li>
                            <Li>
                              <Value>{hours}</Value>
                              <Name>hours</Name>
                            </Li>
                            <Li>
                              <Value>{minutes}</Value>
                              <Name>minutes</Name>
                            </Li>
                          </Ul>
                        )}
                        {completed && <H2>Now!</H2>}
                      </Fragment>
                    )}
                  />
                </Body>
                {viewButtonTimeline ? button : null}
              </Wrap>
              {expanded && <Bootcamp />}
            </Root>
          )
        }}
      </Query>
    )
  }
}

export default Countdown
