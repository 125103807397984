import React, { Component, Fragment } from 'react'
import { loginCredentials } from 'auth'
import DocumentEvents from 'react-document-events'
import { Form, Icon, message } from 'antd'
import Logo from 'components/Logo'
import SubmitButton from 'components/SubmitButton'
import { AppVersion } from 'constants.js'
import { StyledForm, StyledInput } from 'styled'
import backgroundImage from 'images/sign-in-bg.png'
import { Root, H1, Main, Header, Content, Footer, StyledLink, Version, StyledTooltip } from './Login.styled'
import Peephole from './Peephole'

const FormItem = Form.Item

class Login extends Component {
  state = {
    showPassword: false,
    isCapsLock: false,
  }

  handleShowingPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }))
  }

  pressPassword = (event) => {
    this.setState({
      isCapsLock: event.getModifierState('CapsLock'),
    })
  }

  loginWithCredentials = (event) => {
    event.preventDefault()
    const {
      form: { validateFieldsAndScroll },
    } = this.props
    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, { username, password }) => {
        if (error) {
          console.error(error)
          reject()
          return
        }

        loginCredentials({ username, password, redirect: this.getRedirect() })
          .then(() => resolve())
          .catch((loginError) => {
            let messageToShow = 'Wrong email or password.'
            if (loginError === 'User inactive') messageToShow = loginError
            message.error(messageToShow)
            console.warn(loginError)
            resolve()
          })
      })
    })
  }

  disableCapsLock = (event) => {
    if (event.key === 'CapsLock') {
      this.setState({
        isCapsLock: false,
      })
    }
  }

  getRedirect = (format = true) => {
    let redirectFormat = ''
    if (this.props.location && this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (format && params.redirect) {
        redirectFormat = params.redirect
      } else {
        redirectFormat = this.props.location.search
      }
    }

    return redirectFormat
  }

  render() {
    const { showPassword, isCapsLock } = this.state
    const { getFieldDecorator, getFieldValue } = this.props.form
    return (
      <Root bgUrl={backgroundImage}>
        <DocumentEvents onKeyUp={this.disableCapsLock} />
        <Content>
          <Header>
            <Logo>ketogains</Logo>
          </Header>
          <Main>
            <H1>Sign In</H1>
            <StyledForm hideRequiredMark onSubmit={this.loginWithCredentials}>
              <FormItem label="Email">
                {getFieldDecorator('username', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    { required: true, message: 'Please input your email!' },
                  ],
                })(<StyledInput type="email" />)}
              </FormItem>
              <FormItem label="Password">
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your password!' }],
                })(
                  <StyledInput
                    {...{
                      type: showPassword ? 'text' : 'password',
                      onKeyPress: this.pressPassword,
                      suffix: (
                        <Fragment>
                          {isCapsLock && (
                            <StyledTooltip title="You have CapsLock on!">
                              <Icon type="warning" />
                            </StyledTooltip>
                          )}
                          {!!getFieldValue('password') && <Peephole showPassword={showPassword} tooglePassword={this.handleShowingPassword} />}
                        </Fragment>
                      ),
                    }}
                  />,
                )}
              </FormItem>

              <FormItem>
                <SubmitButton
                  {...{
                    handleSubmit: this.loginWithCredentials,
                    buttonContent: 'Sign In',
                  }}
                />
              </FormItem>

              <FormItem style={{ justifyContent: 'center', display: 'none' }}>
                Need an account?
                {/* <StyledLinkRegister href={`/sign-up${this.getRedirect(false)}`}> Sign Up</StyledLinkRegister> */}
              </FormItem>
              <Version>{AppVersion}</Version>
            </StyledForm>
          </Main>
        </Content>
        <Footer>
          <StyledLink href={`${process.env.REACT_APP_AUTH_SERVER}/wp-login.php?action=lostpassword`} target="_blank">
            Forgot your password?
          </StyledLink>
        </Footer>
      </Root>
    )
  }
}

export default Form.create()(Login)
