import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getCookie } from 'tiny-cookie'
import { renewToken } from 'auth'
import { COOKIE, DB } from 'constants.js'

export default new ApolloClient({
  uri: DB,
  headers: {
    'Content-Type': 'application/json',
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
  request: async (operation) => {
    if (!getCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)) {
      await renewToken().then((token) => {
        operation.setContext({
          headers: {
            Authorization: token,
          },
        })
      })
    } else {
      operation.setContext({
        headers: {
          Authorization: getCookie(COOKIE.USER_TOKEN),
        },
      })
    }
  },
  clientState: {
    defaults: {
      App: {
        loading: false,
        __typename: 'Application',
      },
    },
  },
})
