import React from 'react'
import defaultExerciseThumbnail from 'assets/icons/defaultExerciseThumbnail.svg'
import { deleteCacheById } from './db/indexedDB'

export const GetThumbnail = (exercise) => {
  let thumbnail = defaultExerciseThumbnail
  if (exercise && exercise.thumbnails && exercise.thumbnails.length > 0) {
    const thumbnailsRecommended = exercise.thumbnails.filter((t) => t.is_recommended)
    if (thumbnailsRecommended && thumbnailsRecommended.length > 0) {
      const thumbnailSmall = thumbnailsRecommended.find((t) => t.size === 'SMALL')
      if (thumbnailSmall) {
        thumbnail = thumbnailSmall.url
      } else {
        thumbnail = thumbnailsRecommended[0].url
      }
    } else {
      thumbnail = exercise.thumbnails[0].url
    }
  }

  if (thumbnail !== defaultExerciseThumbnail) {
    if (thumbnail.match(/\.[0-9a-z]+$/i)) {
      const fileName = thumbnail.substring(thumbnail.lastIndexOf('/') + 1, thumbnail.length)
      thumbnail = thumbnail.replace(fileName, '')
    }
    if (!thumbnail.endsWith('/')) thumbnail += '/'
    if (thumbnail.indexOf('quality') === -1) {
      thumbnail += '-/quality/smart/'
    }
    if (thumbnail.indexOf('preview') === -1) {
      thumbnail += '-/preview/100x100'
    }
  }

  return thumbnail
}

export const deleteRoutineSession = async (local_id) => {
  await deleteCacheById('routine_session_set', 'routine_session_exercise_id', local_id)
  await deleteCacheById('routine_session_exercise', 'local_id', local_id)
  await deleteCacheById('routine_session', 'local_id', local_id)
}

export const getGoalExerciseSession = (sets, technique) => {
  const getRir = (sets) => {
    if (sets.length > 0) {
      const set = sets[0]
      if (set.max_rir) return ` (${set.min_rir}-${set.max_rir})`
      if (set.min_rir) return ` (${set.min_rir})`
    }
    return ''
  }
  const getDropsets = (sets) => {
    if (sets.length > 0)
      return ` + ${sets.length} x AMRAP (-${sets[0].drop_set_percent_min}${sets[0].drop_set_percent_max ? `-${sets[0].drop_set_percent_max}` : ''}%)`
    return ''
  }
  const hasAmrap = (sets) => {
    if (sets.length > 0) return !!sets.find((s) => s.is_amrap)
    return false
  }
  const allAreAmrap = (sets) => {
    if (sets.length > 0) return sets.every((s) => s.is_amrap)
    return false
  }
  let originalSets = sets.filter((s) => (typeof s.is_custom === 'boolean' ? false : !s.is_custom))
  const dropsets = originalSets.filter((s) => s.is_drop_set)
  originalSets = originalSets.filter((s) => !s.is_drop_set)
  let lengthSets = originalSets.length
  let isChallenge = true
  let areEquals = true
  let arrayReps = []
  let label = []
  const rir = getRir(originalSets)

  if (technique === 'CHALLENGING') {
    return `${lengthSets} x Challenging`
  } else if (technique === 'RPT') {
    return (
      `${lengthSets} x ${originalSets
        .map((s) =>
          !s.min_repetitions && s.is_amrap
            ? 'AMRAP'
            : s.min_repetitions && s.max_repetitions
            ? `${s.min_repetitions}-${s.max_repetitions}`
            : s.min_repetitions,
        )
        .join('/')}${rir}` + getDropsets(dropsets)
    )
  } else if (technique === 'APT') {
    return (
      `${lengthSets} x ${originalSets.map((s) => (!s.min_repetitions && s.is_amrap ? 'AMRAP' : s.min_repetitions)).join('/')}${rir}` +
      getDropsets(dropsets)
    )
  } else if (technique === 'MYO REPS') {
    const activationSet = originalSets[0]
    const miniset = originalSets[originalSets.length - 1]
    return (
      `1 x ${activationSet.min_repetitions} - ${activationSet.max_repetitions} + ${originalSets.length - 1} x ${miniset.min_repetitions} - ${
        miniset.max_repetitions
      }` + getDropsets(dropsets)
    )
  } else if (allAreAmrap(originalSets)) {
    return `${lengthSets} x AMRAP` + getDropsets(dropsets)
  } else if ((!technique || technique === 'BFR') && hasAmrap(originalSets)) {
    return (
      `${lengthSets} x ${originalSets
        .map((s) =>
          !s.min_repetitions && s.is_amrap ? 'AMRAP' : !!s.max_repetitions ? `${s.min_repetitions}-${s.max_repetitions}` : s.min_repetitions,
        )
        .join('/')}${rir}` + getDropsets(dropsets)
    )
  } else {
    for (const [key, set] of originalSets.entries()) {
      if (!set.is_challenge || set.is_challenge === 0) {
        isChallenge = false
      }

      if (arrayReps.length > 0) {
        const index = arrayReps.length - 1
        if (arrayReps[index].min_repetitions !== set.min_repetitions || arrayReps[index].max_repetitions !== set.max_repetitions) {
          areEquals = false
        }
      }

      arrayReps.push(set)

      const minReps = set.is_amrap && set.min_repetitions === 0 ? 'AMRAP' : set.min_repetitions
      const str = `${key + 1} x ${minReps} ${set.max_repetitions ? '- ' + set.max_repetitions : ''} `
      label.push(str)
    }

    if (isChallenge) {
      return `${lengthSets} x Challenging`
    } else if (areEquals) {
      return `${lengthSets} x ${originalSets[0].min_repetitions} ${
        originalSets[0].max_repetitions ? '- ' + originalSets[0].max_repetitions : ''
      }${rir}${getDropsets(dropsets)}`
    } else {
      return label.join('/ ') + getDropsets(dropsets)
    }
  }
}

export const getTechniqueLabel = (techniqueKey, techniques, deload) => {
  let techniqueLabel = ''
  let technique = techniques.find((t) => t.key === techniqueKey)
  if (technique) {
    techniqueLabel = technique.Label
  } else {
    techniqueLabel = 'Normal'
  }

  if (deload) {
    const { deload_week, deload_percent_min, deload_percent_max } = deload

    let deloadPercent = ` (W${deload_week}${deload_percent_min > 0 ? ` -${deload_percent_min}%` : ''}${
      deload_percent_min > 0 && deload_percent_max > 0 ? ` - ${deload_percent_max}%` : ''
    })`
    techniqueLabel += deload_percent_min > 0 && deload_percent_max > 0 ? `<p>${deloadPercent}</p>` : deloadPercent
  }

  return { __html: techniqueLabel }
}

export const getTechniqueTooltip = (techniqueKey, techniques, techniqueNotes) => {
  const techniqueDescription = techniques.find((t) => t.key === techniqueKey) || { description: '' }
  let tooltip = ''
  if (techniqueDescription.description || techniqueNotes) {
    tooltip = techniqueDescription.description + (techniqueNotes ? '<br />' + techniqueNotes : '')
  }
  return tooltip ? <p style={{ marginBottom: '0' }} dangerouslySetInnerHTML={{ __html: tooltip }} /> : tooltip
}
