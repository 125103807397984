import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { setCookie, getCookie } from 'tiny-cookie'
import { COOKIESTAMPED } from 'constants.js'
import { Dropdown, notification } from 'antd'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary.js'
import { ReactComponent as FolderIcon } from 'assets/icons/IconFolder.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import MainMenu from './MainMenu'
import { Root, StyledAvatar, StyledBadge } from './Avatar.styled'
import Review from './Review'
import moment from 'moment'
import 'assets/scss/index.scss'
import GlobalContext from 'context/GlobalContext'
class Avatar extends Component {
  constructor(props) {
    super(props)
    this.childRef = null
    this.setModal = this.setModal.bind(this)
    this.cancelReview = this.cancelReview.bind(this)
    this.className = ''
  }

  state = {
    showModal: false,
  }

  setModal = (showModal) => {
    this.setState({
      showModal: showModal,
    })
  }

  cancelReview = () => {
    this.setState({ showModal: false })
  }

  showModalToday = (isRatedBootcamp, closingProgram) => {
    let launch
    let pendingReview = null
    let showOption = false

    if (isRatedBootcamp === false && Object.entries(closingProgram).length !== 0) {
      const rest24 = moment()
        .subtract(24, 'hours')
        .format()
      const today = new Date()
      const rawNow = moment(today)
      const now = moment(today).format('YYYY-MM-DD')
      const lastShow = getCookie(COOKIESTAMPED.LAST_OPEN) ? moment(getCookie(COOKIESTAMPED.LAST_OPEN)) : rest24
      const endDate = closingProgram.end_date
      const closeDate = closingProgram.close_date
      const isTimeToShow = rawNow.diff(lastShow, 'hours') >= 24 ? true : false
      let countModal = getCookie(COOKIESTAMPED.COUNT_MODAL) || 0
      const isRated = isRatedBootcamp

      if (now >= endDate && now <= closeDate && countModal < 3 && isRated === false && isTimeToShow) {
        countModal++
        setCookie(COOKIESTAMPED.MODAL_SHOWN_TODAY, true, { expires: 5 })
        setCookie(COOKIESTAMPED.COUNT_MODAL, countModal, { expires: 5 })
        setCookie(COOKIESTAMPED.LAST_OPEN, moment(today).format('YYYY-MM-DD:HH:mm:ss'), { expires: 5 })
        launch = true
      }

      if (now >= endDate && now <= closeDate && (isRated === false || isRated === 'false') && countModal >= 3) {
        pendingReview = 1
      }

      if (now >= endDate && now <= closeDate && (isRated === false || isRated === 'false')) {
        showOption = true
      }
    }

    return {
      launch,
      pendingReview,
      showOption,
    }
  }

  openNotification = (num) => {
    const style = {
      background: '#FF4713',
      boxShadow: '0px 2px 8px rgba(176, 176, 176, 0.19)',
      borderRadius: '22.5px',
      fontSize: '12px',
      height: '29px',
    }
    const key = 'updatable'
    if (window.location.href !== `${process.env.REACT_APP_DOMAIN}/` || num <= 0) {
      notification.close(key)
      return
    }
    notification.open({
      key,
      message: 'New Messages',
      description: `You have ${num} unread message${num > 1 ? 's' : ''}.`,
      icon: <FolderIcon />,
      duration: 0,
      closeIcon: <CloseIcon />,
      btn: (
        <BtnPrimary
          text="Read now"
          style={style}
          onClick={() => {
            navigate('/messages')
            notification.close(key)
          }}
        />
      ),
      className: 'notification',
      top: 50,
      style: { right: '-7px' },
    })
  }

  addClass = () => {
    const element = document.getElementById('card')
    if (element && element.className) {
      element.className += ' tada'
    }
  }

  removeClass = () => {
    setTimeout(() => {
      const element = document.getElementById('card')
      if (element && element.classList) {
        element.classList.remove('tada')
      }
    }, 1360)
  }

  render() {
    const { picture, nickname, disableMenu, userId, traineeId } = this.props.data

    const refetch = () => {
      alert('Need to refetch!')
    }

    if (!userId || !traineeId) return null

    return (
      <GlobalContext.Consumer>
        {({ total_messages, programs }) => {
          let getRate = false
          let closingProgram = programs.find((p) => p.is_closing) || {}
          if (closingProgram) {
            getRate = closingProgram.review_sent
          }

          const actions = this.showModalToday(getRate, closingProgram)
          const launchModal = actions.launch || this.state.showModal
          const pendingReview = actions.pendingReview
          this.openNotification(total_messages)
          if (window.location.href === `${process.env.REACT_APP_DOMAIN}/`) {
            this.addClass()
            setTimeout(() => {
              this.addClass()
              this.removeClass()
            }, 25000)
          } else if (window.location.href !== `${process.env.REACT_APP_DOMAIN}/messages`) {
            setTimeout(() => {
              this.addClass()
              this.removeClass()
            }, 25000)
          }

          this.removeClass()
          return (
            <>
              <Dropdown
                overlay={
                  <MainMenu
                    setModal={this.setModal}
                    pendingReview={pendingReview}
                    showOption={actions.showOption}
                    closingProgram={closingProgram}
                    {...{ isDisabledMenu: disableMenu, userId, traineeId, total_messages }}
                  />
                }
                placement="topRight"
                trigger={['click']}
                overlayClassName="profile-menu-overlay"
              >
                <Root id="card">
                  <StyledBadge size="20" count={total_messages || pendingReview}>
                    <StyledAvatar size="40" icon="user" {...{ src: picture, alt: nickname }} />
                  </StyledBadge>
                </Root>
              </Dropdown>
              <Review modalVisible={launchModal} traineeId={traineeId} refetch={refetch} setModal={this.setModal} closingProgram={closingProgram} />
            </>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Avatar
