import React from 'react'
import { StepperContainer } from './Stepper.styled'

const StepperObject = (totalItems, currentPage = 1, pageSize = 5, maxPages = 5) => {
  //forked from https://github.com/cornflourblue/jw-paginate
  let totalPages = Math.ceil(totalItems / pageSize)

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1
  } else if (currentPage > totalPages) {
    currentPage = totalPages
  }

  let startPage, endPage
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1
    endPage = totalPages
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1
      endPage = maxPages
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1
      endPage = totalPages
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage
      endPage = currentPage + maxPagesAfterCurrentPage
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i)

  // calculate the progress bar percent
  let progress = ((pages.indexOf(currentPage) + 1) / pages.length) * 100

  if (pages.length === 1) progress = 50
  else progress = progress - 100 / pages.length / 2

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages,
    progress,
  }
}

const Stepper = (props) => {
  const { steps } = props
  if (!steps || steps.length === 0) {
    return null
  }

  const stepper = StepperObject(steps.length, props.selected, 1, 5)
  const stepsToShow = steps
    .filter((s) => stepper.pages.find((p) => p === s.id))
    .map((step, index, auxiliarySteps) => {
      let classes = ''
      let showMore = false

      if (step.id === props.selected) classes += ' selected-text'
      if (step.id <= props.selected) classes += ' selected-icon'

      if (index === 0 && step.id > 1) showMore = true
      if (index === auxiliarySteps.length - 1 && step.id < steps.length) showMore = true

      return { ...step, classes, showMore }
    })

  return (
    <StepperContainer progress={stepper.progress}>
      <div className="icons">
        {stepsToShow.map((step) => {
          return (
            <div key={step.id} className={step.classes}>
              {step.showMore && <div className="more">{steps.length - stepsToShow.length}</div>}
              <img src={step.icon} alt={step.title} />
            </div>
          )
        })}
      </div>
      <div className="titles">
        {stepsToShow.map((step) => {
          return (
            <div key={step.id} className={step.classes}>
              {step.title}
            </div>
          )
        })}
      </div>
    </StepperContainer>
  )
}

export default Stepper
