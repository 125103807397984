export const adherencesDisplayMap = [
  {
    id: 'macro_adherence',
    name: 'Macro adherence',
    mockValue: 0,
    maxValue: 100,
  },
  {
    id: 'training_adherence',
    name: 'Training adherence',
    mockValue: 0,
    maxValue: 100,
  },
  {
    id: 'sleep_issues',
    name: 'Sleep issues',
    mockValue: 0,
    maxValue: 5,
  },
  {
    id: 'hunger_issues',
    name: 'Hunger issues',
    mockValue: 0,
    maxValue: 5,
  },
  {
    id: 'fatigue',
    name: 'Fatigue',
    mockValue: 0,
    maxValue: 5,
  },
  {
    id: 'lethargy',
    name: 'Lethargy',
    mockValue: 0,
    maxValue: 5,
  },
  {
    id: 'stress',
    name: 'Stress',
    mockValue: 0,
    maxValue: 5,
  },
]

export const sliderMarksShort = {
  0: '0',
  5: '5',
}

export const sliderMarksLong = {
  0: '0',
  100: '100',
}

export const periodMap = [{ name: 'No', value: false }, { name: 'Yes', value: true }]
