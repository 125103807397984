import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import SelectableCards from 'components/Selectable/Cards'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import { MaterializeInput } from 'components/Materialize'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [bodyFatMethodsCatalog, setBodyFatMethodCatalog] = useState([])
  const [bodyFatMethod, setBodyFatMethod] = useState()
  const [bodyFatPercentage, setBodyFatPercentage] = useState()
  const bodyFatMethodRef = useRef(null)
  const bodyFatPercentageRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, bf_method, bf_pct } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          const bodyFatCatalog = response.catalogs.body_fat_methods.map((bf) => {
            return {
              id: bf.id,
              name: bf.label,
              thumbnail: bf.icon,
              isReliable: bf.isReliable,
            }
          })
          setBodyFatMethodCatalog(bodyFatCatalog)
          setBodyFatMethod(bf_method)
          setBodyFatPercentage(bf_method === 'NO' ? null : bf_pct)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!loading && !!bodyFatMethod && bodyFatMethod !== 'NO' && !bodyFatPercentage) {
      scrollToRef(bodyFatPercentageRef)
    }
    validate()
  }, [bodyFatMethod, bodyFatPercentage])

  const saveCallback = async ({ goBack }) => {
    let response = false
    const data = {
      bf_method: bodyFatMethod,
      bf_pct: bodyFatPercentage ? bodyFatPercentage : null,
      was_calculated: false,
    }
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = !!bodyFatMethod
    if (bodyFatMethod !== 'NO') {
      isValid = isValid && (Number(bodyFatPercentage) > 0 && Number(bodyFatPercentage) <= 100)
    }
    if (isValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          if (!bodyFatMethod) scrollToRef(bodyFatMethodRef)
          if (!!bodyFatMethod) {
            if (Number(bodyFatPercentage) <= 0 || Number(bodyFatPercentage) > 100) scrollToRef(bodyFatPercentageRef)
          }
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'body_composition' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={bodyFatMethodRef}>
            {submit && !bodyFatMethod && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Do you know your body fat percentage?</p>
            </div>
            <div className="content">
              <SelectableCards
                type="radio"
                value={bodyFatMethod}
                catalog={bodyFatMethodsCatalog}
                onChange={(e) => setBodyFatMethod(e.target.value)}
              />
            </div>
          </QuestionContainer>
          {bodyFatMethod && bodyFatMethod !== 'NO' && (
            <QuestionContainer ref={bodyFatPercentageRef}>
              {submit && !bodyFatPercentage && <Message type="error" message={'This field is required'} />}
              <div className="question">
                <p className="title">Please fill the following field with your body fat percentage.</p>
              </div>
              <div className="content" style={{ paddingTop: '12px', paddingBottom: '55px' }}>
                <MaterializeInput
                  name="bodyFatPercentage"
                  type="number"
                  label="Body Fat (%)"
                  maxLength={5}
                  value={bodyFatPercentage || undefined}
                  isValid={bodyFatPercentage && (Number(bodyFatPercentage) > 0 && Number(bodyFatPercentage) <= 100)}
                  className="short"
                  onChange={(e) => setBodyFatPercentage(Number(e.target.value))}
                />
              </div>
            </QuestionContainer>
          )}
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
