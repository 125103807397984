import React from 'react'
import { StyledLink } from './ActiveLink.styled'

const activeLinkProps = {
  style: { color: '#ff6b00' },
}

const ActiveLink = (props) => (
  <StyledLink {...props} getProps={({ isCurrent }) => (isCurrent ? activeLinkProps : null)} />
)

export default ActiveLink
