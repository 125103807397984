import React, { Component, Fragment } from 'react'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'
import moment from 'moment'
import get from 'lodash/get'
import { Form, Select, Radio, notification } from 'antd'
import countries from 'countries'
import { LOCAL_STORAGE } from 'constants.js'
import SubmitButton from 'components/SubmitButton'
import {
  EnrollmentForm,
  EnrollmentContent,
  EnrollmentWrap,
  EnrollmentTitle,
  StyledRadioGroup,
  StyledDatePicker,
  StyledInput,
  StyledSelect,
} from 'styled'
import PictureUploader from 'components/PictureUploader'
import pictureIcon from 'images/picture.svg'

const FormItem = Form.Item
const { Option } = Select

// GQL for Personal data
const getPersonalData = gql`
  query getPersonalData($email: String!) {
    user(where: { email: { _eq: $email } }) {
      id
      picture
      birthday
      country
      email
      first_name
      last_name
      gender
    }
  }
`
const upsertPersonalData = gql`
  mutation upsertPersonalData($data: user_insert_input!) {
    insert_user(
      objects: [$data]
      on_conflict: { constraint: user_contact_id_key, update_columns: [first_name, last_name, birthday, country, email, gender, picture] }
    ) {
      returning {
        id
        gender
      }
    }
  }
`

class MainForm extends Component {
  state = { uploadedFileUrl: null }

  lastValidDate = () =>
    // const today = new Date()
    moment().subtract(18, 'years') // new Date(today.getFullYear() - 18, today.getMonth() - 1, today.getDay())

  disabledDate = (dateValue) => {
    const validDate = this.lastValidDate()
    return validDate.diff(moment(dateValue)) < 0 // dateValue.valueOf() > validDate.valueOf()
  }

  handleNewDate = (rule, value, callback) => {
    if (value && this.disabledDate(value)) {
      callback('You must be at least 18 years old')
    }

    // Note: Always need to callback
    callback()
  }

  handleUploadedFile = (uploadedFileUrl) => {
    this.setState({ uploadedFileUrl })
  }

  handleSubmit = (save, picture) => (event) => {
    event.preventDefault()
    const {
      form: { validateFieldsAndScroll },
      contact_id,
    } = this.props

    const { uploadedFileUrl } = this.state

    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, values) => {
        if (error) {
          console.error(error)
          notification.warning({ message: 'Please complete the required fields.' })
          reject()
          return
        }
        var variables = {
          data: {
            ...values,
            contact_id,
            picture: !!uploadedFileUrl ? uploadedFileUrl : picture,
          },
        }

        save({
          variables: variables,
        })
          .then((response) => {
            if (response.data) {
              localStorage.setItem(LOCAL_STORAGE.USER_GENDER, response.data.insert_user.returning[0].gender)
              if (!!uploadedFileUrl) {
                localStorage.setItem(LOCAL_STORAGE.USER_PICTURE, uploadedFileUrl)
              }
              notification.success({
                message: 'Changes has been saved successfully!',
              })
            }
          })
          .catch((error) => {
            console.log('error :>> ', error)
            notification.error({
              message: 'Something failed, try again later.',
            })
          })
      })
      resolve()
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      authEmail,
    } = this.props

    const { uploadedFileUrl } = this.state
    const eighteenYearsAgo = this.lastValidDate()
    return (
      <Query query={getPersonalData} variables={{ email: authEmail }} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading) return null
          if (error) {
            console.error(error)
            return null
          }
          const { first_name, last_name, email, birthday, country, gender, picture } = get(data, 'user[0]') || { birthday: eighteenYearsAgo }

          return (
            <Mutation mutation={upsertPersonalData}>
              {(mutation) => (
                <EnrollmentForm hideRequiredMark onSubmit={this.handleSubmit(mutation, picture)}>
                  <EnrollmentContent style={{ background: '#f4f4f4' }}>
                    <EnrollmentTitle>Personal Data</EnrollmentTitle>
                    <EnrollmentWrap>
                      <PictureUploader
                        picture={uploadedFileUrl || picture}
                        placeholder={pictureIcon}
                        onSelect={(pictureUrl) => this.handleUploadedFile(pictureUrl)}
                      />
                      <FormItem label="First Name">
                        {getFieldDecorator('first_name', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your first name!',
                            },
                          ],
                          initialValue: first_name || '',
                        })(<StyledInput />)}
                      </FormItem>
                      <FormItem label="Last Name">
                        {getFieldDecorator('last_name', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your last name!',
                            },
                          ],
                          initialValue: last_name || '',
                        })(<StyledInput />)}
                      </FormItem>
                      <FormItem label="Preferred contact email">
                        {getFieldDecorator('email', {
                          rules: [
                            {
                              type: 'email',
                              message: 'The input is not valid email!',
                            },
                          ],
                          initialValue: email || authEmail || '',
                        })(<StyledInput type="email" disabled={true} />)}
                      </FormItem>
                      <FormItem
                        label={
                          <Fragment>
                            Birthdate <span className="notation">(MM/DD/YYYY)</span>
                          </Fragment>
                        }
                      >
                        {getFieldDecorator('birthday', {
                          rules: [
                            {
                              type: 'object',
                              required: true,
                              message: 'Please input your birthdate!',
                            },
                          ],
                          initialValue: moment(birthday),
                        })(<StyledDatePicker showToday={false} disabledDate={this.disabledDate} format="MM/DD/YYYY" />)}
                      </FormItem>
                      <FormItem label="Country of residence" hasFeedback>
                        {getFieldDecorator('country', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your country!',
                            },
                          ],
                          initialValue: country || 'US',
                        })(
                          <StyledSelect
                            showSearch
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            placeholder="Select"
                            autoClearSearchValue={false}
                          >
                            {countries.map(({ label, id }) => (
                              <Option key={id} value={id}>
                                {label}
                              </Option>
                            ))}
                          </StyledSelect>,
                        )}
                      </FormItem>
                      <FormItem label="Biological Gender">
                        {getFieldDecorator('gender', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input your biological gender!',
                            },
                          ],
                          initialValue: gender || '',
                        })(
                          <StyledRadioGroup custom="inline">
                            <Radio value="female">Female</Radio>
                            <Radio value="male">Male</Radio>
                          </StyledRadioGroup>,
                        )}
                      </FormItem>
                    </EnrollmentWrap>
                  </EnrollmentContent>
                  <EnrollmentContent>
                    <FormItem>
                      <SubmitButton
                        {...{
                          handleSubmit: this.handleSubmit(mutation, picture),
                          buttonContent: 'Save',
                        }}
                      />
                    </FormItem>
                  </EnrollmentContent>
                </EnrollmentForm>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default Form.create()(MainForm)
