import React from 'react'
import { BannerContainer } from './BannerSection.styled'
import { Avatar } from 'antd'

const Banner = (props) => {
  const { title, picture, type, subTitle } = props

  return (
    <BannerContainer {...type}>
      <Avatar src={picture} icon="user" />
      <p>
        <span className="title"> {title}</span>
      </p>
      {subTitle && <>{subTitle}</>}
    </BannerContainer>
  )
}

export default Banner
