import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import countries from 'countries'
import { MaterializeInput, MaterializeSelect, MaterializeDatePicker } from 'components/Materialize'
import SelectableToggle from 'components/Selectable/Toggle'
import Female from 'assets/icons/enrollment/female.png'
import Male from 'assets/icons/enrollment/male.png'
import AvatarPlaceholder from 'assets/icons/enrollment/AvatarPlaceholder.png'
import PictureUploader from 'components/PictureUploader'
import moment from 'moment'
import { LOCAL_STORAGE } from 'constants.js'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [country, setCountry] = useState()
  const [birthdate, setBirthdate] = useState()
  const [ethnicHeritage, setEthnicHeritage] = useState()
  const [gender, setGender] = useState()
  const [genderIdentity, setGenderIdentity] = useState()
  const [ethnicCatalog, setEthnicCatalog] = useState()
  const [genderIdentificationCatalog, setGenderIdentificationCatalog] = useState()

  const [uploadedFileURL, setUploadedFileURL] = useState(null)

  const personalDataRef = useRef(null)
  const pictureRef = useRef(null)
  const ethnicRef = useRef(null)
  const genderRef = useRef(null)
  const genderIdentityRef = useRef(null)

  const female = { value: 'female', label: 'Female', icon: Female }
  const male = { value: 'male', label: 'Male', icon: Male }

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { first_name, last_name, email, country, birthday, picture, ethnic_heritage, gender, gender_identity } = response.enrollment.data
        setFirstName(first_name)
        setLastName(last_name)
        setEmail(email)
        setBirthdate(birthday)
        setCountry(country)
        setUploadedFileURL(picture)
        if (ethnic_heritage) setEthnicHeritage(ethnic_heritage)
        setGender(gender)
        if (gender_identity) setGenderIdentity(gender_identity)
        setEthnicCatalog(response.catalogs.ethnic_heritage)
        setGenderIdentificationCatalog(response.catalogs.gender_identification)
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [firstName, lastName, email, country, birthdate, ethnicHeritage, gender, genderIdentity])

  const saveCallback = async ({ goBack }) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      country,
      birthday: birthdate,
      picture: uploadedFileURL,
      ethnic_heritage: ethnicHeritage,
      gender,
      gender_identity: genderIdentity,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = !!firstName && !!lastName && !!email && !!country && !!birthdate && !!ethnicHeritage && !!gender && !!genderIdentity
    if (isValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          !genderIdentity && scrollToRef(genderIdentityRef)
          !gender && scrollToRef(genderRef)
          !ethnicHeritage && scrollToRef(ethnicRef)
          if (!firstName || !lastName || !email || !country || !birthdate) {
            scrollToRef(personalDataRef)
          }
        }
      }
    }
  }

  const lastValidDate = () => moment().subtract(18, 'years')

  const disabledDate = (dateValue) => {
    const validDate = lastValidDate()
    return validDate.diff(moment(dateValue)) < 0
  }

  const handleSetGender = (value) => {
    setGender(value)
    localStorage.setItem(LOCAL_STORAGE.USER_GENDER, value)
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'personal' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={personalDataRef}>
            {submit && (!firstName || !lastName || !email || !country || !birthdate) && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Please fill the following information fields to getting you know better.</p>
            </div>
            <div className="content">
              <MaterializeInput
                name="first_name"
                label="First Name"
                value={firstName}
                isValid={firstName && !!firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <MaterializeInput
                name="last_name"
                label="Last Name"
                value={lastName}
                isValid={lastName && !!lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <MaterializeInput name="email" label="Account email" isValid={!!email} value={email} disabled readOnly />
              <MaterializeSelect
                label="Select a Country"
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                autoClearSearchValue={false}
                value={country}
                options={countries}
                onChange={(value) => setCountry(value)}
              />
              <MaterializeDatePicker
                disabledDate={disabledDate}
                defaultPickerValue={lastValidDate()}
                style={{ width: 400 }}
                label="Birthdate (MM/DD/YYYY)"
                value={!!birthdate ? moment(birthdate) : null}
                format="MM/DD/YYYY"
                onChange={(value) => setBirthdate(value)}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={pictureRef}>
            <div className="question">
              <p className="title">Would you like to upload a profile picture?</p>
              <p>This is optional and you can do it at any moment during the Bootcamp.</p>
            </div>
            <div className="content">
              <PictureUploader
                picture={uploadedFileURL}
                placeholder={AvatarPlaceholder}
                urlModifiers="-/quality/lighter/"
                onSelect={(pictureUrl) => setUploadedFileURL(pictureUrl)}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={ethnicRef}>
            {submit && !ethnicHeritage && <Message type="error" message={'Please select an option'} />}
            <div className="question">
              <p className="title">Which of these best describes your ethnic heritage?</p>
              <p>In some instances, this info can help us determine the lower limits of fat loss.</p>
            </div>
            <div className="content">
              <MaterializeSelect value={ethnicHeritage} options={ethnicCatalog} onChange={(value) => setEthnicHeritage(value)} />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={genderRef}>
            {submit && !gender && <Message type="error" message={'Please select an option'} />}
            <div className="question">
              <p className="title">What sex were you assigned at birth?</p>
              <p>This helps us to calculate your Macros better.</p>
            </div>
            <div className="content">
              <SelectableToggle optionA={female} optionB={male} value={gender} onChange={(e) => handleSetGender(e.target.value)} />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={genderIdentityRef}>
            {submit && !genderIdentity && <Message type="error" message={'Please select an option'} />}
            <div className="question">
              <p className="title">Please tell us about your gender identity.</p>
              <p>This helps your coach know how you preferred to be addressed.</p>
            </div>
            <div className="content">
              <MaterializeSelect value={genderIdentity} options={genderIdentificationCatalog} onChange={(value) => setGenderIdentity(value)} />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
