import React from 'react'
import { CardContainer, FirstSection, ScrollContainer, ToolsObjective } from './CardInfoExercise.styled'
import { ReactComponent as ArrowDown } from '../../../../images/exercise/arrow-down-info.svg'
import { ReactComponent as Equip } from '../../../../images/exercise/kettlebell-info.svg'
import { ReactComponent as Arm } from '../../../../images/exercise/arm-exercise.svg'

const CardInfoExercise = ({ data, setData, title, description, preparation, execution, equipments, muscles }) => {
  const style = {
    marginBottom: '2px',
  }

  const muscleArr = muscles.map((muscle) => muscle.name)
  return (
    <CardContainer data={data}>
      <FirstSection>
        <div>{title}</div>
        <ArrowDown
          onClick={() => {
            setData(data === 0 ? data - 740 : data + 740)
          }}
        />
      </FirstSection>
      <div className="equipment-main">
        <ToolsObjective>
          <Equip />
          <div style={{ display: 'inline-block' }}>
            <p style={style}>Equipment:</p>
            {equipments.length > 0 && <p>{equipments[0].name}</p>}
            {equipments.length === 0 && <p style={{ textAlign: 'center' }}>-</p>}
          </div>
        </ToolsObjective>
        <ToolsObjective style={{ width: '60%' }}>
          <Arm />
          <div style={{ display: 'inline-block', maxWidth: '75%' }}>
            <p style={style}>Main Muscles:</p>
            {muscles.length > 0 && <p>{muscleArr.join('/')}</p>}
            {muscles.length === 0 && <p style={{ textAlign: 'center' }}>-</p>}
          </div>
        </ToolsObjective>
      </div>
      <div className="info-exercise">
        <ScrollContainer>
          {description && (
            <>
              <b>Description</b>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </>
          )}
          {preparation && (
            <>
              <b>Preparation</b>
              <div dangerouslySetInnerHTML={{ __html: preparation }} />
            </>
          )}
          {execution && (
            <>
              <b>Execution</b>
              <div dangerouslySetInnerHTML={{ __html: execution }} />
            </>
          )}
        </ScrollContainer>
      </div>
    </CardContainer>
  )
}

export default CardInfoExercise
