import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import SectionError from 'components/SectionError'
import Card from 'components/Cards/Card/Card'
import { apiFetch } from 'services/API/apiFetch'
import { Collapse, Tooltip } from 'antd'
import { ExercisesContainer, SessionSetsTable } from './Reports.styled'
import { GetThumbnail } from 'services/ExerciseUtilities'
import { ReactComponent as IconBar } from 'assets/icons/IconBarra.svg'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/iconPerson.svg'
import { ReactComponent as Helper } from 'assets/icons/question-circle.svg'
import { ReactComponent as Dumbbell } from 'images/exercise/dumbbell.svg'
import pass from 'assets/icons/pass.svg'
import noPass from 'assets/icons/no-pass.svg'
import { getTechniqueLabel, getTechniqueTooltip, getGoalExerciseSession } from 'services/ExerciseUtilities'
import NetworkDetector from 'services/NetworkDetector'
import ButtonFakeSuccess from 'components/CustomButtons/BtnFakeSuccess/BtnFakeSuccess'

const { Panel } = Collapse

const EmptySession = () => {
  return (
    <SectionError
      message={
        <>
          <p>You don't have data for this routine in the selected week</p>
          <p>Please choose another workout to see your report.</p>
        </>
      }
      icon={<IconBar />}
    />
  )
}

const SessionSetHeader = ({ exercise, gymEquipments }) => {
  let thumbnail = GetThumbnail(exercise)
  let equipmentsLabel = []

  if (exercise && exercise.gym_equipments) {
    exercise.gym_equipments.forEach((ge) => {
      const gymEquipment = gymEquipments.find((gec) => gec.id === ge.gym_equipment_id)
      if (gymEquipment) {
        equipmentsLabel.push(`${gymEquipment.name}${ge.quantity > 1 ? ` (${ge.quantity})` : ''}`)
      }
    })
  }

  return (
    <>
      <img src={thumbnail} alt="" />
      <div>
        <p className="exercise-name">{exercise.name}</p>
        {equipmentsLabel && (
          <p className="equipment">
            <Dumbbell />
            <span>{equipmentsLabel.join(' / ')}</span>
          </p>
        )}
      </div>
    </>
  )
}

export default ({ routine, sessions, weekSelected, exercises, techniques, gymEquipments, onSave }) => {
  const [sessionToShow, setSessionToShow] = useState()
  const [saving, setSaving] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    let sessionToShow = {}
    if (sessions && sessions.length > 0) {
      sessionToShow = sessions.find((s) => s.routine_week_id === weekSelected)
      if (sessionToShow) {
        const routineWeek = routine.routine_weeks.find((rw) => rw.id === sessionToShow.routine_week_id)
        sessionToShow.session_exercises = sessionToShow.session_exercises.map((se) => {
          const exercise = exercises.find((e) => e.id === se.exercise_id)
          const configuration = routineWeek.week_exercises.find((we) => we.exercise_id === se.exercise_id)
          return { ...se, exercise, configuration }
        })
      }
      setSessionToShow(sessionToShow)
    }
  }, [weekSelected])

  const handleSetChange = (sessionExerciseId, setId, type, value) => {
    value = Number(value)
    const sessionExercise = sessionToShow.session_exercises.find((se) => se.id === sessionExerciseId)
    if (sessionExercise) {
      const sessionSet = sessionExercise.session_sets.find((ss) => ss.id === setId)
      console.log('SESSION', sessionSet)
      if (sessionSet) {
        if (type === 'reps') sessionSet.reps = value
        if (type === 'weight') sessionSet.weight = sessionToShow.weight_unit === 'LB' ? value : value * 2.2046
        sessionSet.updated = true
      }
    }
    setSessionToShow(JSON.parse(JSON.stringify(sessionToShow)))
  }

  const handleSaveSession = async () => {
    setSaving(true)
    let setsToBeUpdated = []
    sessionToShow.session_exercises.forEach((se) => {
      setsToBeUpdated = setsToBeUpdated.concat(
        se.session_sets
          .filter((ss) => ss.updated)
          .map((ss) => {
            delete ss.updated
            return ss
          }),
      )
    })

    const result = await apiFetch({
      method: 'POST',
      url: 'routine-sessions-set',
      body: JSON.stringify(setsToBeUpdated),
      isConnected: await NetworkDetector(),
    })
      .then((data) => {
        if (!data.hasError) {
          const affectedRows = data.insert_routine_session_set.affected_rows
          if (affectedRows === setsToBeUpdated.length) {
            return data
          } else {
            return { hasError: true, data }
          }
        } else {
          return data
        }
      })
      .catch((err) => {
        return { hasError: true, err }
      })

    if (typeof onSave === 'function') {
      onSave(!result.hasError)
    }

    setSaving(false)

    if (!result.hasError) {
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 4000)
    }
  }

  if (!sessionToShow) {
    return <EmptySession />
  } else {
    const weightUnit = sessionToShow.weight_unit.toLowerCase()
    return (
      <ExercisesContainer>
        <p>Your Report</p>
        <hr />
        <Collapse accordion bordered={false} expandIconPosition={'right'} expandIcon={(state) => (state.isActive ? <ArrowUp /> : <ArrowDown />)}>
          {sessionToShow.session_exercises.map((sessionExercise) => {
            let deload
            if (sessionExercise.configuration.has_deload) {
              deload = {
                deload_week: sessionExercise.configuration.deload_week,
                deload_percent_min: sessionExercise.configuration.deload_percent_min,
                deload_percent_max: sessionExercise.configuration.deload_percent_max,
              }
            }
            const columns = [
              {
                title: 'Set',
                dataIndex: 'order',
                key: 'set',
              },
              {
                title: 'Warmup',
                key: 'warmup',
                render: (set) => {
                  return <PersonIcon width="14.15px" height="29px" className={!set.is_warmup ? 'not-warmup' : ''} />
                },
              },
              {
                title: 'Reps',
                key: 'reps',
                render: (set) => {
                  return (
                    <input
                      value={Number(set.reps).toString()}
                      onChange={(e) => handleSetChange(sessionExercise.id, set.id, 'reps', e.target.value)}
                      type="number"
                      step="1"
                      min="0"
                    />
                  )
                },
              },
              {
                title: 'Weight',
                key: 'weight',
                render: (set) => {
                  return (
                    <>
                      <input
                        value={sessionToShow.weight_unit === 'LB' ? Number(set.weight).toString() : Number(set.weight / 2.2046).toString()}
                        onChange={(e) => handleSetChange(sessionExercise.id, set.id, 'weight', e.target.value)}
                        type="number"
                      />{' '}
                      <span>{weightUnit}</span>
                    </>
                  )
                },
              },
              {
                title: 'Volume',
                key: 'volume',
                render: (set) => {
                  let volume = Math.round(set.reps * (sessionToShow.weight_unit === 'LB' ? set.weight : set.weight / 2.2046))
                  return (
                    <p>
                      <span className={volume.toString().length > 3 ? 'volume reduce-text' : 'volume'}>{`${volume}`}</span> {weightUnit}
                    </p>
                  )
                },
              },
              {
                title: ' ',
                key: 'complete',
                render: (set) => {
                  if (set.reps >= set.reps_goal) {
                    return <img src={pass} alt="Complete" />
                  }
                  return <img src={noPass} alt="Complete" />
                },
              },
            ]

            const techniqueTooltip = getTechniqueTooltip(sessionExercise.configuration.technique, techniques, sessionExercise.technique_notes)
            return (
              <Panel header={<SessionSetHeader {...{ exercise: sessionExercise.exercise, gymEquipments }} />} key={sessionExercise.id}>
                <p>Goal</p>
                <hr />
                <div className="container-goal">
                  <Card>
                    <p style={{ fontWeight: 'bold' }}>
                      {getGoalExerciseSession(sessionExercise.configuration.week_sets, sessionExercise.configuration.technique)}
                    </p>
                    <p>Sets x {sessionExercise.configuration.reps_unit}</p>
                  </Card>
                  <Card>
                    <p
                      style={{ fontWeight: 'bold', wordBreak: 'break-word' }}
                      dangerouslySetInnerHTML={getTechniqueLabel(sessionExercise.configuration.technique, techniques, deload)}
                    />
                    <p>Technique</p>
                    {techniqueTooltip && (
                      <Tooltip title={techniqueTooltip}>
                        <Helper className="technique-helper" />
                      </Tooltip>
                    )}
                  </Card>
                </div>
                <p>Achieved</p>
                <hr />
                <SessionSetsTable columns={columns} dataSource={sessionExercise.session_sets} rowKey="id" pagination={false} />
                {/* <SessionSets {...{ sessionExercise: exercise, weightUnit: sessionToShow.weight_unit, techniques, onSetChange: handleSetChange }} /> */}
              </Panel>
            )
          })}
        </Collapse>
        {showSuccess && <ButtonFakeSuccess />}
        {!showSuccess && (
          <Button type="primary" block={true} onClick={handleSaveSession} loading={saving}>
            Save Changes
          </Button>
        )}
      </ExercisesContainer>
    )
  }
}
