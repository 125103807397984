import React, { Component } from 'react'
import ActiveLink from 'components/ActiveLink'
import { Root, Li } from './MainMenu.styled'
import { Button, Badge } from 'antd'
class MainMenu extends Component {
  constructor(props) {
    super(props)
    this.handleClickReview = this.handleClickReview.bind(this)
  }

  handleClickReview(value) {
    this.props.setModal(value)
  }

  getNameBootcamp = (closingProgram) => {
    if (closingProgram && closingProgram.description) {
      const arrBoot = closingProgram.description.split(' ')
      return `${arrBoot[0]} ${arrBoot[1]}`
    } else {
      return 'this bootcamp'
    }
  }

  render() {
    const { isDisabledMenu, unreadMessages, pendingReview, showOption, closingProgram } = this.props
    return (
      <Root>
        <ul>
          <Li>
            <ActiveLink disabled={isDisabledMenu} to="/personal/edit">
              Personal info
            </ActiveLink>
          </Li>
          <Li>
            {/* TODO: *quickfix* Removed disabled={isDisabledMenu} attribute to enable messages */}
            <ActiveLink disabled={isDisabledMenu} to="/messages">
              {!!unreadMessages && `(${unreadMessages})`} Messages
            </ActiveLink>
          </Li>
          {showOption ? (
            <Li>
              <Button type="link" className="btn-review" onClick={() => this.handleClickReview(true)}>
                {`Review ${this.getNameBootcamp(closingProgram)}`}
              </Button>
              {pendingReview ? <Badge status="error" style={{ position: 'absolute', bottom: '33px', right: '27px' }} /> : null}
            </Li>
          ) : null}
        </ul>
      </Root>
    )
  }
}

export default MainMenu
