import styled from '@emotion/styled'
import HeaderBanner from 'images/banner-exercises.png'

export const BannerContainer = styled.header`
  background-image: url(${HeaderBanner}), linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
  ${(reports) => reports && 'background-size: cover;'}
  display: flex;
  flex-direction: column;
  min-height: 160px;
  align-items: center;
  padding: 13px 18px 18px;
  width: 100%;
  margin-bottom: 12px;

  .ant-avatar {
    border: 2px solid;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 42px;
  }

  p {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0px;

    .title {
      font-size: 22px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`
