import React, { Component } from 'react'
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward.svg'
import Layout from 'components/Layout'
import { SmallNotificationBanner } from 'components/Homepage/Homepage.styled'
import Countdown from 'components/Countdown/Countdown'
import { Root, Content, WrapTimeLine } from './BootcampTimeLine.styled'
import Tutorial from 'components/Tutorial'
import TutorialTimeline from 'components/Tutorial/TutorialTimeline'
import TutorialBannerBackground from 'images/Tutorial/tutorial-banner-background.svg'
import TutorialBannerPin from 'images/Tutorial/tutorial-banner-pin.svg'
import GlobalContext from 'context/GlobalContext'

class TutorialBanner extends React.Component {
  state = {
    openTutorial: false,
  }

  ShowTutorial = () => {
    this.setState({
      openTutorial: true,
    })
  }

  handleCloseTutorial = () => {
    this.setState({
      openTutorial: false,
    })
  }

  render() {
    const { type, style } = this.props
    return (
      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <SmallNotificationBanner background={TutorialBannerBackground} onClick={this.ShowTutorial} style={style}>
          {type === 'newStudent' && <p>New to Ketogains?</p>}
          {type !== 'newStudent' && <img src={TutorialBannerPin} alt="" />}
          <div>
            Take the Portal Tour!
            <ArrowForward className="anticon" />
          </div>
        </SmallNotificationBanner>
        <Tutorial showed={this.state.openTutorial} onCloseTutorial={this.handleCloseTutorial} />
      </div>
    )
  }
}

class BootcampTimeLine extends Component {
  ShowTutorial = () => {
    this.setState({
      openTutorial: true,
    })
  }

  handleCloseTutorial = () => {
    this.setState({
      openTutorial: false,
    })
  }

  render() {
    const { programId } = this.props
    return (
      <GlobalContext.Consumer>
        {({ user }) => {
          return (
            <Layout>
              <Root>
                <Content>
                  {!!programId && user.trainee_id && (
                    <WrapTimeLine>
                      <TutorialBanner />
                      <Countdown {...{ expanded: false, programId: programId, viewButtonTimeline: false }} />
                      <TutorialTimeline programId={programId} traineeId={user.trainee_id} />
                      {!user.is_returning && <TutorialBanner type="newStudent" style={{ flexDirection: 'column', padding: '40px 0px' }} />}
                    </WrapTimeLine>
                  )}
                </Content>
              </Root>
            </Layout>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default BootcampTimeLine
