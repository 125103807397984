import React, { Component } from 'react'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import moment from 'moment'
import { notification } from 'antd'
import EnrollmentData from './EnrollmentData'
import { Redirect } from '@reach/router'
import GlobalContext from 'context/GlobalContext'

const getEnrollmentData = gql`
  query getEnrollmentData(
    $id: uuid!
    $program_id: uuid
    $programDate: String
    $programDateEnd: String
    $next_program: uuid
    $next_programDate: String
    $next_programDateEnd: String
  ) {
    trainee(where: { id: { _eq: $id } }) {
      current_data: trainee_details(where: { program_id: { _eq: $program_id } }) {
        id
        neck
        chest
        shoulder
        arm
        forearm
        belly_waist
        narrow_waist
        hip
        thigh
        calf
        current_goal
        height
        weight
        bf_pct
        bf_method
        level
        metric
        photo_front_url
        photo_side_url
        photo_back_url
      }
      next_data: trainee_details(where: { program_id: { _eq: $next_program } }) {
        id
        neck
        chest
        shoulder
        arm
        forearm
        belly_waist
        narrow_waist
        hip
        thigh
        calf
        current_goal
        height
        weight
        bf_pct
        bf_method
        level
        metric
        photo_front_url
        photo_side_url
        photo_back_url
      }
      current_macros: macros(where: { program_id: { _eq: $program_id } }) {
        status
      }
      next_macros: macros(where: { program_id: { _eq: $next_program } }) {
        status
      }
    }
    current_program: program(where: { program_id: { _eq: $program_id } }) {
      description
      program_id
    }
    next_program: program(where: { program_id: { _eq: $next_program } }) {
      description
      program_id
    }
  }
`
const updateEnrollmentData = gql`
  mutation updateEnrollmentData(
    $detailsId: uuid!
    $neck: numeric
    $chest: numeric
    $shoulder: numeric
    $arm: numeric
    $forearm: numeric
    $belly_waist: numeric
    $narrow_waist: numeric
    $hip: numeric
    $thigh: numeric
    $calf: numeric
    $current_goal: String
    $height: numeric
    $weight: numeric
    $bf_pct: numeric
    $bf_method: String
    $level: Int
    $photo_front_url: String
    $photo_side_url: String
    $photo_back_url: String
    $need_coach_approval: Boolean
  ) {
    update_trainee_details(
      where: { id: { _eq: $detailsId } }
      _set: {
        neck: $neck
        chest: $chest
        shoulder: $shoulder
        arm: $arm
        forearm: $forearm
        belly_waist: $belly_waist
        narrow_waist: $narrow_waist
        hip: $hip
        thigh: $thigh
        calf: $calf
        current_goal: $current_goal
        height: $height
        weight: $weight
        bf_pct: $bf_pct
        bf_method: $bf_method
        level: $level
        photo_front_url: $photo_front_url
        photo_side_url: $photo_side_url
        photo_back_url: $photo_back_url
        need_coach_approval: $need_coach_approval
      }
    ) {
      returning {
        id
      }
    }
  }
`

class Enrollment extends Component {
  handleSave = ({
    detailsId,
    neck,
    chest,
    shoulder,
    arm,
    forearm,
    belly_waist,
    narrow_waist,
    hip,
    thigh,
    calf,
    current_goal,
    height,
    weight,
    bf_pct,
    bf_method,
    level,
    photo_front_url,
    photo_side_url,
    photo_back_url,
    need_coach_approval,
    refetch,
  }) => {
    apolloClient
      .mutate({
        mutation: updateEnrollmentData,
        variables: {
          detailsId: detailsId,
          neck: neck,
          chest: chest,
          shoulder: shoulder,
          arm: arm,
          forearm: forearm,
          belly_waist: belly_waist,
          narrow_waist: narrow_waist,
          hip: hip,
          thigh: thigh,
          calf: calf,
          current_goal: current_goal,
          height: height,
          weight: weight,
          bf_pct: bf_pct,
          bf_method: bf_method,
          level: level,
          photo_front_url: photo_front_url,
          photo_side_url: photo_side_url,
          photo_back_url: photo_back_url,
          need_coach_approval: need_coach_approval,
        },
      })
      .then(function(data) {
        refetch()
        notification.success({
          message: 'Changes saved correctly!',
          description: 'Your changes have been saved and updated correctly',
          style: {
            width: '100%',
            top: 42,
            marginLeft: 8,
          },
        })
      })
      .catch((err) => {
        console.log(err)
        notification.error({
          message: 'Something happened!',
          description: 'Please contact us to solve this issue',
          style: {
            width: '100%',
            top: 42,
            marginLeft: 8,
          },
        })
      })
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const upcomingProgram = programs.find((p) => p.is_upcoming) || {}

          const currentPicsDate = moment(currentProgram.start_date)
            .subtract(1, 'week')
            .format('YYYYMMDD')
          const nextPicsDate = moment(upcomingProgram.start_date)
            .subtract(1, 'week')
            .format('YYYYMMDD')

          return (
            <Query
              query={getEnrollmentData}
              fetchPolicy="cache-and-network"
              variables={{
                id: user.trainee_id,
                program_id: currentProgram.program_id,
                programDate: currentPicsDate,
                programDateEnd: moment(currentPicsDate)
                  .add(6, 'day')
                  .format('YYYYMMDD'),
                next_program: upcomingProgram.program_id,
                next_programDate: nextPicsDate,
                next_programDateEnd: moment(nextPicsDate)
                  .add(6, 'day')
                  .format('YYYYMMDD'),
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (error) {
                  console.log(error)
                  return null
                }

                if (!loading) {
                  let bootcamps = []

                  let currentData =
                    data && data.trainee[0].current_data && data.trainee[0].current_data.length === 1 ? data.trainee[0].current_data[0] : null
                  let currentMacrosStatus =
                    data && data.trainee[0].current_macros && data.trainee[0].current_macros.length === 1 ? data.trainee[0].current_macros[0] : null
                  let nextData = data && data.trainee[0].next_data && data.trainee[0].next_data.length === 1 ? data.trainee[0].next_data[0] : null
                  let nextMacrosStatus =
                    data && data.trainee[0].next_macros && data.trainee[0].next_macros.length === 1 ? data.trainee[0].next_macros[0] : null

                  if (currentProgram && currentProgram.is_enrollment_completed) {
                    bootcamps.push({
                      bootcamp: 'current',
                      id: currentProgram.program_id,
                      description: currentProgram.description,
                    })
                  }

                  if (upcomingProgram && upcomingProgram.is_enrollment_completed) {
                    bootcamps.push({
                      bootcamp: 'upcoming',
                      id: upcomingProgram.program_id,
                      description: upcomingProgram.description,
                    })
                  }

                  if (bootcamps.length <= 0) {
                    return <Redirect to="/" noThrow />
                  }

                  return (
                    <EnrollmentData
                      {...{
                        bootcamps,
                        currentData,
                        currentMacrosStatus,
                        nextData,
                        nextMacrosStatus,
                        handleSave: this.handleSave,
                        refetch,
                      }}
                    />
                  )
                } else {
                  return null
                }
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Enrollment
