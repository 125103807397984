import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Root = styled.div`
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 56px;
`

export const Title = styled.h2`
  color: #4a4a4a;
  font-size: 12px;
  text-align: center;
`

export const UploadedPicture = styled.img`
  max-width: 100%;
  height: 140px;
  display: block;
  margin: auto;
  border-radius: 2px;
  ${({ isFileUploaded }) =>
    isFileUploaded &&
    css`
      width: 210px;
      height: 210px;
      object-fit: cover;
    `}
`
