import React, { useState } from 'react'
import './index.scss'
import { Select } from 'antd'
const { Option } = Select

const MaterializeSelect = ({ value, label, className, options, onChange, showSearch, filterOption, autoClearSearchValue, disabled }) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className={`${className ? className : ''} ${disabled ? 'disabled' : ''} wrap`}>
      <div className={`${isFocused || value ? 'is-active is-completed' : ''} mat-div`}>
        <label htmlFor={label} className="mat-label">
          {label || 'Select an option'}
        </label>
        <Select
          value={value}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={(e) => {
            setIsFocused(false)
          }}
          disabled={disabled}
          onChange={onChange}
          showSearch={showSearch}
          filterOption={filterOption}
          autoClearSearchValue={autoClearSearchValue}
        >
          {options.map((element) => {
            return (
              <Option className="optionMultiple" key={element.id} value={element.id}>
                {element.label}
              </Option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default MaterializeSelect
