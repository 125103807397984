import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import LayoutNavigation from 'components/LayoutNavigation'
import SectionError from 'components/SectionError'
import { getCache, getCacheWhere } from 'services/db/indexedDB'
import CarouselWorkout from '../Workout/CarouselWorkout/CarouselWorkout'
import Banner from '../Main/Banner/Banner'
import { apiFetch } from 'services/API/apiFetch'
import NetworkDetector from 'services/NetworkDetector'
import Loading from 'components/Loading/Loading'
import moment from 'moment'
import CircleWeeks from './CircleWeeks'
import SessionExercises from './SessionExercises'
import Message from 'components/Message'
import { LOCAL_STORAGE } from 'constants.js'

export default () => {
  const [loading, setLoading] = useState(true)
  const [connected, setConnected] = useState(false)
  const [workout, setWorkout] = useState({})
  const [routineSelected, setRoutineSelected] = useState()
  const [weekSelected, setWeekSelected] = useState()
  const [exercises, setExercises] = useState([])
  const [techniques, setTechniques] = useState([])
  const [gymEquipments, setGymEquipments] = useState([])
  const [message, setMessage] = useState({ showed: false })

  const today = moment().format('YYYY-MM-DD')

  useEffect(() => {
    const handleAsync = async () => {
      const workoutPlansCache = await getCache('workout_plans').catch((e) => console.error(e))
      const workoutPlanCache = workoutPlansCache.find((wp) => wp.is_current === 1 && wp.program.start_date <= today && wp.program.close_date >= today)
      if (!workoutPlanCache) {
        navigate('/my-exercises')
        return
      }

      const isConnected = await NetworkDetector()
      setConnected(isConnected)

      if (isConnected) {
        const workoutPlanDB = await apiFetch({
          url: `students/${localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)}/workouts/${
            workoutPlanCache.id
          }?with_sessions=true&with_routines=true&with_exercises=true`,
          method: 'GET',
          cacheTable: null,
          expiredIn: null,
          isConnected: isConnected,
        }).catch((err) => err)
        if (!workoutPlanDB.hasError && workoutPlanDB.length > 0) {
          const gymEquipments = await getCache('gym_equipments').catch((e) => console.error(e))
          const exercises = await getCache('exercises').catch((e) => console.error(e))
          const techniques = await getCacheWhere('configuration', { id: 'techniques' }).catch((e) => console.error(e))
          setWorkout(workoutPlanDB[0])
          setRoutineSelected(workoutPlanDB[0].routines[0])
          setWeekSelected(workoutPlanDB[0].routines[0].routine_weeks[0].id)
          setExercises(exercises)
          setTechniques(techniques.length > 0 && techniques[0].value ? techniques[0].value : [])
          setGymEquipments(gymEquipments)
        }
      }

      setLoading(false)
    }
    handleAsync()
  }, [])

  const handleSelectRoutine = (routine) => {
    setRoutineSelected(routine)
    setWeekSelected(routine.routine_weeks[0].id)
  }

  return (
    <LayoutNavigation title="Report by type of day" hideFooter={true} isBack={true}>
      {loading && (
        <Loading>
          <div className="rectangle" />
          <div className="rectangle" />
          <div className="rectangle" />
        </Loading>
      )}
      {!loading && (
        <>
          {(!connected || !workout.routines) && (
            <>
              <Banner
                name={localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)}
                picture={localStorage.getItem(LOCAL_STORAGE.USER_PICTURE)}
                reports={true}
                style={{ marginBottom: 0 }}
              />
              <SectionError type="LOST_NETWORK" />
            </>
          )}
          {workout.routines && workout.routines.length > 0 && (
            <>
              <CarouselWorkout
                title="Select a routine to edit"
                routines={workout.routines}
                handleSelectRoutine={handleSelectRoutine}
                reports={true}
              />
              {message.showed && (
                <Message
                  type={message.type}
                  message={message.message}
                  cardStyle={{ margin: '10px 0px -10px' }}
                  textStyle={{ fontSize: '14px', color: '#5d5d5d', fontWeight: '600' }}
                />
              )}

              <CircleWeeks {...{ routine: routineSelected, weekSelected, onWeekSelected: setWeekSelected }} />
              <SessionExercises
                {...{
                  routine: routineSelected,
                  sessions: workout.routine_sessions,
                  weekSelected,
                  exercises,
                  techniques,
                  gymEquipments,
                  onSave: (success) => {
                    setMessage({
                      showed: true,
                      type: success ? 'success' : 'warning',
                      message: success ? 'Your workout data was successfully saved!' : 'Changes not saved. Please try again.',
                    })
                    setTimeout(() => {
                      setMessage({ showed: false })
                    }, 4000)
                  },
                }}
              />
            </>
          )}
        </>
      )}
    </LayoutNavigation>
  )
}
