import styled from '@emotion/styled'

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  width: 95%;
  padding: 20px 10px;
  text-align: center;
  margin-bottom: 10px;

  .message {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #c4c4c4;
  }

  .icon {
    width: 100px;
    height: 100px;

    path,
    circle {
      stroke: rgb(207, 207, 207);
      fill: rgb(207, 207, 207);
    }
  }
`
