import styled from '@emotion/styled'
import { Card } from 'antd'

export const CarouselCard = styled(Card)`
  width: 80% !important;
  height: 104px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;

  .ant-card {
    height: 104px;
    margin: 0px;
  }

  .ant-card-body {
    width: 100%;
    padding: 0px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div:nth-of-type(2) {
      & p:nth-of-type(1) {
        max-width: 100%;
        height: 18px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #ff6b00;
        text-align: left;
      }
      & p:nth-of-type(2) {
        max-width: 100%;
        height: 23px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #4a4a4a;
        text-align: left;
      }
    }
  }

  .container {
    left: 16px !important;
  }

  .title {
    position: relative !important;
    left: 10px !important;
  }

  .text {
    width: 100%;
    max-width: 214px;
    height: 23px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #4a4a4a;
    margin: 0px 0px 0px 2px;
    position: relative !important;
    left: 10px !important;
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    .text {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 320px) {
    .text {
      font-size: 14px;
    }
  }
`
