import React, { useContext } from 'react'
import BannerSection from 'components/Banner/BannerSection'
import GlobalContext from 'context/GlobalContext'
import { Query } from 'react-apollo'
import LayoutNavigation from 'components/LayoutNavigation/LayoutNavigation'
import { Icon, Result } from 'antd'
import moment from 'moment'
import { Root } from '../subscriptions.styled'
import { Wrap, Content, DownloadBottom } from './History.styled'
import { getProgram } from '../querys'
import PDFReportProgress from '../../../components/Homepage/PDFProgressReport'

const BootcampHistoric = () => {
  const globalContext = useContext(GlobalContext)
  const traineeId = globalContext.user.trainee_id

  const getCancellationReason = (reason) => {
    let reasonFormat
    switch (reason) {
      case 'MANUAL':
        reasonFormat = 'Blocked by a Ketogains administrator'
        break
      case 'PAYMENT_FAILURE':
        reasonFormat = 'Blocked by payment issues'
        break
      case 'UNFINISHED_ENROLLMENT':
        reasonFormat = 'Blocked by unfinished enrollment'
        break
      case 'UNSTARTED':
        reasonFormat = 'Blocked by unstarted bootcamp'
        break
      default:
        reasonFormat = 'Blocked by unknown reason'
        break
    }
    return reasonFormat
  }

  const enrollmentCompleted = (bootcamp) => {
    if (bootcamp !== null) {
      if (bootcamp[0].id) {
        const enrollment = bootcamp.filter((b) => b.id === 'finish')
        if (enrollment.length > 0) {
          return true
        }
        return false
      } else if (!bootcamp[0].id) {
        const enrollment = bootcamp.filter((b) => b === 'finish')
        if (enrollment.length > 0) {
          return true
        }
        return false
      }
    }
    return false
  }

  return (
    <Query query={getProgram} fetchPolicy="cache-and-network" variables={{ traineeId }}>
      {({ loading, error, data }) => {
        if (loading) return null
        if (error) return null
        const bootcamps = data.trainee_program_coach

        return (
          <LayoutNavigation title="My Subscription History" hideFooter isBack menu>
            <Root>
              <div
                style={{
                  backgroundColor: '#fff',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: '400px',
                  marginTop: '-50px',
                  minWidth: '360px',
                }}
              >
                <BannerSection title={`${globalContext.user.first_name}'s Subscriptions`} picture={globalContext.user.picture} />
                <Wrap>
                  <Content>
                    {bootcamps.length === 0 && <Result status="info" title="No subscriptions... yet" />}
                    {bootcamps.length > 0 && (
                      <div className="content">
                        {bootcamps.map((b) => {
                          return (
                            <div className={`subscription ${b.is_active ? '' : 'blocked'}`} key={b.program_id}>
                              <div>
                                <p>{b.program.description}</p>
                                {!b.is_active && (
                                  <>
                                    <p>{getCancellationReason(b.cancellation_reason)}</p>
                                    <p>Blocked Date: {moment(b.cancellation_date).format('YYYY-MM-DD HH:mm')}</p>
                                  </>
                                )}
                              </div>
                              <div>
                                {b.is_active && enrollmentCompleted(b.enrollment_stage) && (
                                  <>
                                    {/* <p>{b.enrollment_stage}</p> */}
                                    <DownloadBottom
                                      onClick={PDFReportProgress(b.program.start_date, traineeId, b.program.program_id)}
                                      custom="transparentLink"
                                    >
                                      <Icon type="download" style={{ fontSize: '16px' }} />
                                    </DownloadBottom>
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </Content>
                </Wrap>
              </div>
            </Root>
          </LayoutNavigation>
        )
      }}
    </Query>
  )
}

export default BootcampHistoric
