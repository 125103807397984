import React, { Component } from 'react'
import { Match } from '@reach/router'
import moment from 'moment'
import Navigation from '../Navigation'
import Logo from '../Logo'
import Avatar from '../Avatar'
import { Root } from './Header.styled'
import GlobalContext from 'context/GlobalContext'

const disableEnrollmentMenu = (has_enrollment, startDate, duration, currentProgramId) => {
  if (has_enrollment) {
    return true
  }

  if (!!!currentProgramId) {
    return true
  }

  if (!!!startDate && !!!duration) {
    return true
  }

  const today = moment()
  return !(today > moment(startDate) && today < moment(startDate).add(duration, 'week'))
}

const disableDailyMenu = (has_enrollment, startDate, duration) => {
  if (!!has_enrollment || !startDate || !duration) {
    return true
  }
  const today = moment()
  return today < moment(startDate) || today > moment(startDate).add(duration, 'week')
}

const disableAvatarMenu = (currentProgram, has_enrollment, is_returning) => {
  if (!!currentProgram) {
    return false
  }
  return has_enrollment && !is_returning
}

class Header extends Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const upcomingProgram = programs.find((p) => p.is_upcoming) || {}

          let has_enrollment = false

          if (currentProgram.program_id && !currentProgram.is_enrollment_completed) {
            has_enrollment = true
          } else if (upcomingProgram.program_id && !upcomingProgram.is_enrollment_completed) {
            has_enrollment = true
          }

          return (
            <Match path="/:dummy/edit">
              {({ match }) => (
                <Root>
                  <Navigation
                    {...{
                      isDisabledEnrollment: disableEnrollmentMenu(
                        has_enrollment,
                        currentProgram.start_date,
                        currentProgram.duration,
                        currentProgram.program_id,
                      ),
                      isDisabledDaily: disableDailyMenu(has_enrollment, currentProgram.start_date, currentProgram.duration),
                      has_enrollment,
                      currentProgramId: currentProgram.program_id,
                      current_has_enrollment: !currentProgram.is_enrollment_completed,
                      new_program: upcomingProgram.program_id,
                      next_has_enrollment: !upcomingProgram.is_enrollment_completed,
                      is_returning: user.is_returning,
                      isOpenedEnrollmentData: !!match,
                    }}
                  />
                  <Logo />
                  <Avatar
                    data={{
                      picture: user.picture,
                      nickname: user.nickname,
                      disableMenu: disableAvatarMenu(currentProgram.program_id, has_enrollment, user.is_returning),
                      userId: user.id,
                      traineeId: user.trainee_id,
                    }}
                  />
                </Root>
              )}
            </Match>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Header
