import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableCards from 'components/Selectable/Cards'
import { getSuggestedGoal, getBodyFatPercentage } from 'services'
import { LOCAL_STORAGE } from 'constants.js'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [goal, setGoal] = useState()
  const [goalCatalog, setGoalCatalog] = useState([])
  const goalRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const catalogGoals = response.catalogs.goal.map((g) => {
          return {
            id: g.id,
            name: g.label,
            thumbnail: g.icon,
            helper: g.description,
          }
        })
        const { id, current_goal, bf_pct, metric, height, neck, belly_waist, narrow_waist, hip } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          let goalAux = current_goal
          if (!goalAux) {
            const gender = localStorage.getItem(LOCAL_STORAGE.USER_GENDER)
            let bodyFatPercent = bf_pct
            let unit = 'in'
            const metricSplit = metric.split('/')
            if (metricSplit.length > 1) unit = metricSplit[1]
            if (!bodyFatPercent) {
              bodyFatPercent = getBodyFatPercentage(gender, unit, height, neck, belly_waist, narrow_waist, hip)
            }
            goalAux = getSuggestedGoal(gender, bodyFatPercent, response.catalogs.goal)
          }

          setGoalCatalog(catalogGoals)
          setGoal(goalAux)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [goal])

  const saveCallback = async ({ goBack }) => {
    const data = { current_goal: goal }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (goal) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(goalRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'finish' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={goalRef}>
          {submit && !goal && <Message type="error" message="Please complete all of the mandatory fields" />}
          <div className="question">
            <p className="title">Based on the information you provided, we recommend your goal.</p>
            <p>It's best to follow our suggestions, but know that you can also self select your own goal and bypass our suggestions.</p>
          </div>
          <div className="content">
            <SelectableCards type="radio" value={goal} catalog={goalCatalog} onChange={(e) => setGoal(e.target.value)} />
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
