import styled from '@emotion/styled'
import { Card } from 'antd'
import passWhite from '../../../../images/exercise/pass-white.svg'
import pass from '../../../../images/exercise/pass.svg'

export const CardUnits = styled(Card)`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  margin-top: 15px;

  .container-pref-units {
    width: 225px;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
  }

  p {
    font-size: 11px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;

    color: #4a4a4a;
  }

  .ant-card-body {
    display: flex;
    padding: 8px 0px 8px 20px;
    width: 100%;
    /* max-width: 356px; */
  }

  .opt-measurements {
    width: 131px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    & span:nth-of-type(1) {
      top: 11px;
    }

    & span:nth-of-type(2) {
      top: -15px;
    }
  }

  .ant-switch {
    width: 55px;
    height: 28px;
    position: relative;
    bottom: 8px;
    background: #efefef;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    &::after {
      content: '';
      width: 24px;
      height: 24px;
      background: #ff6b00;
      background-image: url(${passWhite});
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }
  }

  .ant-switch-checked {
    width: 55px;
    height: 28px;
    position: relative;
    bottom: 8px;
    background: #ff6b00;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    &::after {
      content: '';
      width: 24px;
      height: 24px;
      background: #ffffff;
      background-image: url(${pass});
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    }
  }

  span {
    position: relative;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ff6b00;
  }

  .change-units {
    visibility: hidden;
  }
`
