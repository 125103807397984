import React, { Component, Fragment } from 'react'
import { validateField } from 'services/validation'
import { MEASUREMENTS_DISPLAY_MAP } from 'constants.js'
import { Icon } from 'antd'
import { StyledFormItem } from './WeeksEdit.styled'
import { StyledInput } from 'styled'

class WeeklyMeasurements extends Component {
  render() {
    const { getFieldDecorator, getFieldValue, measurementData, unitsLength, prevMeasurementData } = this.props

    const formItemLayout = {
      labelCol: { span: 11 },
      wrapperCol: { span: 13 },
    }

    return (
      <>
        {MEASUREMENTS_DISPLAY_MAP.map(({ id, name, description }) => (
          <Fragment key={id}>
            <StyledFormItem
              {...{
                formItemLayout,
                validateStatus: (validateField(getFieldValue(id), prevMeasurementData[id]) && 'warning') || '',
                help: validateField(getFieldValue(id), prevMeasurementData[id]) && (
                  <span className="warning-cont">
                    <span className="icon-container">
                      <Icon type="exclamation-circle" theme="filled" />
                    </span>
                    <span>This is very different from last week! Are you using a different unit of measurement? Did you mistype your entry?</span>
                  </span>
                ),
              }}
            >
              {getFieldDecorator(id, {
                initialValue: measurementData[id] ? measurementData[id] : MEASUREMENTS_DISPLAY_MAP[id],
              })(
                <StyledInput
                  className="input-with-fake-label"
                  type="number"
                  step=".01"
                  min="0"
                  addonBefore={
                    <div className="fake-label">
                      {name} <small>{description}</small>
                    </div>
                  }
                  addonAfter={unitsLength}
                />,
              )}
            </StyledFormItem>
          </Fragment>
        ))}
      </>
    )
  }
}

export default WeeklyMeasurements
