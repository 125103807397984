import React, { useContext, useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { navigate } from '@reach/router'
import { Root } from './Macros.styled'
import GlobalContext from 'context/GlobalContext'
import Loading from 'components/Loading/Loading'
import BannerSection from 'components/Banner/BannerSection'
import SectionError from 'components/SectionError'
import BootcampSelect from 'components/BootcampSelect/'
import apolloClient from 'apolloClient'
import moment from 'moment'
import { getMacros } from './data'
import { MacroTabs } from './Macros.styled'
import { Tabs, Icon } from 'antd'
import MacroTable from './MacroTable'

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'

import MacroDownload from 'images/Download.svg'
import MacrosPDF from './MacrosPDF'
import Message from 'components/Message'
import CardResult from 'components/Cards/Result'

const { TabPane } = Tabs

const index = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const globalContext = useContext(GlobalContext)
  const [programs, setPrograms] = useState([])
  const [macros, setMacros] = useState([])
  const [isBootcamp, setIsBootcamp] = useState(false)
  const [showDailies, setShowDailies] = useState(false)
  const [restDailies, setRestDailies] = useState([])
  const [trainingDailies, setTrainingDailies] = useState([])
  const [programSelected, setProgramSelected] = useState()
  const [macroSelected, setMacroSelected] = useState()
  const [showPrintSection, setShowPrintSection] = useState(false)
  const [isEnrollmentCompleted, setIsEnrollmentCompleted] = useState(false)

  useEffect(() => {
    if (globalContext.programs.length <= 0) {
      navigate('/')
    } else {
      const currentProgram = globalContext.programs.find((p) => p.is_current) || {}
      const upcomingProgram = globalContext.programs.find((p) => p.is_upcoming) || {}
      const closingProgram = globalContext.programs.find((p) => p.is_closing) || {}
      let programsToShow = []
      let bootcampStartDate = moment()
        .local()
        .format('YYYYMMDD')
      let programDefault
      let isEnrollmentCompleted

      if (currentProgram.program_id) {
        programsToShow.push({ id: currentProgram.program_id, label: currentProgram.description, isCurrent: true })
      }

      if (upcomingProgram.program_id) {
        programsToShow.push({ id: upcomingProgram.program_id, label: upcomingProgram.description, isCurrent: false })
      }

      if (closingProgram.program_id) {
        programsToShow.push({ id: closingProgram.program_id, label: closingProgram.description, isCurrent: true })
      }

      if (currentProgram.program_id) {
        isEnrollmentCompleted = currentProgram.is_enrollment_completed
        programDefault = currentProgram.program_id
        bootcampStartDate = moment(currentProgram.start_date)
          .local()
          .format('YYYYMMDD')
      } else if (upcomingProgram.program_id) {
        isEnrollmentCompleted = upcomingProgram.is_enrollment_completed
        programDefault = upcomingProgram.program_id
        bootcampStartDate = moment(upcomingProgram.start_date)
          .local()
          .format('YYYYMMDD')
      } else if (closingProgram.program_id) {
        isEnrollmentCompleted = closingProgram.is_enrollment_completed
        programDefault = closingProgram.program_id
        bootcampStartDate = moment(closingProgram.start_date)
          .local()
          .format('YYYYMMDD')
      }

      apolloClient
        .query({
          query: getMacros,
          variables: {
            traineeId: globalContext.user.trainee_id,
            programs: programsToShow.map((p) => p.id),
            bootcampStartDate,
          },
        })
        .then((response) => {
          setPrograms(programsToShow)
          setMacros(response.data.macro)
          setProgramSelected(programDefault)
          if (currentProgram.type === 'bootcamp' || closingProgram.type === 'bootcamp') {
            setShowDailies(true)
            setRestDailies(response.data.rest_dailies)
            setTrainingDailies(response.data.training_dailies)
          }
          setIsBootcamp(globalContext.programs.every((p) => p.type === 'bootcamp'))
          setIsEnrollmentCompleted(isEnrollmentCompleted)
          setLoading(false)
        })
        .catch((error) => {
          setError(`${error}`)
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    let macrosToShow
    if (programSelected && macros.length > 0) {
      macrosToShow = macros.find((m) => m.program_id === programSelected)
      if (macrosToShow) {
        macrosToShow.macro_tables = macrosToShow.macro_tables
          .map((macro) => {
            let order = 2
            if (macro.type === 'TrainingDay') order = 1
            if (macro.type === 'Alternate') order = 3
            return { ...macro, order }
          })
          .sort((a, b) => a.order - b.order)
      }
    }
    setMacroSelected(macrosToShow)
  }, [programSelected])

  const printDocument = async (fileName) => {
    await setShowPrintSection(true)
    const input = document.getElementById('divToOfferInfo')
    console.log('input :>> ', input)
    html2canvas(input, {
      scale: 1.8,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const doc = new jsPDF('p', 'mm')
      const width = doc.internal.pageSize.getWidth() - 30
      const height = doc.internal.pageSize.getHeight() - 10
      doc.addImage(imgData, 'PNG', 15, 5, width, height)
      doc.save(`Macros ${fileName}.pdf`)
    })
    await setShowPrintSection(false)
  }

  const handleSelectBootcamp = (value) => {
    setProgramSelected(value.key)
  }

  return (
    <Layout>
      <Root>
        <BannerSection
          title="Suggested Macros"
          picture={globalContext.user.picture}
          subTitle={
            <p style={{ fontSize: '9px', width: '100%' }}>
              Macros are for your eyes only please do not share.
              {macroSelected && macroSelected.status === 'Approved' && (
                <span style={{ float: 'right', cursor: 'pointer' }}>
                  <img src={MacroDownload} alt="" onClick={() => printDocument(macroSelected.program.description)} />
                </span>
              )}
            </p>
          }
        />
        {loading && !error && (
          <Loading>
            <div className="rectangle" />
          </Loading>
        )}
        {!loading && error && <SectionError type={error} />}
        {!loading && !error && (
          <>
            {isBootcamp && programs.length > 0 && (
              <BootcampSelect bootcamps={programs} value={programSelected} onBootcampSelect={handleSelectBootcamp} />
            )}
            {macroSelected && macroSelected.status === 'Approved' && !isBootcamp && (
              <Message title="Macros Review" message="You have 14 days to download your Macros" type="warning" />
            )}
            {macroSelected && macroSelected.status === 'Approved' && (
              <div>
                <MacroTabs defaultActiveKey="1" type="card" className={`${macroSelected.hasAlternate ? 'has-alternate' : ''}`}>
                  {macroSelected.macro_tables.map((macroTable, index) => {
                    let tabTitle = 'Training Day'
                    let tabClass = ''
                    let dailies = trainingDailies

                    if (macroTable.type === 'BaseDay') {
                      tabTitle = 'Rest Day'
                      tabClass = 'blue'
                      dailies = restDailies
                    }
                    if (macroTable.type === 'Alternate') {
                      tabTitle = 'Alternate'
                      tabClass = 'green'
                    }

                    return (
                      <TabPane tab={<span className={tabClass}>{tabTitle}</span>} key={index + 1}>
                        <MacroTable data={macroTable} dailies={dailies} showDailies={showDailies} key={index} />
                      </TabPane>
                    )
                  })}
                </MacroTabs>
                <MacrosPDF macros={macroSelected} display={showPrintSection ? 'block' : 'none'} />
              </div>
            )}
            {!macroSelected && (
              <CardResult
                {...{
                  text: '<b>Please submit your information so we can begin reviewing your macros!</b>',
                  icon: <Icon type="hourglass" style={{ fontSize: '80px' }} />,
                  actionLink: '/enrollment',
                  actionText: 'Go to enrollment',
                }}
              />
            )}
            {macroSelected && macroSelected.status !== 'Approved' && isEnrollmentCompleted && (
              <CardResult
                {...{
                  text: '<b>We are working on your macros</b>',
                  icon: <Icon type="hourglass" style={{ fontSize: '80px' }} />,
                  secondaryText: moment()
                    .local()
                    .isBefore(macroSelected.program.start_date)
                    ? 'Please come back the weekend before camp starts'
                    : 'Please come back later',
                }}
              />
            )}
          </>
        )}
      </Root>
    </Layout>
  )
}

export default index
