import styled from '@emotion/styled'
import { Card } from 'antd'

export const ContainerSectionError = styled(Card)`
  height: 418px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border-width: 0px;
  margin-bottom: 5px;

  .ant-card-body {
    padding: 30px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  & svg {
    margin-bottom: 37px;
    min-width: 146px;
    min-height: 146px;
    fill: #c4c4c4;
  }

  p {
    text-align: center;
    color: #c4c4c4;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0.8rem;

    small {
      font-style: italic;
    }
  }
`
