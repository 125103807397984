import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const StyledLink = styled(Link)`
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #fff;
    font-weight: 500;
    position: relative;

    &:hover {
      color: #fff;
      &:after {
        position: absolute;
        top: 7px;
        right: -25px;
        font-size: 32px;
        line-height: 0;
        content: '•';
      }
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;

      a {
        &:hover {
          cursor: not-allowed;
          &:after {
            display: none;
          }
        }
      }
    `}
`
// export const StyledLink = styled(Link)``
