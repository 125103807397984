import React, { Component } from 'react'
import apolloClient from 'apolloClient'
import { STAMPED } from 'constants.js'
import { updateTraineeProgramCoachRate } from './query'
import { apiServices } from '../../services/api'
import { Modal, Form, Input, Rate, Icon, Upload, notification } from 'antd'
import { CustomModal } from './Review.styled'
import { ReactComponent as Logo } from 'images/review-logo.svg'
import './index.scss'
import { LOCAL_STORAGE } from 'constants.js'

class Review extends Component {
  constructor(props) {
    super(props)
    this.closeModal = this.closeModal.bind(this)
  }
  state = {
    checkedLike: false,
    checkedDislike: false,
    imageUrl: [],
    loading: false,
    previewVisible: false,
    previewImage: '',
    fileList: [],
    confirmLoading: false,
  }

  updateRate = (isRated) => {
    const closeProgram = this.props.closingProgram

    apolloClient
      .mutate({
        mutation: updateTraineeProgramCoachRate,
        variables: {
          traineeId: this.props.traineeId,
          programId: closeProgram.program_id,
          data: isRated,
        },
      })
      .then((data) => this.refetchAndClearCookies())
      .catch((error) => console.log(error))
  }

  refetchAndClearCookies = () => {
    this.closeModal(false)
    this.success()
    this.props.refetch()
  }

  closeModal = (isCancel) => {
    this.props.form.resetFields()
    this.setState({
      fileList: [],
      confirmLoading: false,
    })
    this.props.setModal(isCancel)
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  guessImageMime(data) {
    return data.substr(data.indexOf(',') + 1)
  }

  convertImages = async () => {
    let filesBase64 = []
    for (const file of this.state.fileList) {
      await this.getBase64(file.originFileObj).then((resp) => {
        filesBase64.push(this.guessImageMime(resp))
      })
    }
    return filesBase64
  }

  handleLoadImage = ({ file, fileList }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      console.error('You can only upload JPG/PNG file!')
      return Modal.error({
        width: 420,
        content: <h3>The file that you attempdted to upload ({file.name}) is not supported. Only png/jpg files are accepted</h3>,
        okText: 'Close',
      })
    }
    const isLt2M = file.size / 1024 / 1024 < 7
    if (!isLt2M) {
      console.error('Image must smaller than 7MB!')
      return
    }
    this.setState({ fileList })
  }

  handleLikes = (e) => {
    if (e.length > 1) {
      e.shift()
    }
  }

  handleSubmit = async () => {
    this.props.form.validateFields(async (err, value) => {
      if (err) return
      const images = await this.convertImages()
      this.setState({
        confirmLoading: true,
      })

      this.sendToAzure(value, images)
        .then((data) => {
          if (!data.status || data.status !== 201) {
            notification.error({
              message: 'An error has ocurred',
              description: 'The server cannot save the review please try later.',
            })
            this.setState({
              confirmLoading: false,
            })
          } else if (data.status === 201) {
            this.updateRate(true)
          }
        })
        .catch((error) => {
          notification.error({
            message: 'An error has ocurred',
            description: 'The server cannot save the review please try later.',
          })
          this.setState({
            confirmLoading: false,
          })
        })
    })
  }

  sendToAzure = async (value, filesBase64) => {
    const closeProgram = this.props.closingProgram

    const data = {
      productId: STAMPED.PRODUCT_ID,
      author: localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME),
      email: localStorage.getItem(LOCAL_STORAGE.USER_EMAIL),
      location: localStorage.getItem(LOCAL_STORAGE.USER_COUNTRY),
      reviewRating: value.rate,
      reviewTitle: ' ',
      reviewMessage: value.comments,
      reviewRecommendProduct: '',
      productName: closeProgram.description,
      productSKU: 'Bootcamp',
      productImageUrl: STAMPED.PRODUCT_IMG_URL,
      productUrl: STAMPED.PRODUCT_URL,
      imageUrl: filesBase64,
      reviewSource: 'api',
    }

    const response = await apiServices('POST', 'stamped/review', data)
    return await response
  }

  success() {
    let secondsToGo = 3
    const modal = Modal.success({
      content: 'Thank you very much for taking the time to give us a review!',
      icon: <Logo />,
      footer: null,
      closable: true,
    })

    setTimeout(() => {
      modal.destroy()
    }, secondsToGo * 1000)
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { fileList, confirmLoading } = this.state
    const { modalVisible, closingProgram } = this.props

    const showModal = modalVisible
    const closeProgram = closingProgram
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    )
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    }

    return (
      <CustomModal
        title="Your opinion is important for us!"
        style={{ top: 15, paddingBottom: '10px' }}
        visible={showModal}
        closable={false}
        okText="Send review"
        cancelText="Not now"
        onOk={() => this.handleSubmit()}
        confirmLoading={confirmLoading}
        onCancel={() => this.closeModal(false)}
      >
        <Form name="review">
          <Form.Item label={`How would you rate ${closeProgram && closeProgram.description ? closeProgram.description : 'this bootcamp'}?`}>
            {getFieldDecorator('rate', {
              rules: [{ required: true, message: ' ' }],
            })(<Rate allowClear={false} style={{ color: '#FF6B00' }} />)}
          </Form.Item>
          <Form.Item label="Your Review">
            {getFieldDecorator('comments', {
              rules: [{ required: true, message: ' ' }],
            })(<Input.TextArea style={{ fontSize: '13px' }} rows={4} placeholder="Tell us about your experience" />)}
          </Form.Item>
          <Form.Item label="Upload images" className="label-txt">
            {getFieldDecorator(
              'image',
              {},
            )(
              <div className="clearfix">
                <Upload onChange={this.handleLoadImage} fileList={fileList} customRequest={dummyRequest} name="image" listType="picture-card">
                  {fileList.length >= 2 ? null : uploadButton}
                </Upload>
              </div>,
            )}
          </Form.Item>
        </Form>
      </CustomModal>
    )
  }
}

export default Form.create()(Review)
