import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import SelectableCards from 'components/Selectable/Cards'
import SelectableToggle from 'components/Selectable/Toggle'
import TrainingYes from 'assets/icons/enrollment/training-yes.png'
import TrainingNo from 'assets/icons/enrollment/training-no.png'
import { Input } from 'antd'

const { TextArea } = Input
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const hasTrainingRef = useRef(null)
  const trainingRegularRef = useRef(null)
  const trainingLocationRef = useRef(null)
  const trainingPerWeekRef = useRef(null)
  const trainingPerSessionRef = useRef(null)
  const injuriesRef = useRef(null)

  const [hasTraining, setHasTraining] = useState()
  const [trainingRegular, setTrainingRegular] = useState()
  const [trainingLocation, setTrainingLocation] = useState()
  const [trainingPerWeek, setTrainingPerWeek] = useState()
  const [trainingPerSession, setTrainingPerSession] = useState()
  const [injuries, setInjuries] = useState()

  const [trainingRegularCatalog, setTrainingRegularCatalog] = useState([])
  const [trainingLocationCatalog, setTrainingLocationCatalog] = useState([])
  const [trainingPerWeekCatalog, setTrainingPerWeekCatalog] = useState([])
  const [trainingPerSessionCatalog, setTrainingPerSessionCatalog] = useState([])

  const yesStrength = { value: 'yes', label: 'Yes', icon: TrainingYes }
  const noStrength = { value: 'no', label: 'No', icon: TrainingNo }

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, has_training, training_regular, training_location, training_per_week, training_session, injuries } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          const regularTrainingCatalog = response.catalogs.regularity_training.map((rt) => {
            return {
              id: rt.id,
              name: rt.label,
              thumbnail: rt.icon,
            }
          })
          const locationTrainingCatalog = response.catalogs.places_train.map((pt) => {
            return {
              id: pt.id,
              name: pt.label,
              thumbnail: pt.icon,
            }
          })
          const daysPerWeekTrainingCatalog = response.catalogs.days_per_week_training.map((dpw) => {
            return {
              id: dpw.id,
              name: dpw.label,
              thumbnail: dpw.icon,
            }
          })
          const timePerSessionTrainingCatalog = response.catalogs.time_per_training_session.map((tpt) => {
            return {
              id: tpt.id,
              name: tpt.label,
              thumbnail: tpt.icon,
            }
          })
          setTrainingRegularCatalog(regularTrainingCatalog)
          setTrainingLocationCatalog(locationTrainingCatalog)
          setTrainingPerWeekCatalog(daysPerWeekTrainingCatalog)
          setTrainingPerSessionCatalog(timePerSessionTrainingCatalog)
          if (typeof has_training === 'boolean') {
            setHasTraining(has_training ? 'yes' : 'no')
          }
          setTrainingRegular(training_regular)
          setTrainingLocation(training_location)
          setTrainingPerWeek(training_per_week)
          setTrainingPerSession(training_session)
          setInjuries(injuries)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (hasTraining === 'no') {
      setTrainingRegular(null)
      setTrainingLocation(null)
    }
    validate()
  }, [hasTraining, trainingRegular, trainingLocation, trainingPerWeek, trainingPerSession, trainingPerSession, injuries])

  const saveCallback = async ({ goBack }) => {
    const data = {
      has_training: hasTraining === 'yes',
      training_regular: trainingRegular,
      training_location: trainingLocation,
      training_per_week: trainingPerWeek,
      training_session: trainingPerSession,
      injuries,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = !!hasTraining && !!trainingPerWeek && !!trainingPerSession
    if (hasTraining === 'yes') {
      isValid = isValid && !!trainingLocation && !!trainingRegular
    }
    if (isValid) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          !trainingPerSession && scrollToRef(trainingPerSessionRef)
          !trainingPerWeek && scrollToRef(trainingPerWeekRef)
          if (hasTraining === 'yes') {
            !trainingLocation && scrollToRef(trainingLocationRef)
            !trainingRegular && scrollToRef(trainingRegularRef)
          }
          !hasTraining && scrollToRef(hasTrainingRef)
        }
      }
    }
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'lifestyle' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={hasTrainingRef}>
            {submit && !hasTraining && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">Do you strength train regularly?</p>
            </div>
            <div className="content">
              <SelectableToggle optionA={yesStrength} optionB={noStrength} value={hasTraining} onChange={(e) => setHasTraining(e.target.value)} />
            </div>
          </QuestionContainer>
          {hasTraining === 'yes' && (
            <>
              <QuestionContainer ref={trainingRegularRef}>
                {submit && !trainingRegular && <Message type="error" message={'This field is required'} />}
                <div className="question">
                  <p className="title">How long have you been doing strength training?</p>
                </div>
                <div className="content">
                  <SelectableCards
                    type="radio"
                    value={trainingRegular}
                    catalog={trainingRegularCatalog}
                    onChange={(e) => setTrainingRegular(e.target.value)}
                  />
                </div>
              </QuestionContainer>
              <QuestionContainer ref={trainingLocationRef}>
                {submit && !trainingLocation && <Message type="error" message={'This field is required'} />}
                <div className="question">
                  <p className="title">Where do you normally train?</p>
                </div>
                <div className="content">
                  <SelectableCards
                    type="radio"
                    value={trainingLocation}
                    catalog={trainingLocationCatalog}
                    onChange={(e) => setTrainingLocation(e.target.value)}
                  />
                </div>
              </QuestionContainer>
            </>
          )}
          <QuestionContainer ref={trainingPerWeekRef}>
            {submit && !trainingPerWeek && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">How many days per week can you commit to training?</p>
            </div>
            <div className="content">
              <SelectableCards
                type="radio"
                value={trainingPerWeek}
                catalog={trainingPerWeekCatalog}
                onChange={(e) => setTrainingPerWeek(e.target.value)}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={trainingPerSessionRef}>
            {submit && !trainingPerSession && <Message type="error" message={'This field is required'} />}
            <div className="question">
              <p className="title">How much time can you commit per training session?</p>
            </div>
            <div className="content">
              <SelectableCards
                type="radio"
                value={trainingPerSession}
                catalog={trainingPerSessionCatalog}
                onChange={(e) => setTrainingPerSession(e.target.value)}
              />
            </div>
          </QuestionContainer>
          <QuestionContainer ref={injuriesRef}>
            <div className="question">
              <p className="title">
                Do you have any pre-existing injury or problem? <span>(Optional)</span>
              </p>
              <p>Please give full information below.</p>
            </div>
            <div className="content">
              <TextArea
                value={injuries}
                onChange={(e) => setInjuries(e.target.value)}
                maxLength={500}
                rows={8}
                placeholder="Type here..."
                autoSize={{ minRows: 8, maxRows: 8 }}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
