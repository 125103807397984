import styled from '@emotion/styled'

export const CardMessage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 5px 10px 10px;
  min-height: 45px;
  border-radius: 4px;
  border-left: solid 6px ${({ color }) => color};

  .icon {
    flex: none;
    width: 28px;
    height: 28px;
  }

  .wrap-text {
    padding-left: 15px;

    p {
      font-size: 12px;
      color: #5d5d5d;
      margin-bottom: 0;
    }
  }
`
