import React from 'react'
import { Button } from 'styled'

const SectionStatus = (props) => {
  const { image, text, actionLink, actionText } = props

  return (
    <div className="status-container">
      <img src={image} alt="jsx-a11y/img-redundant-alt" width="80px" />
      <p className="text">{text}</p>
      {actionLink && actionText && (
        <div className="content-button">
          <Button href={actionLink} type="primary" block>
            {actionText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SectionStatus
