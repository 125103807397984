import { getCookie } from 'tiny-cookie'
import { COOKIE, LOCAL_STORAGE } from 'constants.js'
import moment from 'moment'
import { renewToken } from 'auth'

export const apiFetch = async ({ url, method, body, cacheTable, expiredIn, isConnected }) => {
  const apiUrl = process.env.REACT_APP_API_URL + url

  if (!getCookie(COOKIE.USER_TOKEN)) {
    await renewToken(localStorage.getItem(LOCAL_STORAGE.USER_EMAIL), false)
  }

  return await new Promise((resolve, reject) => {
    if (isConnected) {
      const options = {
        method,
        headers: {
          Authorization: getCookie(COOKIE.USER_TOKEN),
        },
        body,
      }
      fetch(apiUrl, options)
        .then((resp) => {
          if (resp.ok) {
            if (resp.status === 204) {
              resolve({})
            } else {
              return resp.json()
            }
          } else {
            reject({ hasError: true, error: 'Error downloading data', status: resp.status })
          }
        })
        .then(async (data) => {
          if (!!cacheTable) {
            for (const value of data) {
              value.expired_in = expiredIn
              for (const key in value) {
                if (value.hasOwnProperty(key)) {
                  const element = value[key]
                  if (typeof element === 'boolean') {
                    value[key] = element === true ? 1 : 0
                  }
                  if (key === 'created_date') {
                    value[key] = moment(element).format('YYYY-MM-DD HH:mms:ss')
                  }
                }
              }
            }
          }

          resolve(data)
        })
        .catch((error) => {
          reject({ hasError: true, error })
        })
    } else {
      reject({ hasError: true, error: 'Network connection failed' })
    }
  })
}
