import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { PreFillEnrollmentContainer } from './Main.styled'
import moment from 'moment'

export default ({ visible, weekly, enrollment, preFillEnrollment }) => {
  const [visibleModal, setVisibleModal] = useState(visible)

  const handlePreFillEnrollment = (type, date, programId) => {
    preFillEnrollment(type, date, programId)
    setVisibleModal(false)
  }

  return (
    <Modal
      title="We have some data that we can use to pre-fill some sections of this Enrollment"
      footer={null}
      visible={visibleModal}
      closable={false}
      onCancel={() => handlePreFillEnrollment('none', null, enrollment.program_id)}
    >
      <PreFillEnrollmentContainer>
        <p>
          <b>Do you want to use your previous data?</b>
        </p>
        {weekly && (
          <>
            <Button type="primary" onClick={() => handlePreFillEnrollment('weekly', weekly, enrollment.program_id)} block>
              Yes, use my last <br />
              weekly submission.
            </Button>
            <p>
              <small>Your last update was on {moment(weekly).format('MMM Do, YYYY')}</small>
            </p>
          </>
        )}
        {enrollment && (
          <>
            <Button type="primary" onClick={() => handlePreFillEnrollment('enrollment', enrollment.start_date, enrollment.program_id)} block>
              Yes, use my last <br />
              Enrollment data.
            </Button>
            <p>
              <small>Your last update was on {moment(enrollment.created).format('MMM Do, YYYY')}</small>
            </p>
          </>
        )}

        <Button onClick={() => handlePreFillEnrollment('none', null, enrollment.program_id)} block>
          No, I'd like to start fresh.
        </Button>
      </PreFillEnrollmentContainer>
    </Modal>
  )
}
