import React from 'react'
import Layout from 'components/Layout'
import { Root } from 'components/Homepage/Homepage.styled'
import Card from 'components/Cards/Card/Card'
import { CstDiv } from './LostNetwork.styled'
import { ReactComponent as IconWiFi } from 'assets/icons/WiFi.svg'

const LostNetwork = () => {
  return (
    <Layout hideFooter={true} isBack={true}>
      <Root>
        <Card style={{ height: '80vh', display: 'flex', justifyContent: 'center' }}>
          <CstDiv>
            <IconWiFi />
            <p>Oops!</p>
            <p>You need to access Exercises with Internet connection at least one time to download your program.</p>
            <p>Please connect to a network and try again</p>
          </CstDiv>
        </Card>
      </Root>
    </Layout>
  )
}

export default LostNetwork
