import styled from '@emotion/styled'
/* import menu from '../images/exercise/menu-points.png'
import imageUp from '../images/exercise/menu-no-dnd-up.svg'
import imageDown from '../images/exercise/menu-no-dnd-down.svg' */

export const ContSets = styled.div`
  padding: 12px;
  .ant-divider-horizontal {
    height: 4px;
    margin-top: 0px;
    margin-bottom: 12px;
    border-radius: 10%;
    background-color: #dcdcdc;
  }

  .ant-progress-inner {
    max-width: 313px;
    background-color: #dcdcdc;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
  }

  p {
    margin: 0px;
  }

  .details-info {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    position: relative;
    justify-content: space-between;

    & div {
      max-width: 237px;
      height: 36px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      color: #4a4a4a;
      position: relative;
      top: 10px;
      /* left: 12px; */
    }

    & button {
      background: rgba(0, 0, 0, 0);
      border: 0px;
      position: relative;
      top: 13px;
      right: 25px;
    }
  }

  .container-cards {
    display: flex;
    margin-top: 2px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding-bottom: 8px;
    word-break: break-all;
    .ant-card {
      width: 49.5%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .ant-card-body {
        padding: 0px 6px;
      }
    }

    & .technique-helper {
      position: absolute;
      right: 5px;
      top: calc(50% - 11px);
      cursor: pointer;
    }
  }

  .container-sets {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 7px 10px;
    .container-controls {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50%;

      .ant-btn:hover,
      .ant-btn:focus {
        color: #ff6b00;
        background-color: #fff;
        border-color: rgba(0, 0, 0, 0);
      }

      & button {
        & svg {
          position: relative;
          top: -2px;
        }
      }

      & span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        color: #ff6b00;
        margin-right: 5px;
      }

      & p {
        font-family: Montserrat;
        font-style: normal;
        font-size: 18px;
        line-height: 26px;
        color: black;
      }
    }

    div:nth-of-type(2) {
      svg {
        path {
          stroke: ${({ warmUp }) => (warmUp ? '#FF6B00' : '#cfcfcf')};
          fill: ${({ warmUp }) => (warmUp ? '#FF6B00' : '#cfcfcf')};
        }
        circle {
          fill: ${({ warmUp }) => (warmUp ? '#FF6B00' : '#cfcfcf')};
          stroke: ${({ warmUp }) => (warmUp ? '#FF6B00' : '#cfcfcf')};
        }
      }
    }
  }

  .container-user-notes {
    margin-top: 19px;
    padding: 0 30px;

    p {
      font-weight: 500;
      font-size: 14px;
      color: #5d5d5d;
      margin-bottom: 7px;
    }

    textarea {
      width: 100%;
      background: #ffffff;
      border: 1px solid #cfcfcf;
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 7px 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #717271;
      min-height: 68px;
      max-height: 68px;
      resize: none;

      &:focus {
        outline: 0;
      }
    }
  }

  .container-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 0px;
    /* margin-top: 10px; */
    .wrap-arrow {
      text-align: center;
      cursor: pointer;
      p {
        color: #ff6b00;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
      }
    }
    .ant-card {
      width: 32%;
      border: 1px solid #cfcfcf;
      box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
      text-align: center;
      margin-bottom: 0px;
      margin-right: 3px;
      display: flex;
      flex-direction: column;
      p {
        background: ${({ changeColor }) => (changeColor ? 'linear-gradient(90deg, #FFA400 0%, #FF6B00 100%);' : '#fff;')};
        color: ${({ changeColor }) => (changeColor ? '#fff' : '#000')};
        box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
      }

      .ant-card-body p:first-of-type:not(.label-info-sets) {
        font-size: 30px;
        font-weight: bold;
        color: ${({ changeColor }) => (changeColor ? '#fff' : '#ff6b00')};
      }

      .ant-card-body input {
        background: ${({ changeColor }) => (changeColor ? 'linear-gradient(90deg, #FFA400 0%, #FF6B00 100%);' : '#fff;')};
        width: 100%;
        max-height: 47px;
        font-size: 30px;
        font-weight: bold;
        color: ${({ changeColor }) => (changeColor ? '#fff' : '#ff6b00')};
        text-align: center;
        border: none;
        border-radius: 0px !important;
        box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
      }
      .ant-card-body p:nth-of-type(2) {
        font-weight: normal;
      }
    }
  }

  .ant-btn:hover {
    border-color: rgba(0, 0, 0, 0) !important;
  }

  .container-btn {
    text-align: center;
    padding: 10px 50px;
    .ant-btn.ant-btn-loading {
      padding-left: 0px !important;
    }
    & .ant-btn:hover,
    .ant-btn:focus {
      color: #fff;
      background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
      border-color: #ff6b00;
    }
  }

  .label-info-sets {
    box-shadow: inset 2px 0px 0px rgba(0, 0, 0, 0.2) !important;
  }
`
export const ButtonFakeSuccess = styled.div`
  border-radius: 50px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
  padding: 7px 0px 13px 0px;
  height: 43px;
  background: #6fcf97;

  i {
    display: block !important;
  }
`
