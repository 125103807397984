import styled from '@emotion/styled'
import { Modal } from 'antd'
import FirstEnrollmentBanner from 'assets/icons/enrollment/first-enrollment-banner.png'

export const MainEnrollmentContainer = styled.div`
  margin: 0px 10px;

  > .content {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
    border-radius: 4px;
    padding: 15px 5px;
    text-align: center;

    hr {
      max-width: 290px;
      margin: 12px auto;
      border: none;
      border-top: 1px solid #c4c4c4;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      max-width: 290px;
      color: #959595;
      margin: 0 auto;
      margin-bottom: 14px;

      b {
        color: #5d5d5d;
      }
    }
  }
`

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px 14px;
  text-align: center;

  h1 {
    font-size: 15px;
    font-weight: bold;
    color: #5d5d5d;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #979797;
    margin-bottom: 0px;
  }

  &.stage {
    display: grid;
    grid-template-columns: 70px auto;
    grid-column-gap: 20px;
    cursor: pointer;
    text-align: left;

    div {
      align-self: center;

      .content {
        display: grid;
        grid-template-columns: auto 20px;
        grid-column-gap: 20px;

        svg {
          align-self: center;
          justify-self: center;
        }
      }
    }

    .anticon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ffa401;
      color: #ffffff;
      padding-top: 2px;

      &.success {
        background: #6fcf97;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      touch-action: none;
    }

    @media screen and (max-width: 350px) {
      padding: 6px 8px;
      grid-column-gap: 10px;
    }
  }
`

export const FirstEnrollmentContainerModal = styled(Modal)`
  top: 56px;

  .ant-modal-header {
    padding: 0;
    border-radius: 6px;
    margin-bottom: 10px;

    .banner {
      background-image: url(${FirstEnrollmentBanner});
      background-size: cover;
      background-repeat: no-repeat;
      height: 155px;
    }
  }

  .ant-modal-body {
    max-height: calc(100vh - 380px);
    padding: 10px;
    margin: 10px;
    overflow-y: scroll;

    h3 {
      color: #4a4a4a;
      font-weight: bold;
      font-size: 16px;
    }

    p {
      color: #4a4a4a;

      span {
        font-weight: 600;
        color: #ff4713;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10%;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ff6b00;
      border-radius: 3px;
    }
  }

  .ant-modal-footer {
    border: none;
    margin-top: 30px;
    text-align: center;

    .ant-btn {
      width: 80%;
    }
  }
`

export const PreFillEnrollmentContainer = styled.div`
  p {
    b {
      color: #4a4a4a;
    }

    small {
      font-weight: 500;
      font-size: 12px;
      color: #ff6b00;
    }
  }

  .ant-btn-primary {
    height: 43px;
    margin-bottom: 0px !important;
  }
`
