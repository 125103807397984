import { INFUSIONSOFT_API } from 'constants.js'
import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'

import gql from 'graphql-tag'

export const setContactInfusionSoftTag = (contactId, programId, tags) => {
  return fetch(INFUSIONSOFT_API.URI, {
    method: 'POST',
    headers: {
      Authorization: getCookie(COOKIE.USER_TOKEN),
    },
    body: JSON.stringify({
      action: 'ContactTag',
      infusionSoftObject: {
        Id: contactId,
        ProgramId: programId,
        Tags: tags,
      },
    }),
  })
}

export const imageExists = (image_url) => {
  let http = new XMLHttpRequest()
  http.open('HEAD', image_url, false)
  http.send()
  return http.status !== 404
}

export const currentProgram = gql`
  query getcurrentProgram($today: date!) {
    currentProgram: program(where: { end_date: { _gte: $today }, _and: [{ start_date: { _lte: $today } }] }) {
      product_tag
    }
  }
`
export const getUserData = gql`
  query GetUserData($id: String!, $email: String!, $today: date!) {
    user(where: { email: { _eq: $email } }) {
      id
      email
      gender
      birthday
      units
      country
      contact_id
      picture
      trainee {
        id
        currentProgram: selected(
          where: {
            program: { start_date: { _lte: $today } }
            _and: [{ program: { end_date: { _gte: $today } }, _and: { is_active: { _eq: true } } }]
          }
        ) {
          sign_date
          enrollment_stage
          extras
          program {
            program_id
            description
            start_date
            end_date
            access_date
            close_date
            duration
          }
        }
        nextProgram: selected(
          where: {
            program: { start_date: { _gte: $today } }
            _and: [{ program: { access_date: { _lte: $today } }, _and: { is_active: { _eq: true } } }]
          }
        ) {
          sign_date
          enrollment_stage
          extras
          program {
            program_id
            description
            start_date
            end_date
            access_date
            close_date
            duration
          }
        }
        countProgram: selected_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`
