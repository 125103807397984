import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import SelectableCircles from 'components/Selectable/Circles'
import SelectableCards from 'components/Selectable/Cards'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import fireXs from 'assets/images/fire-xs.png'
import fireSm from 'assets/images/fire-sm.png'
import fireMd from 'assets/images/fire-md.png'
import fireLg from 'assets/images/fire-lg.png'
import { Radio } from 'antd'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [catalogMealPlanTypes, setCatalogMealPlanTypes] = useState([])
  const [preferences, setPreferences] = useState({
    id: null,
    mealsPerDay: null,
    levelHeat: null,
    mealType: null,
  })

  const mealsPerDaysRef = useRef(null)
  const heatLevelRef = useRef(null)
  const mealPlanTypeRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const catalogTypes = response.catalogs.meal_plan_type
        const mealPlan = response.enrollment.mealPlan
        const { id, meals_per_day, heat_level, meal_plan_type } = mealPlan
        setCatalogMealPlanTypes(catalogTypes)
        setPreferences({
          id: id ? id : null,
          mealsPerDay: meals_per_day ? Number(meals_per_day) : null,
          levelHeat: heat_level ? Number(heat_level) : null,
          mealType: meal_plan_type ? meal_plan_type : null,
        })
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [preferences])

  const saveCallback = async ({ goBack }) => {
    const { id, mealsPerDay, levelHeat, mealType } = preferences
    const data = {
      id: id,
      meals_per_day: mealsPerDay,
      heat_level: levelHeat,
      meal_plan_type: mealType,
    }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    const { mealsPerDay, levelHeat, mealType } = preferences

    if (!!mealsPerDay && !!levelHeat && !!mealType) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          if (!mealsPerDay) scrollToRef(mealsPerDaysRef)
          else if (!levelHeat) scrollToRef(heatLevelRef)
          else if (!mealType) scrollToRef(mealPlanTypeRef)
        }
      }
    }
  }

  const warningMessage = () => {
    return (
      <Message
        style={{ marginBottom: '3px' }}
        type="error"
        message="You need to select 1 option in this question"
        textStyle={{ fontSize: '15px' }}
        isMealPlan={true}
      />
    )
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'meal_plan' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer ref={mealsPerDaysRef}>
            {submit && preferences.mealsPerDay === null && warningMessage()}
            <div className="question">
              <p className="title">How many meals do you want to eat per day?</p>
              <p>To make 4 meals, use the 2 meal option and split both meals in half to make 4.</p>
            </div>
            <div className="content">
              <SelectableCircles>
                <Radio.Group
                  className="group"
                  defaultValue={preferences.mealsPerDay}
                  onChange={(e) => setPreferences((prev) => ({ ...prev, mealsPerDay: e.target.value }))}
                >
                  <Radio.Button value={1}>1</Radio.Button>
                  <Radio.Button value={2}>2</Radio.Button>
                  <Radio.Button value={3}>3</Radio.Button>
                </Radio.Group>
              </SelectableCircles>
            </div>
            {preferences.mealsPerDay === 1 && (
              <Message
                type="warning"
                message={`We <b>do not recommended</b> you to have just 1 meal per day!`}
                textStyle={{ fontSize: '15px' }}
                isMealPlan={true}
              />
            )}
          </QuestionContainer>
          <QuestionContainer ref={heatLevelRef}>
            {submit && preferences.levelHeat === null && warningMessage()}
            <div className="question">
              <p className="title">What level of “Heat” is it good for you?</p>
            </div>
            <div className="content">
              <SelectableCircles image={true}>
                <Radio.Group
                  className="group"
                  defaultValue={preferences.levelHeat}
                  onChange={(e) => setPreferences((prev) => ({ ...prev, levelHeat: e.target.value }))}
                >
                  <Radio.Button value={1}>
                    <img src={fireXs} alt="fire" />
                  </Radio.Button>
                  <Radio.Button value={2}>
                    <img src={fireSm} alt="fire" />
                  </Radio.Button>
                  <Radio.Button value={3}>
                    <img src={fireMd} alt="fire" />
                  </Radio.Button>
                  <Radio.Button value={4}>
                    <img src={fireLg} alt="fire" />
                  </Radio.Button>
                </Radio.Group>
              </SelectableCircles>
            </div>
          </QuestionContainer>
          <QuestionContainer ref={mealPlanTypeRef}>
            {submit && preferences.mealType === null && warningMessage()}
            <div className="question">
              <p className="title">What type of Meal Plan do you prefer?</p>
            </div>
            <div className="content">
              <SelectableCards
                type="radio"
                value={preferences.mealType}
                catalog={catalogMealPlanTypes}
                onChange={(e) => setPreferences((prev) => ({ ...prev, mealType: e.target.value }))}
              />
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
