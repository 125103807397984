import React, { Component, Fragment } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Root, Content, Main, ImpersonateIndicator } from './Layout.styled'
import { LOCAL_STORAGE } from 'constants.js'

class Layout extends Component {
  render() {
    const { children, disabledFooter } = this.props
    const isAdminImpersonated = localStorage.getItem(LOCAL_STORAGE.IMPERSONATE_USER) === 'true'
    return (
      <Fragment>
        <Root>
          <Content disabledFooter={disabledFooter} id="content">
            <Header />
            <Main>{children}</Main>
          </Content>
          {!disabledFooter && <Footer />}
          {isAdminImpersonated && <ImpersonateIndicator>You are logged in as a student, be CAREFUL!</ImpersonateIndicator>}
        </Root>
      </Fragment>
    )
  }
}

export default Layout
