import styled from '@emotion/styled'

export const Root = styled.div`
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  width: 100%;
`

export const Content = styled.div`
  width: 100%;
  background-color: #f8f9fa;
  padding-top: 54px;
  padding-bottom: 10px;
`

export const WrapTimeLine = styled.div`
  background: #fff;
  width: 100%;
  max-width: 460px;
  margin: auto;
`
