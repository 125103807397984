import styled from '@emotion/styled'
import { Modal } from 'antd'

export const BaseModal = styled(Modal)`
  width: 311px !important;
  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    text-align: center;
    border: 0px;
  }
  .ant-modal-header {
    padding: 28px 24px 0px;
  }
  .ant-modal-body {
    padding: 0px 12px 0px;
  }
`

export const InfoModal = styled.div``
