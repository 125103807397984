import React from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Label, Line } from 'recharts'

const LineGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="85%">
      <LineChart data={data}>
        <CartesianGrid fill="rgba(196, 196, 196, 0.2)" horizontal={false} vertical={false} />
        <XAxis dataKey="order" stroke="#FFD27A" tick={{ stroke: '#4A4A4A', fontSize: 12 }} padding={{ left: 20, right: 20 }}>
          <Label value="Weeks" dy={15} />
        </XAxis>
        <YAxis stroke="#FFD27A" tick={{ stroke: '#4A4A4A', fontSize: 12 }}>
          <Label value="Volume" angle={-90} dx={-25} />
        </YAxis>
        <Line connectNulls type="linear" dataKey="volume" stroke="#FF6B00" strokeWidth={2} fill="#FF6B00" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineGraph
