import React, { Component } from 'react'
import { StyledButton } from 'styled'

class SubmitButton extends Component {
  state = {
    isDisabledButton: false,
  }

  handleClick = (event) => {
    event.preventDefault()
    this.setState({
      isDisabledButton: true,
    })
    const { handleSubmit } = this.props
    if (handleSubmit) {
      handleSubmit(event)
        .then(() => {
          setTimeout(() => {
            this.setState({
              isDisabledButton: false,
            })
          }, 200)
        })
        .catch((error) => {
          setTimeout(() => {
            this.setState({
              isDisabledButton: false,
            })
          }, 200)
        })
    } else {
      this.setState({
        isDisabledButton: false,
      })
    }
  }

  render() {
    const { isAgreementChecked, custom, picture, uploadedFileUrl, className, buttonContent } = this.props
    const { isDisabledButton } = this.state
    let commonDisabled
    if (typeof isAgreementChecked !== 'undefined') {
      commonDisabled = !isAgreementChecked || isDisabledButton
    } else if (typeof picture !== 'undefined' && typeof uploadedFileUrl !== 'undefined') {
      commonDisabled = !picture && !uploadedFileUrl
    }
    return (
      <StyledButton
        {...{
          onClick: this.handleClick,
          disabled: typeof commonDisabled !== 'undefined' ? commonDisabled || isDisabledButton : isDisabledButton,
          loading: typeof commonDisabled !== 'undefined' ? commonDisabled || isDisabledButton : isDisabledButton,
          custom,
          className,
        }}
      >
        {buttonContent || 'Save changes'}
      </StyledButton>
    )
  }
}

export default SubmitButton
