import React from 'react'
import { ContainerWeeks } from './Reports.styled'
import moment from 'moment'

export default ({ routine, weekSelected, onWeekSelected }) => {
  let weeks = []
  const thisWeek = moment().startOf('isoWeek')

  if (routine && routine.routine_weeks && routine.routine_weeks.length > 0) {
    weeks = routine.routine_weeks.map((week, index) => {
      return {
        id: week.id,
        identifier: week.identifier,
        label: index + 1,
      }
    })
  }

  if (weeks.length === 0) return null

  return (
    <ContainerWeeks>
      <p>Select a Week</p>
      <hr />
      <div className="weeks-button">
        {!!weeks &&
          weeks.map((week) => {
            return (
              <span
                className={`${weekSelected === week.id ? 'active' : ''} ${moment(week.identifier).isAfter(thisWeek) ? 'disabled' : ''}`}
                key={week.id}
                onClick={() => onWeekSelected(week.id)}
                disabled={moment(week.identifier).isAfter(thisWeek)}
              >
                {week.label}
              </span>
            )
          })}
      </div>
    </ContainerWeeks>
  )
}
