import { getCache, getCacheWhere } from 'services/db/indexedDB'
import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'
import NetworkDetector from '../../../services/NetworkDetector'
import { deleteRoutineSession } from 'services/ExerciseUtilities'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

const synchronizeRoutineSession = async (routineSession) => {
  return new Promise((resolve, reject) => {
    const localId = routineSession.local_id

    delete routineSession.session_id
    delete routineSession.local_id
    delete routineSession.id

    const options = {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: JSON.stringify(routineSession),
    }

    if (routineSession.session_exercises && routineSession.session_exercises.length > 0) {
      const uniqueExercises = []
      let duplicatedExercises = routineSession.session_exercises.filter((se) => {
        if (uniqueExercises.find((ue) => ue.exercise_id === se.exercise_id)) {
          return true
        }
        uniqueExercises.push(se)
        return false
      })
      if (duplicatedExercises.length > 0) {
        duplicatedExercises = duplicatedExercises.map((de) => de.exercise_id)
        let sentryLog = {}
        for (let i = 0; i < duplicatedExercises.length; i++) sentryLog[duplicatedExercises[i]] = 'duplicated'

        Sentry.addBreadcrumb({ category: 'exercise', message: 'List of duplicated exercises', data: sentryLog })
        Sentry.captureMessage('The user has duplicated exercises')
      }
    }

    fetch(`${process.env.REACT_APP_API_URL}routine-sessions`, options)
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }
        throw new Error('Error on server connection')
      })
      .then(async (data) => {
        await deleteRoutineSession(localId)
        resolve({ success: true, message: 'Successful', data })
      })
      .catch((err) => {
        reject({ hasError: true, error: err })
      })
  })
}

const synchronizeRoutineExercisesNotes = async (routineSession) => {
  return new Promise(async (resolve, reject) => {
    const exercisesWithNotes = routineSession.session_exercises.filter((re) => !!re.user_notes)
    if (exercisesWithNotes && exercisesWithNotes.length > 0) {
      const workoutsDB = await getCache('workout_plans').catch((e) => console.error(e))
      if (workoutsDB && workoutsDB.length > 0) {
        const currentWorkout = workoutsDB.find((workout) => workout.is_current === 1)
        if (currentWorkout) {
          let routineExercises = []
          let userNotes = currentWorkout.routine_exercise_user_notes.filter((rn) => rn.routine_id === routineSession.session_id)
          exercisesWithNotes.forEach((en) => {
            let addNote = true
            let obj = {
              routine_id: routineSession.session_id,
              workout_plan_id: currentWorkout.id,
              exercise_id: en.exercise_id,
              note: en.user_notes,
              parent: en.parent === '' ? null : en.parent,
            }

            if (userNotes.length > 0) {
              const foundExercise = userNotes.find((un) => un.exercise_id === en.exercise_id && un.note === en.user_notes && un.parent === en.parent)
              if (!!foundExercise) {
                addNote = false
              }
            }

            if (addNote) {
              routineExercises.push(obj)
            }
          })

          if (routineExercises.length > 0) {
            const options = {
              method: 'POST',
              headers: {
                Authorization: getCookie(COOKIE.USER_TOKEN),
              },
              body: JSON.stringify(routineExercises),
            }
            fetch(`${process.env.REACT_APP_API_URL}routine-exercises/user-notes/`, options)
              .then((resp) => {
                if (resp.status === 200) {
                  resolve('Notes updated successfully!')
                }
                throw new Error('Could not update exercises notes.')
              })
              .catch((error) => reject(error))
          } else {
            resolve('Nothing to update')
          }
        } else {
          reject('Workout selected not found')
        }
      } else {
        reject('Workouts not found')
      }
    } else {
      resolve('Nothing to Synchronize')
    }
  })
}

/**
 * Synchronize the routine sessions in the cache with the server
 *
 * @returns Promise of all the process
 */
export default async () => {
  return new Promise(async (resolve, reject) => {
    try {
      if ((await NetworkDetector()) && getCookie(COOKIE.USER_TOKEN)) {
        let routineSessions = await getCacheWhere('routine_session', { is_completed: 1 }).catch((e) => console.error(e))
        if (routineSessions && routineSessions.length > 0) {
          routineSessions = routineSessions.sort((a, b) => b.finish_date - a.finish_date).slice(-1)
          let processRoutines = []

          for (let routineSession of routineSessions.filter((rs) => !!rs.local_id)) {
            routineSession.start_date = moment(routineSession.start_date).toISOString()
            routineSession.finish_date = moment(routineSession.finish_date).toISOString()
            routineSession.is_completed = routineSession.is_completed === 1
            routineSession.weight_unit = localStorage.getItem('PREFERRED_UNITS')

            const routineExercises = await getCacheWhere('routine_session_exercise', { local_id: routineSession.local_id })
            let data
            await Promise.all(
              routineExercises.map(async (exercise) => {
                let sets = await getCacheWhere('routine_session_set', {
                  routine_session_exercise_id: exercise.local_id,
                  exercise_id: exercise.exercise_id,
                  parent: exercise.parent === null ? '' : exercise.parent,
                })
                const parent = exercise.parent === '' ? null : exercise.parent
                sets = sets.map((set) => {
                  if (localStorage.getItem('PREFERRED_UNITS') === 'KG') {
                    set.weight = set.weight * 2.2046
                  }
                  set.is_warmup = set.is_warmup === 1 ? true : false
                  delete set.exercise_id
                  delete set.id
                  delete set.routine_session_exercise_id
                  delete set.is_done
                  delete set.parent
                  return set
                })
                delete exercise.id
                delete exercise.local_id
                delete exercise.routine_session_id
                exercise.parent = parent
                return { ...exercise, session_sets: sets }
              }),
            ).then((exercises) => (data = exercises))

            routineSession = { ...routineSession, session_exercises: data }
            processRoutines.push(
              new Promise((resolveRoutine, rejectRoutine) => {
                synchronizeRoutineExercisesNotes(routineSession)
                  .then((resp) => {
                    routineSession.session_exercises = routineSession.session_exercises.map((rse) => {
                      delete rse.user_notes
                      return rse
                    })
                    synchronizeRoutineSession(routineSession)
                      .then((resp) => resolveRoutine({ ...resp, ...routineSession }))
                      .catch((error) => rejectRoutine(error))
                  })
                  .catch((error) => {
                    console.log('ERROR ON EXERCISES NOTES: ', error)
                    rejectRoutine(error)
                  })
              }),
            )
          }

          Promise.all(processRoutines)
            .then((data) => {
              if (data.length > 0) {
                for (const responseRoutineSession of data) {
                  Sentry.addBreadcrumb({
                    category: 'exercise',
                    message: `Synchronize a session for routine week ${responseRoutineSession.routine_week_id}`,
                    data: responseRoutineSession,
                  })
                }
              }
              resolve('Finish process')
            })
            .catch((error) => {
              Sentry.addBreadcrumb({ category: 'exercise', message: `${JSON.stringify(error, null, 4)}` })
              Sentry.captureMessage('Could not synchronize a routine session!')
              resolve({ hasError: true, error })
            })
        } else {
          resolve('Nothing to synchronize :)')
        }
      } else {
        resolve('No network connection.')
      }
    } catch (error) {
      console.log(error)
      resolve({ hasError: true, error })
    }
  })
}
