import React, { Component } from 'react'
import { Menu, Icon } from 'antd'
import { logout } from 'auth'
import ActiveLink from 'components/ActiveLink'
import Tutorial from '../Tutorial'
import trademarkBackground from 'images/tm.svg'
import burgerNavigationIcon from 'images/navigation.svg'
import { ReactComponent as BackIcon } from 'images/icon-back.svg'
import { ReactComponent as EnrollmentIcon } from 'images/icon-enrollment.svg'
import { ReactComponent as SecurityIcon } from 'images/icon-security.svg'
import { ReactComponent as Weigh } from '../../assets/icons/weigh.svg'
import { ReactComponent as LogoutIcon } from 'images/icon-logout.svg'
import DownloadIcon from 'images/download-white-icon.svg'
import FacebookIcon from 'images/icon-facebook.png'

import { AppVersion, COOKIE } from 'constants.js'

import { Root, Version, WrapTrademark, Trademark, StyledA, StyledSpan, StyledDrawer, StyledMenu, StyledLink } from './Navigation.styled'
import { getCookie } from 'tiny-cookie'
import GlobalContext from 'context/GlobalContext'
import { getGainspotSSO } from 'services'
const moment = require('moment-timezone')

const { SubMenu, Item: MenuItem } = Menu

class Navigation extends Component {
  rootSubmenuKeys = ['Enrollment Data']

  state = {
    visible: false,
    openKeys: this.props.isOpenedEnrollmentData ? ['Enrollment Data'] : [],
    showTutorial: false,
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  handleClose = () => {
    this.setState({
      visible: false,
    })
  }

  openTutorial = () => {
    this.setState({
      showTutorial: true,
      visible: false,
    })
  }

  handleCloseTutorial = () => {
    this.setState({
      showTutorial: false,
    })
  }

  handleOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1)
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      })
    }
  }

  render() {
    const { has_enrollment, currentProgramId, current_has_enrollment, new_program, next_has_enrollment, is_returning } = this.props
    const { visible, openKeys } = this.state
    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          let gymExercises = []
          let homeExercises = []
          let learningUrls = []
          let groupUrls = []
          const todayTZ = moment.tz('America/Chicago')

          programs.forEach((program) => {
            if (program.is_enrollment_completed && program.enrollment && program.enrollment.level > 0) {
              if (program.program_urls.length > 0) {
                const groupDeliveryDate = moment.tz(program.group_delivery_date, 'America/Chicago')
                const ExerciseLinksDeliveryDate = moment.tz(program.exercises_delivery_date, 'America/Chicago')

                groupUrls = groupUrls.concat(
                  program.program_urls
                    .filter((u) => {
                      if (user.total_past_programs <= 3) {
                        return u.type === 'flex' && user.total_past_programs <= 3 && todayTZ.isSameOrAfter(groupDeliveryDate)
                      } else {
                        return u.type === 'foundation' && user.total_past_programs > 3 && todayTZ.isSameOrAfter(groupDeliveryDate)
                      }
                    })
                    .map((u) => {
                      return { url: getGainspotSSO(u.url), label: `Group ${program.description}` }
                    }),
                )

                gymExercises = gymExercises.concat(
                  program.program_urls
                    .filter(
                      (u) =>
                        u.type === 'exercise' &&
                        u.level === program.enrollment.level &&
                        u.sub_level === program.enrollment.sub_level &&
                        todayTZ.isSameOrAfter(ExerciseLinksDeliveryDate),
                    )
                    .map((u) => {
                      return { url: u.url, label: program.description }
                    }),
                )

                homeExercises = homeExercises.concat(
                  program.program_urls
                    .filter(
                      (u) =>
                        u.type === 'exercise_home' &&
                        u.level === program.enrollment.level &&
                        u.sub_level === program.enrollment.sub_level &&
                        todayTZ.isSameOrAfter(ExerciseLinksDeliveryDate),
                    )
                    .map((u) => {
                      return { url: u.url, label: program.description }
                    }),
                )

                learningUrls = learningUrls.concat(
                  program.program_urls
                    .filter((u) => u.type === 'learning')
                    .map((u) => {
                      return { url: u.url, label: program.description }
                    }),
                )
              }
            }
          })

          return (
            <Root>
              <div>
                <StyledSpan onClick={this.showDrawer}>
                  <img src={burgerNavigationIcon} alt="" />
                </StyledSpan>
                <StyledDrawer
                  {...{
                    placement: 'left',
                    closable: false,
                    onClose: this.handleClose,
                    visible,
                    className: 'custom-nav',
                  }}
                >
                  <WrapTrademark>
                    <Trademark bgUrl={trademarkBackground} />
                    <Version>{AppVersion}</Version>
                  </WrapTrademark>
                  <StyledA className="close" onClick={this.handleClose}>
                    <BackIcon />
                  </StyledA>
                  <StyledMenu
                    {...{
                      mode: 'inline',
                      openKeys,
                      onOpenChange: this.handleOpenChange,
                      width: 256,
                    }}
                  >
                    {((currentProgramId !== null && current_has_enrollment !== true) || (new_program !== null && next_has_enrollment !== true)) && (
                      <MenuItem>
                        <EnrollmentIcon />
                        Enrollment Data
                        <ActiveLink to="/enrollment_data">EnrollmentData</ActiveLink>
                      </MenuItem>
                    )}
                    <MenuItem disabled={has_enrollment && !is_returning}>
                      <SecurityIcon />
                      My Subscriptions
                      <StyledLink href={'/history'}> My Subscriptions</StyledLink>
                    </MenuItem>
                    <MenuItem disabled={has_enrollment && !is_returning}>
                      <SecurityIcon />
                      My Account
                      <StyledLink
                        href={`${process.env.REACT_APP_AUTH_SERVER}?aam-jwt=${getCookie(
                          COOKIE.USER_TOKEN.replace('Bearer ', ''),
                        )}&redirect_to=/my-account/`}
                        target="_blank"
                      >
                        My Account
                      </StyledLink>
                    </MenuItem>
                    {learningUrls.length > 0 && (
                      <SubMenu
                        key="Learning Course"
                        title={
                          <span>
                            <Icon type="thunderbolt" />
                            <span>Learning Course</span>
                          </span>
                        }
                      >
                        {learningUrls.map((url, index) => {
                          url.url += `&aam-jwt=${getCookie(COOKIE.USER_TOKEN).replace('Bearer ', '')}`
                          return (
                            <MenuItem key={index} className="learning">
                              <span>{url.label}</span>
                              <StyledLink href={url.url} target="_blank">
                                {url.label}
                              </StyledLink>
                            </MenuItem>
                          )
                        })}
                      </SubMenu>
                    )}
                    {(homeExercises.length > 0 || gymExercises.length > 0) && (
                      <SubMenu
                        key="Training Program"
                        title={
                          <span>
                            <Weigh />
                            <span>Training Program</span>
                          </span>
                        }
                      >
                        {!!homeExercises &&
                          homeExercises.length > 0 &&
                          homeExercises.map((program, index) => {
                            return (
                              <MenuItem key={`home-${index}`} className="learning">
                                <span>{`Home Routine ${program.label}`}</span>
                                <div className="download">
                                  <img src={DownloadIcon} alt="" />
                                </div>
                                <StyledLink href={program.url} target="_blank">
                                  Home Routine
                                </StyledLink>
                              </MenuItem>
                            )
                          })}

                        {!!gymExercises &&
                          gymExercises.length > 0 &&
                          gymExercises.map((e, i) => {
                            return (
                              <MenuItem key={`gym-${i}`} className="learning">
                                <span>{`Gym Routine ${e.label}`}</span>
                                <div className="download">
                                  <img src={DownloadIcon} alt="" />
                                </div>
                                <StyledLink href={e.url} target="_blank">
                                  Gym Routine
                                </StyledLink>
                              </MenuItem>
                            )
                          })}
                      </SubMenu>
                    )}

                    {!!groupUrls &&
                      groupUrls.length > 0 &&
                      groupUrls.map((f, i) => {
                        return (
                          <MenuItem key={i}>
                            <img
                              src={FacebookIcon}
                              alt="Facebook Group Icon"
                              style={{
                                fontSize: '14px',
                                height: '14px',
                                width: '14px',
                                marginRight: '8px',
                              }}
                            />
                            {f.label}
                            <StyledLink href={f.url} target="_blank">
                              {f.label}
                            </StyledLink>
                          </MenuItem>
                        )
                      })}

                    <MenuItem onClick={this.openTutorial}>
                      <Icon type="info-circle" />
                      Portal Tour
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        logout()
                      }}
                    >
                      <LogoutIcon className="logout" />
                      Logout
                    </MenuItem>
                  </StyledMenu>
                </StyledDrawer>
              </div>
              <Tutorial showed={this.state.showTutorial} onCloseTutorial={this.handleCloseTutorial} />
            </Root>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Navigation
