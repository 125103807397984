import React, { Component } from 'react'
import { Root, Wrap, H1, A, Centered } from './Bootcamp.styled'

class Bootcamp extends Component {
  render() {
    return (
      <Root>
        <Wrap>
          <H1>Bootcamp Facebook Group</H1>
          <p>
            Below you will find a link to join the a pre-bootcamp learning course. Please click on the link and use the password mentioned below to
            join this community. This is a collection of steps to take, best practices, and some generally useful advice. Likewise, there is also a
            Q&A where you may ask any questions that you need answered.
          </p>
          <p>
            On the weekend before camp begins, you will receive an email from your coach with an introduction as well as a PDF file that includes
            instructions to join the actual bootcamp group. On that Sunday morning (US Central Time), prior to the beginning of camp, we will begin
            admitting people into that group, which will contain your full bootcamp manual and the training file, which you will then be able to
            download to your personal laptop or device.
          </p>
          <p>
            If you have any needs related to customer service, please don’t hesitate to contact us via our customer service email:
            <Centered>
              <A href="mailto:info@ketogains.com">info@ketogains.com</A>.
            </Centered>
          </p>
          <p>With Warmest Regards,</p>
          <p>
            <strong>The Ketogains Coaching Team</strong>
          </p>
        </Wrap>
      </Root>
    )
  }
}

export default Bootcamp
