import React from 'react'
import { Redirect } from '@reach/router'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import GlobalContext from 'context/GlobalContext'
import moment from 'moment'
import { LOCAL_STORAGE } from 'constants.js'
import { Result, Button, Spin } from 'antd'
import AppLoading from '../AppLoading'

const today = moment().local()

const getData = gql`
  query {
    App @client {
      loading
    }
  }
`

const getUserData = gql`
  query getUserData($userId: uuid!, $traineeId: uuid!, $today: date!) {
    user(where: { id: { _eq: $userId } }) {
      id
      contact_id
      email
      first_name
      last_name
      picture
      units
      gender
      country
      trainee {
        id
        programs: selected(
          where: { _and: [{ is_active: { _eq: true } }, { program: { close_date: { _gte: $today } } }] }
          order_by: { program: { start_date: asc } }
        ) {
          id
          program_id
          review_sent
          student_can_reply
          is_active
          coach_id
          extras
          coach {
            id
            user {
              id
            }
          }
          thread_id
          enrollment {
            level
            sub_level
          }
          enrollment_stage
          program {
            type
            description
            access_date
            start_date
            end_date
            close_date
            duration
            group_delivery_date
            exercises_delivery_date
            program_urls {
              type
              level
              sub_level
              url
            }
          }
        }
        cancelled_programs: selected(
          where: { _and: [{ is_active: { _eq: false } }, { program: { close_date: { _gte: $today } } }] }
          order_by: { program: { start_date: asc } }
        ) {
          id
          cancellation_reason
          cancellation_date
          program {
            type
            description
            access_date
            start_date
            end_date
            close_date
            duration
          }
        }
        past_programs: selected_aggregate(where: { _and: [{ is_active: { _eq: true } }, { program: { access_date: { _lte: $today } } }] }) {
          aggregate {
            count
          }
        }
      }
    }
    message_aggregate(
      where: { _and: [{ sender_id: { _neq: $userId } }, { read: { _eq: false } }, { thread: { trainee_id: { _eq: $traineeId } } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Query query={getData}>
      {({ data }) => {
        const {
          App: { loading },
        } = data

        const exUserId = localStorage.getItem(LOCAL_STORAGE.EX_USER_ID)
        const id = localStorage.getItem(LOCAL_STORAGE.USER_ID)
        const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)

        let loginUrl = '/login'

        if (rest.path) {
          if (rest.programTag) {
            loginUrl += `?redirect=${rest.uri}`
          } else if (rest.path !== '/') {
            loginUrl += `?redirect=${rest.path}`
          }
        }

        if (!exUserId) {
          return <Redirect to={loginUrl} noThrow />
        }
        if (loading) {
          return <AppLoading type="loading" />
        }

        return (
          <Query query={getUserData} variables={{ userId: id, traineeId, today: today.format('YYYYMMDD') }} fetchPolicy="network-only">
            {({ error, loading: loadingDB, data: dataDB }) => {
              if (loadingDB) {
                return <Spin />
              }
              if (error) {
                return (
                  <Result
                    status="warning"
                    title="We could not connect to the server."
                    subTitle={`${error.message}`}
                    extra={
                      <Button type="primary" onClick={() => window.location.reload()}>
                        Try again
                      </Button>
                    }
                  />
                )
              }

              const totalMessages = dataDB.message_aggregate ? dataDB.message_aggregate.aggregate.count : 0
              let user = {}
              let programs = []
              let cancelledPrograms = []

              if (dataDB.user && dataDB.user.length > 0) {
                user = JSON.parse(JSON.stringify(dataDB.user[0]))
                user.is_returning = false
                user.nickname = `${user.first_name} ${user.last_name}`
                user.trainee_id = user.trainee.id

                if (user.trainee.past_programs) {
                  user.is_returning = user.trainee.past_programs.aggregate.count > 0
                  user.total_past_programs = user.trainee.past_programs.aggregate.count
                } else {
                  user.total_past_programs = 0
                }

                if (user.trainee.programs.length > 0) {
                  programs = user.trainee.programs
                }

                if (user.trainee.cancelled_programs.length > 0) {
                  cancelledPrograms = user.trainee.cancelled_programs
                }
              }

              delete user.trainee

              programs = programs.map((p) => {
                const isClosing = today.isBetween(
                  moment(p.program.end_date)
                    .add(1, 'day')
                    .local(),
                  moment(p.program.close_date).local(),
                )
                const isCurrent = today.isBetween(
                  moment(p.program.start_date).local(),
                  moment(p.program.end_date)
                    .add(1, 'day')
                    .local(),
                )
                const isUpcoming = today.isBefore(moment(p.program.start_date).local()) && today.isSameOrAfter(moment(p.program.access_date).local())

                const lastEnrollmentStep = p.enrollment_stage.find((es) => es.id === 'finish')
                let isEnrollmentCompleted = false

                if (lastEnrollmentStep) {
                  isEnrollmentCompleted = lastEnrollmentStep.stages.length > 0 && lastEnrollmentStep.stages.indexOf('terms-and-conditions') !== -1
                }

                let typeLabel = ''

                switch (p.program.type) {
                  case 'macros':
                    typeLabel = 'Macros Review'
                    break
                  case 'meal_plan':
                    typeLabel = 'Meal Plan'
                    break
                  case 'bootcamp':
                  default:
                    typeLabel = 'Bootcamp'
                    break
                }

                const programToReturn = {
                  ...p,
                  ...p.program,
                  is_closing: isClosing,
                  is_current: isCurrent,
                  is_upcoming: isUpcoming,
                  is_enrollment_completed: isEnrollmentCompleted,
                  typeLabel,
                }
                delete programToReturn.program
                return programToReturn
              })

              cancelledPrograms = cancelledPrograms.map((p) => {
                const isCurrent = today.isBetween(moment(p.program.start_date).local(), moment(p.program.end_date).local())
                const isUpcoming = today.isBefore(moment(p.program.start_date).local()) && today.isSameOrAfter(moment(p.program.access_date).local())

                const programToReturn = { ...p, ...p.program, is_current: isCurrent, is_upcoming: isUpcoming }
                delete programToReturn.program
                return programToReturn
              })

              localStorage.setItem(LOCAL_STORAGE.CONTACT_ID, user.contact_id)
              localStorage.setItem(LOCAL_STORAGE.USER_EMAIL, user.email)
              localStorage.setItem(LOCAL_STORAGE.USER_NICKNAME, user.nickname)
              localStorage.setItem(LOCAL_STORAGE.USER_COUNTRY, user.country)
              localStorage.setItem(LOCAL_STORAGE.USER_GENDER, user.gender)
              const context = {
                user,
                programs,
                cancelled_programs: cancelledPrograms,
                total_messages: totalMessages,
              }

              return (
                <GlobalContext.Provider value={context}>
                  <Component {...rest} />
                </GlobalContext.Provider>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default PrivateRoute
