import React from 'react'
import { BtnFakeSuccess } from './BtnFakeSuccess.styled'
import { ReactComponent as Ok } from 'assets/icons/ok.svg'

const ButtonFakeSuccess = () => {
  return (
    <BtnFakeSuccess>
      <Ok />
    </BtnFakeSuccess>
  )
}

export default ButtonFakeSuccess
