import styled from '@emotion/styled'
import { Card } from 'antd'

export const CstCard = styled(Card)`
  box-shadow: ${({ mealplan }) => (mealplan === 'true' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '0px 2px 12px rgba(0, 0, 0, 0.147454)')};
  border-radius: 4px;
  width: 100%;
  //max-width: ${({ mealplan }) => (mealplan === 'true' ? '100%' : '400px')};
  border: ${({ mealplan }) => (mealplan === 'true' ? 'border: 1px solid #DFDFDF' : 'none')};
  //margin-bottom: ${({ mealplan }) => (mealplan === 'true' ? '10px' : 'none')};
  margin-bottom: 10px;
  .ant-card-body {
    padding: 0px;
  }
`
