import styled from '@emotion/styled'
import { PageHeader } from 'antd'

export const Root = styled.header`
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.65);
  text-align: center;
  padding: 10px 20px;
  position: absolute;
  left: 20px;
  right: 20px;
`

export const PageHeaderStyled = styled(PageHeader)`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  height: 64px;

  .ant-page-header-heading {
    max-width: 460px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50px auto 50px;

    .ant-page-header-back {
      margin: 0;
      align-self: center;
    }

    .ant-page-header-heading-title {
      text-align: center;
      align-self: center;
      margin: 0;
      padding: 0;
      color: #4a4a4a;
      font-weight: bold;
    }

    .ant-page-header-heading-extra {
      text-align: right;
      margin: 0;
      align-self: center;
      padding: 0;

      .ant-btn {
        height: auto;
        padding: 3px;

        svg {
          vertical-align: middle;
        }
      }
    }
  }

  @media screen and (max-width: 399.99px) {
    padding: 16px 8px;

    .ant-page-header-heading {
      .ant-page-header-heading-title {
        font-size: 16px;
      }
    }
  }
`
