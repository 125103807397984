import { Select } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export default styled(Select)`
  width: 100%;
  .ant-select-selection {
    font-family: 'Montserrat', sans-serif;
    height: 35px;
    font-size: 10px;
    &:hover {
      border-color: #5d5d5d;
    }
    &:focus,
    &:active {
      border-color: #5d5d5d;
      box-shadow: 0 0 3px 0 #5d5d5d;
    }
  }
  .ant-select-selection__rendered {
    line-height: 32px;
  }
  .ant-select-dropdown {
    font-size: 10px;
  }
  .ant-form-item-children-icon {
    display: none;
  }

  ${({ custom }) => {
    if (custom === 'short') {
      return css`
        && {
          max-width: 130px;
        }
      `
    }
  }}
`
