import React, { Component, Fragment } from 'react'
import { RegisterUser } from 'auth'
import DocumentEvents from 'react-document-events'
import { Form, Icon, message, Result } from 'antd'
import Logo from 'components/Logo'
import SubmitButton from 'components/SubmitButton'
import { AppVersion } from 'constants.js'
import { StyledForm, StyledInput } from 'styled'
import backgroundImage from 'images/sign-in-bg.png'
import { Root, H1, Main, Header, Content, Version, StyledTooltip, StyledLinkLogin } from './SignUp.styled'
import Peephole from './Peephole'

const FormItem = Form.Item

class Register extends Component {
  state = {
    showPassword: false,
    isCapsLock: false,
    showSuccess: false,
  }

  handleShowingPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }))
  }

  pressPassword = (event) => {
    this.setState({
      isCapsLock: event.getModifierState('CapsLock'),
    })
  }

  RegisterUserValidation = (event) => {
    event.preventDefault()
    const {
      form: { validateFieldsAndScroll },
    } = this.props
    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, { firstName, lastName, username, password }) => {
        if (error) {
          console.error(error)
          reject()
          return
        }
        RegisterUser({ firstName, lastName, username, password })
          .then((data) => {
            if (data.hasError) {
              throw new Error(data.message)
            }
            this.setState(() => ({
              showSuccess: true,
            }))
            setTimeout(() => {
              window.location.href = `/login${this.getRedirect(false)}`
            }, 1500)
            resolve()
          })
          .catch((errorResponse) => {
            message.error(`We could not create your account. ${errorResponse}`)
            console.warn(errorResponse)
            resolve()
          })
      })
    })
  }

  disableCapsLock = (event) => {
    if (event.key === 'CapsLock') {
      this.setState({
        isCapsLock: false,
      })
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props
    if (value && value !== form.getFieldValue('password')) {
      callback('Password confirm does not match')
    } else {
      callback()
    }
  }

  getRedirect = (format = true) => {
    let redirectFormat = ''
    if (this.props.location && this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (format && params.redirect) {
        redirectFormat = params.redirect
      } else {
        redirectFormat = this.props.location.search
      }
    }

    return redirectFormat
  }

  render() {
    const { showPassword, isCapsLock, showSuccess } = this.state
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    return (
      <Root bgUrl={backgroundImage}>
        <DocumentEvents onKeyUp={this.disableCapsLock} />
        <Content>
          <Header>
            <Logo>ketogains</Logo>
          </Header>
          <Main>
            <H1>Sign Up</H1>
            {showSuccess && (
              <Result
                status="success"
                title="Registration Successful"
                subTitle={
                  <small>
                    You will be redirect to <a href={`/login${this.getRedirect(false)}`}>login</a>
                  </small>
                }
              />
            )}
            {!showSuccess && (
              <StyledForm hideRequiredMark onSubmit={this.RegisterUserValidation}>
                <FormItem label="First name">
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: 'Please input your first name' }],
                  })(<StyledInput type="String" />)}
                </FormItem>
                <FormItem label="Last name">
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: 'Please input your last name!' }],
                  })(<StyledInput type="String" />)}
                </FormItem>
                <FormItem label="Email">
                  {getFieldDecorator('username', {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid email!',
                      },
                      { required: true, message: 'Please input your email!' },
                    ],
                  })(<StyledInput type="email" />)}
                </FormItem>
                <FormItem label="Password" style={{ maxWidth: '270px' }}>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Please input your password!' },
                      {
                        pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!#$%\-_=+<>])([a-zA-Z0-9!#$%\-_=+<>]+){8,}$/,
                        message: 'Password must contain 8 characters(at least one letter, one number and one symbol)',
                      },
                    ],
                  })(
                    <StyledInput
                      {...{
                        type: showPassword ? 'text' : 'password',
                        onKeyPress: this.pressPassword,
                        suffix: (
                          <Fragment>
                            {isCapsLock && (
                              <StyledTooltip title="You have CapsLock on!">
                                <Icon type="warning" />
                              </StyledTooltip>
                            )}
                            {!!getFieldValue('password') && <Peephole showPassword={showPassword} tooglePassword={this.handleShowingPassword} />}
                          </Fragment>
                        ),
                      }}
                    />,
                  )}
                </FormItem>
                <FormItem label="Confirm Password">
                  {getFieldDecorator('confirm', {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <StyledInput
                      {...{
                        type: showPassword ? 'text' : 'password',
                        onKeyPress: this.pressPassword,
                        suffix: (
                          <Fragment>
                            {isCapsLock && (
                              <StyledTooltip title="You have CapsLock on!">
                                <Icon type="warning" />
                              </StyledTooltip>
                            )}
                            {!!getFieldValue('confirm') && <Peephole showPassword={showPassword} tooglePassword={this.handleShowingPassword} />}
                          </Fragment>
                        ),
                      }}
                    />,
                  )}
                </FormItem>

                <FormItem>
                  <SubmitButton
                    {...{
                      handleSubmit: this.RegisterUserValidation,
                      buttonContent: 'Register',
                    }}
                  />
                </FormItem>

                <FormItem style={{ justifyContent: 'center', display: 'flex' }}>
                  Already have an account?
                  <StyledLinkLogin href={`/login${this.getRedirect(false)}`}> Sign In</StyledLinkLogin>
                </FormItem>
                <Version>{AppVersion}</Version>
              </StyledForm>
            )}
          </Main>
        </Content>
      </Root>
    )
  }
}

export default Form.create()(Register)
