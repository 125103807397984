import gql from 'graphql-tag'

export const getProgramStatuses = gql`
  query getProgramStatuses($traineeId: uuid!, $programId: uuid!) {
    trainee_program_coach(where: { trainee_id: { _eq: $traineeId }, program_id: { _eq: $programId } }) {
      macro {
        status
      }
      meal_plan {
        status
        url
      }
    }
  }
`

export const getBootcampComing = gql`
  query getBootcampComing($date: date!) {
    program(where: { start_date: { _gte: $date } }, order_by: { access_date: asc }) {
      program_id
      product_tag
      description
      start_date
      access_date
    }
  }
`
export const getBootcampNewUser = gql`
  query getBootcampNewUser($trainee: uuid) {
    trainee_program_coach_aggregate(where: { trainee_id: { _eq: $trainee } }) {
      aggregate {
        count
      }
    }
  }
`
