import React, { Component } from 'react'
import './index.scss'
import Layout from 'components/Layout'
import iconEdit from 'images/icon_edit.svg'
import { Card, Input, Button, Menu, Dropdown, Icon } from 'antd'
import { getBodyFatPercentage } from 'services'
import { LOCAL_STORAGE } from 'constants.js'
import iconLoseBodyFat from 'images/icon-lose-body-fat.png'
import iconGainMuscle from 'images/icon-gain-muscle.png'
import iconBodyRecomposition from 'images/icon-body-recomposition.png'
import iconLevel1 from 'images/icon_level_1.png'
import iconLevel2 from 'images/icon_level_2.png'
import iconLevel3 from 'images/icon_level_3.png'
import iconWarning from 'images/enrollment_warning.svg'
import iconWarning2 from 'images/enrollment_warning_2.svg'
import PictureUploader from 'components/PictureUploader'
import pictureIcon from 'images/picture.svg'
import { apiServices } from 'services/api'

const FooterEditButton = ({ buttonText, type, handleClick }) => {
  return (
    <Button className={type} onClick={handleClick}>
      {buttonText}
    </Button>
  )
}

const FooterEdit = ({ handleSave, handleCancel }) => {
  return (
    <div className="footer-edit">
      <FooterEditButton buttonText="CANCEL" type="default" handleClick={handleCancel} />
      <FooterEditButton buttonText="SAVE" type="primary" handleClick={handleSave} />
    </div>
  )
}

const LevelCard = ({ level, handleChange, editable, detailsEdit }) => {
  let icon = null
  let text = ''
  let leveltxt = ''

  switch (level) {
    case 1:
      icon = iconLevel1
      text = 'A starting point for many, and a launching point for all!'
      leveltxt = level
      break
    case 2:
      icon = iconLevel2
      text = "You have mastered the basics, now let's learn new things!"
      leveltxt = level
      break
    case 3:
      icon = iconLevel3
      text = 'You are ready to test your mind and body at the next level!'
      leveltxt = level
      break
    default:
      text = level
      break
  }

  return (
    <Card title="Your Level" className="card level-card" extra={<div className="yellow-border-title" />}>
      <div className="overhead-card">
        <img src={icon} alt="" />
        <div className="info-container">
          <p className="info-title">Level {leveltxt}</p>
          <p className="info-description">{text}</p>
        </div>
      </div>
    </Card>
  )
}

const GoalCard = ({ current_goal, handleChange, editable, detailsEdit }) => {
  let icon = null

  const menu = (
    <Menu onClick={(e) => handleChange('current_goal', e.key)}>
      <Menu.Item key="LOSE_BODY_FAT">Lose Body Fat</Menu.Item>
      <Menu.Item key="GAIN_MUSCLE">Gain Muscle & Increase Strength</Menu.Item>
      <Menu.Item key="BODY_RECOMPOSITION">Body Recomposition</Menu.Item>
    </Menu>
  )

  switch (detailsEdit && detailsEdit.current_goal ? detailsEdit.current_goal : current_goal) {
    case 'LOSE_BODY_FAT':
      icon = iconLoseBodyFat
      break
    case 'GAIN_MUSCLE':
      icon = iconGainMuscle
      break
    case 'BODY_RECOMPOSITION':
      icon = iconBodyRecomposition
      break
    default:
      break
  }

  let current_goal_formatted = ''

  switch (current_goal) {
    default:
    case 'LOSE_BODY_FAT':
      current_goal_formatted = 'Lose Body Fat'
      break
    case 'GAIN_MUSCLE':
      current_goal_formatted = 'Gain Muscle & Increase Strength'
      break
    case 'BODY_RECOMPOSITION':
      current_goal_formatted = 'Body Recomposition'
      break
  }

  return (
    <Card title="Your Goal" className="card goal-card" extra={<div className="yellow-border-title" />}>
      <div className="overhead-card">
        {icon ? <img src={icon} alt="" /> : ''}
        <div className={editable ? 'info-container editable' : 'info-container'}>
          {editable ? '' : <p className="info-title">Initial Goal</p>}
          {editable ? (
            <Dropdown overlay={menu}>
              <Button>
                <span>
                  {current_goal_formatted} <Icon type="down" />
                </span>
              </Button>
            </Dropdown>
          ) : (
            <p className="info-description">{current_goal_formatted}</p>
          )}
        </div>
      </div>
    </Card>
  )
}

const PicturesCard = ({ photo_front_url, photo_back_url, photo_side_url, editable, handleChange }) => {
  return (
    <Card title="Your Starting Pictures" className="card" extra={<div className="yellow-border-title" />}>
      <PictureUploader
        picture={photo_front_url ? photo_front_url : ''}
        placeholder={pictureIcon}
        onSelect={(pictureUrl) => handleChange('photo_front_url', pictureUrl)}
        disabled={!editable}
      />
      <PictureUploader
        picture={photo_back_url ? photo_back_url : ''}
        placeholder={pictureIcon}
        onSelect={(pictureUrl) => handleChange('photo_back_url', pictureUrl)}
        disabled={!editable}
      />
      <PictureUploader
        picture={photo_side_url ? photo_side_url : ''}
        placeholder={pictureIcon}
        onSelect={(pictureUrl) => handleChange('photo_side_url', pictureUrl)}
        disabled={!editable}
      />
    </Card>
  )
}

const BodyFatCard = ({ editable, bf_method, bf_pct, handleChange, detailsEdit }) => {
  let droptext = ''

  let bodyfattxt = ''

  let methodtrust = null

  switch (bf_method) {
    case 'DEXA':
      droptext = 'DEXA'
      break
    case 'BODPOD':
      droptext = 'BodPod'
      break
    case 'NAVY':
      droptext = 'Navy Bodyfat'
      break
    case 'SKULPT':
      droptext = 'Skulpt'
      break
    case 'OTHER':
      droptext = 'Another Method'
      break
    case 'NO':
      droptext = 'No'
      break
    default:
      break
  }

  if (editable) {
    switch (detailsEdit.bf_method) {
      case 'DEXA':
        bodyfattxt = 'Great! Enter your known Body Fat in the field below'
        methodtrust = true
        break
      case 'BODPOD':
        bodyfattxt = 'Great! Enter your known Body Fat in the field below'
        methodtrust = true
        break
      case 'NAVY':
        bodyfattxt = 'Great! Enter your known Body Fat in the field below'
        methodtrust = true
        break
      case 'SKULPT':
        bodyfattxt = 'Great! Enter your known Body Fat in the field below'
        methodtrust = true
        break
      case 'OTHER':
        bodyfattxt = 'The method you chose is not accurate enough. We had calculated it for you.'
        methodtrust = false
        break
      case 'NO':
        bodyfattxt = 'No worries, we will calculate it for you'
        methodtrust = false
        break
      default:
        break
    }
  }

  const menu = (
    <Menu onClick={(e) => handleChange('bf_method', e.key)}>
      <Menu.Item key="DEXA">Yes, by using DEXA</Menu.Item>
      <Menu.Item key="BODPOD">Yes, by using BodPod</Menu.Item>
      <Menu.Item key="NAVY">Yes, by using Navy Bodyfat</Menu.Item>
      <Menu.Item key="SKULPT">Yes, by using Skulpt</Menu.Item>
      <Menu.Item key="OTHER">Yes, by using another method</Menu.Item>
      <Menu.Item key="NO">No</Menu.Item>
    </Menu>
  )

  return (
    <Card title="Your Body Fat" className="card body-fat-card" extra={<div className="yellow-border-title" />}>
      {editable ? (
        <div>
          <p className="item-title">Do you know your body fat percentage?</p>
          <Dropdown overlay={menu}>
            <Button>
              <span>{detailsEdit.bf_method}</span>
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
      ) : (
        <div className="body-fat-method">{droptext}</div>
      )}
      <div className="body-fat-item">
        <div className="title-container">
          <p className="item-title">{editable ? bodyfattxt : 'My Body Fat Percentage'}</p>
        </div>
        {editable ? (
          <div>
            <Input
              value={detailsEdit.bf_pct}
              type="number"
              name="bf_pct"
              disabled={!methodtrust}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <span className="item-edit-measurement">%</span>
          </div>
        ) : (
          <p className="item-value">{bf_pct} %</p>
        )}
      </div>
    </Card>
  )
}

const MeasurementsCard = ({
  editable,
  neck,
  chest,
  shoulder,
  arm,
  forearm,
  belly_waist,
  narrow_waist,
  hip,
  thigh,
  calf,
  metric,
  handleChange,
  detailsEdit,
}) => {
  let mtr = metric === 'lbs/in' ? 'In' : 'Cm'

  return (
    <Card title="Your Measurements" className="card measurements-card" extra={<div className="yellow-border-title" />}>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Neck</p>
          <p className="item-subtitle">(Narrowest point)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.neck} type="number" name="neck" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {neck} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Chest</p>
          <p className="item-subtitle">(Around nipple height)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.chest} type="number" name="chest" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {chest} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Shoulder</p>
          <p className="item-subtitle">(Circumference)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.shoulder} type="number" name="shoulder" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {shoulder} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Arm</p>
          <p className="item-subtitle">(Dominant arm, unflexed)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.arm} type="number" name="arm" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {arm} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Forearm</p>
          <p className="item-subtitle">(Dominant arm, unflexed)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.forearm} type="number" name="forearm" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {forearm} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Waist</p>
          <p className="item-subtitle">(At belly button/navel)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.belly_waist} type="number" name="belly_waist" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {belly_waist} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Waist</p>
          <p className="item-subtitle">(At narrowest point)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.narrow_waist} type="number" name="narrow_waist" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {narrow_waist} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Hip</p>
          <p className="item-subtitle">(At widest point of the hip, not glutes)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.hip} type="number" name="hip" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {hip} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Thigh</p>
          <p className="item-subtitle">(Dominant leg, unflexed)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.thigh} type="number" name="thigh" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {thigh} {mtr}
          </p>
        )}
      </div>
      <div className="measurements-item">
        <div className="title-container">
          <p className="item-title">Calf</p>
          <p className="item-subtitle">(Dominant leg, unflexed)</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.calf} type="number" name="calf" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{mtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {calf} {mtr}
          </p>
        )}
      </div>
    </Card>
  )
}

const BodyCompositionCard = ({ editable, height, weight, metric, handleChange, detailsEdit }) => {
  let heightmtr = metric === 'lbs/in' ? 'In' : 'Cm'
  let weightmtr = metric === 'lbs/in' ? 'Lbs' : 'Kg'

  return (
    <Card title="Body Composition" className="card body-composition-card" extra={<div className="yellow-border-title" />}>
      <div className="body-composition-item">
        <div className="title-container">
          <p className="item-title">Height</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.height} type="number" name="height" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{heightmtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {height} {heightmtr}
          </p>
        )}
      </div>
      <div className="body-composition-item">
        <div className="title-container">
          <p className="item-title">Weight</p>
        </div>
        {editable ? (
          <div>
            <Input value={detailsEdit.weight} type="number" name="weight" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <span className="item-edit-measurement">{weightmtr}</span>
          </div>
        ) : (
          <p className="item-value">
            {weight} {weightmtr}
          </p>
        )}
      </div>
    </Card>
  )
}

const EditSection = ({ handleClick }) => {
  return (
    <div className="edit-enrollment">
      <p onClick={handleClick}>Edit your enrollment data</p>
      <img src={iconEdit} alt="" onClick={handleClick} />
    </div>
  )
}

const Warning = ({ status }) => {
  let uppertext = ''
  let bottomtext = ''
  let icon = ''

  if (status === 'Approved') {
    uppertext = 'Your are editing your enrollment data!'
    bottomtext = 'Adjustment in your data will required to be approve. Please contact your Bootcamp Coach.'
    icon = iconWarning
  } else {
    uppertext = 'Your data is in review by our Ketogains Team'
    bottomtext = 'You need to receive your Macros to edit your Enrollment Data'
    icon = iconWarning2
  }

  return (
    <div className="warning-container">
      <div className={status === 'Approved' ? 'upper-container approved' : 'upper-container warning'}>
        <img src={icon} alt="" />
      </div>
      <div className="text-container">
        <p className="uppertext">{uppertext}</p>
        <p className="bottomtext">{bottomtext}</p>
      </div>
    </div>
  )
}

const BootcampSelection = ({ editable, handleClick, programDescription, bootcamps, bootcamp }) => {
  const menu = (
    <Menu onClick={(e) => handleClick(e)}>
      {bootcamps
        ? bootcamps.map((bootcamp) => {
            return (
              <Menu.Item key={bootcamp.bootcamp} title={bootcamp.id}>
                {bootcamp.description}
              </Menu.Item>
            )
          })
        : ''}
    </Menu>
  )

  let text = editable ? 'Your Bootcamp' : 'Select the Bootcamp'

  return (
    <div className="bootcamp-selection">
      <div className="message-container">
        <span>{text}</span>
      </div>
      <div className="dropdown-container">
        {editable ? (
          <div className="button-text-container">
            <p className="button-uppertext">{programDescription}</p>
            <p className="button-bottomtext">{`${bootcamp} Bootcamp`}</p>
          </div>
        ) : (
          <Dropdown overlay={menu}>
            <Button>
              <div className="button-text-container">
                <p className="button-uppertext">{programDescription}</p>
                <p className="button-bottomtext">{`${bootcamp} Bootcamp`}</p>
              </div>
              <Icon type="down" />
            </Button>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

const Title = () => {
  return (
    <div className="enrollment-title">
      <p className="upper-text">Your</p>
      <p className="bottom-text">Enrollment Data</p>
    </div>
  )
}

class EnrollmentData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editable: false,
      bootcamps: [],
      loading: true,
    }
    this.saveData = this.props.handleSave
  }

  handleBootcampSelection = async (e) => {
    let bootcamp = await this.state.bootcamp

    if (e.key !== bootcamp) {
      if (e.key === 'current') {
        this.setState({
          editable: false,
          bootcamp: e.key,
          details: this.props.currentData,
          macrosStatus: this.props.currentMacrosStatus,
          programDescription: e.item.props.children,
          detailsEdit: this.props.currentData,
          program_id: e.item.props.title,
        })
      } else {
        this.setState({
          editable: false,
          bootcamp: e.key,
          details: this.props.nextData,
          macrosStatus: this.props.nextMacrosStatus,
          programDescription: e.item.props.children,
          detailsEdit: this.props.nextData,
          program_id: e.item.props.title,
        })
      }
    }
  }

  handleBodyFatCalculate = async () => {
    let gender = await localStorage.getItem(LOCAL_STORAGE.USER_GENDER)
    let { metric } = await this.state
    let { height, neck, belly_waist, narrow_waist, hip } = await this.state.details
    let lengthUnit = (await metric) === 'lbs/in' ? 'in' : 'cm'

    let bfpct = await getBodyFatPercentage(gender, lengthUnit, height, neck, belly_waist, narrow_waist, hip)

    await this.setState((prevState) => ({
      detailsEdit: {
        ...prevState.detailsEdit,
        bf_pct: bfpct,
      },
    }))
  }

  handleDetailsChange = async (name, value) => {
    if (name === 'bf_method') {
      if (
        value !== 'Yes, by using DEXA' &&
        value !== 'Yes, by using BodPod' &&
        value !== 'Yes, by using Navy Bodyfat' &&
        value !== 'Yes, by using Skulpt'
      ) {
        this.handleBodyFatCalculate()
      }
    }

    await this.setState((prevState) => ({
      detailsEdit: {
        ...prevState.detailsEdit,
        [name]: value,
      },
    }))
  }

  handleSetEditable = async () => {
    let edit = await this.state.editable

    let dis = (await edit) ? 'block' : 'none'

    document.getElementById('footer').style.display = await dis
    this.setState({
      editable: !this.state.editable,
    })
  }

  handleCancel = async () => {
    let { details } = await this.state

    document.getElementById('footer').style.display = 'block'
    this.setState({
      editable: !this.state.editable,
      detailsEdit: details,
    })
  }

  handleSave = async () => {
    let { detailsEdit, macrosStatus, program_id } = await this.state
    let trainee_id = await localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
    let need_coach_approval = macrosStatus.status === 'Approved' ? true : false
    let { refetch } = this.props

    document.getElementById('footer').style.display = 'block'

    let {
      id: detailsId,
      neck,
      chest,
      shoulder,
      arm,
      forearm,
      belly_waist,
      narrow_waist,
      hip,
      thigh,
      calf,
      current_goal,
      height,
      weight,
      bf_pct,
      bf_method,
      level,
      photo_front_url,
      photo_side_url,
      photo_back_url,
    } = await this.state.detailsEdit

    if (bf_method === 'NO' || bf_method === 'OTHER') bf_pct = null

    await this.saveData({
      detailsId,
      neck: `${neck}`,
      chest: `${chest}`,
      shoulder: `${shoulder}`,
      arm: `${arm}`,
      forearm: `${forearm}`,
      belly_waist: `${belly_waist}`,
      narrow_waist: `${narrow_waist}`,
      hip: `${hip}`,
      thigh: `${thigh}`,
      calf: `${calf}`,
      current_goal: `${current_goal}`,
      height: `${height}`,
      weight: `${weight}`,
      bf_pct: bf_pct,
      bf_method: `${bf_method}`,
      level: `${level}`,
      photo_front_url: !!photo_front_url ? `${photo_front_url}` : null,
      photo_side_url: !!photo_side_url ? `${photo_side_url}` : null,
      photo_back_url: !!photo_back_url ? `${photo_back_url}` : null,
      need_coach_approval: need_coach_approval,
      refetch: refetch,
    })

    if (macrosStatus) {
      if (macrosStatus.status === 'PendingToReview') {
        await apiServices('POST', `macro-calculator`, { trainee_id, program_id })
          .then(async () => {
            console.log('success send macro-calculator')
          })
          .catch((error) => console.log(error))
      }
    }

    this.setState({
      editable: !this.state.editable,
      details: detailsEdit,
    })
  }

  componentDidMount() {
    let details = this.props.bootcamps[0].bootcamp === 'current' ? this.props.currentData : this.props.nextData
    this.setState({
      metric: details.metric,
      bootcamps: this.props.bootcamps,
      bootcamp: this.props.bootcamps[0].bootcamp === 'current' ? 'current' : 'upcoming',
      details: details,
      macrosStatus: this.props.bootcamps[0].bootcamp === 'current' ? this.props.currentMacrosStatus : this.props.nextMacrosStatus,
      programDescription: this.props.bootcamps[0].description,
      program_id: this.props.bootcamps[0].id,
      detailsEdit: this.props.bootcamps[0].bootcamp === 'current' ? this.props.currentData : this.props.nextData,
      loading: false,
    })
  }

  render() {
    const { metric, details, programDescription, bootcamps, bootcamp, loading } = this.state

    if (loading) return null

    return (
      <Layout>
        <div className="root">
          <Title />
          <BootcampSelection
            handleClick={this.handleBootcampSelection}
            programDescription={programDescription}
            bootcamps={bootcamps}
            bootcamp={bootcamp}
          />
          {this.state.editable ? (
            this.state.macrosStatus.status === 'Approved' && <Warning status={this.state.macrosStatus.status} />
          ) : this.state.macrosStatus.status === 'InProgress' ? (
            <Warning status={this.state.macrosStatus.status} />
          ) : (
            <EditSection handleClick={this.handleSetEditable} />
          )}
          <BodyCompositionCard
            editable={this.state.editable}
            height={details && details.height ? details.height : 0}
            weight={details && details.weight ? details.weight : 0}
            detailsEdit={this.state.detailsEdit}
            metric={metric}
            handleChange={this.handleDetailsChange}
          />
          <MeasurementsCard
            editable={this.state.editable}
            neck={details && details.neck ? details.neck : 0}
            chest={details && details.chest ? details.chest : 0}
            shoulder={details && details.shoulder ? details.shoulder : 0}
            arm={details && details.arm ? details.arm : 0}
            forearm={details && details.forearm ? details.forearm : 0}
            belly_waist={details && details.belly_waist ? details.belly_waist : 0}
            narrow_waist={details && details.narrow_waist ? details.narrow_waist : 0}
            hip={details && details.hip ? details.hip : 0}
            thigh={details && details.thigh ? details.thigh : 0}
            calf={details && details.calf ? details.calf : 0}
            detailsEdit={this.state.detailsEdit}
            metric={metric}
            handleChange={this.handleDetailsChange}
          />
          <BodyFatCard
            editable={this.state.editable}
            bf_method={details && details.bf_method ? details.bf_method : 'None'}
            bf_pct={details && details.bf_pct ? details.bf_pct : 0}
            detailsEdit={this.state.detailsEdit}
            handleChange={this.handleDetailsChange}
            handleBodyFatCalculate={this.handleBodyFatCalculate}
          />
          {details && (
            <PicturesCard
              photo_front_url={details.photo_front_url ? details.photo_front_url : null}
              photo_side_url={details.photo_side_url ? details.photo_side_url : null}
              photo_back_url={details.photo_back_url ? details.photo_back_url : null}
              editable={this.state.editable}
              handleChange={this.handleDetailsChange}
            />
          )}
          <GoalCard
            editable={this.state.editable}
            current_goal={details && details.current_goal ? details.current_goal : 'Not defined'}
            detailsEdit={this.state.detailsEdit}
            handleChange={this.handleDetailsChange}
          />
          <LevelCard
            editable={this.state.editable}
            level={details && details.level ? details.level : 'Not Defined'}
            detailsEdit={this.state.detailsEdit}
            handleChange={this.handleDetailsChange}
          />
        </div>
        {this.state.editable ? <FooterEdit handleSave={this.handleSave} handleCancel={this.handleCancel} /> : ''}
      </Layout>
    )
  }
}

export default EnrollmentData
