import { workFlow } from './WizardData'
import { navigate } from '@reach/router'
import { notification } from 'antd'

export const getStageInformation = (section, actual, module, programType) => {
  const actualSection = workFlow.find((w) => w.id === section)
  if (actualSection) {
    if (programType && programType === 'meal_plan') {
      if (section === 'lifestyle') {
        actualSection.steps = actualSection.steps
          .filter((s) => ['food', 'medical'].indexOf(s.path) !== -1)
          .map((s, index) => {
            s.order = index + 1
            return s
          })
      }

      if (section === 'finish') {
        actualSection.steps = actualSection.steps
          .filter((s) => ['summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
          .map((s, index) => {
            s.order = index + 1
            return s
          })
      }
    }

    if (programType && programType === 'macros') {
      if (section === 'finish') {
        actualSection.steps = actualSection.steps
          .filter((s) => ['goal', 'summary', 'terms-and-conditions'].indexOf(s.path) !== -1)
          .map((s, index) => {
            s.order = index + 1
            return s
          })
      }
    }

    const actualStage = actualSection.steps.find((s) => s.path === actual)
    if (actualStage) {
      return { ...actualStage, section: actualSection }
    }
  }
  return {}
}

export const backStage = (section, actual, module = 'enrollment') => {
  let error = ''
  const actualSection = workFlow.find((w) => w.id === section)
  if (actualSection) {
    const actualStage = actualSection.steps.find((s) => s.path === actual)
    if (actualStage) {
      if (actualStage.order === 1) {
        navigate(`/${module}`)
      } else {
        const nextStage = actualSection.steps.find((s) => s.order === actualStage.order - 1)
        if (nextStage) {
          navigate(`/${module}/${nextStage.path}`)
        } else {
          error = 'Back stage not found'
        }
      }
    } else {
      error = 'Actual stage not found'
    }
  } else {
    error = 'Actual section not found'
  }
  if (error) {
    notification.warning({ message: 'Could not go to the next step', description: error })
  }
}

export const nextStage = (section, actual, module = 'enrollment') => {
  let error = ''
  const actualSection = workFlow.find((w) => w.id === section)
  if (actualSection) {
    const actualStage = actualSection.steps.find((s) => s.path === actual)
    if (actualStage) {
      if (actualSection.steps.length === actualStage.order) {
        if (actualStage.path === 'terms-and-conditions') {
          navigate('congratulations/')
        } else {
          if (module === 'meal-plan') {
            sessionStorage.setItem('MEAL_PLAN_FINISHED', sessionStorage.getItem('BOOTCAMP_ID_SELECTED'))
          }
          navigate(`/${module}`)
        }
      } else {
        const nextStage = actualSection.steps.find((s) => s.order === actualStage.order + 1)
        if (nextStage) {
          navigate(`/${module}/${nextStage.path}`)
        } else {
          error = 'Next stage not found'
        }
      }
    } else {
      error = 'Actual stage not found'
    }
  } else {
    error = 'Actual section not found'
  }
  if (error) {
    notification.warning({ message: 'Could not go to the back step', description: error })
  }
}
