import React from 'react'
import { Motivation, Kpi, ContainerKpi } from './Complete.styled'
import { motivational } from './motivationalQuotes'

const Complete = ({ volume, previousVolume, prevVol }) => {
  let result = Math.round(Math.random() * motivational.length)
  return (
    <>
      <Motivation>
        <section>
          <p className="final-quote">{motivational[result > 0 && result < motivational.length ? result : 0][0]}</p>
          <p className="author-quote">{motivational[result > 0 && result < motivational.length ? result : 0][1]}</p>
        </section>
      </Motivation>
      <ContainerKpi previousVolume={previousVolume ? 'true' : 'false'}>
        {previousVolume && (
          <Kpi>
            <p>{parseFloat(prevVol).toFixed(2)}</p>
            <p>Previous Volume</p>
          </Kpi>
        )}
        <Kpi>
          <p>{parseFloat(volume).toFixed(2)}</p>
          <p>Today's Volume</p>
        </Kpi>
      </ContainerKpi>
    </>
  )
}

export default Complete
