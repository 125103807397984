import React, { useState, useEffect, useRef, useContext } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import { MaterializeInput } from 'components/Materialize'
import SignatureCanvas from 'react-signature-canvas'
import { apiServices } from 'services/api'
import { setContactInfusionSoftTag } from 'queries'
import { ReactComponent as ResetIcon } from 'assets/icons/counterclockwise-circular-arrow.svg'

import styled from '@emotion/styled'
import { LOCAL_STORAGE } from 'constants.js'
import GlobalContext from 'context/GlobalContext'

const SignPadContainer = styled.div`
  position: relative;
  .sign-reset-button {
    cursor: pointer;
    margin: 0;
    background: transparent;
    border: none;
    position: absolute;
    top: 37px;
    right: 2px;
    &:focus,
    :active {
      outline: none;
    }

    svg {
      display: flex;
      justify-content: center;
      width: 30;
      height: 30;
    }
  }
`

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [fullLegalName, setFullLegalName] = useState()
  const [signature, setSignature] = useState()
  const [programId, setProgramId] = useState()
  const questionRef = useRef(null)
  const signatureRef = useRef({})
  const globalContext = useContext(GlobalContext)
  const currentProgram = globalContext.programs.find((p) => p.program_id === sessionStorage.getItem('BOOTCAMP_ID_SELECTED'))

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const enrollment = response.enrollment.data
        if (!enrollment.id) {
          setError('It looks you have not started the enrollment')
        } else {
          const enrollment = response.enrollment.data
          if (!enrollment.id) {
            setError('It looks you have not started the enrollment')
          } else {
            const program = response.enrollment.program
            setProgramId(program.program_id)
            setFullLegalName(program.legal_name)
            setSignature(program.signature)
          }
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (signature && signatureRef.current.isEmpty && signatureRef.current.isEmpty()) {
      signatureRef.current.fromDataURL(signature)
    }
    validate()
  }, [fullLegalName, signature, loading])

  const saveCallback = async ({ goBack }) => {
    const data = { legal_name: fullLegalName, signature }
    let response = false
    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
        if (!response.hasError) {
          const traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
          const contact_id = localStorage.getItem(LOCAL_STORAGE.CONTACT_ID)
          if (currentProgram.type === 'bootcamp') {
            await apiServices('POST', `students/${traineeId}/AssignCoachAndLevel`, { program_id: programId })
              .then(async () => {
                await apiServices('POST', `infusion-soft/update-coach-and-level`, { traineeId, programId })
              })
              .catch((error) => console.log(error))
            await setContactInfusionSoftTag(contact_id, programId, ['SignedForm']).catch((error) => console.log(error))
          }
          if (currentProgram.type !== 'meal_plan') {
            await apiServices('POST', `macro-calculator`, { program_id: programId, trainee_id: traineeId }).catch((error) => console.log(error))
          }
        }
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    if (signatureRef.current.isEmpty && fullLegalName && !signatureRef.current.isEmpty()) {
      setCanGoNext(true)
    } else {
      setCanGoNext(false)
      if (shouldFocusError) {
        if (!loading) {
          scrollToRef(questionRef)
        }
      }
    }
  }

  const clearSignaturePad = () => {
    signatureRef.current.clear()
    setSignature()
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'finish' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={questionRef}>
          {submit && (!fullLegalName || !signature) && <Message type="error" message="Please complete all of the mandatory fields" />}
          <div className="question">
            <p className="title">
              Please agree with our{' '}
              <a
                href="https://www.ketogains.com/wp-content/uploads/2020/08/Coaching-Consulting-Terms-Conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>{' '}
              by adding your full legal name and signature.
            </p>
          </div>
          <div className="content">
            <MaterializeInput
              name="full_name"
              label="Full Legal Name"
              value={fullLegalName}
              isValid={!!fullLegalName}
              onChange={(e) => setFullLegalName(e.target.value)}
            />
            <SignPadContainer>
              <SignatureCanvas
                penColor="green"
                clearOnResize={false}
                ref={signatureRef}
                onEnd={() => setSignature(signatureRef.current.toDataURL('image/png'))}
                canvasProps={{
                  style: { width: '100%', minHeight: '150px', borderRadius: '3px', border: '1.5px solid #FF6B00', marginTop: '30px' },
                  className: 'sigCanvas',
                }}
              />
              <button className="sign-reset-button" onClick={clearSignaturePad}>
                <ResetIcon />
              </button>
            </SignPadContainer>
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
