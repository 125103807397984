import React from 'react'
import { Icon } from 'antd'
import { ButtonPrimary } from './BtnPrimary.styled'

//FIXME: This component should be deprecated and use the generic button

const BtnPrimary = ({ text, icon, style, onClick, loading, className }) => {
  return (
    <ButtonPrimary loading={loading} style={style} onClick={onClick} className={className}>
      {text ? text : "Begin Today's Training"}
      {icon && <Icon type={icon} theme="filled" />}
    </ButtonPrimary>
  )
}

export default BtnPrimary
