import styled from '@emotion/styled'
import { Carousel } from 'antd'
import HeaderBanner from 'images/banner-exercises.png'
import ReportsBanner from 'assets/icons/bannerReports.svg'

export const CarouselWorkout = styled(Carousel)`
  text-align: center;
  height: 202px;
  width: 100%;
  background-image: url(${({ reports }) => (reports ? ReportsBanner : HeaderBanner)}), linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
  max-width: 400px;
  ${({ reports }) => (reports ? 'background-size: cover;' : '')}

  ${({ customTitle }) => {
    if (customTitle) {
      return `&:before{
        content: '${customTitle}';
        display:block;
        font-weight: bold;
        font-size: 21px;
        color: #FFFFFF;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        padding: 14px 0 8px 0;
      }`
    }
  }}

  .slick-dots {
    height: 9px;
    bottom: 15px;

    li {
      width: 5px;
      height: 5px;
      margin: 2px 2px;
      margin-left: 9px;

      button {
        width: 5px !important;
        height: 5px !important;
        border-radius: 50% !important;
        opacity: 0.5 !important;
      }

      &.slick-active {
        margin: 0px 2px;
        margin-left: 9px;

        button {
          width: 9px !important;
          height: 9px !important;
          color: #fff !important;
          opacity: 1 !important;
        }
      }
    }
  }

  .slick-arrow {
    top: 182px;
    z-index: 1;

    &.slick-prev {
      left: 95px;
    }

    &.slick-next {
      right: 95px;
    }
  }
`

export const WeekHighlight = styled.div`
  width: 100%;
  height: 30px;
  padding: 5px;
  text-align: center;
  color: #ff6b00;
  font-weight: 500;
  font-size: 14px;
  border-top: 1px solid #cdcdcd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
