import styled from '@emotion/styled'

export const Root = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 56px;

  @media screen and (max-width: 720px) {
    .mat-label {
      font-size: 16px !important;
    }
  }
`

export const Main = styled.main`
  background-color: #f8f9fa;
  max-width: 460px;
  min-height: calc(100vh - 168px);
  margin: 0 auto;
  margin-bottom: 88px;

  section {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin: 10px auto;
    width: calc(100% - 16px);
    padding: 0px 5px 20px;

    header {
      padding: 16px 10px;
      border-bottom: 1px solid #cfcfcf;
      width: calc(100% - 20px);
      margin: 0 auto 12px;

      h2 {
        font-weight: bold;
        font-size: 21px;
        color: #4a4a4a;
        margin: 0;
      }
    }
  }
`

export const QuestionContainer = styled.article`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  border-radius: 4px;

  .question {
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
    border-radius: 4px;
    padding: 14px 18px;
    border-left: 6px solid #ff6b00;

    p {
      font-size: 14px;
      line-height: 17px;
      color: #979797;
      font-weight: 500;
      margin: 0;

      span {
        color: #979797;
      }

      &.title {
        color: #4a4a4a;
        margin-bottom: 4px;
      }
    }

    &.with-icon {
      display: grid;
      grid-template-columns: auto 30px;
      grid-gap: 3px;

      p {
        align-self: center;
      }

      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }

  .content {
    padding: 15px 12px;

    .sub-component {
      margin-right: -12px;
      margin-left: -12px;
      padding: 5px 12px 18px;
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
      border-radius: 4px;

      p {
        color: #4a4a4a;
        font-weight: 500;
      }
    }
  }
`

export const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  padding: 20px 30px;
  width: 100%;
  z-index: 999;
  div {
    max-width: 400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
  }
  .opacity {
    opacity: 0.5;
  }
`
