import gql from 'graphql-tag'

export const setWeeklyMeasurementData = gql`
  mutation($data: measurement_insert_input!) {
    insert_measurement(objects: [$data]) {
      returning {
        id
      }
    }
  }
`
export const updateWeeklyMeasurementData = gql`
  mutation($id: uuid!, $data: measurement_set_input!) {
    update_measurement(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
      }
    }
  }
`

export const getWeeklyMeasurementData = gql`
  query getWeeklyMeasurementData($traineeId: uuid!, $forDate: String!) {
    measurement(where: { trainee_id: { _eq: $traineeId }, _and: [{ for_date: { _eq: $forDate } }] }) {
      id
      neck
      chest
      shoulder
      arm
      forearm
      belly_waist
      narrow_waist
      hip
      thigh
      calf
      for_date
      macro_adherence
      training_adherence
      sleep_issues
      hunger_issues
      fatigue
      lethargy
      stress
      period
      desc_well
      desc_bad
      desc_improve
      desc_other
      trainee {
        user {
          gender
        }
      }
    }
    prevMeasurement: measurement(
      limit: 1
      where: { trainee_id: { _eq: $traineeId }, _and: [{ for_date: { _lte: $forDate } }] }
      order_by: { for_date: desc }
    ) {
      id
      neck
      chest
      shoulder
      arm
      forearm
      belly_waist
      narrow_waist
      hip
      thigh
      calf
    }
  }
`

export const getWeeklys = gql`
  query getWeeklys($traineeId: uuid!, $startDate: String!, $endDate: String!) {
    measurement(
      where: { _and: [{ trainee_id: { _eq: $traineeId } }, { for_date: { _gte: $startDate } }, { for_date: { _lte: $endDate } }] }
      order_by: { for_date: asc }
    ) {
      id
      neck
      chest
      shoulder
      arm
      forearm
      belly_waist
      narrow_waist
      hip
      thigh
      calf
      for_date
      macro_adherence
      training_adherence
      sleep_issues
      hunger_issues
      fatigue
      lethargy
      stress
      period
      desc_well
      desc_bad
      desc_improve
      desc_other
      trainee {
        user {
          gender
          contact_id
        }
      }
    }
    pictures(
      where: { _and: [{ trainee_id: { _eq: $traineeId } }, { for_date: { _gte: $startDate } }, { for_date: { _lte: $endDate } }] }
      order_by: { for_date: asc }
    ) {
      id
      front_url
      side_url
      back_url
      for_date
    }
  }
`

export const upsertWeekly = gql`
  mutation upsertWeekly($dataMeasurements: measurement_insert_input!, $dataPictures: pictures_insert_input!) {
    insert_measurement(
      objects: [$dataMeasurements]
      on_conflict: {
        constraint: measurement_trainee_for_date
        update_columns: [
          neck
          chest
          shoulder
          arm
          forearm
          belly_waist
          narrow_waist
          hip
          thigh
          calf
          for_date
          macro_adherence
          training_adherence
          sleep_issues
          hunger_issues
          fatigue
          lethargy
          stress
          period
          desc_well
          desc_bad
          desc_improve
          desc_other
        ]
      }
    ) {
      returning {
        id
        for_date
      }
    }
    insert_pictures(
      objects: [$dataPictures]
      on_conflict: { constraint: pictures_trainee_for_date, update_columns: [front_url, side_url, back_url] }
    ) {
      returning {
        id
        for_date
      }
    }
  }
`
