import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { deleteRoutineSession } from 'services/ExerciseUtilities'
import { getLastDB, saveCache, updateCache, getCacheWhere, deleteCacheById, deleteCacheWhereNot, getCacheSort, getCache } from 'services/db/indexedDB'
import synchronizeRoutineSession from '../uses/synchronizeRoutineSession'
import LayoutNavigation from 'components/LayoutNavigation/LayoutNavigation'
import CarouselSets from './CarouselSets/CarouselSets'
import ContainerSets from './ContainerSets/ContainerSets'
import Modal from '../Modal'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

const index = () => {
  const [dataState, setDataState] = useState({
    routine: [],
    exercises: [],
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [currentExercise, setCurrentExercise] = useState(null)
  const [routineSession, setRoutineSession] = useState(null)
  const [workout, setCurrentWorkout] = useState(null)
  const [numWorkouts, setNumWorkouts] = useState(0)
  const [techniques, setTechniques] = useState([])
  const [loading, setLoading] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  useEffect(() => {
    const getRoutine = async () => {
      let techniques = []
      let exercises = []
      let lastExercise = null
      let currentWorkout = null
      let orderLastExercise = null
      let lastExerciseIsFinished = false
      let exercisesSessionRoutine = null

      const routineSession = await getLastDB(null, 'routine_session', 'id')
      if (!routineSession.session_id) {
        navigate('/my-exercises')
        return
      } else {
        if (!sessionStorage.getItem('SESSION_ROUTINE_SELECTED')) {
          sessionStorage.setItem('SESSION_ROUTINE_SELECTED', routineSession.session_id)
        }
        techniques = await getCacheWhere('configuration', { id: 'techniques' })
        currentWorkout = await getLastDB({ is_completed: false }, 'current_workout', 'id')
        exercisesSessionRoutine = await getCacheWhere('routine_session_exercise', { local_id: routineSession.local_id })

        if (!!exercisesSessionRoutine && exercisesSessionRoutine.length > 0) {
          lastExercise = exercisesSessionRoutine[exercisesSessionRoutine.length - 1]
        }
        const {
          routine: { sets },
          routine_exercise_user_notes,
        } = currentWorkout
        const setExercises = sets.map((set) => set.exercises[0])
        const allNotes = routine_exercise_user_notes || []

        exercises = await Promise.all(
          setExercises.map(async (exercise) => {
            let exerciseAux = exercise
            if (!!exerciseAux.alt_selected) {
              exerciseAux = exercise.alternatives.find((alt) => alt.exercise_id === exercise.alt_selected)
            }
            exerciseAux = {
              ...exerciseAux,
              isCurrent: false,
              isCompleted: false,
              sets_data: [],
              user_notes: '',
            }
            const { exercise_id, week_sets, order, parent } = exerciseAux
            if (!!exercisesSessionRoutine) {
              const foundSessionRoutine = await exercisesSessionRoutine.find((ers) => ers.exercise_id === exercise_id && ers.parent === parent)

              if (!!foundSessionRoutine) {
                const setsExercise = await getSetsOfExercises(foundSessionRoutine)
                if (!!setsExercise) {
                  exerciseAux = {
                    ...exerciseAux,
                    sets_data: setsExercise,
                    isCompleted: setsExercise.length >= week_sets.length,
                    user_notes: foundSessionRoutine.user_notes,
                  }
                }
                if (!!lastExercise && exerciseAux.exercise_id === lastExercise.exercise_id) {
                  const { sets_data, week_sets, order } = exerciseAux

                  const isSetFinished = sets_data.length >= week_sets.length
                  exerciseAux = {
                    ...exerciseAux,
                    isCurrent: !isSetFinished,
                    isCompleted: isSetFinished,
                  }
                  lastExerciseIsFinished = isSetFinished
                  orderLastExercise = order
                }
              }
              if (!!lastExerciseIsFinished) {
                if (order === orderLastExercise + 1) {
                  exerciseAux.isCurrent = true
                  lastExerciseIsFinished = false
                }
              }
            }
            const lastExerciseNote = allNotes.find(
              (e) => e.routine_id === routineSession.session_id && e.exercise_id === exercise_id && e.parent === parent,
            )
            if (!!lastExerciseNote && !exerciseAux.user_notes) {
              exerciseAux.user_notes = lastExerciseNote.note
            }
            return exerciseAux
          }),
        )
      }
      let firstExercise = exercises.find((e) => !!e.isCurrent)
      if (!firstExercise) {
        if (exercises.filter((e) => e.isCompleted).length > 0) {
          firstExercise = exercises.find((e) => !e.isCompleted)
        } else {
          firstExercise = exercises[0]
        }
        firstExercise.isCurrent = true
      }

      setRoutineSession(routineSession)
      setCurrentExercise(firstExercise)
      setCurrentWorkout(currentWorkout)
      setDataState({
        routine: currentWorkout.routine,
        exercises: exercises,
      })
      setTechniques(techniques[0].value)
      setLoading(false)
    }
    getRoutine()
  }, [])

  const getSetsOfExercises = async (exercise) => {
    return await getCacheWhere('routine_session_set', {
      routine_session_exercise_id: exercise.local_id,
      exercise_id: exercise.exercise_id,
      parent: exercise.parent === null ? '' : exercise.parent,
    }).catch((e) => console.error(e))
  }

  const handleSkipExercise = async (index) => {
    let nextExercise = dataState.exercises.find((e, key) => key === index)
    if (!!nextExercise) {
      currentExercise.isCurrent = false
      nextExercise.isCurrent = true
      setCurrentExercise(nextExercise)
    }
  }

  const handleWeekSets = async (weekSets) => {
    const {
      routine: { sets },
    } = workout
    let newWeekSets = sets.map((set) => {
      set.exercises = set.exercises.map((exercise) => {
        if (!!currentExercise.is_alternative) {
          if (exercise.alt_selected === currentExercise.exercise_id) {
            exercise.alternatives = exercise.alternatives.map((altExercise) => {
              if (altExercise.exercise_id === currentExercise.exercise_id) {
                altExercise.week_sets = weekSets
              }
              return altExercise
            })
          }
        } else {
          if (exercise.exercise_id === currentExercise.exercise_id) {
            exercise.week_sets = weekSets
          }
        }
        return exercise
      })
      return set
    })
    let overwriteWorkout = { ...workout, routine: { ...workout.routine, sets: newWeekSets } }
    updateCache('current_workout', overwriteWorkout.id, overwriteWorkout).catch((e) => console.error(e))
  }

  const handleOverwriteWeekSet = async (setOverwritten) => {
    const {
      routine: { sets },
    } = workout
    let overwrittenSets = sets.map((set) => {
      set.exercises = set.exercises.map((exercise) => {
        if (!!currentExercise.is_alternative) {
          if (exercise.alt_selected === currentExercise.exercise_id) {
            exercise.alternatives = exercise.alternatives.map((altExercise) => {
              if (altExercise.exercise_id === currentExercise.exercise_id) {
                altExercise.week_sets = altExercise.week_sets.map((set) => {
                  if (set.id === setOverwritten.id) {
                    set = setOverwritten
                  }
                  return set
                })
              }
              return altExercise
            })
          }
        } else {
          if (exercise.exercise_id === currentExercise.exercise_id) {
            exercise.week_sets = exercise.week_sets.map((set) => {
              if (set.id === setOverwritten.id) {
                set = setOverwritten
              }
              return set
            })
          }
        }
        return exercise
      })
      return set
    })

    let overwriteWorkout = { ...workout, routine: { ...workout.routine, sets: overwrittenSets } }
    await updateCache('current_workout', overwriteWorkout.id, overwriteWorkout).catch((e) => console.error(e))
  }

  const handleFinishSet = async (exercise, set, notes) => {
    await getDataOfExercises(exercise, set, notes)
  }

  const handleUpdateSet = async (set) => {
    const parent = currentExercise.parent === null ? '' : currentExercise.parent
    let exercisesSessionSet = await getCacheWhere('routine_session_set', {
      routine_session_exercise_id: routineSession.local_id,
      parent: parent,
    }).catch((e) => console.error(e))
    let foundSet = exercisesSessionSet.find(
      (es) => es.routine_session_exercise_id === set.routine_session_exercise_id && es.exercise_id === set.exercise_id && es.order === set.order,
    )
    if (!!foundSet) {
      await updateCache('routine_session_set', foundSet.id, {
        reps: set.reps,
        weight: set.weight,
      }).catch((e) => console.error(e))
      setShowSuccess(true)

      setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    }
  }

  const handleFinishExercise = async (exercise, set, notes) => {
    await getDataOfExercises(exercise, set, notes)
    currentExercise.isCurrent = false
    currentExercise.isCompleted = true
    let foundExercise = null
    let position = dataState.exercises.findIndex((e) => e.id === currentExercise.id)
    dataState.exercises[position].user_notes = notes
    if (position !== -1) {
      foundExercise = dataState.exercises[position + 1]
    }

    if (foundExercise) {
      foundExercise.isCurrent = true
      const strData = JSON.stringify(dataState)
      const parseData = JSON.parse(strData)
      setDataState((prev) => ({
        ...prev,
        exercises: parseData.exercises,
      }))
    } else if (position === dataState.exercises.length - 1) {
      Sentry.addBreadcrumb({ category: 'exercise', message: `Mark as finished the routine session ${routineSession.id}` })
      await updateCache('routine_session', routineSession.id, { is_completed: 1, finish_date: moment().format('YYYY-MM-DD HH:mm:ss') }).catch((e) =>
        console.error(e),
      )
      await handleVolumeSession()

      synchronizeRoutineSession()
        .then(async (data) => {
          await deleteCacheById('current_workout', 'id', workout.id).catch((e) => console.error(e))
          navigate('/exercises/workout/complete')
        })
        .catch(() => navigate('/exercises/workout/complete'))
    }
  }

  const getDataOfExercises = async (exercise, config, notes) => {
    const parent = exercise.parent != null ? exercise.parent : ''
    const foundSessionExercise = await getCacheWhere('routine_session_exercise', {
      local_id: routineSession.local_id,
      exercise_id: exercise.exercise_id,
      parent: parent,
    }).catch((e) => console.error(e))

    if (!foundSessionExercise) {
      const session_exercise = {
        routine_session_id: routineSession.session_id,
        local_id: routineSession.local_id,
        exercise_id: exercise.exercise_id,
        user_notes: notes,
        parent: parent,
      }
      await saveCache('routine_session_exercise', session_exercise).catch((e) => console.error(e))
    } else if (foundSessionExercise && foundSessionExercise.length > 0) {
      await updateCache('routine_session_exercise', foundSessionExercise[0].id, { user_notes: notes }).catch((e) => console.error(e))
    }
    const { exercise_id, routine_session_exercise_id, reps, min_repetitions, weight, min_weight, is_warmup, is_custom, order, is_done } = config

    const session_set = {
      reps,
      order,
      weight,
      is_done,
      exercise_id,
      is_warmup,
      is_custom,
      weight_goal: min_weight,
      reps_goal: min_repetitions,
      routine_session_exercise_id,
      parent,
    }

    await saveCache('routine_session_set', session_set).catch((e) => console.error(e))
    return
  }

  const handleVolumeSession = async () => {
    const routine = dataState.routine
    let data = []
    let session = await getLastDB(null, 'routine_session', 'id').catch((e) => console.error(e))
    data = await getCacheWhere('routine_session_set', { routine_session_exercise_id: session.local_id }).catch((e) => console.error(e))
    let resultLast = await getCacheSort('volume_session', { routine_id: session.session_id }, 'date', 1).catch((e) => console.error(e))
    let prevVolume = null
    if (resultLast) {
      prevVolume = resultLast.volume
    }

    let res = 0
    await data.forEach((elem) => {
      if (!elem.is_warmup) {
        let resultMultiplication = 0
        resultMultiplication = elem.reps * elem.weight
        res += resultMultiplication
      }
    })
    await saveCache('volume_session', {
      workout_plan_id: routine.workout_plan_id,
      routine_id: routine.id,
      session_id: session.id,
      week_id: session.routine_week_id,
      identifier: session.for_date,
      date: moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      prev_volume: prevVolume,
      volume: res,
    }).catch((e) => console.error(e))
    return
  }

  const getModalSignal = async () => {
    const foundWorkouts = await getCache('current_workout').catch((e) => console.error(e))
    if (foundWorkouts) {
      setNumWorkouts(foundWorkouts.length)
    }
    setModalVisible(true)
  }

  const handleSaveRoutine = async () => {
    setModalVisible(false)
    const routineSessions = await getCacheWhere('routine_session', { is_completed: 0 }).catch((e) => console.error(e))
    await deleteCacheWhereNot('current_workout', 'id', workout.id).catch((e) => console.error(e))
    if (!!routineSessions) {
      for await (const routine of routineSessions) {
        if (routine.session_id !== routineSession.session_id) {
          await deleteRoutineSession(routine.local_id)
        }
      }
    }
    navigate('/my-exercises')
  }

  const handleOutOfRoutine = async () => {
    await deleteRoutineSession(routineSession.local_id)
    await deleteCacheById('current_workout', 'id', workout.id).catch((e) => console.error(e))
    setModalVisible(false)
    navigate(`/my-exercises`)
  }

  let exercise = currentExercise || dataState.exercises[0]
  return (
    !loading && (
      <LayoutNavigation title="Your Sets" hideFooter={true} isBack={false} completeRoutine={true} isModal={getModalSignal}>
        <CarouselSets exercises={dataState.exercises} skipExercise={handleSkipExercise} showMessage={showSuccess} />
        <ContainerSets
          localId={routineSession.local_id}
          exercise={exercise}
          techniques={techniques}
          onFinishSet={handleFinishSet}
          onUpdateSet={handleUpdateSet}
          onFinishExercise={handleFinishExercise}
          overwriteWeekSet={handleOverwriteWeekSet}
          handleWeekSets={handleWeekSets}
          showSuccessButton={showSuccess}
        />
        <Modal
          modalVisible={modalVisible}
          message={'You are leaving before completing your routine'}
          question={'Do you want to save your progress to finish later?'}
          warning={numWorkouts > 1 ? 'Your previous unfinished routine will be deleted' : null}
          setModalVisible={setModalVisible}
          textFirstButton={'Yes, save for later'}
          textSecondButton={"No, don't save my progress"}
          handleClickFirstButton={handleSaveRoutine}
          handleClickSecondButton={handleOutOfRoutine}
        />
      </LayoutNavigation>
    )
  )
}

export default index
