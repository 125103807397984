import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { navigate } from '@reach/router'
import Loading from 'components/Loading/Loading'
import Layout from 'components/Layout'
import { Root } from 'components/Homepage/Homepage.styled'
import Message from 'components/Message'
import Banner from './Banner/Banner'
import BtnPrimary from 'components/CustomButtons/BtnPrimary/BtnPrimary'
import PreferredUnits from './PreferredUnits/PreferredUnits'
import KpisCards from './Cards/KpisCards'
import SectionCard from './Cards/SectionCard'
import WeeklyEstimated from './WeeklyEstimated/WeeklyEstimated'
import image from 'images/table-control.svg'
import imageSettings from 'images/settings.svg'
import { StyledDropdown } from 'styled'
import BoxBootcamps from './BoxBootcamps'
import NetworkDetector from 'services/NetworkDetector'
import synchronizeRoutineSessions from '../uses/synchronizeRoutineSession'
import { deleteRoutineSession } from 'services/ExerciseUtilities'
import {
  getCache,
  getFirstCache,
  getCacheWhere,
  updateCache,
  updateTableCache,
  cacheTransaction,
  saveCache,
  clearCache,
  createCache,
  deleteCacheWhereNot,
} from 'services/db/indexedDB'
import { apiFetch } from 'services/API/apiFetch'
import { apiServices } from 'services/api'
import LostNetwork from './Screens/LostNetwork'
import InformationModal from 'components/Modals/Information'
import InitialInfoModal from './initialInfoModal/InitialInfoModal'
import moment from 'moment'
import NoWorkoutMessage from './NoWorkoutMessage'
import { LOCAL_STORAGE } from 'constants.js'

const index = () => {
  const [state, setState] = useState({
    data: [],
    stats: {},
    error: null,
  })
  const [loading, setLoading] = useState(true)
  const [unit, setUnit] = useState()
  const [stats, setStats] = useState({})

  const [workouts, setWorkouts] = useState([])
  const [workout, setWorkout] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [reportAndProgressModal, setReportAndProgressModal] = useState(false)
  const cacheWarning = false
  const [firstModalVisible, setFirstModalVisible] = useState(false)
  const today = moment().format('YYYY-MM-DD')
  const monday = moment()
    .startOf('isoWeek')
    .format('YYYYMMDD')

  useEffect(() => {
    const handleSync = async () => {
      const isConnected = await NetworkDetector()

      Sentry.addBreadcrumb({ category: 'exercise', message: 'Get cache from workout_plans' })
      let workoutsDB = await getCache('workout_plans').catch((e) => console.error(e))
      //TODO Implement the cache strategy bellow
      // let needWorkoutSync = true
      // if (workoutsDB && workoutsDB.length > 0) {
      //   needWorkoutSync = workoutsDB.every((wp) => {
      //     if (moment(today).isBefore(moment(wp.program.start_date).add(1, 'week'))) {
      //       return true
      //     } else if (wp.expired_in) {
      //       return !moment(today).isBefore(moment(wp.expired_in))
      //     }
      //     return true
      //   })
      // }

      if (isConnected) {
        workoutsDB = await apiFetch({
          url: `students/${localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)}/workouts/?week=${monday}`,
          method: 'GET',
          cacheTable: null,
          expiredIn: null,
          isConnected,
        }).catch((err) => err)

        if (!!workoutsDB && workoutsDB.length > 0) {
          for (const wp of workoutsDB) {
            const week =
              moment(today)
                .startOf('isoWeek')
                .diff(moment(wp.program.start_date), 'week') + 1

            if (week === 2) {
              wp.expired_in = moment(today)
                .add(3, 'day')
                .format('YYYYMMDD')
            } else if (week > 2) {
              wp.expired_in = moment(today)
                .add(3, 'week')
                .startOf('isoWeek')
                .format('YYYYMMDD')
            }
          }
          await saveWorkouts(workoutsDB)
        } else {
          if (workoutsDB && workoutsDB.length === 0) {
            setState({ error: 'NO_WORKOUT' })
            return
          }
        }
      }

      if (workoutsDB && workoutsDB.length === 0) {
        setState({ error: 'NO_WORKOUT' })
        return
      }

      setWorkouts(workoutsDB)

      const workout = await getDefaultWorkout(workoutsDB)
      setWorkout(workout)

      let statsDB = await getCache('stats').catch((e) => console.error(e))
      if (statsDB) {
        statsDB = statsDB[0]
      }

      if (isConnected) {
        let statsFetch = await apiFetch({
          url: `student/${localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)}/workouts/${workout.id}/stats`,
          method: 'GET',
          cacheTable: null,
          expiredIn: null,
          isConnected,
        }).catch((err) => console.error(err))

        if (!!statsFetch && !statsFetch.hasError) {
          let stats = statsFetch.stats
          stats = { ...stats, date: moment(today).format('YYYY-MM-DD') }
          if (!statsDB) {
            await saveCache('stats', stats).catch((e) => console.error(e))
          } else {
            await updateCache('stats', statsDB.id, stats).catch((e) => console.error(e))
          }
          statsDB = stats

          if (statsFetch.workout_volume.length > 0) {
            statsFetch.workout_volume = statsFetch.workout_volume.map((volume) => {
              volume.created_date = moment(volume.created_date).format('YYYY-MM-DD HH:mm:ss')
              volume['volume'] = volume.total_volume
              delete volume.total_volume
              return volume
            })
            Sentry.addBreadcrumb({ category: 'exercise', message: 'Clean cache volume_session' })
            await clearCache('volume_session').catch((e) => console.error(e))

            Sentry.addBreadcrumb({ category: 'exercise', message: 'Save volume_session' })
            await createCache('volume_session', statsFetch.workout_volume).catch((e) => console.error(e))
          }
        }
      }

      setStats(statsDB)
      await syncCatalogs(workoutsDB, isConnected)
      await syncSessions()

      const data = await getCacheStorage(['stats'])
      setUnit(workout.weight_unit)
      setState((prev) => ({ ...prev, data }))
      const validationModal = localStorage.getItem('FIRST_TIME_EXERCISES')
      if (!validationModal) {
        localStorage.setItem('FIRST_TIME_EXERCISES', true)
        setFirstModalVisible(true)
      }
      setLoading(false)
    }
    handleSync()
  }, [])

  const isExistError = async (param) => {
    if (param.hasError) {
      setState({ error: param.error })
      setLoading(false)
      return true
    }
    return false
  }

  const syncSessions = async () => {
    const foundSessions = await getCacheWhere('routine_session', { is_completed: 0 }).catch((e) => console.error(e))
    if (foundSessions) {
      const filterSessions = foundSessions.filter((session) => session.for_date < monday)
      if (filterSessions.length > 0) {
        for (const session of filterSessions) {
          await deleteRoutineSession(session.local_id)
        }
      }
    }
    await synchronizeRoutineSessions()
    await checkLastWorkout()
  }

  const syncCatalogs = async (workoutsDB, isConnected) => {
    //TODO: We should make a catalogs endpoint to improve the extraction
    //      The Idea: Now the exercises and the rest of catalogs are synchronizing by workout
    //      but it should only synchronize once time to get all the necessary catalogs
    let catalogs = []
    for (const workout of workoutsDB) {
      const workoutExercises = [].concat
        .apply(
          [],
          workout.routines.map((r) => {
            return r.routine_exercises.map((re) => re.exercise_id)
          }),
        )
        .filter((v, i, a) => a.indexOf(v) === i)

      const cacheExercises = (await getCache('exercises')) || []
      const cacheExercisesMapped = cacheExercises.map((ce) => ce.id)
      const exercisesAlreadySynchronized =
        cacheExercisesMapped.length === workoutExercises.length && workoutExercises.every((val) => cacheExercisesMapped.includes(val))

      if (!exercisesAlreadySynchronized) {
        catalogs = await apiFetch({
          url: `workout-plans/${workout.parent ? workout.parent : workout.id}/exercises/`,
          method: 'GET',
          cacheTable: null,
          expiredIn: workout.program.close_date,
          isConnected,
        }).catch((err) => err)
        if (await isExistError(catalogs)) {
          return
        }

        if (!!catalogs) {
          catalogs.configuration = catalogs.configuration.map((c) => {
            if (c.id === 'techniques') {
              c.value = JSON.parse(c.value)
            }
            return c
          })

          const transaction = await cacheTransaction(catalogs).catch((e) => console.error(e))
          if (!transaction) {
            setState({ error: 'BROWSER_CACHE_ERROR' })
            setLoading(false)
            return
          }
        } else {
          Sentry.addBreadcrumb({ category: 'exercise', message: 'Catalogs could not be downloaded' })
        }
      }
    }
  }

  const checkLastWorkout = async () => {
    let lastDate = null
    let currentWorkoutId = null
    const allWorkouts = await getCache('current_workout').catch((e) => console.error(e))
    if (allWorkouts && allWorkouts.length > 1) {
      for await (const workout of allWorkouts) {
        const foundRoutineSession = await getFirstCache('routine_session', { session_id: workout.routine.id }).catch((e) => console.error(e))
        if (foundRoutineSession) {
          if (!lastDate || foundRoutineSession.start_date > lastDate) {
            currentWorkoutId = workout.id
          }
        }
      }
      for await (const workout of allWorkouts) {
        if (workout.id !== currentWorkoutId) {
          const foundRoutine = await getFirstCache('routine_session', { session_id: workout.routine.id }).catch((e) => console.error(e))
          if (foundRoutine) {
            deleteRoutineSession(foundRoutine.local_id)
          }
        }
      }
      await deleteCacheWhereNot('current_workout', 'id', currentWorkoutId).catch((e) => console.error(e))
    }
  }

  const saveWorkouts = async (workouts) => {
    for await (const workout of workouts) {
      workout['is_selected'] = 0
      for await (const routine of workout.routines) {
        routine['workout_plan_id'] = workout.id
        for await (const week of routine.routine_weeks) {
          for await (const exercise of week.week_exercises) {
            await mapBooleans(exercise)
            for await (const set of exercise.week_sets) {
              await mapBooleans(set)
            }
          }
        }
      }
      await setCurrentWorkout(workout)

      if (workout.is_current === 1) {
        Sentry.addBreadcrumb({ category: 'exercise', message: 'Clean and Update cache report_last_weight' })
        await updateTableCache('report_last_weight', workout.report_last_weight).catch(console.error)
      }
      Sentry.addBreadcrumb({ category: 'exercise', message: 'Save workouts' })
      await saveCache('workout_plans', workout).catch((e) => console.error(e))
    }
  }

  const mapBooleans = async (object) => {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key]
        if (typeof element === 'boolean') {
          object[key] = element === true ? 1 : 0
        }
      }
    }
  }

  const setCurrentWorkout = async (workout) => {
    workout.is_current = 0

    const program = workout.program
    if (today >= program.start_date && today <= program.close_date) {
      workout.is_current = 1
    }

    return
  }

  const getDefaultWorkout = async (workouts) => {
    let foundWorkout = null
    if (!!workouts && workouts.length > 0) {
      if (workouts.length === 1) {
        foundWorkout = workouts[0]
      } else if (workouts.length > 1) {
        const isSelected = await workouts.find((workout) => workout.is_selected === 1)

        if (isSelected) {
          foundWorkout = isSelected
          return foundWorkout
        }

        foundWorkout = await workouts.find((workout) => workout.is_current === 1 || workout.is_current === 0)
      }
    }
    return foundWorkout
  }

  const getCacheStorage = async (tables) => {
    let data = {}
    for (const table of tables) {
      const resp = await getCache(table).catch((e) => console.error(e))
      if (resp) {
        data[table] = resp
      }
    }
    return data
  }

  const handleSelectWorkout = async (e) => {
    const workout = workouts.find((workout) => workout.id === Number(e.key))
    if (workout) {
      setWorkout(workout)
      setUnit(workout.weight_unit)
    }
  }

  const handleClickWorkout = async (workout, table) => {
    const program = workout.program
    if (workout.is_current === 0) {
      if (moment(today).isBefore(moment(program.start_date).add(-1, 'day'))) {
        setModalVisible(true)
        return
      }
    }
    const workouts = await getCache(table).catch((e) => console.error(e))
    if (!!workouts) {
      workouts.map((workout) => (workout.is_selected = 0))
      await updateTableCache(table, workouts).catch((e) => console.error(e))

      const updated = await updateCache(table, workout.id, { is_selected: 1 }).catch((e) => console.error(e))
      if (updated) {
        localStorage.removeItem('WORKOUT')
        navigate('/exercises/workout')
      }
    }
  }

  const handlePreferredUnits = async (unit) => {
    let data = {
      weight_unit: unit,
    }
    await apiServices('POST', `students/${localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)}/workouts/${workout.id}`, data).catch((err) =>
      console.error(err),
    )
    await updateCache('workout_plans', workout.id, { weight_unit: unit }).catch((e) => console.error(e))
    const work = await workouts.find((w) => w.id === workout.id)
    if (work) {
      workout.weight_unit = unit
    }
    setUnit(unit)
  }

  const handleReportsAndProgressClick = () => {
    const program = workout.program
    if (moment(today).isBefore(moment(program.start_date))) {
      setReportAndProgressModal(true)
      return
    }
    navigate('/my-exercises/reports')
  }

  const handleSettingsClick = () => {
    navigate('/my-exercises/under-construction')
  }

  if (state.error && state.error === 'LOST_NETWORK') return <LostNetwork />

  let workoutSelected
  let programOptions
  if (!loading && !!!state.error) {
    workoutSelected = workout
    programOptions = workouts.map((p) => {
      return { id: p.id, label: p.program.description }
    })
  }

  return (
    <Layout>
      <Root>
        <div style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '400px' }}>
          {cacheWarning && (
            <Message
              type="warning"
              title="Action Required!"
              message={
                'Your data is not syncing correctly. <b>Please log out the Ketogains portal and log back in.</b> If you have already logged out, ignore this message.'
              }
            />
          )}
          {stats && stats.date < moment(today).format('YYYY-MM-DD') && (
            <Message
              type="info"
              title={null}
              message={'No Internet connection found! The data displayed might not be update. Once you connect to a network again it will sync.'}
            />
          )}
          <Banner
            name={localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME)}
            picture={localStorage.getItem(LOCAL_STORAGE.USER_PICTURE)}
            marginBottom={!!state.error ? '10px' : null}
          />
          {!state.error && <KpisCards {...stats} />}

          {loading && !!!state.error && (
            <Loading>
              <div className="rectangle" />
              <div className="button" />
              <div className="rectangle" />
              <div className="rectangle" />
              <div className="rectangle" />
              <div className="rectangle" />
            </Loading>
          )}
          {!loading && !!!state.error && (
            <>
              <BoxBootcamps>
                <StyledDropdown
                  label={workoutSelected.program.description}
                  description={workoutSelected.is_current ? 'Current Bootcamp' : 'Upcoming Bootcamp'}
                  onSelect={handleSelectWorkout}
                  options={programOptions}
                  className="vertical"
                />
              </BoxBootcamps>
              <BtnPrimary text="Begin Today's Training" icon="play-circle" onClick={() => handleClickWorkout(workoutSelected, 'workout_plans')} />
              <PreferredUnits
                title={'Preferred Units of Measurement'}
                text="Change the default of the settings of your routines."
                weightUnit={unit}
                handlePreferredUnits={handlePreferredUnits}
              />
              <WeeklyEstimated title="Weekly estimated vs achieved" />

              <SectionCard
                onClick={handleReportsAndProgressClick}
                image={image}
                title="Reports & Progress"
                text="Consult the reports of the routines made during this Bootcamp and check your progress."
                color="#ffab2b"
                grad="#FFD05C"
                colorText="#4A4A4A"
              />
              <SectionCard onClick={handleSettingsClick} image={imageSettings} title="Settings" text="Coming soon!" color="#00B2BD" grad="#6CCED4" />

              <InitialInfoModal firstModalVisible={firstModalVisible} setFirstModalVisible={setFirstModalVisible} />

              <InformationModal
                visible={modalVisible}
                title={<p style={{ fontSize: '12px', paddingTop: '20px' }}>The new Bootcamp is quickly approaching!</p>}
                child={
                  <p style={{ color: '#4A4A4A', fontWeight: 'bold', fontSize: '13px' }}>
                    Your new training program will be available for download on{' '}
                    <span style={{ color: '#ff4713' }}>
                      {moment(workoutSelected.program.start_date)
                        .add(-1, 'day')
                        .format('MMMM Do')}
                    </span>
                    .
                  </p>
                }
                onOk={() => setModalVisible(false)}
              />

              <InformationModal
                visible={reportAndProgressModal}
                title={<p style={{ fontSize: '12px', paddingTop: '20px' }}>The new Bootcamp is quickly approaching!</p>}
                child={
                  <p style={{ color: '#4A4A4A', fontWeight: 'bold', fontSize: '13px' }}>
                    Your new training program will be available for download on{' '}
                    <span style={{ color: '#ff4713' }}>{moment(workoutSelected.program.start_date).format('MMMM Do')}</span>.
                  </p>
                }
                onOk={() => setReportAndProgressModal(false)}
              />
            </>
          )}
          {!!state.error && <NoWorkoutMessage reason={state.error} />}
        </div>
      </Root>
    </Layout>
  )
}

export default index
