import styled from '@emotion/styled'

export const DropdownContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
  border-radius: 5px;
  margin-bottom: 6px;

  .title {
    background: linear-gradient(90deg, #ff6b00 7.81%, #ffa400 96.3%);
    box-shadow: 0px 2px 8px rgba(176, 176, 176, 0.19);
    border-radius: 5px 5px 0px 0px;
    color: #ffffff;
    padding: 8px 14px;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .ant-btn {
    margin: 0 10px 5px;
    width: calc(100% - 20px);
  }
`
