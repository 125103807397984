import styled from '@emotion/styled'
import clip from 'assets/icons/clip.svg'
import { Button } from 'antd'

export const StyledButton = styled(Button)`
  height: 32px !important;
  width: 32px !important;
  padding: 1px 5px;
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 50% !important;
  border-color: #ff6b00 !important;
  background: #ff6b00 !important;
  margin-left: 5px !important;
  margin-right: 0px !important;

  &:hover,
  &:active,
  &:focus {
    background: #ff6b00;
    border-color: #ff6b00;
    color: #ffffff;
  }

  img {
    height: 17px;
    width: 17px;
  }

  .css-6ox459 button {
    position: absolute;
  }
`

export const GifButton = styled(Button)`
  width: 33px;
  height: 20px;
  padding: 2px;
  border: 1px solid #ff6b00;
  font-weight: 800;
  color: #ff6b00;
  font-size: 8px !important;
  background-color: #ffffff !important;
  letter-spacing: 1px;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #ff6b00;
    color: #ff6b00;
  }
`

export const ContainerTextEditor = styled.div`
  small {
    color: red;
    padding: 5px;
  }

  .wrap-editor {
    border: 1px solid #cfcccc;
    border-radius: 4px;
    .quill {
      .ql-container {
        border: none !important;
        .ql-editor {
          min-height: 120px;
          max-height: 300px;
          padding-right: 15px;
        }
        .ql-tooltip {
          left: auto !important;
        }
      }
    }
    .attachments {
      background: #e4e4e4;
      border-radius: 7px;
      padding: 6px 16px 10px;

      .attachment-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 4px;

        .preview {
          max-width: 36px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #979797;
          border-radius: 4px;
          flex-grow: 1;

          &.icon {
            padding: 8px;
            text-align: center;
            color: #979797;
          }

          &.image {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .detail {
          color: #5d5d5d;
          font-size: 10px;
          margin-left: 10px;
          flex-grow: 2;

          .name {
            font-weight: 600;
            margin-bottom: 3px;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .size {
            font-weight: normal;
            margin: 0;
          }
        }

        .actions {
          .anticon {
            font-size: 20px;
            cursor: pointer;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 1px;
    z-index: 0;
    border: none;

    button,
    .ql-picker {
      border: 1px solid #ff6b00;
      border-radius: 7px;
      margin-right: 3px;
      width: 25px;
      height: 25px;
      padding: 3px;

      svg {
        width: 16px;

        .ql-stroke {
          stroke: #ff6b00;
          border-color: #ff6b00;
          background: #ff6b00;
        }

        .ql-fill {
          fill: #ff6b00;
        }
      }

      &::hover,
      &::active,
      &::visited {
        color: #ff6b00;

        svg {
          .ql-stroke {
            stroke: #ff6b00;
            border-color: #ff6b00;
            background: #ff6b00;
          }

          .ql-fill {
            fill: #ff6b00;
          }
        }
      }
    }

    .ql-picker {
      .ql-picker-label {
        border: none !important;
        padding: 0 !important;
        outline: 0 !important;
      }

      .ql-picker-options {
        margin-top: -106px;
        margin-left: -4px;
      }

      &.ql-color {
        .ql-picker-options {
          margin-top: -53px;
          margin-left: -4px;
        }
      }

      &.ql-expanded {
        .ql-picker-label {
          border: none !important;
          outline: 0 !important;
        }
      }
    }

    .uploadcare--widget__button_type_remove,
    .uploadcare--widget__button_type_cancel,
    .uploadcare--widget__text {
      display: none !important;
    }

    .uploadcare--widget__button_type_open {
      cursor: pointer;
      margin: 0;
      color: #383838;
      font-family: Montserrat;
      font-size: 0;
      font-weight: 500;
      border-radius: 4px;
      background-color: #ffffff;
      margin-right: 5px;

      &:hover,
      &:active {
        border-color: #ff6b00;
        color: #ffffff;
      }

      &:before {
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        background-image: url(${clip});
        background-size: contain;
      }
    }

    @media (max-width: 375.9px) {
      .ql-video,
      .uploadcare--widget__button,
      .ql-align {
        display: none;
      }
    }

    @media (min-width: 1200px) and (max-width: 1379.9px) {
      .ql-video,
      .ql-color,
      .ql-link,
      .ql-align {
        display: none;
      }
    }

    @media (min-width: 1380px) and (max-width: 1399.9px) {
      .ql-video,
      .ql-color,
      .ql-align {
        display: none;
      }
    }

    @media (min-width: 1400px) and (max-width: 1639.9px) {
      .ql-video,
      .ql-color {
        display: none;
      }
    }
  }
`

export const GiftSelector = styled.div`
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.25);

  .search-icon {
    position: absolute;
    top: 13px;
    left: 12px;
    z-index: 100;
    color: #ff6b00;
  }

  .giphy-picker {
    max-width: 300px;
    padding: 5px;

    &:after {
      position: relative;
      padding-top: 20px;
      font-size: 9px;
      bottom: 30px;
      height: 30px;
      content: 'Powered By GIPHY';
      color: #959595;
      display: block;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 58.21%, #ffffff 100%);
    }

    div {
      flex: 100%;
      max-width: 100%;
    }

    input[type='text'] {
      padding-left: 25px;
      color: #959595;
      background-color: #f3f3f3;
      font-size: 10px;

      &:before {
        content: 'icon';
        display: block;
        position: relative;
        left: 10px;
      }
    }

    .anticon {
      width: 100%;
      color: #ff6b00;
      font-size: 70px;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
`
