import React, { Component, Fragment } from 'react'
import { Button, Popconfirm, Icon } from 'antd'
import gql from 'graphql-tag'
import Uploader from 'components/Uploader'
import apolloClient from 'apolloClient.js'
import { Image, ModalPicture } from './UserPictures.styled'
import { images } from './data'

const updatePictures = gql`
  mutation($id: uuid!, $data: pictures_set_input) {
    update_pictures(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
      }
    }
  }
`

const setSrc = (src, type) => {
  if (!src) {
    return images[type]
  }
  return src
}

class ChangeablePicture extends Component {
  state = {
    isPhotoModalVisible: false,
    isRotating: false,
  }

  openModal = () => {
    const { disableModal } = this.props
    if (!disableModal) {
      this.setState({
        isPhotoModalVisible: true,
      })
    }
  }

  toggleModalVisibility = () => {
    this.setState({
      isPhotoModalVisible: false,
    })
  }

  handleUploadedFile = (uploadedFileUrl, callback) => {
    const url = uploadedFileUrl === '' ? '' : uploadedFileUrl.cdnUrl
    apolloClient
      .mutate({
        mutation: updatePictures,
        variables: { id: this.props.id, data: { [`${this.props.type}_url`]: url } },
      })
      .then(() => {
        if (typeof callback === 'function') {
          console.log(callback)
          callback()
        } else {
          this.setState({
            isPhotoModalVisible: false,
            photoModified: url,
          })
          this.props.refetch()
        }
      })
  }

  handleDelete = (result) => {
    this.setState({
      isPhotoModalVisible: false,
    })
    if (result) {
      this.handleUploadedFile('')
    }
  }

  handleRotate = () => {
    const { src } = this.props
    const { photoModified } = this.state
    this.setState({ isRotating: true })
    let picture = photoModified || src
    const rotateRegExp = /-\/rotate\/(([0-9]+)\/)/i
    const rotateValues = [0, 270, 180, 90]
    const rotateValue = picture.match(rotateRegExp)
    let nextRotateValue = 270
    if (rotateValue) {
      const actualRotateValue = parseInt(rotateValue[2])
      const currentIndex = rotateValues.indexOf(actualRotateValue)
      if (!~currentIndex || currentIndex === rotateValues.length - 1) {
        nextRotateValue = rotateValues[0]
      } else {
        nextRotateValue = rotateValues[currentIndex + 1]
      }
      picture = picture.replace(rotateValue[0], `-/rotate/${nextRotateValue}/`)
    } else {
      picture += `-/rotate/${nextRotateValue}/`
    }
    this.handleUploadedFile({ cdnUrl: picture }, () => {
      this.setState({ photoModified: picture, isRotating: false })
    })
  }

  render() {
    const { src, alt, type, disableModal } = this.props
    const { isPhotoModalVisible, photoModified, isRotating } = this.state
    return (
      <Fragment>
        <Image src={setSrc(photoModified || src, type)} alt={alt} onClick={this.openModal} />
        {!disableModal && (
          <ModalPicture
            wrapClassName="photo-modal-wrap"
            className="photo-modal"
            centered
            zIndex={10000}
            visible={isPhotoModalVisible}
            onCancel={this.toggleModalVisibility}
            title="Edit Image"
            footer={
              <Fragment>
                {!!src && (
                  <>
                    <div className="uploadcare--footer__additions uploadcare-tab-effects--additions">
                      <div className="uploadcare-tab-effects--effects">
                        <div
                          role="button"
                          tabIndex="0"
                          className="uploadcare--button uploadcare--button_icon uploadcare-tab-effects--effect-button"
                          aria-label="Rotate"
                          onClick={this.handleRotate}
                        >
                          {isRotating && <Icon type="loading" />}
                          {!isRotating && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              className="uploadcare-tab-effects--icon"
                            >
                              <path d="M22.8449302,10.1550698 L22.8449302,10.1550698 C21.1616077,8.18356972 18.6836184,7 16,7 C11.0294373,7 7,11.0294373 7,16 C7,20.9705627 11.0294373,25 16,25 C18.6610655,25 21.131472,23.8397191 22.8318195,21.8591349 L24.3493086,23.1619115 C22.2729281,25.5805032 19.2506133,27 16,27 C9.92486775,27 5,22.0751322 5,16 C5,9.92486775 9.92486775,5 16,5 C19.224943,5 22.2084802,6.39983031 24.2627348,8.73726518 L27,6 L27,11.9970707 C27,12.5509732 26.5621186,13 25.9970707,13 L20,13 L22.8449302,10.1550698 Z M15,14 L17,14 C17.5522847,14 18,14.4477153 18,15 L18,17 C18,17.5522847 17.5522847,18 17,18 L15,18 C14.4477153,18 14,17.5522847 14,17 L14,15 C14,14.4477153 14.4477153,14 15,14 Z" />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>

                    <Popconfirm
                      placement="topLeft"
                      style={{ zIndex: '10001' }}
                      overlayStyle={{ zIndex: '10001' }}
                      title="Delete this photo?"
                      onConfirm={this.handleDelete}
                      okText="Yes, delete"
                      okButtonProps={{ style: { color: '#fff', backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' } }}
                      cancelText="No"
                      overlayClassName="photo-modal-delete-confirm"
                    >
                      <Button className="btn-delete" key="delete">
                        Delete
                      </Button>
                    </Popconfirm>
                  </>
                )}
                <Uploader onUploadComplete={this.handleUploadedFile} ref={(el) => (this.uploader = el)} />
              </Fragment>
            }
          >
            <img src={setSrc(photoModified || src, type)} alt={alt} />
          </ModalPicture>
        )}
      </Fragment>
    )
  }
}

export default ChangeablePicture
