import React from 'react'
import PropTypes from 'prop-types'

import { getWeekPrompt } from 'services'
import Layout from 'components/Layout'
import SidePhotosUploader from 'components/SidePhotosUploader'

// Refactored as pure function
const WeeklyPictures = ({ weekTag }) => {
  return (
    <Layout>
      <SidePhotosUploader
        nextUrl="user-pictures"
        pre={weekTag ? 'Upload your photos for' : 'Upload your photos'}
        title={weekTag ? getWeekPrompt(weekTag) : null}
        edit
        forDate={weekTag}
      />
    </Layout>
  )
}

// Set default props
WeeklyPictures.defaultProps = {
  weekTag: null,
}

WeeklyPictures.propTypes = {
  weekTag: PropTypes.string,
}

export default WeeklyPictures
