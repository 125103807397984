import styled from '@emotion/styled'

export const CstDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  width: 100%;
  height: 100%;
  p {
    text-align: center;
    color: #c4c4c4;
    font-size: 17px;
    font-weight: bold;
  }
`
