import React, { useEffect, useState } from 'react'
import EnrollmentLayout from '../Layout'
import { QuestionContainer } from '../Layout/Layout.styled'
import { Collapse } from 'antd'
import { CheckboxList, SelectMultiple, StyledCollapse } from 'styled'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import SectionError from 'components/SectionError'

const { Panel } = Collapse

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [ingredientsSelected, setIngredientsSelected] = useState([])
  const [proteins, setProteins] = useState([])
  const [proteinsSelected, setProteinsSelected] = useState([])
  const [carbs, setCarbs] = useState([])
  const [carbsSelected, setCarbsSelected] = useState([])
  const [fats, setFats] = useState([])
  const [fatsSelected, setFatsSelected] = useState([])
  const [herbs, setHerbs] = useState([])
  const [herbsSelected, setHerbsSelected] = useState([])
  const [sauces, setSauces] = useState([])
  const [saucesSelected, setSaucesSelected] = useState([])
  const [otherIngredients, setOtherIngredients] = useState([])
  const [otherIngredientsSelected, setOtherIngredientsSelected] = useState([])

  const loadCatalog = (catalog, id) => {
    return catalog
      .filter((i) => i.ingredient_categories.find((ic) => ic === id) && i.show_on_dislike)
      .map((i) => {
        return {
          value: i.id,
          label: i.name,
        }
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const catalogs = response.catalogs
        const mealPlan = response.enrollment.mealPlan
        let proteins = []
        let carbs = []
        let fats = []
        let herbs = []
        let sauces = []
        let otherIngredients = []

        if (catalogs.ingredients && catalogs.ingredients.length > 0) {
          proteins = loadCatalog(catalogs.ingredients, 1)
          setProteins(proteins)

          carbs = loadCatalog(catalogs.ingredients, 2)
          setCarbs(carbs)

          fats = loadCatalog(catalogs.ingredients, 3)
          setFats(fats)

          herbs = loadCatalog(catalogs.ingredients, 4)
          setHerbs(herbs)

          sauces = loadCatalog(catalogs.ingredients, 5)
          setSauces(sauces)

          otherIngredients = catalogs.ingredients
            .filter((i) => {
              return (
                !proteins.find((p) => p.value === i.id) &&
                !carbs.find((p) => p.value === i.id) &&
                !fats.find((p) => p.value === i.id) &&
                !herbs.find((p) => p.value === i.id) &&
                !sauces.find((p) => p.value === i.id)
              )
            })
            .map((i) => {
              return {
                id: i.id,
                name: i.name,
              }
            })
          setOtherIngredients(otherIngredients)
        }

        if (mealPlan.ingredient_dislikes && mealPlan.ingredient_dislikes.length > 0) {
          setIngredientsSelected(mealPlan.ingredient_dislikes)
          setProteinsSelected(proteins.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.value)).map((p) => p.value))
          setCarbsSelected(carbs.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.value)).map((p) => p.value))
          setFatsSelected(fats.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.value)).map((p) => p.value))
          setHerbsSelected(herbs.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.value)).map((p) => p.value))
          setSaucesSelected(sauces.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.value)).map((p) => p.value))
          setOtherIngredientsSelected(otherIngredients.filter((p) => mealPlan.ingredient_dislikes.find((id) => id === p.id)).map((p) => p.id))
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const newIngredients = proteinsSelected
      .concat(carbsSelected)
      .concat(fatsSelected)
      .concat(herbsSelected)
      .concat(saucesSelected)
      .concat(otherIngredientsSelected)
    setIngredientsSelected(newIngredients)
  }, [proteinsSelected, carbsSelected, fatsSelected, herbsSelected, saucesSelected, otherIngredientsSelected])

  const handleIngredientSelect = (checkedIngredients, type) => {
    switch (type) {
      case 'proteins':
        setProteinsSelected(checkedIngredients)
        break
      case 'carbs':
        setCarbsSelected(checkedIngredients)
        break
      case 'fats':
        setFatsSelected(checkedIngredients)
        break
      case 'herbs':
        setHerbsSelected(checkedIngredients)
        break
      case 'sauces':
        setSaucesSelected(checkedIngredients)
        break
      default:
        if (Array.isArray(checkedIngredients)) {
          setOtherIngredientsSelected(checkedIngredients)
        } else {
          setOtherIngredientsSelected((prev) => [...prev, checkedIngredients])
        }
        break
    }
  }

  const saveCallback = async ({ goBack }) => {
    const data = {
      ingredient_dislikes: ingredientsSelected,
    }
    return await HandleResponse({ ...props, data, goBack })
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext: true, section: 'meal_plan' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <>
          <QuestionContainer>
            <div className="question">
              <p className="title">
                What foods do you <b>dislike</b>? <span>(Optional)</span>
              </p>
            </div>
            <div className="content">
              <StyledCollapse accordion expandIcon={StyledCollapse.expandIcon}>
                <Panel header="Proteins" className="red" key="1">
                  <CheckboxList
                    value={proteinsSelected}
                    options={proteins}
                    onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'proteins')}
                  />
                </Panel>
                <Panel header="Carbs" className="green" key="2">
                  <CheckboxList value={carbsSelected} options={carbs} onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'carbs')} />
                </Panel>
                <Panel header="Fat" className="orange" key="3">
                  <CheckboxList value={fatsSelected} options={fats} onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'fats')} />
                </Panel>
                <Panel header="Herbs and Seasoning" className="blue" key="4">
                  <CheckboxList value={herbsSelected} options={herbs} onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'herbs')} />
                </Panel>
                <Panel header="Sauces" className="purple" key="5">
                  <CheckboxList value={saucesSelected} options={sauces} onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'sauces')} />
                </Panel>
              </StyledCollapse>
              <div style={{ width: '90%', margin: '0 auto' }}>
                <CheckboxList
                  value={otherIngredientsSelected}
                  options={otherIngredientsSelected.map((i) => {
                    const itemAux = otherIngredients.find((p) => p.id === i)
                    return { value: itemAux.id, label: itemAux.name }
                  })}
                  onChange={(checkedValue) => handleIngredientSelect(checkedValue, 'otherIngredients')}
                />
              </div>
              <div className="sub-component">
                <p>
                  Any other foods you <b>dislike</b>
                </p>
                <SelectMultiple
                  onSelect={(checkedValue) => handleIngredientSelect(checkedValue, 'otherIngredients')}
                  options={otherIngredients.filter((i) => !ingredientsSelected.find((is) => is === i.id))}
                  placeholder="eg.avocado"
                />
              </div>
            </div>
          </QuestionContainer>
        </>
      )}
    </EnrollmentLayout>
  )
}

export default index
