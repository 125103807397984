import React, { useRef, useEffect, useState } from 'react'
import { Widget } from '@uploadcare/react-widget/dist/cjs'
import uploadcare from 'uploadcare-widget'
import uploadcareTabEffects from 'uploadcare-widget-tab-effects'
import { PictureUploaderContainer } from './PictureUploader.styled'
import { UPLOADS } from '../../constants'
import { imageExists } from 'queries'
export default (props) => {
  const { title, picture, placeholder, onSelect, urlModifiers, disabled } = props
  const uploadCareWidgetApi = useRef()
  const [pictureSelected, setPictureSelected] = useState()
  const [pictureRendered, setPictureRendered] = useState()

  useEffect(() => {
    uploadcare.registerTab('preview', uploadcareTabEffects)
    if (picture && imageExists(picture)) {
      setPictureSelected(picture)
      setPictureRendered(`${picture}${urlModifiers ? urlModifiers : ''}`)
    } else if (placeholder) {
      setPictureRendered(placeholder)
    }
  }, [])

  useEffect(() => {
    if (picture && imageExists(picture)) {
      setPictureSelected(picture)
      setPictureRendered(`${picture}${urlModifiers ? urlModifiers : ''}`)
    } else if (placeholder) {
      setPictureRendered(placeholder)
    }
  }, [picture])

  const onChange = (e) => {
    setPictureSelected(e.cdnUrl)
    setPictureRendered(`${e.cdnUrl}${urlModifiers ? urlModifiers : ''}`)
    if (typeof onSelect === 'function') {
      if (picture !== e.cdnUrl) onSelect(e.cdnUrl)
    }
  }

  return (
    <PictureUploaderContainer className={disabled ? 'disabled' : ''}>
      {title && <p>{title}</p>}
      <div className="picture" onClick={() => !disabled && uploadCareWidgetApi.current.openDialog()}>
        <img src={`${pictureRendered}`} alt={title || 'picture'} />
        <Widget
          ref={uploadCareWidgetApi}
          value={pictureSelected}
          publicKey={UPLOADS.UPLOADCARE_PUBLIC_KEY}
          tabs={UPLOADS.UPLOADCARE_TABS}
          imageShrink={UPLOADS.UPLOADCARE_SHRINK}
          imagesOnly={UPLOADS.UPLOADCARE_IMAGES_ONLY}
          previewStep={UPLOADS.UPLOADCARE_PREVIEW}
          effects={['rotate', 'crop']}
          locale={UPLOADS.UPLOADCARE_LOCALE}
          localeTranslations={UPLOADS.UPLOADCARE_LOCALE_TRANSLATIONS}
          onChange={onChange}
        />
      </div>
    </PictureUploaderContainer>
  )
}
