import styled from '@emotion/styled'
import { Card } from 'antd'

export default styled(Card)`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  margin-bottom: 12px;
  .ant-card-body {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    img {
      height: 80px;
    }
    &::before {
      display: none;
    }
    .wrapper {
      width: 70%;
      padding: 1px 0px 5px;
      p {
        font-size: 18px;
        margin-bottom: 0px;
        color: #4a4a4a;
        span {
          font-weight: 500;
          color: #9e9d9d;
        }
      }
      div {
        margin-top: -10px;
      }
    }
  }
`
