import iconBodyFront from 'images/icon-body-front.png'
import iconBodyBack from 'images/icon-body-back.png'
import iconBodySide from 'images/icon-body-side.png'

export const previewTransform = '-/autorotate/yes/-/preview/208x208/'

export const uploaderOptions = [
  {
    pictureTitle: 'Front picture',
    name: 'front_url',
    pictureDescription: 'Upright posture, chin up, arms extended, with palms facing away with elbows in slightly.',
    pictureName: 'frontPicture',
    defaultPicture: iconBodyFront,
  },
  {
    pictureTitle: 'Back picture',
    name: 'back_url',
    pictureDescription: 'Upright posture, chin up, arms extended, with palms facing away with elbows in slightly.',
    pictureName: 'backPicture',
    defaultPicture: iconBodyBack,
  },
  {
    pictureTitle: 'Side picture',
    name: 'side_url',
    pictureDescription: 'Upright posture, chin up, arms extended, with palms facing away with elbows in slightly.',
    pictureName: 'sidePicture',
    defaultPicture: iconBodySide,
  },
]
