import React, { useState, useEffect, useRef } from 'react'
import EnrollmentLayout from '../Layout'
import { HandleRequest, HandleResponse } from '../uses/HandleRequestApi.js'
import { QuestionContainer } from '../Layout/Layout.styled'
import Message from 'components/Message'
import SectionError from 'components/SectionError'
import { CardInput } from 'styled'
import Neck from 'assets/icons/enrollment/neck.png'
import Shoulder from 'assets/icons/enrollment/shoulder.png'
import Chest from 'assets/icons/enrollment/chest.png'
import Arm from 'assets/icons/enrollment/arm.png'
import Forearm from 'assets/icons/enrollment/forearm.png'
import BellyWaist from 'assets/icons/enrollment/waist-belly.png'
import BellyWest from 'assets/icons/enrollment/waist-narrowest.png'
import Hip from 'assets/icons/enrollment/hip.png'
import Thigh from 'assets/icons/enrollment/thigh.png'
import Calf from 'assets/icons/enrollment/calf.png'
import { MaterializeInput } from 'components/Materialize'
import { getBodyFatPercentage } from 'services'
import { LOCAL_STORAGE } from 'constants.js'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const index = (props) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [submit, setSubmit] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [measurements, setMeasurements] = useState([])
  const [isValidSection, setIsValidSection] = useState(true)
  const [isValidMeasurements, setIsValidMeasurements] = useState(true)
  const [hasTheSameNumbers, setHasTheSameNumbers] = useState(false)
  const [units, setUnits] = useState()
  const [height, setHeight] = useState()
  const measurementsRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await HandleRequest({ ...props })
      if (response && response.hasError) {
        setError(response.message)
      } else if (response && !response.hasError) {
        const { id, height, neck, shoulder, chest, arm, forearm, narrow_waist, belly_waist, hip, thigh, calf, metric } = response.enrollment.data
        if (!id) {
          setError('It looks you have not started the enrollment')
        } else {
          setHeight(height)
          setUnits(metric)
          const arrayMeasurements = [
            { id: 'neck', icon: Neck, name: 'Neck', detail: '(narrowest point)', value: neck },
            { id: 'shoulder', icon: Shoulder, name: 'Shoulder', detail: '(circumference)', value: shoulder },
            { id: 'chest', icon: Chest, name: 'Chest', detail: '(nipple height)', value: chest },
            { id: 'arm', icon: Arm, name: 'Arm', detail: '(dominant side)', value: arm },
            { id: 'forearm', icon: Forearm, name: 'Forearm', detail: '(dominant side)', value: forearm },
            { id: 'narrow_waist', icon: BellyWaist, name: 'Waist', detail: '(narrowest point)', value: narrow_waist },
            { id: 'belly_waist', icon: BellyWest, name: 'Waist', detail: '(at belly button)', value: belly_waist },
            { id: 'hip', icon: Hip, name: 'Hip', detail: '(widest point, not glutes)', value: hip },
            { id: 'thigh', icon: Thigh, name: 'Thigh', detail: '(dominant side)', value: thigh },
            { id: 'calf', icon: Calf, name: 'Calf', detail: '(dominant side)', value: calf },
          ]
          setMeasurements(arrayMeasurements)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [])

  useEffect(() => {
    validate()
  }, [measurements])

  const saveCallback = async ({ goBack }) => {
    let response = false
    const data = {}

    measurements.forEach((element) => {
      data[element.id] = Number(element.value)
    })

    if (goBack) {
      response = await HandleResponse({ ...props, data, goBack })
    } else {
      setSubmit(true)
      validate(true)
      if (canGoNext) {
        response = await HandleResponse({ ...props, data, goBack })
      }
    }
    return response
  }

  const validate = (shouldFocusError) => {
    let isValid = true
    let values = []
    if (measurements.length > 0) {
      measurements.every((element) => {
        if (element.value <= 0) {
          isValid = false
          return false
        }
        values.push(element.value)
        return true
      })
      setIsValidSection(isValid)
      if (isValid) {
        const num = values.filter((v) => v === values[0])
        const inputsHasSameNumber = num.length === values.length
        isValid = isValid && !inputsHasSameNumber
        setHasTheSameNumbers(inputsHasSameNumber)
        if (isValid) {
          const bodyFat = validateBodyFat()
          isValid = isValid && bodyFat > 0 && bodyFat <= 100
          setIsValidMeasurements(bodyFat > 0 && bodyFat <= 100)
        }
      }

      if (isValid) {
        setCanGoNext(true)
      } else {
        setCanGoNext(false)
        if (shouldFocusError) {
          if (!loading) {
            scrollToRef(measurementsRef)
          }
        }
      }
    }
  }

  const handleSetValue = (id, value) => {
    const data = measurements.map((m) => {
      if (m.id === id) {
        m.value = value ? Number(value) : value
      }
      return m
    })
    setMeasurements(data)
    setHasTheSameNumbers(false)
  }

  const checkIsValid = (id, value) => {
    const gender = localStorage.getItem(LOCAL_STORAGE.USER_GENDER)
    const num = Number(value)
    let isValid

    if (hasTheSameNumbers) {
      isValid = false
    } else if (num > 0) {
      isValid = true
      if (!isValidMeasurements) {
        if (gender === 'male') {
          if (id === 'belly_waist' || id === 'neck') {
            isValid = false
          }
        } else {
          if (id === 'narrow_waist' || id === 'neck' || id === 'hip') {
            isValid = false
          }
        }
      }
    }
    return isValid
  }

  const validateBodyFat = () => {
    const gender = localStorage.getItem(LOCAL_STORAGE.USER_GENDER)
    let metric = units === 'lbs/in' ? 'in' : 'kg'
    let params = {}
    let result
    measurements.forEach((measurement) => {
      params[measurement.id] = measurement.value
    })
    result = getBodyFatPercentage(gender, metric, height, params.neck, params.belly_waist, params.narrow_waist, params.hip)
    return result
  }

  return (
    <EnrollmentLayout {...{ ...props, loading, saveCallback, canGoNext, section: 'body_composition' }}>
      {!loading && error && <SectionError type={error} />}
      {!loading && !error && (
        <QuestionContainer ref={measurementsRef}>
          {submit && !isValidSection && <Message type="error" message="All fields are required" />}
          {submit && isValidSection && (!isValidMeasurements || hasTheSameNumbers) && (
            <Message type="error" message="Some measurements look invalid, please double-check them!" />
          )}
          <div className="question">
            <p className="title">Please fill the following fields with your body measurements.</p>
          </div>
          <div className="content">
            {measurements.map((m) => {
              return (
                <CardInput key={m.id}>
                  <div>
                    {' '}
                    <img src={m.icon} alt="" />{' '}
                  </div>
                  <div className="wrapper">
                    <p>
                      <b>{m.name}</b> <span>{m.detail}</span>
                    </p>
                    <MaterializeInput
                      id={m.id}
                      type="number"
                      suffix={units === 'kg/cm' ? 'centimeters' : 'inches'}
                      label={null}
                      value={m.value}
                      maxLength="5"
                      isValid={checkIsValid(m.id, m.value)}
                      onChange={(e) => handleSetValue(m.id, e.target.value)}
                    />
                  </div>
                </CardInput>
              )
            })}
          </div>
        </QuestionContainer>
      )}
    </EnrollmentLayout>
  )
}

export default index
