import React, { useState } from 'react'
import { DatePicker } from 'antd'
import './index.scss'

const MaterializeDatePicker = ({ value, defaultPickerValue, label, style, disabledDate, format, onChange, disabled }) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className={`${disabled ? 'disabled' : ''} wrap`}>
      <div className={`${isFocused || value ? 'is-active is-completed' : ''} mat-div`}>
        <label htmlFor={label} className="mat-label">
          {label}
        </label>
        <DatePicker
          placeholder=""
          disabledDate={disabledDate}
          defaultPickerValue={defaultPickerValue}
          defaultValue={value}
          format={format}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={(e) => {
            setIsFocused(false)
          }}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default MaterializeDatePicker
