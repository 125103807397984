import styled from '@emotion/styled'
import { Table } from 'antd'

export const ContainerWeeks = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 17px 13px 21px;
  margin: 20px 0px;
  width: calc(100% - 16px);

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #5d5d5d;
    margin-bottom: 8px;
  }

  hr {
    border: none;
    border-top: 2px solid #c4c4c4;
  }

  .weeks-button {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 5px;
    row-gap: 5px;
    justify-items: center;
    width: 100%;

    span {
      display: block;
      width: 35px;
      height: 35px;
      color: #5d5d5d;
      border-radius: 50%;
      padding: 7px 0px;
      text-align: center;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;

      &.disabled {
        color: #c4c4c4;
        pointer-events: none;
      }

      &.active {
        background: #ff6b00;
        color: #ffffff;
      }
    }
  }
`

export const ExercisesContainer = styled.div`
  width: calc(100% - 30px);
  padding-bottom: 14px;

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #4a4a4a;
    margin-bottom: 3px;
    padding-left: 5px;
  }

  hr {
    border: none;
    border-top: 2px solid #c4c4c4;
  }

  .container-goal {
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;

    .ant-card {
      text-align: center;
      padding: 4px 5px;
      display: flex;
      justify-content: center;
      .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .technique-helper {
        position: absolute;
        right: 5px;
        top: calc(50% - 11px);
        cursor: pointer;
      }
    }
  }

  .ant-collapse {
    border: none;
    background: #ffffff;

    .ant-collapse-item {
      border: none;
      margin-bottom: 15px;
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
      border-radius: 4px;

      .ant-collapse-header {
        background: #ffffff;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
        border-radius: 4px;
        display: grid;
        grid-template-columns: 65px auto;
        grid-column-gap: 6px;

        img {
          width: 65px;
          height: 65px;
          object-fit: cover;
          border-radius: 4px;
        }

        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #4a4a4a;

          &.exercise-name {
            font-weight: bold;
            font-size: 17px;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }

          &.equipment {
            display: flex;
            align-items: center;

            span {
              margin-left: 4px;
            }
          }
        }
      }

      .ant-collapse-content {
        .sets {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
        }
      }
    }

    .ant-collapse-content {
    }
  }
`

export const SessionSetsTable = styled(Table)`
  width: 100%;
  margin-top: 7px;

  .ant-table-thead {
    tr {
      th {
        background: transparent;
        font-size: 11px;
        color: #4a4a4a;
        padding: 7px 0px;
        font-weight: 500;
        text-align: center;
        border-bottom: none;
      }
    }
  }

  .ant-table-tbody {
    tr {
      margin-bottom: 11px;

      &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        td {
          background: none;
        }
      }

      td {
        padding: 0 !important;
        padding-bottom: 11px !important;
        text-align: center;
        border-bottom: none;

        input {
          width: 46px !important;
          height: 35px !important;
          background: #ffffff;
          border: 1px solid #cfcfcf;
          box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          text-align: center;
          color: #979797;
          font-weight: bold;
          font-size: 18px;
        }

        .not-warmup {
          path {
            stroke: #cfcfcf;
            fill: #cfcfcf;
          }
          circle {
            fill: #cfcfcf;
            stroke: #cfcfcf;
          }
        }

        .volume {
          display: inline-block;
          border-bottom: 2px solid #ffbb40;
          width: 40px;
          height: auto;
          padding: 8px 0px;
          font-weight: bold;
          font-size: 18px;
          color: #979797;
          text-align: center;
        }
        .reduce-text {
          font-size: 15px !important;
        }
      }
    }
  }
`
