import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import * as Sentry from '@sentry/browser'
import GlobalStyles from 'components/GlobalStyles'
import ErrorBoundary from 'components/ErrorBoundary'
import * as serviceWorker from './serviceWorker'
import apolloClient from './apolloClient.js'
import Routes from './routes.js'
import { LOCAL_STORAGE } from './constants'
import { Spin } from 'antd'

const sentryVersion = `${process.env.REACT_APP_VERSION}`

Sentry.init({
  dsn: 'https://0ad6a1b0f01d4457b500db372744166e@sentry.io/1406119',
  environment: process.env.REACT_APP_BRANCH || 'production',
  release: 'portal@' + sentryVersion,
  beforeSend(event, hint) {
    if (process.env.REACT_APP_BRANCH === 'local') {
      return null
    }
    // Check if it is an exception, and has a message
    const { message } = hint && hint.originalException ? hint.originalException : { message: 'Exception not received' }

    if (message && (message.match('{"error":"login_required","error_description":"Login required"}') || message.match('login_required'))) {
      //According to Docs if null is returned the event will be discarted.
      return null
    }

    if (message && message.match(/Network error: Response not successful: Received status code 400/i)) {
      event.fingerprint = ['hasura token expired']
    } else if (event.exception) {
      Sentry.showReportDialog()
    }

    event.user = {
      id: localStorage.getItem(LOCAL_STORAGE.USER_ID),
      email: localStorage.getItem(LOCAL_STORAGE.USER_EMAIL),
    }

    return event
  },
  beforeBreadcrumb(breadcrumb, hint) {
    const getHTMLElementData = (element) => {
      let data = {}
      if (element.viewportElement && element.viewportElement.tagName.toLowerCase() === 'svg') {
        element = element.viewportElement
      }

      data.type = element.tagName.toLowerCase()
      if (data.type !== 'div') data.text = element.innerText
      if (element.title || element.dataset.title) data.title = element.title || element.dataset.title
      if (element.name) data.name = element.name
      if (element.dataset.ignoreSentry) data.ignoreSentry = element.dataset.ignoreSentry
      return data
    }

    switch (breadcrumb.category) {
      case 'fetch':
        if (breadcrumb.data && breadcrumb.data.status_code === 0) {
          breadcrumb.message = 'Response 0 from ' + breadcrumb.data.url
        }
        break
      case 'ui.click':
        let data = getHTMLElementData(hint.event.target)
        breadcrumb.data = breadcrumb.data ? { ...breadcrumb.data, data } : data
        if (data.text) {
          breadcrumb.message = `${data.text} => ${breadcrumb.message}`
        }
        if (breadcrumb.data.ignoreSentry) return null
        break
      default:
        break
    }
    return breadcrumb
  },
  ignoreErrors: ['top.GLOBALS', 'ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications'],
})

const ConnectedRootElement = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    demoAsyncCall()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  })
  if (loading) {
    return (
      <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100vh' }}>
        <Spin />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <Fragment>
          <GlobalStyles />
          <Routes />
        </Fragment>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 1500))
}

ReactDOM.render(<ConnectedRootElement />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
