import styled from '@emotion/styled'

export const StepperContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.5);
  height: 106px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .icons {
    height: 65px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    text-align: center;
    ${({ progress }) =>
      `background: -webkit-linear-gradient(left, rgba(255, 183, 43, 0.8) ${progress}%, white 0%);
      background: -moz-linear-gradient(left, rgba(255, 183, 43, 0.8) ${progress}%, white 0%);
      background: -ms-linear-gradient(left, rgba(255, 183, 43, 0.8) ${progress}%, white 0%);
      background: linear-gradient(left, rgba(255, 183, 43, 0.8) ${progress}%, white 0%);`}

    div {
      align-self: center;
      justify-self: center;
      height: 50px;
      width: 50px;

      .more {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        width: 50px;
        height: 50px;
        position: absolute;
        color: #ffffff;
        font-weight: bold;
        font-size: 15px;
        padding: 14px;
      }

      img {
        opacity: 0.5;
      }

      &.selected-icon {
        img {
          opacity: 1;
        }
      }
    }
  }

  .titles {
    height: 41px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    text-align: center;

    div {
      align-self: center;
      justify-self: center;
      color: #5d5d5d;
      font-size: 12px;
      font-weight: 500;

      &.selected-text {
        font-weight: bold;
      }
    }
  }
`
