import moment from 'moment'
import { getCookie } from 'tiny-cookie'
import { LOCAL_STORAGE, COOKIE } from 'constants.js'

export const getSuffix = (number) => {
  let suffix = ''
  switch (number) {
    case 1:
      suffix = 'st'
      break
    case 2:
      suffix = 'nd'
      break
    case 3:
      suffix = 'rd'
      break
    default:
      suffix = 'th'
  }
  return suffix
}

export const getExtraInfoForWeek = (week, additionalEntries) => {
  const startDate = moment(week.date)
  const finishDate = startDate.clone().add(6, 'days')
  let count = 0
  let weight = null
  const finishDateOut = finishDate.clone()
  const additionalEntriesObject = additionalEntries
    .filter((data) => moment(data.date).isBetween(startDate - 1, finishDate + 1))
    .reduce((acc, item) => {
      return { ...acc, [item.date]: item }
    }, {})

  while (finishDate.isSameOrAfter(startDate)) {
    const key = finishDate.clone().format('YYYYMMDD')
    if (additionalEntriesObject[key]) {
      let days = key - Object.keys(additionalEntriesObject).length

      for (days; days <= key; days++) {
        if (additionalEntriesObject[days] && additionalEntriesObject[days].weight != null) {
          count = count + 1
          weight = additionalEntriesObject[days].weight + weight
        }
      }
      break
    }
    finishDate.subtract(1, 'days')
  }
  weight = weight / count
  return { weight, finishDate: finishDateOut.format('YYYYMMDD') }
}

export const getMeasurementName = (name) => {
  let result = ''
  switch (name) {
    case 'belly_waist':
      result = 'Waist (Navel)'
      break
    case 'narrow_waist':
      result = 'Waist (Narrowest)'
      break
    default:
      result = name
      break
  }
  return result
}

export const getWeekNumber = (startBootcamp, duration, currentWeekStart) => {
  const currentWeekStartMoment = moment(currentWeekStart)
  const finishDate = moment(startBootcamp)
    .add(duration, 'weeks')
    .subtract(1, 'days')
  if (currentWeekStartMoment.isAfter(finishDate, 'day') || currentWeekStartMoment.isBefore(startBootcamp, 'day')) {
    return 0
  }
  const weekNumber = currentWeekStartMoment.diff(startBootcamp, 'weeks') + 1
  return weekNumber
}

/**
 * Takes a valid date and returns a string with format nn-nn
 * with start and end day of ISO week where that date lands
 * @param {Date} date
 */
export const getWeekRange = (date) => {
  const startDate = moment(date)
  const endDate = moment(date).add(6, 'd')

  return `${startDate.format('DD')}-${endDate.format('DD')}`
}

/**
 * Takes a valid date and returns a string with format MONTH nn-nn YEAR
 * with start and end day of ISO week where that date lands
 * @param {Date} date
 */
export const getWeekPrompt = (date) => {
  const startDate = moment(date)
  const endDate = moment(date).add(6, 'd')

  return `${startDate.format('MMMM DD')}-${endDate.format('DD')} ${startDate.format('YYYY')}`
}

export const getWeekArray = (startDate, finishDate, duration) => {
  const dates = []
  while (!startDate.isAfter(finishDate, 'day') && dates.length < duration) {
    const date = moment(startDate).format('YYYYMMDD')
    dates.push(date)
    startDate.add(1, 'weeks')
  }
  return dates
}

/**
 * gets start of week standarized to avoid local diferences in start of week
 * Starting on Monday we add 5 days to get to Saturday and 17 hours to start after 5 PM
 *
 * @return {Object} - which has properties:
 * currentWeek {a moment object) - current Week for filling,
 * isStartOfFilledWeek {Boolean} - allow we fill current week,
 * today {String} - current Day in special format for using in Queries,
 * currentWeekStart {String} - start of filled week in special format for using in Queries,
 * currentWeekEnd {String} - end of filled week in special format for using in Queries,
 * prevWeekStart {String} - start of previous from filled week in special format  for using in Queries,
 * prevWeekEnd {String} - end of previous from filled week in special format for using in Queries
 */
export const getDateReview = () => {
  const today = moment()
    .local()
    .format('YYYYMMDD')
  // const today = moment('2019-03-04 17:01:01')
  const endOfWeekDay = moment()
    .local()
    .startOf('isoWeek')
    .add(5, 'd')
    .add(1, 'm')
  const isStartOfFilledWeek = moment()
    .local()
    .isAfter(endOfWeekDay, 'minute')
  const currentWeek = isStartOfFilledWeek
    ? moment().local()
    : moment()
        .local()
        .subtract(1, 'weeks')
  const currentWeekStart = moment(currentWeek.startOf('isoWeek')).format('YYYYMMDD')
  const currentWeekEnd = moment(currentWeek.endOf('isoWeek')).format('YYYYMMDD')
  const prevWeek = moment(currentWeek).subtract(1, 'weeks')
  const prevWeekStart = moment(prevWeek.startOf('isoWeek')).format('YYYYMMDD')
  const prevWeekEnd = moment(prevWeek.endOf('isoWeek')).format('YYYYMMDD')

  return {
    today,
    currentWeek,
    isStartOfFilledWeek,
    currentWeekStart,
    currentWeekEnd,
    prevWeekStart,
    prevWeekEnd,
  }
}

/**
 * gets body fat percentage by US Navy formula
 *
 * @param {String} gender - 'male' or 'female'
 * @param {string} lengthUnit - 'in' or 'cm'
 * @param {Number} height
 * @param {Number} neck
 * @param {Number} waist
 * @param {Number} hip
 *
 * @return {Number / null} -  body fat percentage by US Navy formula
 */
export const getBodyFatPercentage = (gender, lengthUnit, height, neck, waist, narrowWaist, hip) => {
  const isAllParams = gender === 'female' ? !!height && !!narrowWaist && !!neck && !!hip : !!height && !!waist && !!neck

  if (!isAllParams) {
    return null
  }

  const metricHeight = lengthUnit !== 'in' ? height / 2.54 : height
  const metricNeck = lengthUnit !== 'in' ? neck / 2.54 : neck
  const metricWaist = lengthUnit !== 'in' ? waist / 2.54 : waist
  const metricNarrowWaist = lengthUnit !== 'in' ? narrowWaist / 2.54 : narrowWaist
  const metricHip = lengthUnit !== 'in' ? hip / 2.54 : hip

  let result
  switch (gender) {
    case 'male':
      result = 86.01 * Math.log10(metricWaist - metricNeck) - 70.041 * Math.log10(metricHeight) + 36.76
      break
    case 'female':
      result = 163.205 * Math.log10(metricNarrowWaist + metricHip - metricNeck) - 97.684 * Math.log10(metricHeight) - 78.387
      break
    default:
      return null
  }
  return +result.toFixed(0.01)
}

/**
 * gets body fat percentage by US Navy formula
 *
 * @param {String} gender - 'male' or 'female'
 * @param {string} bodyFatPercentage
 * @param {Array} goalConfigurations
 *
 * @return {String / null} -  goal suggested based in BF %
 */
export const getSuggestedGoal = (gender, bodyFatPercentage, goalConfiguration) => {
  const isAllParams = !!gender && bodyFatPercentage >= 0 && !!goalConfiguration

  if (!isAllParams) {
    return null
  }

  var goalSuggested = goalConfiguration.find((goal) => {
    var rangeConfiguration = gender === 'male' ? goal.suggested_configuration.male : goal.suggested_configuration.female
    return rangeConfiguration.min <= bodyFatPercentage && bodyFatPercentage <= rangeConfiguration.max
  })

  let result
  if (!!goalSuggested) {
    result = goalSuggested.id
  }

  return result
}

export function GetPendingEnrollment(queryData) {
  var has_enrollment = false
  var trainee = queryData.trainee

  if (!!trainee) {
    if (!!trainee.currentProgram && trainee.currentProgram.length > 0) {
      const currentEnrollmentStage = trainee.currentProgram[0].enrollment_stage
      if (currentEnrollmentStage.every((ces) => typeof ces === 'string')) {
        if (currentEnrollmentStage.indexOf('finish') === -1) {
          has_enrollment = true
        }
      } else if (!!currentEnrollmentStage && currentEnrollmentStage.length > 0) {
        const sectionFinished = currentEnrollmentStage.find(
          (ces) => ces.stages && ces.stages.length > 0 && ces.stages.indexOf('terms-and-conditions') !== -1,
        )
        if (!!!sectionFinished) {
          has_enrollment = true
        }
      }
    }
    if (!!trainee.nextProgram && trainee.nextProgram.length > 0) {
      const nextEnrollmentStage = trainee.nextProgram[0].enrollment_stage
      if (!!nextEnrollmentStage && nextEnrollmentStage.length > 0) {
        const sectionFinished = nextEnrollmentStage.find(
          (nes) => nes.stages && nes.stages.length > 0 && nes.stages.indexOf('terms-and-conditions') !== -1,
        )
        if (!!!sectionFinished) {
          var access_date = moment(trainee.nextProgram[0].program.access_date)

          if (
            moment()
              .local()
              .isSameOrAfter(access_date)
          ) {
            has_enrollment = true
          }
        }
      }
    }
  }

  return { has_enrollment }
}

export function loadInitialStorage(queryData, authData) {
  const { id = null } = queryData.trainee || {}

  // If there is a starting program with no signature or current one has not been signed
  const { has_enrollment } = GetPendingEnrollment(queryData)

  const totalBootcampsAssigned = queryData.trainee ? queryData.trainee.countProgram.aggregate.count : 0

  // Saving USER data to localStorage
  localStorage.setItem(LOCAL_STORAGE.USER_ID, queryData.id ? queryData.id : null)
  localStorage.setItem(LOCAL_STORAGE.EX_USER_ID, authData.id)
  localStorage.setItem(LOCAL_STORAGE.TRAINEE_ID, id || null)

  return {
    has_enrollment,
    totalBootcampsAssigned,
  }
}

export function getGainspotSSO(url) {
  if (url.indexOf('gainspot.app') === -1) return url
  url = url.replace('https://gainspot.app', '')
  url = url.replace('https://www.gainspot.app', '')
  const token = getCookie(COOKIE.USER_TOKEN).replace('Bearer ', '')
  const SSOLink = `https://www.gainspot.app/login/?redirect_to=${url}&aam-jwt=${token}`
  return SSOLink
}
