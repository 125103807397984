import React from 'react'
import { CstModal } from './Modal.styled'
import { Button } from 'antd'

const Modal = ({
  modalVisible,
  message,
  routineName,
  question,
  extraText,
  warning,
  textFirstButton,
  textSecondButton,
  setModalVisible,
  handleClickFirstButton,
  handleClickSecondButton,
  footerText,
}) => {
  return (
    <CstModal
      title=""
      centered={true}
      visible={modalVisible}
      maskClosable={false}
      footer={null}
      icon=""
      onOk={() => setModalVisible(false)}
      onCancel={() => setModalVisible(false)}
    >
      <div className="wrap-content">
        <p>
          {message} <span>{routineName}</span>
        </p>
        <p>
          {question} <small>{extraText}</small>
        </p>

        <Button type="primary" onClick={() => handleClickFirstButton()} block>
          {textFirstButton}
        </Button>
        <small className="txt-warning">{warning}</small>
        <Button onClick={() => handleClickSecondButton()} block>
          {textSecondButton}
        </Button>
        <small className="text-footer">{footerText}</small>
      </div>
    </CstModal>
  )
}

export default Modal
