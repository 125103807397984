import styled from '@emotion/styled'
import { Modal } from 'antd'

export const FirstModal = styled(Modal)`
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`

export const SpanText = styled('span')`
  font-family: Montserrat;
  font-style: bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ff6b00;
`
