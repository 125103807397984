import React, { Component } from 'react'
import { ReactComponent as DashboardIcon } from 'images/icon-dashboard.svg'
import { ReactComponent as ExercisesIcon } from 'images/icon-excercises.svg'
import { ReactComponent as MealPlan } from 'assets/icons/footer/meal-plan.svg'
import { ReactComponent as MacrosIcon } from 'images/icon-macros.svg'
import { ReactComponent as PhotosIcon } from 'images/icon-photos.svg'
import { Root, StyledLink, Ul, Li } from './Footer.styled'
import GlobalContext from 'context/GlobalContext'

const Link = (props) => (
  <StyledLink
    {...props}
    getProps={({ isCurrent }) => {
      return {
        'data-footer-link': isCurrent ? 'active' : null,
      }
    }}
  />
)

class Footer extends Component {
  render() {
    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const upcomingProgram = programs.find((p) => p.is_upcoming) || {}
          const closingProgram = programs.find((p) => p.is_closing) || {}
          const hasEnrollment = !!programs.find((p) => !p.is_enrollment_completed) || false

          const isMenuDisabled = (hasEnrollment && !user.is_returning) || (!!!currentProgram.program_id && !!!closingProgram.program_id)

          let isMacroDisabled = isMenuDisabled
          let isExerciseDisabled = isMenuDisabled
          let isMealPlanDisabled = isMenuDisabled
          let isPhotoDisabled = isMenuDisabled
          if ((!!currentProgram.program_id || !!upcomingProgram.program_id || !!closingProgram.program_id) && !hasEnrollment) {
            isMacroDisabled = false
            isExerciseDisabled = false
            isMealPlanDisabled = false
            isPhotoDisabled = false
          }
          let mealPlanTag = 0

          if (currentProgram.extras && currentProgram.extras.meal_plan) {
            mealPlanTag = parseInt(currentProgram.extras.meal_plan)
            if (isNaN(mealPlanTag)) mealPlanTag = 0
          }

          if (currentProgram.type && currentProgram.type !== 'bootcamp') {
            isExerciseDisabled = isPhotoDisabled = true
            isMacroDisabled = currentProgram.type !== 'macros'
            if (currentProgram.type !== 'meal_plan' && mealPlanTag <= 0) {
              isMealPlanDisabled = true
            }
          }

          return (
            <Root id="footer">
              <Ul>
                <Li>
                  <Link to="/">
                    <DashboardIcon />
                    <span>dashboard</span>
                  </Link>
                </Li>
                <Li>
                  <Link disabled={isMacroDisabled} to="/my-macros">
                    <MacrosIcon className="stroke" />
                    <span>macros</span>
                  </Link>
                </Li>
                <Li>
                  <Link disabled={isMealPlanDisabled} to="/meal-plan">
                    <MealPlan className="stroke" />
                    <span>Meal Plan</span>
                  </Link>
                </Li>
                <Li style={{ position: 'relative' }}>
                  <Link disabled={isExerciseDisabled} to="/my-exercises">
                    <div className="beta-tag">Beta</div>
                    <ExercisesIcon className="exercises" />
                    <span>exercises</span>
                  </Link>
                </Li>
                <Li>
                  <Link disabled={isPhotoDisabled} to="/user-pictures">
                    <PhotosIcon className="photos" />
                    <span>photos</span>
                  </Link>
                </Li>
              </Ul>
            </Root>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Footer
