import gql from 'graphql-tag'

export const baseMacroColumns = [
  {
    title: '',
    dataIndex: 'name',
  },
  {
    title: 'Base Macro Grams',
    dataIndex: 'baseMacroGrams',
  },
  {
    title: 'Total Grams',
    dataIndex: 'totalGrams',
  },
  {
    title: 'Kcal/gram',
    dataIndex: 'kcalGram',
  },
  {
    title: 'Daily Kcals',
    dataIndex: 'dailyKcals',
  },
]

export const trainingAlternateMacroColumns = [
  {
    title: '',
    dataIndex: 'name',
  },
  {
    title: 'Base Macro Grams',
    dataIndex: 'baseMacroGrams',
  },
  {
    title: 'Pre-Workout Grams',
    dataIndex: 'preWorkoutGrams',
  },
  {
    title: 'Total Grams',
    dataIndex: 'totalGrams',
  },
  {
    title: 'Kcal/gram',
    dataIndex: 'kcalGram',
  },
  {
    title: 'Daily Kcals',
    dataIndex: 'dailyKcals',
  },
]

export const getMacros = gql`
  query getMacros($traineeId: uuid!, $programs: [uuid!]!, $bootcampStartDate: String!) {
    trainee_program_coach(where: { _and: { program_id: { _in: $programs }, trainee_id: { _eq: $traineeId } } }) {
      program_id
      program {
        program_id
        description
        start_date
      }
    }
    macro(where: { _and: { program_id: { _in: $programs }, trainee_id: { _eq: $traineeId } } }) {
      status
      program_id
      macro_tables {
        type
        protein_base_grams
        protein_pre_workout_grams
        protein_total_grams
        protein_kcal_gram
        protein_daily_kcals
        protein_percent_calories
        carbs_base_grams
        carbs_pre_workout_grams
        carbs_total_grams
        carbs_kcal_gram
        carbs_daily_kcals
        carbs_percent_calories
        fat_base_grams
        fat_pre_workout_grams
        fat_total_grams
        fat_kcal_gram
        fat_daily_kcals
        fat_percent_calories
        total_daily_kcals
        total_percent_calories
      }
      program {
        description
        start_date
      }
    }
    training_dailies: macro_entry(
      where: { _and: { trainee_id: { _eq: $traineeId }, day_type: { _eq: "train" }, for_date: { _gte: $bootcampStartDate } } }
      limit: 7
      order_by: { for_date: desc_nulls_first }
    ) {
      for_date
      prot
      carbs
      fat
    }
    rest_dailies: macro_entry(
      where: { _and: { trainee_id: { _eq: $traineeId }, day_type: { _eq: "base" }, for_date: { _gte: $bootcampStartDate } } }
      limit: 7
      order_by: { for_date: desc_nulls_first }
    ) {
      for_date
      prot
      carbs
      fat
    }
  }
`
