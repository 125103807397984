import styled from '@emotion/styled'

export const ContainerBtnWorkout = styled.div`
  width: 100%;
  max-width: 400px;
  height: 69px;
  bottom: 0px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e4e4e4;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;

  & button {
    width: 100%;
    max-width: 344px;
    height: 43px;

    & i {
      width: 29px;
      height: 29px;
      & svg {
        width: 29px;
        height: 29px;
      }
    }
  }

  @media screen and (max-width: 320px) {
    & button {
      max-width: 300px;
    }
  }
`
