import React, { Component, Fragment } from 'react'
import { Form, Slider, Radio } from 'antd'
import { adherencesDisplayMap, sliderMarksShort, sliderMarksLong, periodMap } from './data'
import { StyledRadioGroup } from 'styled'
import { Label } from './WeeksEdit.styled'

const FormItem = Form.Item

class WeeklyIssuesEdit extends Component {
  render() {
    const { getFieldDecorator, measurementData, gender: traineeGender } = this.props
    const { period } = measurementData || ''
    return (
      <>
        {adherencesDisplayMap.map(({ id, name, mockValue, maxValue }) => (
          <Fragment key={id}>
            <Label>{name}</Label>
            <FormItem>
              {getFieldDecorator(id, {
                initialValue: measurementData[id] ? measurementData[id] || 0 : mockValue,
              })(<Slider step={maxValue === 100 ? 10 : 1} marks={maxValue === 100 ? sliderMarksLong : sliderMarksShort} min={0} max={maxValue} />)}
            </FormItem>
          </Fragment>
        ))}
        {traineeGender === 'female' && (
          <FormItem>
            <Label>Period</Label>
            {getFieldDecorator('period', {
              initialValue: measurementData.period ? measurementData.period : period,
            })(
              <StyledRadioGroup custom="inlineCenter">
                {periodMap.map((item) => (
                  <Radio key={item.name} value={item.value}>
                    {item.name}
                  </Radio>
                ))}
              </StyledRadioGroup>,
            )}
          </FormItem>
        )}
      </>
    )
  }
}

export default WeeklyIssuesEdit
