import styled from '@emotion/styled'
import { Form } from 'antd'
import BannerBackground from '../../images/banner-collapse-title-background.svg'

const FormItem = Form.Item

export const Wrap = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 56px;
`
export const Title = styled.h2`
  color: #4a4a4a;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
`
export const Label = styled.label`
  color: #959595;
  font-size: 10px;
  line-height: 13px;

  b {
    color: #636363;
  }
`
export const CollapseWrap = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export const Content = styled.div`
  border-radius: 4px;
  margin: 0px 10px;
  padding: 12px 10px;
  background-color: #fbfbfb;
  box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);

  p {
    color: #5d5d5d;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
  }

  hr {
    border: none;
    border-top: 2px solid #d5d5d5;
  }
`

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 10px;
  background-color: #f2f2f2;
  .ant-form-item-control-wrapper {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 500;

    .input-with-fake-label {
      .ant-input-group-addon:first-of-type {
        text-align: left;
        display: inline-block;
        width: 49.933333%;
        font-size: 12px;
        padding: 0;
        color: rgba(0, 0, 0, 0.85) !important;
      }

      input {
        width: 49.166667%;
        max-width: 83px;
        float: right;
        display: inline-block;
      }
    }

    .ant-form-explain {
      padding-top: 5px;
      font-size: 9px;
      font-weight: 600;
      text-align: left;
      color: #5d5d5d !important;
      .warning-cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-container {
          width: 20%;
          font-size: 18px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #faad14;
        }
      }
    }
  }
  .ant-form-item-label {
    flex-grow: 1;
    label {
      display: block;
      margin-left: 5px;
      color: #4a4a4a;
      font-size: 12px;
      text-align: left;
    }
  }
`

export const LastWeekly = styled.div`
  margin: 0px 10px;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(270deg, #ffa400 0%, #ff6b00 100%);
  background-image: url(${BannerBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  padding: 10px 14px;

  h3 {
    text-shadow: 0 2px rgba(0, 0, 0, 0.22);
    font-weight: bold;
    margin: 0;
    color: #ffffff;

    small {
      font-weight: 500;
      margin-left: 10px;
    }
  }
`
