import React, { Component } from 'react'
import Layout from 'components/Layout'
import { Timeline } from 'components/Timeline'
import { StyledForm, StyledSelect } from 'styled'
import { getWeekArray, getDateReview } from 'services'
import { Form, Select } from 'antd'
import moment from 'moment'
import GlobalContext from 'context/GlobalContext'

const FormItem = Form.Item
const { Option } = Select
const { currentWeekStart } = getDateReview()

class DailyEntry extends Component {
  state = {
    forDate: null,
  }

  handleChangeSelect = (value) => {
    this.setState({
      forDate: value,
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      edit,
    } = this.props

    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const closingProgram = programs.find((p) => p.is_closing) || {}
          let programId
          let startDate
          let duration

          if (currentProgram.program_id) {
            programId = currentProgram.program_id
            startDate = moment(currentProgram.start_date).format('YYYYMMDD')
            duration = currentProgram.duration
          } else if (closingProgram.program_id) {
            programId = closingProgram.program_id
            startDate = moment(closingProgram.start_date).format('YYYYMMDD')
            duration = closingProgram.duration
          }

          const weeks = getWeekArray(moment(startDate), moment(currentWeekStart), duration)

          return (
            <Layout disabledFooter={!edit}>
              <StyledForm custom="short" style={{ paddingTop: '60px' }}>
                <h3>Choose the proper week to edit a daily entry</h3>
                <FormItem>
                  {getFieldDecorator('weeks', {
                    initialValue: weeks[0],
                  })(
                    <StyledSelect
                      {...{
                        showSearch: true,
                        onChange: this.handleChangeSelect,
                      }}
                    >
                      {weeks.map((value, index) => {
                        const startDate = moment(value).format('MMM DD')
                        const endDate = moment(value)
                          .endOf('isoWeek')
                          .format('MMM DD')
                        return (
                          <Option key={value}>
                            <b>{`WEEK ${index + 1}`}&nbsp;</b>
                            {` ${startDate} - ${endDate}`}
                          </Option>
                        )
                      })}
                    </StyledSelect>,
                  )}
                </FormItem>
              </StyledForm>
              <Timeline
                {...{
                  traineeId: user.trainee_id,
                  programId,
                  forDate: this.state.forDate || startDate,
                }}
              />
            </Layout>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Form.create()(DailyEntry)
