import React from 'react'
import styled from '@emotion/styled'
import MacroImageBanner from '../images/macro-banner.svg'

const BannerSection = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #ff4713 0%, #ff7e22 100%);
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  height: 100px;
  padding: 16px 12px 16px;
  margin-bottom: 7px;

  .title {
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    vertical-align: middle;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    svg {
      border: 2px solid #ffffff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #ffffff;
  }

  p {
    width: 70%;
    margin: 0 auto;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  img {
    position: relative;
    top: -14px;
    cursor: pointer;
    float: right;
  }
`

export const StyledSectionBanner = (props) => {
  const { title, description, icon } = props
  return (
    <BannerSection style={{ backgroundImage: `url(${MacroImageBanner})` }}>
      <div className="title">
        {icon}
        <span>{title}</span>
      </div>
      <hr />
      <p>{description}</p>
    </BannerSection>
  )
}
