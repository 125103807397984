import React, { Component } from 'react'
import TextEditor from './TextEditor'
import { LOCAL_STORAGE } from '../../constants'
import { Query, Mutation } from 'react-apollo'
import { setContactInfusionSoftTag } from 'queries'
import Layout from 'components/Layout'
import Messages from './Messages'
import { Root, Wrap, Content, SubTitle } from './Chat.styled'
import { getMessages, appendMessage } from './data'
import GlobalContext from 'context/GlobalContext'

class Chat extends Component {
  state = {
    gifVisible: false,
  }

  handleChangeMessage = (event) => {
    localStorage.setItem(LOCAL_STORAGE.CURRENT_MESSAGE, event.target.innerHTML)
  }

  handleSubmit = async (threadId, senderID, isWeeklyFeedback, traineeCID, program_id, message, refetch, operation, callback) => {
    await operation({
      query: appendMessage,
      variables: {
        data: { thread_id: threadId, body: message, sender_id: senderID },
      },
    }).then(() => {
      if (isWeeklyFeedback) setContactInfusionSoftTag(traineeCID, program_id, ['WeeklyFeedback'])
      callback()
      refetch()
    })
  }

  handleGifSelection = (message, threadId, senderID, refetch, operation, callback) => {
    operation({
      mutation: appendMessage,
      variables: {
        data: {
          thread_id: threadId,
          body: message,
          sender_id: senderID,
        },
      },
    }).then(function(data) {
      callback()
      refetch()
    })
  }

  handleGifVisibleChange = (visible) => {
    this.setState({ gifVisible: visible })
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ user, programs }) => {
          const currentProgram = programs.find((p) => p.is_current) || {}
          const upcomingProgram = programs.find((p) => p.is_upcoming) || {}

          let programIDs = []
          if (currentProgram.program_id) programIDs.push(currentProgram.program_id)
          if (upcomingProgram.program_id) programIDs.push(upcomingProgram.program_id)

          let student_can_reply = false
          let thread_id = ''
          let coach = {}

          if (currentProgram.program_id) {
            student_can_reply = currentProgram.student_can_reply && currentProgram.coach
            thread_id = currentProgram.thread_id
            coach = currentProgram.coach && currentProgram.coach.user ? currentProgram.coach.user : {}
          }

          return (
            <Query query={getMessages} fetchPolicy="cache-and-network" variables={{ id: user.trainee_id }}>
              {({ loading, error, data, refetch }) => {
                if (error) {
                  console.error(error)
                  return null
                }

                let messages = loading ? [] : data.message
                let testMessage = messages[messages.length - 2]
                let test2Message = messages[messages.length - 1]
                let isWeeklyFeedback = false

                if (testMessage !== {} && !!testMessage) {
                  if (testMessage.body.includes('What went well this week?') && testMessage.sender_id === user.id) {
                    if (test2Message !== {} && !!test2Message && test2Message.sender_id === coach.id) {
                      isWeeklyFeedback = true
                    }
                  }
                }

                return (
                  <Layout disabledFooter={false}>
                    <Root>
                      <Wrap>
                        <Content>
                          <SubTitle>Messages</SubTitle>
                          <Messages
                            {...{
                              messages,
                              userID: user.id,
                              loading,
                            }}
                          />
                          {student_can_reply && (
                            <Mutation mutation={appendMessage}>
                              {(appendMessage) => {
                                return (
                                  <div className="content-box">
                                    {error && <div>Please enter message</div>}
                                    <TextEditor
                                      {...{
                                        thread_id,
                                        appendMessage,
                                        id: user.id,
                                        isWeeklyFeedback,
                                        traineeCID: user.contact_id,
                                        currentProgramId: currentProgram.program_id,
                                        send: this.handleSubmit,
                                        refetch,
                                        handleGifSelection: this.handleGifSelection,
                                      }}
                                    />
                                  </div>
                                )
                              }}
                            </Mutation>
                          )}
                        </Content>
                      </Wrap>
                    </Root>
                  </Layout>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Chat
