import Personal from 'assets/icons/enrollment/personal.png'
import PersonalStage from 'assets/icons/enrollment/personal-stage.png'

import Lifestyle from 'assets/icons/enrollment/lifestyle.png'
import ActivityLevel from 'assets/icons/enrollment/lifestyle-activity-level.png'
import Rest from 'assets/icons/enrollment/lifestyle-rest.png'
import Exercise from 'assets/icons/enrollment/lifestyle-exercise.png'
import Food from 'assets/icons/enrollment/lifestyle-food.png'
import Medical from 'assets/icons/enrollment/lifestyle-medical.png'

import MealPlan from 'assets/icons/enrollment/meal-plan.png'
import FoodPreferences from 'assets/icons/enrollment/meal-plan-preferences.png'
import CuisineStyles from 'assets/icons/enrollment/meal-plan-styles.png'
import FoodAllergies from 'assets/icons/enrollment/meal-plan-allergies.png'
import FoodDislikes from 'assets/icons/enrollment/meal-plan-dislikes.png'
import FoodFavorites from 'assets/icons/enrollment/meal-plan-favorites.png'
import MealPlanComments from 'assets/icons/enrollment/meal-plan-comments.png'

import BodyComposition from 'assets/icons/enrollment/body-composition.png'
import Biometrics from 'assets/icons/enrollment/body-composition-biometrics.png'
import Measurements from 'assets/icons/enrollment/body-composition-measurements.png'
import BodyFat from 'assets/icons/enrollment/body-composition-body-fat.png'
import Photos from 'assets/icons/enrollment/body-composition-photos.png'

import Finish from 'assets/icons/enrollment/finish.png'
import Goal from 'assets/icons/enrollment/finish-goal.png'
import Level from 'assets/icons/enrollment/finish-level.png'
import Summary from 'assets/icons/enrollment/finish-summary.png'
import TermsConditions from 'assets/icons/enrollment/finish-terms-conditions.png'

export const workFlow = [
  {
    id: 'personal',
    title: 'My Personal Information',
    description: 'Tell us about yourself.<br />&nbsp;',
    icon: Personal,
    steps: [
      {
        order: 1,
        path: 'personal',
        title: 'Personal Data',
        shortTitle: 'Personal Data',
        icon: PersonalStage,
      },
    ],
  },
  {
    id: 'lifestyle',
    title: 'My Lifestyle',
    description: 'Give us a little info about how you live and work.',
    icon: Lifestyle,
    steps: [
      {
        order: 1,
        path: 'activity-level',
        title: 'Activity Level',
        shortTitle: 'Activity',
        icon: ActivityLevel,
      },
      {
        order: 2,
        path: 'rest',
        title: 'Rest',
        shortTitle: 'Rest',
        icon: Rest,
      },
      {
        order: 3,
        path: 'exercise',
        title: 'Exercise',
        shortTitle: 'Exercise',
        icon: Exercise,
      },
      {
        order: 4,
        path: 'food',
        title: 'Food',
        shortTitle: 'Food',
        icon: Food,
      },
      {
        order: 5,
        path: 'medical',
        title: 'Medical Conditions',
        shortTitle: 'Medical',
        icon: Medical,
      },
    ],
  },
  {
    id: 'meal_plan',
    title: 'My Meal Plan',
    description: 'If you’ve purchased a meal plan, please submit your preferences here.',
    icon: MealPlan,
    steps: [
      {
        order: 1,
        path: 'food-preferences',
        title: 'Meal Plan Preferences',
        shortTitle: 'Preferences',
        icon: FoodPreferences,
      },
      {
        order: 2,
        path: 'cuisine-styles',
        title: 'Cuisine Styles',
        shortTitle: 'Styles',
        icon: CuisineStyles,
      },
      {
        order: 3,
        path: 'food-allergies',
        title: 'Allergies and Sensitivities',
        shortTitle: 'Allergies',
        icon: FoodAllergies,
      },
      {
        order: 4,
        path: 'food-dislikes',
        title: 'Food Dislikes',
        shortTitle: 'Dislikes',
        icon: FoodDislikes,
      },
      {
        order: 5,
        path: 'favorite-meals',
        title: 'Ketogains Favorite Meals',
        shortTitle: 'Favorites',
        icon: FoodFavorites,
      },
      {
        order: 6,
        path: 'meal-plan-notes',
        title: 'Additional Comments',
        shortTitle: 'Comments',
        icon: MealPlanComments,
      },
    ],
  },
  {
    id: 'body_composition',
    title: 'My Body Composition',
    description: 'You’ll be taking measurements here, so have a tape measure handy!',
    icon: BodyComposition,
    steps: [
      {
        order: 1,
        path: 'biometrics',
        title: 'Biometrics',
        shortTitle: 'Biometrics',
        icon: Biometrics,
      },
      {
        order: 2,
        path: 'measurements',
        title: 'Body Measurements',
        shortTitle: 'Measurements',
        icon: Measurements,
      },
      {
        order: 3,
        path: 'body-fat',
        title: 'Body Fat',
        shortTitle: 'Body Fat',
        icon: BodyFat,
      },
      {
        order: 4,
        path: 'photos',
        title: 'Starting Photos',
        shortTitle: 'Photos',
        icon: Photos,
      },
    ],
  },
  {
    id: 'finish',
    title: 'Finale',
    description: 'All done!<br />&nbsp;',
    icon: Finish,
    steps: [
      {
        order: 1,
        path: 'goal',
        title: 'Bootcamp Goal',
        shortTitle: 'Goal',
        icon: Goal,
      },
      {
        order: 2,
        path: 'level',
        title: 'Bootcamp Level',
        shortTitle: 'Level',
        icon: Level,
      },
      {
        order: 3,
        path: 'summary',
        title: 'Enrollment Summary',
        shortTitle: 'Summary',
        icon: Summary,
      },
      {
        order: 4,
        path: 'terms-and-conditions',
        title: 'Terms and Conditions',
        shortTitle: 'T & C',
        icon: TermsConditions,
      },
    ],
  },
]
