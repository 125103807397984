import styled from '@emotion/styled'
import { Card } from 'antd'

export const ContainerComplete = styled('div')`
  .container-complete {
    max-width: 400px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .congratulations {
    position: relative;
    top: -10px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  & header {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;

    & p {
      text-align: center;
      max-width: 200px;
      max-height: 54px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      margin: 0px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`

export const Motivation = styled(Card)`
  max-width: 338px;
  height: 100%;
  min-height: 264px;
  background: linear-gradient(91.42deg, #ff6c01 5.48%, #ffa401 99.28%);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  display: flex;
  align-items: center;

  & p {
    display: flex;
    align-items: center;
    margin: 0px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .author-quote {
    font-size: 13px;
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
`

export const ContainerKpi = styled(Card)`
  width: 100%;
  max-width: 370px;
  display: flex;
  flex-direction: row;
  border: 0px;
  margin: 12px 0px;

  .ant-card {
    ${({ previousVolume }) => (previousVolume !== 'true' ? 'width: 338px !important;' : '')}
  }

  .ant-card-body {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Kpi = styled(Card)`
  width: 163px;
  height: 57px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  display: flex;
  flex-direction: column !important;
  & .ant-card-body {
    flex-direction: column !important;
    align-items: center;
    height: 100%;

    & p:nth-of-type(1) {
      max-width: 87px;
      margin: 0px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #4a4a4a;
    }
    & p:nth-of-type(2) {
      width: 133px;
      margin: 0px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #979797;
    }
  }
`
export const GraphContainer = styled(Card)`
  width: 100%;
  max-width: 400px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
  border-radius: 4px;
  display: flex;
  flex-direction: column !important;
  & .ant-card-body {
    flex-direction: column !important;
    align-items: center;
    height: 100%;
    padding: 10px;

    & p:nth-of-type(1) {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      color: #4a4a4a;
      margin-bottom: 5px;
    }
  }
`

export const Button = styled('div')`
  width: 100%;
  max-width: 262px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  & button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & span {
    max-width: 228px;
    height: 24px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  & i {
    display: none;
  }
`

export const ButtonFakeSuccess = styled.div`
  border-radius: 50px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
  padding: 7px 0px 13px 0px;
  height: 43px;
  background: #6fcf97;

  i {
    display: block !important;
  }
`
