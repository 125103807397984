import Dexie from 'dexie'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
const today = moment().format('YYYY-MM-DD')
const previousVersion = 12
const version = 13
const db = new Dexie('Exercises')

db.version(previousVersion).stores({
  check_fetch: 'id, is_fetch, date',
  stats: '++id, workouts, total_volume, goal, date',
  workout_plans:
    'id, program_id, trainee_id, name, level, sub_level, weight_unit, program, routines, expired_in, is_current, is_selected, have_exercises',
  exercises: 'id, name, description, thumbnails, gif_url, video_url, gym_equipments, muscles, created_date',
  gym_equipments: 'id, name, expired_in',
  muscles: 'id, name, expired_in',
  configuration: 'id, value',
  routine_session: '++id, session_id, local_id, routine_week_id, for_date, is_completed, start_date, finish_date',
  routine_session_exercise: '++id, routine_session_id, local_id, exercise_id, user_notes',
  routine_session_set: '++id, routine_session_exercise_id, exercise_id, reps, reps_goal, weight, weight_goal, is_warmup, is_custom, order',
  report_last_weight: '++id, workout_plan_id, trainee_id, routine_id, week, routine_session_id, exercise_id, technique, has_deload, weight',
  current_workout: '++id, workout_id, trainee_id, routine, is_current, is_selected',
  volume_session: '++id, workout_plan_id, routine_id, week_id, identifier, session_id, created_date, prev_volume, volume',
})
db.version(version).stores({
  routine_session_exercise: '++id, routine_session_id, local_id, exercise_id, parent, user_notes',
  routine_session_set: '++id, routine_session_exercise_id, exercise_id, parent, reps, reps_goal, weight, weight_goal, is_warmup, is_custom, order',
  report_last_weight: '++id, workout_plan_id, trainee_id, routine_id, week, routine_session_id, exercise_id, parent, technique, has_deload, weight',
})

export const cacheTransaction = async (data) => {
  let transaction
  await db.transaction('rw', db.exercises, db.gym_equipments, db.muscles, db.configuration, async () => {
    await db.exercises
      .bulkPut(data.exercises)
      .then(async (id) => {
        await db.gym_equipments
          .bulkPut(data.gymEquipments)
          .then(async (id) => {
            await db.muscles
              .bulkPut(data.muscles)
              .then(async (id) => {
                await db.configuration
                  .bulkPut(data.configuration)
                  .then(async () => {
                    transaction = true
                  })
                  .catch(() => {
                    transaction = null
                  })
              })
              .catch((e) => {
                transaction = null
              })
          })
          .catch((e) => {
            transaction = null
          })
      })
      .catch((e) => {
        transaction = null
      })
  })
  return transaction
}

export const deleteDB = () => {
  Dexie.exists('Exercises').then(async function(exists) {
    if (exists) {
      await Dexie.delete('Exercises').catch((error) => {
        Sentry.addBreadcrumb({
          type: 'debug',
          category: 'exercise',
          message: 'Data',
          data: { error },
        })
        Sentry.captureMessage('Could not delete the Dexie database')
      })
    }
  })
}

export const createCache = async (table, data) => {
  const request = await db[table].bulkPut(data)
  return request
}

export const saveCache = async (table, data) => {
  const request = await db[table].put(data)
  return request
}

export const getCache = async (table) => {
  const tableData = await db[table]
    .toArray()
    .then((data) => {
      if (data.length <= 0) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)

  return tableData
}

export const getFirstCache = async (table, filter) => {
  const tableData = await db[table]
    .where(filter)
    .first((data) => {
      if (!data) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)
  return tableData
}

export const getLimitedCache = async (table, limit) => {
  const tableData = await db[table]
    .limit(limit)
    .toArray()
    .then((data) => {
      if (data.length <= 0) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)

  if (tableData) {
    const data = tableData[0]
    if (data.expired_in > today) return tableData
  }
  return null
}

export const getCacheWhere = async (table, filter) => {
  const tableData = await db[table]
    .where(filter)
    .toArray()
    .then((data) => {
      if (data.length <= 0) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)
  return tableData
}

export const getCacheWhereNot = async (table, where, key) => {
  const tableData = await db[table]
    .where(where)
    .notEqual(key)
    .toArray((data) => {
      if (data.length <= 0) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)
  return tableData
}

export const getCacheWhereBetween = async (table, filter, key, lower, upper) => {
  const tableData = await db[table]
    .where(key)
    .between(lower, upper, true, true)
    .and((data) => data.routine_id === filter.routine_id && data.exercise_id === filter.exercise_id && data.parent === filter.parent)
    .reverse()
    .limit(1)
    .toArray()
  return tableData
}

export const getCacheSort = async (table, filter, sort, limit) => {
  const tableData = await db[table]
    .where(filter)
    .reverse()
    .sortBy(sort)
    .then((data) => {
      if (data.length > 0) return data[0]
      else return null
    })
    .catch((err) => null)

  return tableData
}

export const getCacheAnyOf = async (table, filter, arrayKeys) => {
  const tableData = await db[table]
    .where(filter)
    .anyOf(arrayKeys)
    .toArray((data) => {
      if (data.length <= 0) {
        return null
      } else {
        return data
      }
    })
    .catch((err) => null)
  return tableData
}

export const updateTableCache = async (table, data) => {
  await db[table].clear()
  const create = await createCache(table, data)
  return create
}

export const updateCache = async (table, key, changes) => {
  const tableData = await db[table].update(key, changes).then((updated) => {
    if (updated) {
      return true
    } else {
      return false
    }
  })

  return tableData
}

export const clearCache = async (table) => {
  return await db[table].clear()
}

export const getLastDB = async (where, table, key) => {
  return await db[table].orderBy(key).last()
}

export const deleteCacheById = async (table, key, filter) => {
  return await db[table]
    .where(key)
    .equals(filter)
    .delete()
}

export const deleteCacheWhereNot = async (table, key, filter) => {
  return await db[table]
    .where(key)
    .notEqual(filter)
    .delete()
}
