import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Task from './Task'
import { Container } from './Column.styled'
const Column = ({ column, tasks, children }) => {
  return (
    <Droppable droppableId={column.id}>
      {(provided) => {
        return (
          <Container {...provided.droppableProps} ref={provided.innerRef}>
            {tasks.map((task, index) => (
              <Task key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </Container>
        )
      }}
    </Droppable>
  )
}

export default Column
