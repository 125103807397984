export const motivational = [
  [['The reason I exercise is for the quality of life I enjoy.'], ['Kenneth Cooper']],
  [['When it comes to health and well-being, regular exercise is about as close to a magic potion as you can get.'], ['Tich Naht Hahn']],
  [['Take care of your body. It’s the only place you have to live in.'], ['Jim Rohn']],
  [
    ['The doctor of the future will give no medicine but will involve the patient in the proper use of food, fresh air, and exercise.'],
    ['Thomas Edison'],
  ],
  [['To enjoy the glow of good health, you must exercise.'], ['Gene Tunney']],
  [['Today I will do what others won’t, so tomorrow I can accomplish what others can’t.'], ['Jerry Rice']],
  [['We are what we repeatedly do. Excellence then is not an act but a habit.'], ['Aristotle']],
  [['Do what you have to do until you can do what you want to do.'], ['Oprah Winfrey']],
  [['All great achievements require time.'], ['Maya Angelou']],
  [['Most people fail, not because of lack of desire, but, because of lack of commitment.'], ['Vince Lombardi']],
  [['The only person you are destined to become is the person you decide to be.'], ['Ralph Waldo Emerson']],
  [['Put all excuses aside and remember this: YOU are capable.'], ['Zig Ziglar']],
  [['The groundwork for all happiness is good health.'], ['Leigh Hunt']],
  [['Exercise is king. Nutrition is queen. Put them together and you’ve got a kingdom.'], ['Jack LaLanne']],
  [
    ['Physical fitness is not only one of the most important keys to a healthy body; it is the basis of dynamic and creative intellectual activity.'],
    ['John F. Kennedy'],
  ],
  [['Discipline is the bridge between goals and accomplishment.'], ['Jim Rohn']],
  [['Life shrinks or expands in proportion to one’s courage.'], ['Anaïs Nin']],
  [['Our greatest glory is not in never falling, but in rising every time we fall.'], ['Confucius']],
  [['Believe in yourself. You are braver than you think, more talented than you know, and capable of more than you imagine.'], ['Roy T. Bennett']],
  [['There’s no secret formula. I lift heavy, work hard, and aim to be the best.'], ['Ronnie Coleman']],
  [['The muscle and the mind must become one. One without the other is zero.'], ['Lee Haney']],
  [['Strength does not come from physical capacity. It comes from an indomitable will.'], ['Mahatma Gandhi']],
  [['Wanting something is not enough. You must hunger for it.'], ['Les Brown']],
  [['Bodybuilding isn’t 90 minutes in the gym. It’s a lifestyle.'], ['Lee Priest']],
  [['Strength is the product of struggle. You must do what others don’t to achieve what others won’t.'], ['Henry Rollins']],
  [['The first and greatest victory is to conquer self.'], ['Plato']],
  [['Success is the sum of small efforts repeated day-in and day-out.'], ['Robert Collier']],
  [['Only those who dare to fail greatly can ever achieve greatly.'], ['John F. Kennedy']],
  [['At the end of the day, let there be no excuses, no explanations, no regrets.'], ['Steve Maraboli']],
  [['People who avoid failure also avoid success'], ['Robert Kiyosaki']],
  [['Kites rise highest against the wind, not with it.'], ['Winston Churchill']],
  [['Always bear in mind that your own resolution to succeed is more important than any one thing.'], ['Abraham Lincoln']],
  [["Our greatest fear should not be of failure but of succeeding at things in life that don't really matter."], ['Francis Chan']],
  [
    [
      'When you take risks, you learn that there will be times when you succeed and there will be times when you fail, and both are equally important.',
    ],
    ['Ellen DeGeneres'],
  ],
  [['Fortune sides with him who dares.'], ['Virgil']],
  [['The way of success is the way of continuous pursuit of knowledge.'], ['Napoleon Hill']],
  [['Doing the best at this moment puts you in the best place for the next moment.'], ['Oprah Winfrey']],
  [['Whosoever desires constant success must change his conduct with the times.'], ['Niccolo Machiavelli']],
  [['Decide what you want to be....Pay the price...And be what you want to be.'], ['John A Widtsoe']],
  [['Your success and happiness lie in you.'], ['Helen Keller']],
]
