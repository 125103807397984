import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from 'components/PrivateRoute'
import DailyEntry from 'components/DailyEntry'
import Login from 'views/Security/Login'
import Logout from 'components/Logout'
import Impersonate from 'views/Security/Impersonate'
import Personal from 'components/Personal'
import WeeklyPictures from 'components/WeeklyPictures'
import WeeksEdit from 'components/WeeksEdit'
import UserPictures from 'components/UserPictures'
import Homepage from 'components/Homepage'
import Macros from 'views/Macros'
import Chat from 'components/Chat'
import NotFound from 'components/NotFound'
import BootcampTimeline from 'views/BootcampTimeline'
import Enrollment from 'views/Enrollment/Main'
import EnrollmentPersonal from 'views/Enrollment/Personal'
import EnrollmentActivityLevel from 'views/Enrollment/Lifestyle/ActivityLevel'
import EnrollmentRest from 'views/Enrollment/Lifestyle/Rest'
import EnrollmentExercise from 'views/Enrollment/Lifestyle/Exercise'
import EnrollmentFood from 'views/Enrollment/Lifestyle/Food'
import EnrollmentMedical from 'views/Enrollment/Lifestyle/Medical'
import EnrollmentBiometrics from 'views/Enrollment/BodyComposition/Biometrics'
import EnrollmentMeasurements from 'views/Enrollment/BodyComposition/Measurements'
import EnrollmentBodyFat from 'views/Enrollment/BodyComposition/BodyFat'
import EnrollmentPhotos from 'views/Enrollment/BodyComposition/Photos'
import EnrollmentGoal from 'views/Enrollment/Finish/Goal'
import EnrollmentLevel from 'views/Enrollment/Finish/Level'
import EnrollmentSummary from 'views/Enrollment/Finish/Summary'
import EnrollmentTermsConditions from 'views/Enrollment/Finish/TermsConditions'
import Congratulations from 'views/Enrollment/Finish/Congratulations'
import MealPlanPreferences from 'views/Enrollment/MealPlan/Preferences'
import MealPlanCuisine from 'views/Enrollment/MealPlan/CuisineStyle'
import MealPlanFoodAllergies from 'views/Enrollment/MealPlan/FoodAllergies'
import MealPlanFoodDislikes from 'views/Enrollment/MealPlan/FoodDislikes'
import MealPlanFavoriteMeals from 'views/Enrollment/MealPlan/FavoriteMeals'
import MealPlanNotes from 'views/Enrollment/MealPlan/Notes'
import MealPlan from 'views/MealPlan/Main'
import Exercises from 'views/Exercises'
import Reports from 'views/Exercises/Reports/index'
import Workout from 'views/Exercises/Workout'
import Sets from 'views/Exercises/Sets'
import UnderConstruction from 'views/Exercises/Main/UnderConstruction'
import CompleteWorkout from 'views/Exercises/CompleteWorkout'
import SignUp from 'views/Security/SignUp'
import CronometerInfo from './components/Cronometer/CronometerInfo'
import EnrollmentData from './components/EnrollmentData'
import SubscriptionHistory from './views/Subscriptions/History'

const App = () => (
  <Router>
    <PrivateRoute path="/weekly-pictures" component={WeeklyPictures} />
    <PrivateRoute path="/weekly-pictures/:weekTag" component={WeeklyPictures} />
    <PrivateRoute path="/weeks-edit" component={WeeksEdit} />
    <PrivateRoute path="/week/:last" component={WeeksEdit} />
    <PrivateRoute path="/user-pictures" component={UserPictures} />
    <PrivateRoute path="/" component={Homepage} />
    <PrivateRoute path="/daily-entry/:edit" component={DailyEntry} />
    <PrivateRoute path="/bootcamp-timeline/:programId" component={BootcampTimeline} />
    <PrivateRoute path="/personal/:edit" component={Personal} />
    <PrivateRoute path="/enrollment" component={Enrollment} />
    <PrivateRoute path="/enrollment/personal/" component={EnrollmentPersonal} />
    <PrivateRoute path="/enrollment/activity-level/" component={EnrollmentActivityLevel} />
    <PrivateRoute path="/enrollment/rest/" component={EnrollmentRest} />
    <PrivateRoute path="/enrollment/exercise/" component={EnrollmentExercise} />
    <PrivateRoute path="/enrollment/food/" component={EnrollmentFood} />
    <PrivateRoute path="/enrollment/medical/" component={EnrollmentMedical} />
    <PrivateRoute path="/enrollment/biometrics/" component={EnrollmentBiometrics} />
    <PrivateRoute path="/enrollment/measurements/" component={EnrollmentMeasurements} />
    <PrivateRoute path="/enrollment/body-fat/" component={EnrollmentBodyFat} />
    <PrivateRoute path="/enrollment/photos/" component={EnrollmentPhotos} />
    <PrivateRoute path="/enrollment/goal/" component={EnrollmentGoal} />
    <PrivateRoute path="/enrollment/level/" component={EnrollmentLevel} />
    <PrivateRoute path="/enrollment/summary/" component={EnrollmentSummary} />
    <PrivateRoute path="/enrollment/terms-and-conditions/" component={EnrollmentTermsConditions} />
    <PrivateRoute path="/enrollment/congratulations/" component={Congratulations} />
    <PrivateRoute path="/enrollment/food-preferences/" component={MealPlanPreferences} />
    <PrivateRoute path="/enrollment/cuisine-styles/" component={MealPlanCuisine} />
    <PrivateRoute path="/enrollment/food-allergies/" component={MealPlanFoodAllergies} />
    <PrivateRoute path="/enrollment/food-dislikes/" component={MealPlanFoodDislikes} />
    <PrivateRoute path="/enrollment/favorite-meals/" component={MealPlanFavoriteMeals} />
    <PrivateRoute path="/enrollment/meal-plan-notes/" component={MealPlanNotes} />
    <PrivateRoute path="/meal-plan/food-preferences/" component={MealPlanPreferences} />
    <PrivateRoute path="/meal-plan/cuisine-styles/" component={MealPlanCuisine} />
    <PrivateRoute path="/meal-plan/food-allergies/" component={MealPlanFoodAllergies} />
    <PrivateRoute path="/meal-plan/food-dislikes/" component={MealPlanFoodDislikes} />
    <PrivateRoute path="/meal-plan/favorite-meals/" component={MealPlanFavoriteMeals} />
    <PrivateRoute path="/meal-plan/meal-plan-notes/" component={MealPlanNotes} />
    <PrivateRoute path="/meal-plan/" component={MealPlan} />
    <PrivateRoute path="/homepage" component={Homepage} />
    <PrivateRoute path="/my-macros" component={Macros} />
    <PrivateRoute path="/my-exercises" component={Exercises} />
    <PrivateRoute path="/my-exercises/under-construction" component={UnderConstruction} />
    <PrivateRoute path="/my-exercises/reports" component={Reports} />
    <PrivateRoute path="/exercises/workout" component={Workout} />
    <PrivateRoute path="/exercises/workout/your-sets" component={Sets} />
    <PrivateRoute path="/exercises/workout/complete" component={CompleteWorkout} />
    <PrivateRoute path="/messages" component={Chat} />
    <PrivateRoute path="/enrollment_data" component={EnrollmentData} />
    <PrivateRoute path="/cronometer" component={CronometerInfo} />
    <PrivateRoute path="/cronometer/:result" component={CronometerInfo} />
    <PrivateRoute path="/history" component={SubscriptionHistory} />
    <Logout path="/logout" />
    <Login path="/login" />
    <SignUp path="/sign-up" />
    <Impersonate path="/sso" />
    <NotFound default />
  </Router>
)

export default App
