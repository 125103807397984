import React, { Component, Fragment } from 'react'
import emotionNormalize from 'emotion-normalize'
import { Global, css } from '@emotion/core'
import iconQuestionsCircular from 'images/icon-questions-circular.svg'
import UploadcareHint from 'assets/uploadcare/hint.png'

class GlobalStyles extends Component {
  render() {
    return (
      <Fragment>
        <Global
          styles={css`
            ${emotionNormalize};
            html {
              scroll-behavior: smooth;
            }
            body {
              font-family: 'Montserrat', sans-serif;
            }
            body,
            form,
            input {
              font-family: 'Montserrat', sans-serif;
            }
            .ant-form {
              font-family: 'Montserrat', sans-serif;
            }
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input {
              border-radius: 4px !important;
            }
            .has-error .ant-input-group-addon {
              background: none;
            }
            .icon-tooltip {
              .ant-tooltip-content {
                font-size: 9px;
                max-width: 200px;

                .ant-tooltip-inner {
                  background-image: url(${iconQuestionsCircular});
                  background-position: 8px center;
                  background-repeat: no-repeat;
                  background-size: 16px;
                  padding-left: 30px;
                }
              }
            }
            /* radiobuttons */
            .ant-radio {
              &-group {
                display: flex;
                flex-direction: column;
              }
              &-wrapper {
                margin: 10px 5px;
              }
            }
            /* checkbox */
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #ff4613;
              border-color: #ff4613;
            }
            .ant-checkbox-checked:after {
              border: 1px solid #ff4613;
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner,
            .ant-checkbox-input:focus + .ant-checkbox-inner {
              border-color: #ff4613;
            }
            /* calendar */
            .ant-calendar {
              font-size: 10px;
            }
            .ant-calendar-picker-container {
              font-family: 'Montserrat', sans-serif;
            }
            .ant-calendar-footer {
              display: none;
            }
            .ant-calendar-today .ant-calendar-date {
              color: #ff4613;
              border-color: #ff4613;
              background-color: rgba(255, 70, 19, 0.2);
            }
            .ant-calendar-date:active {
              background-color: #ff4613;
            }
            .ant-calendar-selected-date .ant-calendar-date,
            .ant-calendar-selected-start-date .ant-calendar-date,
            .ant-calendar-selected-end-date .ant-calendar-date {
              background-color: #ff4613;
            }
            /* select */
            .ant-select-dropdown {
              font-family: 'Montserrat', sans-serif;
              font-size: 9px;
            }

            /** MODAL GENERIC */
            .ant-modal-wrap {
              z-index: 10000;
              overflow-y: auto;
              overflow-x: hidden;

              .ant-modal-header {
                padding: 55px 12px 5px;
                border-bottom: none;

                .ant-modal-title {
                  color: #4a4a4a;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 17px;
                  text-align: center;
                }
              }

              .ant-modal-body {
                text-align: center;
                padding-top: 5px;
              }

              .ant-btn:not(.ant-btn-link) {
                border: 2px solid #ff6b00;
                border-radius: 50px;
                font-weight: bold;
                font-size: 15px;
                color: #ff6b00;
                width: 80%;

                &.ant-btn-primary {
                  color: #ffffff;
                  background: linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
                  border: none;
                }

                &.ant-btn-block {
                  display: block;
                  margin: 0 auto 12px;
                }
              }

              .ant-modal-close {
                color: #ff6b00;
                font-size: 17px;

                svg {
                  path {
                    stroke: #ff6b00;
                    stroke-width: 100;
                  }
                }
              }

              @media screen and (min-width: 400px) {
                .ant-modal {
                  max-width: 380px;
                }
              }
            }

            .photo-modal-wrap {
              z-index: 10000;
              flex-direction: column;
              display: flex;
              align-items: center;
              bottom: auto;
              right: auto;
              width: 100%;
              height: 100%;
              justify-content: center;

              &:before {
                content: none !important;
              }

              .ant-modal-mask {
                background: rgba(0, 0, 0, 0.5) !important;
              }

              @media screen and (min-width: 760px) {
                .photo-modal {
                  max-width: 900px;
                }
              }
            }

            /* modal video */
            .measurements-video-modal {
              max-width: initial !important;

              .ant-modal {
                &-content {
                  background-color: transparent;
                  box-shadow: initial;
                }
                &-body {
                  padding: 0;
                }
                &-header {
                  padding: 16px 0;
                  background-color: transparent;
                  border-bottom: 0;
                }
                &-title {
                  color: #ffffff;
                  font-family: Montserrat;
                  font-size: 11px;
                  line-height: 14px;
                  text-align: center;
                  margin-top: -60px;
                }
              }

              .ant-modal-close-x {
                top: -20px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                color: #fff;
              }
            }

            .ant-dropdown {
              font-family: 'Montserrat', sans-serif;
            }
            .profile-menu-overlay {
              .ant-dropdown-menu {
                border-radius: 0;
                box-shadow: none;
                background-color: #5d5d5d;
              }
              .ant-dropdown-menu-item {
                padding-right: 12px;
                a {
                  color: #fff;
                  text-align: right;
                  margin: 0px -12px;
                }

                &:hover {
                  background-color: initial;
                }
              }
              .ant-dropdown-menu {
                padding: 10px 0;
              }
            }
            .terms-conditions-modal {
              font-family: 'Montserrat', sans-serif;

              .ant-btn {
                border-radius: 40px;

                &:hover {
                  color: #ee742e;
                  border-color: #ee742e;
                }
              }

              .ant-btn-primary {
                background-color: #ee742e;
                border-color: #ee742e;
                border-radius: 40px;

                &:hover {
                  color: #fff;
                }
              }
            }
            a[data-footer-link] {
              color: #ffffff;
              background-color: #ff4613;
              svg {
                fill: #ffffff;
              }
              svg.stroke {
                fill: transparent;
                stroke: #ffffff;
              }
              svg.exercises,
              svg.photos {
                stroke: #ff4613;
              }
            }
            .ant-popover-inner-content {
              padding: 0;
            }
            .photo-modal-delete-confirm {
              .ant-popover-inner-content {
                padding: 10px;
              }
            }

            .uploadcare--tab__content {
              &:not(.uploadcare--preview__content):after,
              &:not(.uploadcare--preview__content_crop):after {
                content: ' ';
                padding: 1em;
                margin: 2em;
                background-image: url(${UploadcareHint});
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                min-width: 500px;
                min-height: 200px;
                height: auto;
                color: #ffffff;
                border-radius: 5px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;

                @media screen and (max-width: 400px) {
                  min-width: 300px;
                }
              }
            }
            .optionMultiple {
              color: #5d5d5d;
              font-size: 16px;
              display: flex;
              justify-content: space-between;
              margin: 5px 0px;
              text-transform: capitalize;
              &:hover:not(.ant-select-dropdown-menu-item-disabled) {
                background-color: rgba(196, 196, 196, 0.5);
              }
            }

            .select.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li.ant-select-selection__choice {
              display: none;
            }
          `}
        />
      </Fragment>
    )
  }
}

export default GlobalStyles
