import styled from '@emotion/styled'

export const BtnFakeSuccess = styled.div`
  border-radius: 50px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 19px;
  font-weight: bold;
  padding: 7px 0px 13px 0px;
  height: 43px;
  background: #6fcf97;
  text-align: center;
  i {
    display: block !important;
  }
`
