const NetworkDetector = async () => {
  let isOnline = false
  if (navigator.onLine) {
    isOnline = await fetch(`${process.env.REACT_APP_API_URL}status`, { method: 'GET' })
      .then(() => true)
      .catch(() => false)
  }
  return isOnline
}

export default NetworkDetector
