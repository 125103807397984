import { Form, Popover } from 'antd'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import React from 'react'

export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        .ant-popover {
          max-width: 240px;
        }

        .ant-popover-inner {
          padding: 10px;

          .ant-popover-title {
            border-bottom: none !important;
            padding: 5px 0px;

            div {
              border-bottom: 2px solid #ff4b2d;
              text-align: center;
              font-size: 14px;
              font-weight: bold;

              h3 {
                color: #5d5d5d;
              }

              p {
                font-size: 12px;
                color: #5d5d5d;
                font-weight: 600;
                text-align: center;

                b {
                  color: #383838;
                  font-weight: bold;
                }
              }

              .snooze-icon {
                background-color: #ff4b2d;
                border-radius: 50%;
                padding: 5px;
                width: 35px;
                height: 35px;

                g path {
                  fill: #ffffff;
                }
              }

              &.snoozed {
                border-bottom: 2px solid #62d37c;

                .snooze-icon {
                  background-color: #62d37c;
                }
              }

              svg {
                &.cronometor-action {
                  margin: 0 18px;
                  top: -7px;
                  position: relative;
                }
              }
            }
          }

          .ant-popover-inner-content {
            text-align: center;

            .ant-btn {
              width: 95%;
              max-width: 230px;
              margin-bottom: 10px;
              font-weight: bold;
              font-size: 12px;

              &:not(.btn-cancel) {
                background-color: #ff4b2d;
                border-color: #ff4b2d;
                color: #ffffff;

                &:first-of-type {
                  margin-top: 10px;
                }
              }
            }

            .snoozed {
              .ant-btn:not(.btn-cancel) {
                background-color: #62d37c;
                border-color: #62d37c;
              }
            }
          }
        }
      `}
    />
  )
}

export const Root = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 10px 0px 20px;
`

export const Wrap = styled.div`
  max-width: 460px;
  margin: 0 auto;
  background-color: #fbfbfb;
  box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`

export const TimelineWrap = styled.div`
  background-color: #fbfbfb;
  border-radius: 4px;

  div.title {
    background-color: #ffffff;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px 4px 0 0;
    padding: 4px 8px;
    margin-bottom: 8px;

    p {
      border-radius: 2.5px;
      border-left: 3px solid #ff6b00;
      margin: 0;
      padding: 5px 8px;
      color: #5d5d5d;
      font-size: 17px;
      font-weight: bold;

      img {
        width: 30px;
        height: 30px;
        margin-right: 4px;
      }
    }
  }

  .content {
    padding: 4px 8px 18px 8px;

    .subtitle {
      font-weight: bold;
      color: #5d5d5d;
      padding-left: 20px;
    }

    .week-days-container {
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.14);
      border-radius: 4px;

      .week-days-top {
        border-radius: 4px 4px 0 0;
        padding: 0px 15px;
        background-color: #ffffff;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14%, 1fr));

        p {
          text-align: center;
          margin: 0;
        }

        .day {
          cursor: pointer;
          padding: 8px 0px;

          &.active {
            border-radius: 3px 3px 0px 0px;
            background-color: #ff6b00;
            color: #ffffff;
          }

          &.is-future {
            color: #bebebe;
            font-weight: 500;
          }
        }
      }

      .week-days-bottom {
        border-radius: 0 0 4px 4px;
        padding: 0px 15px;
        background-color: #ffffff;
        box-shadow: inset 0 8px 6px -6px rgba(0, 0, 0, 0.1);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(14%, 1fr));

        p {
          text-align: center;
          margin: 0;
        }

        .day {
          cursor: pointer;
          padding: 7px 0px 0px 0px;

          &.active {
            border-radius: 0px 0px 3px 3px;
            background-color: #ff6b00;
            color: #ffffff;

            .icon {
              color: red;
              svg {
                g {
                  stroke: white;
                }
              }
            }
          }
        }
      }
    }

    .weekly-daily-complete {
      border: 1px solid #61bc75;
      border-radius: 4px;
      box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      img {
        width: 60px;
        background: linear-gradient(180deg, #72da8a 0%, #6dcb82 100%);
      }

      div {
        float: right;
        width: calc(100% - 70px);
        text-align: center;

        h3 {
          color: #5d5d5d;
          margin-bottom: 2px;
          font-weight: bold;
        }

        p {
          color: #5d5d5d;
          margin-bottom: 2px;
        }
      }
    }

    .missing-data {
      border: 1px solid #ff4d37;
      border-radius: 4px;
      box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 10px;

      .red-title {
        background: #ff4d37;
        color: #ffffff;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        padding: 5px;
        top: -1px;
        position: relative;
        border-radius: 4px 4px 0px 0px;
      }

      .total-missing-data {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));

        div {
          padding-left: 25px;
          border-right: 1px solid gray;
        }

        img {
          top: -5px;
          position: relative;
          margin-right: 5px;
        }

        span.total {
          color: #5d5d5d;
          font-size: 23px;
          font-weight: 600;
        }
      }
    }

    .edit-previous-data {
      width: 49%;
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.14);
      border-radius: 17.5px;
      font-size: 12px;
      color: #5d5d5d;
      border: 1px solid #ff6b00;

      &:first-of-type {
        margin-right: 1%;
      }

      &:hover,
      &:active,
      &:visited {
        color: #ffffff;
        background: #ff6b00;
      }

      &.complete {
        border: 1px solid #61bc75;

        &:hover,
        &:active,
        &:visited {
          color: #ffffff;
          background: #61bc75;
        }
      }
    }

    .notes {
      .link-toggle {
        cursor: pointer;
        font-weight: bold;
        border-bottom: 1px solid;
        width: fit-content;
        padding: 0px 2px;
        margin-left: 18px;
      }

      .counter {
        color: #4a4a4a;
        font-size: 9px;
        line-height: 9px;
        margin: 0;
        text-align: right;
        padding-right: 10px;
      }
    }
  }
`

export const StyledForm = styled(Form)`
  width: 100%;
  padding: 0px 13px;
  margin-top: 12px;
  margin-bottom: 14px;

  p {
    color: #ff6b00;
    padding-left: 18px;
    margin-bottom: 6px;
  }

  p.success-message {
    text-align: center;
    margin-bottom: 1em;
    font-size: 12px;
  }

  .ant-form-item {
    background-color: #f2f2f2;
    padding: 6px 6px 6px 10px;
    margin-bottom: 10px;

    .ant-form-item-label {
      text-align: left;
      @media (max-width: 574px) {
        padding-top: 10px;
      }

      label {
        font-size: 12px;
      }
    }
  }

  .ant-form-item-control-wrapper {
    .ant-input-group-addon {
      min-width: 30px;
    }

    .input-with-fake-label {
      .ant-input-group-addon:first-of-type {
        text-align: left;
        display: inline-block;
        width: 49.933333%;
        font-size: 12px;
        padding: 0;
        color: rgba(0, 0, 0, 0.85) !important;
      }

      input {
        width: 49.166667%;
        float: none;
        display: inline-block;
      }
    }

    .ant-form-explain {
      padding-top: 5px;
      font-size: 9px;
      font-weight: 600;
      text-align: left;
      color: #5d5d5d !important;
      .warning-cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-container {
          padding: 5px 10px;
          font-size: 18px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #faad14;
        }
      }
    }
  }
`

export const SnoozeConfirm = styled(Popover)`
  float: right;
  width: 24px !important;
  height: 24px !important;
  padding: 2px 0px 2px 10px;
  margin-right: 4px;
  border-left: 1px solid #d5d5d5;
  cursor: pointer;

  svg g path {
    fill: #ff4d37;
  }

  &.snoozed {
    svg g path {
      fill: #959595;
    }
  }

  &.complete {
    svg g path {
      fill: #62d37c;
    }
  }
`
