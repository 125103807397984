import backgroundStart from '../../images/tutorial-start-background.svg'
import backgroundDashboard from '../../images/tutorial-dashboard-background.svg'
import backgroundDashboard2 from '../../images/tutorial-dashboard-background-2.svg'
import backgroundMacros from '../../images/tutorial-macros-background.svg'
import backgroundGoal from '../../images/tutorial-goal-background.svg'
import backgroundExercises from '../../images/tutorial-excercise-background.svg'
import backgroundPhotos from '../../images/tutorial-photos-background.svg'
import pinStart from '../../images/tutorial-start-pin.svg'
import pinDashboard from '../../images/tutorial-dashboard-pin.svg'
import pinDashboard2 from '../../images/tutorial-dashboard-pin-2.svg'
import pinMacros from '../../images/tutorial-macros-pin.svg'
import pinGoal from '../../images/tutorial-goal-pin.svg'
import pinExercises from '../../images/tutorial-excercise-pin.svg'
import pinPhotos from '../../images/tutorial-photos-pin.svg'

import startStep1 from '../../images/Tutorial/tutorial-start-step1.png'
import startStep2 from '../../images/Tutorial/tutorial-start-step2.png'
import startStep3 from '../../images/Tutorial/tutorial-start-step3.png'
import startStep4 from '../../images/Tutorial/tutorial-start-step4.png'
import dashboardStep1 from '../../images/Tutorial/tutorial-dashboard-step1.png'
import dashboardStep2 from '../../images/Tutorial/tutorial-dashboard-step2.png'
import dashboardStep3 from '../../images/Tutorial/tutorial-dashboard-step3.png'
import dashboardStep4 from '../../images/Tutorial/tutorial-dashboard-step4.png'
import dashboard2Step1 from '../../images/Tutorial/tutorial-dashboard2-step1.png'
import dashboard2Step2 from '../../images/Tutorial/tutorial-dashboard2-step2.png'
import dashboard2Step3 from '../../images/Tutorial/tutorial-dashboard2-step3.png'
import macrosStep1 from '../../images/Tutorial/tutorial-macros-step1.png'
import macrosStep2 from '../../images/Tutorial/tutorial-macros-step2.png'
import macrosStep3 from '../../images/Tutorial/tutorial-macros-step3.png'
import macrosStep4 from '../../images/Tutorial/tutorial-macros-step4.png'
import learningStep1 from 'assets/tour/learning-step1.png'
import exercisesStep1 from 'assets/tour/exercises-step1.png'
import exercisesStep2 from 'assets/tour/exercises-step2.png'
import exercisesStep3 from 'assets/tour/exercises-step3.png'
import exercisesStep4 from 'assets/tour/exercises-step4.png'
import exercisesStep5 from 'assets/tour/exercises-step5.png'
import exercisesStep6 from 'assets/tour/exercises-step6.png'
import exercisesStep7 from 'assets/tour/exercises-step7.png'
import exercisesStep8 from 'assets/tour/exercises-step8.png'
import exercisesStep9 from 'assets/tour/exercises-step9.png'
import exercisesStep10 from 'assets/tour/exercises-step10.png'
import exercisesStep11 from 'assets/tour/exercises-step11.png'
import exercisesStep12 from 'assets/tour/exercises-step12.png'
import exercisesStep13 from 'assets/tour/exercises-step13.png'
import photosStep1 from '../../images/Tutorial/tutorial-photos-step1.png'

export const TutorialContent = [
  {
    Id: 1,
    Title: 'Start',
    Color: '#FF3931',
    Sections: [
      {
        Id: 1,
        Background: backgroundStart,
        Pin: pinStart,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Welcome to the Ketogains Client Portal!',
            Content:
              'We will teach you here how to use the portal. In case you want to review the information later, it is available by clicking the Main Menu and then selecting Tour.',
            Image: startStep1,
          },
          {
            Id: 2,
            Title: 'Main Menu',
            Content:
              'You will be able to consult and review: <br />1) Your enrollment/starting data<br/>2) Security and password<br/>3) Your learning course material<br/>4) Facebook Group<br/>6) Portal Tour',
            Image: startStep2,
          },
          {
            Id: 3,
            Title: 'Profile',
            Content: "Here is where you are able to update your personal data and to review your coach's weekly feedback.",
            Image: startStep3,
          },
          {
            Id: 4,
            Title: 'Quick Access Bar',
            Content: 'This includes the dashboard view, your macros, your training program, as well as your progress photos.',
            Image: startStep4,
          },
        ],
      },
    ],
  },
  {
    Id: 2,
    Title: 'Dashboard',
    Color: '#FF9A00',
    Sections: [
      {
        Id: 1,
        Background: backgroundDashboard,
        Pin: pinDashboard,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Dashboard',
            Content:
              'Here is where you will input your daily information for tracking. You will record your morning weight and Skulpt data (if known), and each evening you will record your macros for the day.',
            Image: dashboardStep1,
          },
          {
            Id: 2,
            Title: 'Save Info',
            Content: "Don't forget to save your changes. You may edit this as many times as you need.",
            Image: dashboardStep2,
          },
          {
            Id: 3,
            Title: 'Progress Review',
            Content:
              'At the bottom of the Dashboard view, you will be able to compare your entry data with your current information to see how you’ve progressed.',
            Image: dashboardStep3,
          },
          {
            Id: 4,
            Title: 'All your measurements',
            Content: 'You can even download it so you can see all your weekly measurements in one screen.',
            Image: dashboardStep4,
          },
        ],
      },
      {
        Id: 2,
        Background: backgroundDashboard2,
        Pin: pinDashboard2,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Update Daily',
            Content: 'In case you skipped or missed entering your measurements in a prior week, you may enter those measurements here.',
            Image: dashboard2Step1,
          },
          {
            Id: 2,
            Title: 'Update Weekly ',
            Content: 'Past weekly entries may be entered here.',
            Image: dashboard2Step2,
          },
          {
            Id: 3,
            Title: 'Weekly Report',
            Content:
              'Once a week, starting on Saturday, you will be requested to complete your weekly report so you can receive feedback from your coach.',
            Image: dashboard2Step3,
          },
        ],
      },
    ],
  },
  {
    Id: 3,
    Title: 'Macros',
    Color: '#4582EB',
    Sections: [
      {
        Id: 1,
        Background: backgroundMacros,
        Pin: pinMacros,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Macros',
            Content: 'In the macros section, you will be able to see your customized macros. You can also download them by clicking on this icon.',
            Image: macrosStep1,
          },
          {
            Id: 2,
            Title: 'Total Calories',
            Content: 'In this column, you can see the total calories for each macronutrient.',
            Image: macrosStep2,
          },
          {
            Id: 3,
            Title: 'Adherence',
            Content: 'We will help you stay focused on achieving your goals by calculating your adherence to your personalized macros.',
            Image: macrosStep3,
          },
          {
            Id: 4,
            Title: 'We have it by day too',
            Content: 'You can see here how closely you’ve adhered to your macros during the last week.',
            Image: macrosStep4,
          },
        ],
      },
    ],
  },
  {
    Id: 4,
    Title: 'Learning',
    Color: '#A978E7',
    Sections: [
      {
        Id: 1,
        Background: backgroundGoal,
        Pin: pinGoal,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Learning',
            Content: 'Here you can access your learning course material.',
            Image: learningStep1,
          },
        ],
      },
    ],
  },
  {
    Id: 5,
    Title: 'Exercises',
    Color: '#42D1D2',
    Sections: [
      {
        Id: 1,
        Background: backgroundExercises,
        Pin: pinExercises,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Exercises',
            Content: 'In the Exercises section you can see and track your training program and select your preferred units of measurement. ',
            Image: exercisesStep1,
          },
          {
            Id: 2,
            Title: 'Your Workouts',
            Content: 'Your routines are presented in a recommended order. You can navigate through them and see the exercises.',
            Image: exercisesStep2,
          },
          {
            Id: 3,
            Title: 'Your Exercises',
            Content:
              'Change the exercises’ order doing drag and drop on each card (depending on device compatibility) or by clicking on the three dots on the left of each exercise and use the arrows.',
            Image: exercisesStep3,
          },
          {
            Id: 4,
            Title: 'Alternative Exercises',
            Content: 'Select the arrows on the right of each exercise to see the alternative exercises.',
            Image: exercisesStep4,
          },
          {
            Id: 5,
            Title: 'Select a Workout',
            Content: 'Once you chose your routine and configured it, you are ready to begin your training.',
            Image: exercisesStep5,
          },
          {
            Id: 6,
            Title: 'Your Training',
            Content:
              'Your exercises are shown in the order you selected. You can use the arrows on the sides of the video to move forward and backward in the routine.',
            Image: exercisesStep6,
          },
          {
            Id: 7,
            Title: 'Info',
            Content:
              'Select the info icon (i) to see the exercise description, preparation, and technique details by week. <br />You can also see the goal of number of sets and reps your coach set for this exercise.',
            Image: exercisesStep7,
          },
          {
            Id: 8,
            Title: 'Your Sets',
            Content: 'You can add or remove sets in this exercise. However, you cannot go under the goal set up by your coach.',
            Image: exercisesStep8,
          },
          {
            Id: 9,
            Title: 'Warm Up Sets',
            Content:
              'Your coach might have included some warmup sets and the warmup icon will be highlighted in orange. You can also select to have a set as warmup by activating the icon.',
            Image: exercisesStep9,
          },
          {
            Id: 10,
            Title: 'Your Reps',
            Content:
              'The system shows you the minimum number of reps you need to complete the goal in the set. You can edit this field typing the actual number of reps you did if they were different from the number given.',
            Image: exercisesStep10,
          },
          {
            Id: 11,
            Title: 'Your Lifting Weight',
            Content:
              'Type in this field the weight you lifted in the set the first time you perform the exercise. The following times, your previous weight will be used as a reference but you can always edit it if it was different.',
            Image: exercisesStep11,
          },
          {
            Id: 12,
            Title: 'Finish a Set / Exercise',
            Content: 'Finish a set and move on to the next set/exercise.',
            Image: exercisesStep12,
          },
          {
            Id: 13,
            Title: 'Complete Your Workout',
            Content: 'Once you have ended your training session, complete your workout to record your progress.',
            Image: exercisesStep13,
          },
        ],
      },
    ],
  },
  {
    Id: 6,
    Title: 'Photos',
    Color: '#77D53E',
    Sections: [
      {
        Id: 1,
        Background: backgroundPhotos,
        Pin: pinPhotos,
        Color: '',
        Steps: [
          {
            Id: 1,
            Title: 'Photos',
            Content:
              'From here, you may review and compare your progress photos <b>after you have completed the first week</b> of the bootcamp. If you need to update photos, you can do so here also.',
            Image: photosStep1,
          },
        ],
      },
    ],
  },
]
