import gql from 'graphql-tag'

export const dayTypes = [{ name: 'Training Day', value: 'train' }, { name: 'Base Day', value: 'base' }]

export const measurementsDisplayMap = [
  {
    id: 'weight',
    name: 'Weight ',
    message: 'Please enter your weight to continue.',
    displayUnit: null,
    required: false,
  },
  {
    id: 'bf_pct',
    name: 'Skulpt Quick Scan',
    message: 'Enter a valid body fat % taken using Skulpt.',
    displayUnit: '%',
    required: false,
  },
  {
    id: 'prot',
    name: 'Protein',
    displayUnit: 'gr.',
    message: 'Please enter protein intake for this day.',
    required: false,
  },
  {
    id: 'carbs',
    name: 'NET Carbs',
    displayUnit: 'gr.',
    message: 'Please enter net carbohydrates intake for this day.',
    required: false,
  },
  {
    id: 'fat',
    name: 'Fat',
    displayUnit: 'gr.',
    message: 'Please enter fat intake for this day.',
    required: false,
  },
]

export const getDailyEntries = gql`
  query getDailyEntries($traineeId: uuid!, $programId: uuid!, $startDate: String!, $endDate: String!) {
    trainee(where: { id: { _eq: $traineeId } }) {
      configuration
      trainee_details(where: { program_id: { _eq: $programId } }) {
        metric
        height
      }
      user {
        gender
      }
      dailyEntries: macro(where: { _and: [{ for_date: { _gte: $startDate } }, { for_date: { _lte: $endDate } }] }) {
        for_date
        day_type
        weight
        bf_pct
        bf_method
        prot
        carbs
        fat
        notes
      }
      lastEntryWithWeight: macro(where: { _and: [{ for_date: { _lt: $startDate } }, { weight: { _gt: 0 } }] }, order_by: { for_date: desc }) {
        for_date
        weight
        bf_pct
      }
      measurement(where: { for_date: { _lte: $endDate } }, order_by: { for_date: desc }, limit: 1) {
        id
        neck
        belly_waist
        narrow_waist
        hip
      }
      macros(where: { _and: [{ program_id: { _eq: $programId } }, { status: { _eq: "Approved" } }] }) {
        macro_tables {
          type
          protein_total_grams
          carbs_total_grams
          fat_total_grams
        }
      }
    }
  }
`

export const upsertMacroEntry = gql`
  mutation upsert_macro_entry($data: macro_entry_insert_input!) {
    insert_macro_entry(
      objects: [$data]
      on_conflict: { constraint: trainee_date, update_columns: [carbs, weight, bf_pct, bf_method, prot, fat, day_type, notes] }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const getTimelineStats = gql`
  query getTimelineStats($traineeId: uuid!, $startDate: String!) {
    trainee(where: { id: { _eq: $traineeId } }) {
      snooze_timeline
      measurement_aggregate(where: { for_date: { _gte: $startDate } }) {
        aggregate {
          count
        }
      }
      macro_aggregate(where: { for_date: { _gte: $startDate } }) {
        aggregate {
          count
        }
      }
    }
  }
`

export const updateSnooze = gql`
  mutation updateSnooze($traineeId: uuid!, $snoozeTimeline: date) {
    update_trainee(where: { id: { _eq: $traineeId } }, _set: { snooze_timeline: $snoozeTimeline }) {
      returning {
        id
        snooze_timeline
      }
    }
  }
`
