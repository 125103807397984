import React, { useEffect, useState } from 'react'
import { ReactComponent as InputError } from 'assets/icons/input-error.svg'
import { ReactComponent as InputSuccess } from 'assets/icons/input-success.svg'
import './index.scss'

const MaterializeInput = ({ id, label, type, maxLength, suffix, style, isValid, className, value, onChange, disabled }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [updateText, setUpdateText] = useState()
  const [paddingStyle, setPaddingStyle] = useState()

  useEffect(() => {
    if (value) {
      const inputElement = document.getElementById(label || id)
      const suffixElement = document.getElementById(`my-suffix-${label || id}`)
      updateSuffix(inputElement, suffixElement)
    }
  }, [updateText])

  function updateSuffix(inputElement, suffixElement) {
    const width = getTextWidth(inputElement.value, '12px arial')
    suffixElement.style.left = width + 'px'
  }

  const validateNumbers = (e) => {
    let key = e.keyCode || e.which
    key = String.fromCharCode(key)
    let regex = /[0-9]|\./
    if (!regex.test(key)) {
      e.returnValue = false
      if (e.preventDefault) e.preventDefault()
    }
  }

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    let max
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
    var context = canvas.getContext('2d')
    context.font = font
    context.fillText('width:' + context.measureText(text).width, 10, 50)
    var metrics = context.measureText(text)
    let content = document.getElementById('mat-div')
    let suffix = document.getElementById(`my-suffix-${label || id}`)
    let icon = document.getElementById('wrap-icons')
    max = content.offsetWidth - suffix.offsetWidth - icon.offsetWidth - 7
    setPaddingStyle(suffix.offsetWidth + icon.offsetWidth + 7)
    if (metrics.width >= max) {
      metrics = max
    }
    return metrics.width
  }

  return (
    <div className={`${className ? className : ''} ${disabled ? 'disabled' : ''} wrap`}>
      <div id="mat-div" className={`${isFocused || value ? 'is-active is-completed' : ''} mat-div`}>
        <label htmlFor="first-name" className="mat-label">
          {label}
        </label>
        <input
          id={label || id}
          type={type}
          inputMode={type === 'number' ? 'decimal' : 'text'}
          className={`mat-input`}
          maxLength={maxLength}
          style={{ paddingRight: `${paddingStyle}px`, MozAppearance: 'textfield' }}
          value={value === null ? undefined : value}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={(e) => {
            if (e.target.value === '') {
              setIsFocused(false)
            }
          }}
          onKeyUp={(e) => setUpdateText(e.target.value)}
          onChange={onChange}
          disabled={disabled}
          autoComplete="off"
          onKeyPress={type === 'number' ? (e) => validateNumbers(e) : null}
        />
        {!!value && (
          <span id={`my-suffix-${label || id}`} className="suffix">
            {suffix}
          </span>
        )}
      </div>
      <span id="wrap-icons" className="wrap-icon-feedback">
        {isValid === false && (
          <>
            {' '}
            <InputError className="icon-feedback" />
          </>
        )}
        {!!isValid && (
          <>
            {' '}
            <InputSuccess className="icon-feedback" />
          </>
        )}
      </span>
    </div>
  )
}

export default MaterializeInput
