import styled from '@emotion/styled'

export const ContainerCarouselSets = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .wrap-message {
    position: absolute;
    z-index: 1;
    top: 80px;
    width: 100%;
  }
  .container-carousel-sets {
    max-width: 400px;
    height: 63px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: 2px solid rgba(0, 0, 0, 0.08);

    & div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      & div {
        width: 44px;
        height: 46.06px;
        margin-right: 6px;
        background: rgba(215,215,215,1);

        & img {
          width: 44px;
          height: 46.06px;
          margin-right: 6px;
          object-fit: cover;
        }
      }      
    }
  }

  .container-carousel-sets:before {
    content: '+${({ countLeft }) => countLeft}';
    position: absolute;
    top: 8px;
    left: 50px;
    width: 44px;
    height: 46.05px;
    background: rgba(0,0,0,0.3);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background: rgba(0,0,0, );
    visibility: ${({ countLeft }) => (countLeft <= 0 ? 'hidden' : 'visible')};
  }

  .container-carousel-sets:after {
    content: '+${({ count }) => count}';
    position: absolute;
    top: 8px;
    right: 56px;
    width: 44px;
    height: 46.05px;
    background: rgba(0,0,0,0.4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${({ count }) => (count <= 0 ? 'hidden' : 'visible')};
  }

  .progress-bar {
    position: absolute;
    left: ${({ progressBar }) => progressBar}px;
    width: 100%;
    height: 40px;
    background: orange;
  }

  @media screen and (max-width: 375px) and (min-width: 321px) {
    .container-carousel-sets:before {
      left: 40px;
    }
    .container-carousel-sets:after {
      right: 40px;
    }

  }

  @media screen and (max-width: 320px) {
    .container-carousel-sets:before {
      left: 13px;
    }
    .container-carousel-sets:after {
      right: 13px;
    }
  }
`

export const Preview = styled('div')`
  .carousel-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    .previous {
      position: absolute;
      left: 3px;
      cursor: pointer;

      g > circle {
        fill: ${({ countLeft }) => (countLeft > 0 ? '#FF6B00' : 'gray')};
      }
    }

    .next {
      position: absolute;
      right: 3px;
      cursor: pointer;

      g > circle {
        fill: ${({ count }) => (count > 0 ? '#FF6B00' : 'gray')};
      }
    }

    & circle {
      width: 26px;
      height: 26px;
      &:active {
        fill: black;
      }
    }
  }

  .preview {
    max-width: 311px;
    height: 100%;
    border-top: 9px solid #ff6b00;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 16px solid #ff6b00;
      position: absolute;
      top: -8px;
      left: ${({ pointer }) => pointer}px;
    }

    & img {
      width: 311px;
      height: 187px;
      border-bottom-left-radius: 2%;
      border-bottom-right-radius: 2%;
    }

    & video {
      width: 311px;
      height: 187px;
      border-bottom-left-radius: 2%;
      border-bottom-right-radius: 2%;
    }
  }
`

export const ContainerImg = styled('div')`
  & img {
    opacity: ${({ complete }) => (complete ? 1 : 0.5)};
  }
`
