import React from 'react'
import { Reports } from './ReportsProgress.styled'

const ReportProgress = ({ image, title, text, color, grad, pseudo, lastbutton, showArrows, clickButton, styleContent, colorText }) => {
  return (
    <>
      <Reports color={color} grad={grad} pseudo={pseudo} lastbutton={lastbutton} colortext={colorText}>
        <div className={`container`}>
          <img src={image} alt={title} />
        </div>
        <div className="content" style={styleContent}>
          <p className={`title`} style={{ lineHeight: '17px' }}>
            {title}
          </p>
          <p className={`text`} style={{ marginBottom: '0px' }}>
            {text}
          </p>
        </div>
        {showArrows ? <div className={`button`} onClick={clickButton} /> : null}
      </Reports>
    </>
  )
}

export default ReportProgress
