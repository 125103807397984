import React from 'react'
import { Message } from './MessageSelectExercise.styled'
import { ReactComponent as Arrows } from '../../../../images/arrows.svg'

const MessageSelectExercise = () => {
  return (
    <Message>
      <div>
        <span>
          Select <Arrows /> on each exercise to see alternative exercises
        </span>
      </div>
    </Message>
  )
}

export default MessageSelectExercise
