import React, { Component } from 'react'
import { getCookie } from 'tiny-cookie'
import { navigate } from '@reach/router'
import { Button, Spin, Result } from 'antd'
import { Root } from './Cronometer.styled'
import { LOCAL_STORAGE, COOKIE } from 'constants.js'

const queryString = require('querystring')

class CronometerInfo extends Component {
  state = {
    syncStatus: 'processing',
  }

  generateToken = (code, traineeId) => {
    let generateTokeURI = `${process.env.REACT_APP_API_URL}cronometer`
    fetch(generateTokeURI, {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: JSON.stringify({
        action: 'TokenExchange',
        cronometerObject: {
          traineeId: traineeId,
          code,
        },
      }),
    })
      .then(function(response) {
        return response.text()
      })
      .then((data) => {
        data = JSON.parse(data)
        if (!!data.data && !!data.data.hasError) {
          this.setState({ syncStatus: 'error', errorMessage: `${data.message}: ${data.data.message}`, showErrorButtons: true })
        } else {
          this.setState({ syncStatus: 'success', successTitle: 'Cronometer account synchronized successfully!' }, () => {
            setTimeout(() => {
              navigate('/')
            }, 2000)
          })
        }
      })
      .catch((error) => {
        this.setState({ syncStatus: 'error', errorMessage: error.message, showErrorButtons: true })
      })
  }

  unsyncCronometer = (traineeId) => {
    let generateTokeURI = `${process.env.REACT_APP_API_URL}cronometer`
    fetch(generateTokeURI, {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: JSON.stringify({
        action: 'Deauthorization',
        cronometerObject: {
          traineeId,
        },
      }),
    })
      .then(function(response) {
        return response.text()
      })
      .then((data) => {
        data = JSON.parse(data)
        if (!!data.data && !!data.data.hasError) {
          this.setState({ syncStatus: 'error', errorMessage: `${data.message}: ${data.data.message}`, showErrorButtons: false })
        } else {
          this.setState({ syncStatus: 'success', successTitle: 'Cronometer account disconnected successfully!' }, () => {
            setTimeout(() => {
              navigate('/')
            }, 3000)
          })
        }
      })
      .catch((error) => {
        this.setState({ syncStatus: 'error', errorMessage: error.message, showErrorButtons: false })
      })
  }

  componentDidMount() {
    const { result } = this.props

    if (result) {
      const parameterValues = queryString.parse(this.props.location.search.replace('?', ''))

      if (!!parameterValues) {
        let traineeId = localStorage.getItem(LOCAL_STORAGE.TRAINEE_ID)
        if (!!parameterValues.code) {
          this.generateToken(parameterValues.code, traineeId)
        } else if (!!parameterValues.action) {
          this.unsyncCronometer(traineeId)
        } else {
          this.setState({ syncStatus: 'not-received' })
          setTimeout(() => {
            navigate('/')
          }, 5000)
        }
      } else {
        this.setState({ syncStatus: 'not-received' })
        setTimeout(() => {
          navigate('/')
        }, 5000)
      }
    }
  }

  render() {
    const { result } = this.props
    const { syncStatus } = this.state
    const cronometerAuthorizationLink = `https://cronometer.com/oauth/authorize?response_type=code&client_id=KmSiXqPVlUvZMQ&redirect_uri=${process.env.REACT_APP_DOMAIN}/cronometer/result`

    return (
      <Root>
        <div className="banner" />
        {result && (
          <>
            <h3>Cronometer Synchronization</h3>
            <div className="process">
              <Spin spinning={syncStatus === 'processing'} size="large" tip="Please wait...">
                {syncStatus !== 'processing' && (
                  <div>
                    {(() => {
                      switch (syncStatus) {
                        case 'success':
                          return <Result status="success" title={this.state.successTitle} subTitle="You will be redirected..." />
                        case 'error':
                          return (
                            <Result
                              status="error"
                              title="Oh! an error occurred!"
                              subTitle="We couldn't synchronize your Cronometer account."
                              extra={
                                this.state.showErrorButtons
                                  ? [
                                      <Button href={cronometerAuthorizationLink} className="primary">
                                        Retry Sync
                                      </Button>,
                                      <Button onClick={() => navigate('/')}>Cancel</Button>,
                                    ]
                                  : null
                              }
                            >
                              {this.state.errorMessage && (
                                <>
                                  <p>
                                    <b>Error detail</b>
                                  </p>
                                  <p>{this.state.errorMessage}</p>
                                </>
                              )}
                            </Result>
                          )
                        case 'not-received':
                        default:
                          return <Result status="warning" title="We don't receive a result" subTitle="You will be redirected..." />
                      }
                    })()}
                  </div>
                )}
              </Spin>
            </div>
          </>
        )}
        {!result && (
          <>
            <h3>Now you can import your data from Cronometer!</h3>
            <div className="information">
              <p>By agreeing to this authorization, Ketogains will be able to import your daily macros and nutritional data from Cronometer.</p>
              <p>You are able to revoke this permission anytime you want by clicking the On Sync button.</p>
              <p>
                Otherwise, your macros will be imported on a daily basis after midnight (US Central Time). You will have to “mark day complete” in
                your Cronometer account, so we can be sure you are done entering data that day.
              </p>
              <p>
                If you choose to sync now, and you then enter your macros manually in the Ketogains Portal, they will be overwritten during the
                automation process.
              </p>
            </div>
            <Button href={cronometerAuthorizationLink} className="primary">
              Sync my account
            </Button>
            <Button onClick={() => navigate('/')}>Cancel</Button>
            <div className="create-account">
              <p>You don’t have a Cronometer account?</p>
              <p>
                <a href="https://cronometer.com/signup/" target="_blank" rel="noopener noreferrer">
                  Get started absolutely free
                </a>
              </p>
            </div>
          </>
        )}
      </Root>
    )
  }
}

export default CronometerInfo
