import styled from '@emotion/styled'
import HeaderBanner from 'images/banner-exercises.png'
import ReportsBanner from '../../../../assets/icons/bannerReports.svg'

export const CstBanner = styled.header`
  background-image: url(${({ reports }) => (reports ? ReportsBanner : HeaderBanner)}), linear-gradient(270deg, #ffa400 2.12%, #ff6b00 90.61%);
  ${(reports) => reports && 'background-size: cover;'}
  display: flex;
  flex-direction: column;
  height: 202px;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 48px;

  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
    `}

  .sizeAvatar {
    border: 2px solid;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 42px;
  }
`
export const CstP = styled.p`
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;

  .title {
    font-size: 22px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
