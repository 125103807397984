import React from 'react'
import { Query } from 'react-apollo'
import { getProgramStatuses } from './querys'
import { ContentCard } from './Homepage.styled'
import lifestyle from 'assets/icons/enrollment/lifestyle.png'
import mealPlanEnrollment from 'assets/icons/meal-plan/meal-plan-enrollment.png'

import SectionStatus from './SectionStatus'

const ProgramPending = (props) => {
  const { programs, traineeId } = props

  return (
    <Query query={getProgramStatuses} fetchPolicy="cache-and-network" variables={{ traineeId, programId: programs.program_id }}>
      {({ loading, error, data }) => {
        if (loading) return null
        if (error) return null
        if (data) {
          const { trainee_program_coach } = data
          const macro = trainee_program_coach[0].macro
          const mealPlan = trainee_program_coach[0].meal_plan
          let macroStatus = {}
          let mealPlanStatus = {}

          console.log(trainee_program_coach)
          if (programs.type === 'macros') {
            if (programs.is_enrollment_completed && macro.status === 'PendingToApprove') {
              macroStatus = {
                image: lifestyle,
                text: 'We are working on your macros!',
              }
            } else if (programs.is_enrollment_completed && macro.status === 'Approved') {
              macroStatus = {
                image: lifestyle,
                text: 'Your macros are ready!',
                actionLink: '/my-macros',
                actionText: 'View',
              }
            } else {
              macroStatus = {
                image: lifestyle,
                text: 'We are working on your macros!',
              }
            }
          }

          if (mealPlan) {
            if (mealPlan.status === 'EnrollmentCompleted') {
              mealPlanStatus = {
                image: mealPlanEnrollment,
                text: 'We are working on your meal plan!',
              }
            } else if (mealPlan.status === 'MealPlanCompleted' && mealPlan.url) {
              mealPlanStatus = {
                image: mealPlanEnrollment,
                text: 'Your meal plan are ready!',
                actionLink: '/meal-plan',
                actionText: 'View',
              }
            } else {
              mealPlanStatus = {
                image: mealPlanEnrollment,
                text: 'We are working on your meal plan!',
              }
            }
          }

          return (
            <ContentCard>
              <div className="content">
                {programs.type === 'macros' && (
                  <div>
                    <SectionStatus {...macroStatus} />
                    <br />
                    {!!programs.extras && programs.extras.meal_plan ? <SectionStatus {...mealPlanStatus} /> : null}
                  </div>
                )}
                {programs.type === 'meal_plan' && (
                  <div>
                    <SectionStatus {...mealPlanStatus} />
                  </div>
                )}
              </div>
            </ContentCard>
          )
        }
      }}
    </Query>
  )
}

export default ProgramPending
