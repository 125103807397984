import styled from '@emotion/styled'
import { Form, Button } from 'antd'

const FormItem = Form.Item

export const Wrap = styled.div`
  font-family: 'Montserrat', sans-serif;
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
`

export const Content = styled.div`
  background-color: #fbfbfb;
  border-radius: 4px;

  .content {
    padding: 4px 8px 18px 8px;

    .subscription {
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.147454);
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr 65px;
      align-items: center;

      &.blocked {
        opacity: 0.7;
      }

      p {
        margin: 0;
        font-size: 12px;

        &:first-child {
          font-size: 14px;
        }
      }

      button {
        margin: 0;
      }
    }
  }
`

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 10px;
  background-color: #f2f2f2;
  .ant-form-item-control-wrapper {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 500;

    .input-with-fake-label {
      .ant-input-group-addon:first-of-type {
        text-align: left;
        display: inline-block;
        width: 49.933333%;
        font-size: 12px;
        padding: 0;
        color: rgba(0, 0, 0, 0.85) !important;
      }

      input {
        width: 49.166667%;
        max-width: 83px;
        float: right;
        display: inline-block;
      }
    }

    .ant-form-explain {
      padding-top: 5px;
      font-size: 9px;
      font-weight: 600;
      text-align: left;
      color: #5d5d5d !important;
      .warning-cont {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-container {
          width: 20%;
          font-size: 18px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #faad14;
        }
      }
    }
  }
  .ant-form-item-label {
    flex-grow: 1;
    label {
      display: block;
      margin-left: 5px;
      color: #4a4a4a;
      font-size: 12px;
      text-align: left;
    }
  }
`

export const DownloadBottom = styled(Button)`
  &.ant-btn {
    background: #ff4613;
    border: 0;
    border-radius: 50px;
    color: #fff;
    display: block;
    font-family: 'Montserrat', sans-serif;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    outline: none;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
      background: #c62b00;
      box-shadow: 0 0 4px 2px #d4d4d4;
      cursor: pointer;
    }
    span {
      color: #fff;
      font-size: 12px;
      line-height: 15px;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
`
