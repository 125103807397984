import React from 'react'
import { DropdownContainer } from './BootcampSelect.styled'
import { StyledDropdown } from 'styled'

const BootcampSelect = (props) => {
  const { bootcamps, onBootcampSelect, value } = props

  if (!bootcamps || bootcamps.length === 0) return null

  let bootcampSelected

  if (value) {
    bootcampSelected = bootcamps.find((b) => b.id === value)
  }
  if (!bootcampSelected) {
    bootcampSelected = bootcamps[0]
  }

  let title = 'Select your Bootcamp'
  if (props.title) title = props.title

  const handleSelect = (value) => {
    if (typeof onBootcampSelect === 'function') {
      onBootcampSelect(value)
    }
  }

  return (
    <DropdownContainer>
      <p className="title">{title}</p>
      <StyledDropdown
        label={bootcampSelected.label}
        description={bootcampSelected.isCurrent ? 'Current Bootcamp' : 'Upcoming Bootcamp'}
        onSelect={handleSelect}
        options={bootcamps}
        className="vertical"
      />
    </DropdownContainer>
  )
}

export default BootcampSelect
