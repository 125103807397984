import { DatePicker } from 'antd'
import styled from '@emotion/styled'

export default styled(DatePicker)`
  &.ant-calendar-picker {
    width: 100% !important;
    &:hover {
      border-color: #5d5d5d;
    }
    &:focus,
    &:active {
      border-color: #5d5d5d;
      box-shadow: 0 0 3px 0 #5d5d5d;
    }
  }
  &.ant-calendar-picker .ant-calendar-picker-input {
    height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    line-height: 35px;
    &:hover {
      border-color: #5d5d5d;
    }
    &:focus {
      border-color: #5d5d5d;
      box-shadow: 0 0 3px 0 #5d5d5d;
    }
  }
  &.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #5d5d5d;
  }
`
