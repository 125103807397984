import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  width: 100%;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 60px;
  ${({ disabledFooter }) => {
    if (disabledFooter) {
      return css`
        && {
          padding-bottom: 0;
        }
      `
    }
  }}
`
export const Main = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
`
export const Footer = styled.div`
  height: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
`

export const ImpersonateIndicator = styled.div`
  position: fixed;
  width: 100%;
  bottom: 58px;
  text-align: center;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 16px;
`
