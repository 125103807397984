import React from 'react'
import { SectionCardContainer } from './Cards.styled'

const SectionCard = (props) => {
  return (
    <SectionCardContainer {...props}>
      <div className="icon">
        <img src={props.image} alt={props.title} />
      </div>
      <div className="content">
        <p className="title">{props.title}</p>
        <p className="text">{props.text}</p>
      </div>
    </SectionCardContainer>
  )
}

export default SectionCard
